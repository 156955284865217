import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionType,
  MetricMessage,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { MetricName, MetricResult, Operation } from '@bridge/types/MetricTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { DimensionSet } from '@core/metrics/types';

export class MetricPublishRequestHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor(logger: ILogger, metrics: IMetrics) {
    this.logger = logger;
    this.metrics = metrics;
  }

  handleMessage(
    msg: RTCChannelMessage,
    callback?: OnSuccessRTCCallback | undefined
  ): void {
    try {
      this.validate(msg);
      const metricPayload = msg.Payload as MetricMessage;
      if (metricPayload.MetricResult) {
        if (metricPayload.MetricResult === 'UnhandledException') {
          this.metrics.emitUnhandledExcpetion(
            metricPayload.Operation as Operation,
            this.generateError(metricPayload)
          );
          this.logger.info(
            `Error processing:: ${this.generateError(metricPayload)}`
          );
        } else {
          this.metrics.emit(
            metricPayload.Operation as Operation,
            metricPayload.MetricResult as unknown as MetricResult,
            this.generateError(metricPayload)
          );
        }
      } else if (metricPayload.MetricName) {
        this.metrics.emitWithValue(
          metricPayload.Operation as Operation,
          metricPayload.MetricName as MetricName,
          metricPayload.Value,
          metricPayload.Dimensions as unknown as DimensionSet
        );
      }
    } catch (error) {
      this.logger.error(
        `Error processing native client metric:: ${JSON.stringify(msg)}`
      );
      this.handleException(msg, error);
    }
  }

  private generateError(metric: MetricMessage) {
    if (!metric.Exception) {
      return null;
    }
    const error = new WsError(
      WsErrorTypes.ERROR_TYPE_UNKNOWN,
      WsErrorCodes.ERROR_UNKNOWN,
      metric.Exception.ErrorCode as unknown as ClientErrorCode,
      metric.Exception.ErrorReason,
      undefined,
      metric.Exception.StackTrace as string
    );
    error.message = metric.Exception.ErrorMessage;
    error.name = metric.Exception.ErrorType;
    return error;
  }

  handleException(msg: RTCChannelMessage, error: any) {
    const wsError = new WsError(
      WsErrorTypes.ERROR_TYPE_UNKNOWN,
      WsErrorCodes.ERROR_UNKNOWN,
      ClientErrorCode.MetricPublishInvalidInput
    );
    wsError.name = error?.name;
    wsError.message = error?.message + ' Payload: ' + JSON.stringify(msg);
    this.metrics.emit(
      Operation.MetricPublishRequest,
      MetricResult.Fault,
      wsError
    );
  }

  private validate(msg: RTCChannelMessage) {
    const metric = msg.Payload as MetricMessage;
    if (
      msg.Action !== Action.PUBLISH_METRIC ||
      msg.ActionType !== ActionType.REQUEST ||
      !metric.Operation
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_UNKNOWN,
        WsErrorCodes.ERROR_UNKNOWN,
        ClientErrorCode.MetricPublishInvalidInput
      );
    }
  }
}
