/**
 * Left all metric and error handling related code commited.
 * Will handle them when fixing error module and when improving metric module
 * Logger module also need some updates
 * TODO: fix above issues
 */
import { IStxhd, loadStxRtcClient } from '@/streaming/pcoip/StxRtcClientLoader';
import {
  IWorkSpacesInputAdapter,
  WorkSpacesInputAdapter,
} from '@/streaming/pcoip/WorkSpacesInputAdapter';
import {
  ISessionStreamer,
  OnConnectedFn,
  OnFailedFn,
  OnPendingFn,
} from '@bridge/ISessionStreamer';
import { ILogger } from '@bridge/ILogger';
import { WsError, WsErrorCodes, WsErrorTypes } from '@bridge/WsError';
import { getAvailableWorkArea, isFullScreen } from '@views/Stream/utils';
import {
  ITimedMetricOperation,
  MetricResult,
  Operation,
  STREAMING_ON,
  STREAMING_ON_OPTIONS,
} from '@bridge/types/MetricTypes';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { IPcoIPWebContext } from '@/bridge/types/SessionTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

const metrics = CoreFactory.getMetrics();
const store = CoreFactory.getPersistentStorage();

enum States {
  kStateNew,
  kStatePeerSignalerConnecting,
  kStateAwaitingHostCapabilities,
  kStateAwaitingSelectedHostConfiguration,
  kStateAwaitingAppliedHostConfiguration,
  kStatePeerConnectionConnecting,
  kStatePeerConnectionConnected,
  kStateDisconnecting,
  kStateStopped,
}

const statesToName = (state: States) => Object.keys(States)[state];

const CAPABILITIES_EXCHANGE_VERSIONS = [1, 2];
const CAPABILITIES_EXCHANGE_VERSION = 2;

const VERSION_FIELD = 'version';
const PEER_CONNECTION_CONFIG_FIELD = 'peerConnectionConfig';
const PEER_NAME_FIELD = 'peerName';
const KEYBOARD_TYPE_FIELD = 'keyboardType';
const PRIMARY_MONITOR_MEDIA_STREAM_FIELD = 'primaryMonitor-mediaStream';

export const CTRL_KEY_CODE = 17;
export const ALT_KEY_CODE = 18;
export const DEL_KEY_CODE = 46;

const STXHD_LOAD_TIMEOUT = 5000; // 5 sec
const STXHD_LOAD_INTERVAL = 1000; // 1 sec

const ConnectionError = WsError.withType(WsErrorTypes.ERROR_TYPE_CONNECTION);

export class PcoipSessionStreamer implements ISessionStreamer {
  private readonly logger: ILogger;

  // connection instances
  private stxhd?: IStxhd;
  private peerSignaler: any;
  private peerConnection: any;
  private remoteMediaStream: any;
  private localInputStream: any;
  private config: any;
  private sessionContext?: IPcoIPWebContext;
  private stxhdLoadTime: number = 0;

  // states
  private state: States = States.kStateNew;
  private userInitiatedDisconnect: boolean = false;
  private connected: boolean = false;
  private streamingSubmitted: boolean = false;
  private peerConnected: boolean = false;
  private keyboardType?: string;

  // ui elements
  private inputElement?: HTMLDivElement;
  private videoElement?: HTMLVideoElement;

  // customized handlers
  private onConnected?: OnConnectedFn;
  private onFailed?: OnFailedFn;
  private onPending?: OnPendingFn;

  // ui adapter
  private inputAdapter?: IWorkSpacesInputAdapter | null;

  // metrics
  private timeMetricObjectSignal: ITimedMetricOperation | undefined;
  private timeMetricObjectStream: ITimedMetricOperation | undefined;

  constructor(logger: ILogger) {
    loadStxRtcClient().then((stxhd) => {
      this.stxhd = stxhd;
    });
    this.logger = logger;
  }

  streamWorkspace({
    sessionContext,
    inputElement,
    videoElement,
    onConnected,
    onFailed,
    onPending,
  }: any) {
    this.sessionContext = sessionContext;
    this.inputElement = inputElement;
    this.videoElement = videoElement;
    this.onConnected = onConnected;
    this.onFailed = onFailed;
    this.onPending = onPending;

    this.stxhdLoadTime = 0;
    this.connect();
  }

  private connect() {
    if (!this.stxhd) {
      if (this.stxhdLoadTime > STXHD_LOAD_TIMEOUT) {
        throw ConnectionError(WsErrorCodes.ERROR_PCOIP_LOADING_TIMEOUT);
      }
      this.stxhdLoadTime += STXHD_LOAD_INTERVAL;
      setTimeout(this.connect.bind(this), STXHD_LOAD_INTERVAL);
      return;
    }

    this.setupLogging();
    this.configurePeerSignaler(this.sessionContext?.StxHDCredentials);
    this.configurePeerConnection();
    this.startSignaling();
  }

  changeResolution() {
    if (this.config && this.peerConnected) {
      this.selectHostConfiguration(this.config);
      this.peerConnection.flushMessages();
    } else {
      this.logger.error('Error while changing resolution. ');
    }
  }

  disconnect(userInitiated: boolean = false) {
    this.logger.info(`Disconnecting.  User initiated: ${userInitiated}`);

    this.userInitiatedDisconnect = userInitiated;

    if (this.inputAdapter != null) {
      this.inputAdapter.unbindEvents();
      this.inputAdapter = null;
    }
    if (this.peerSignaler) {
      this.peerSignaler.disconnect();
      this.peerSignaler = null;
    }
    if (this.peerConnection) {
      this.peerConnection.disconnect();
      this.peerConnection = null;
    }
    if (this.videoElement != null) {
      this.videoElement.pause();
      this.videoElement.src = '';
    }

    this.connected = false;
  }

  sendCtrlAltDel() {
    this.logger.info('Sending ctrl+alt+del');

    // down
    this.inputAdapter?.sendKey(CTRL_KEY_CODE, true);
    this.inputAdapter?.sendKey(ALT_KEY_CODE, true);
    this.inputAdapter?.sendKey(DEL_KEY_CODE, true);
    // up
    this.inputAdapter?.sendKey(DEL_KEY_CODE, false);
    this.inputAdapter?.sendKey(ALT_KEY_CODE, false);
    this.inputAdapter?.sendKey(CTRL_KEY_CODE, false);
  }

  private handleFailed(
    operation: Operation,
    wsErrorCode: WsErrorCodes,
    reason?: any,
    description?: any,
    exception?: any
  ) {
    // When no reason is passed, used wsErrorCode itself for metrics
    reason = reason ?? wsErrorCode;
    const wsError = this.buildException(wsErrorCode, reason, description);
    if (exception) {
      wsError.setInnerException(exception);
    }
    metrics.emit(operation, MetricResult.Fault, wsError);
    this.onFailed?.(wsError);
  }

  // Binds StxHD's logger to our logger service
  // so that all log calls are handled by our service
  private setupLogging() {
    try {
      const stxLogger = this.stxhd?.getLogger();
      stxLogger.setLevel(2); // TODO: In angular we got it from logger module this.logger.VERBOSE
      // stxLogger.onLog = this.logger.log; // TODO: we don't have a log method in our logger. Should we improve our logger?
      // FIXME: below is a temp solution. Need improvement
      stxLogger.onLog = (level: any, tag: any, message: any) => {
        this.logger.info(`level: ${level}, tag: ${tag}, message: ${message}`);
      };
    } catch (error) {
      this.logger.error(`Error setting up logging: ${error}`);
    }

    this.logger.info(
      `browser info: ${JSON.stringify(this.stxhd?.getBrowserStats())}`
    );
  }

  /// ////////////////////////////////////////////////
  // Signaling handlers
  /// ////////////////////////////////////////////////

  // called while connecting to the signaling server
  private configurePeerSignaler(credentials: unknown) {
    try {
      this.peerSignaler = this.stxhd?.createPeerSignaler(credentials);

      this.peerSignaler.onConnecting = this.peerSignalerOnConnecting.bind(this);
      this.peerSignaler.onFailed = this.peerSignalerOnFailed.bind(this);
      this.peerSignaler.onConnected = this.peerSignalerOnConnected.bind(this);
      this.peerSignaler.onPeerDisconnected =
        this.peerSignalerOnPeerDisconnected.bind(this);
      this.peerSignaler.onUtf8Message =
        this.peerSignalerOnUtf8Message.bind(this);
      this.peerSignaler.onBinaryMessage =
        this.peerSignalerOnBinaryMessage.bind(this);
      this.peerSignaler.onDisconnected =
        this.peerSignalerOnDisconnected.bind(this);
    } catch (ex) {
      this.logger.error(`Error creating peer signaler: ${ex}`);
      this.handleFailed(
        Operation.SSIG,
        WsErrorCodes.ERROR_SIG_CREATE_FAILED,
        null,
        null,
        ex
      );
    }
  }

  private peerSignalerOnConnecting() {
    this.logger.info('Connecting PeerSignaler...');
  }

  // called when the connection to the signaling server fails
  // TODO: call a state update method to update UI state with error
  private peerSignalerOnFailed(reason: any) {
    this.logger.error(
      'Failed to connect to PeerSignaler with reason: ' + reason
    );

    this.peerSignaler.disconnect();
    this.handleFailed(Operation.SSIG, WsErrorCodes.ERROR_SIG_FAILED, reason);
  }

  // called when the signaling server connection has succeeded
  private peerSignalerOnConnected() {
    this.logger.info('peerSignaler.onConnected().');

    clearTimeout(this.peerSignaler.connectTimeout);
  }

  // called when the connection to the signaling server has been terminated
  private peerSignalerOnPeerDisconnected(reason: any) {
    this.logger.info("PeerSignaler's peer disconnected with reason: " + reason);
  }

  private peerSignalerOnUtf8Message(message: any) {
    this.logger.info('Peer Signaler onUtf8Message(): ' + message);
  }

  private peerSignalerOnBinaryMessage(message: any) {
    this.logger.info(
      `Peer Signaler onBinaryMessage() with length: ${message.byteLength}`
    );
  }

  private peerSignalerOnDisconnected(reason: any, description: any) {
    this.logger.info(
      `Peer Signaler onDisconnected() with reason: ${reason}, description: ${description}`
    );
    store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
    this.peerConnectionOnFailed(reason);
  }

  /// ////////////////////////////////////////////////
  // peerConnection handlers
  /// ////////////////////////////////////////////////

  // Creates and configures the PeerConnection object as well as it's handlers
  private configurePeerConnection() {
    try {
      this.peerConnection = this.stxhd?.createPeerConnection(this.peerSignaler);

      this.peerConnection.onSignalingConnecting =
        this.peerConnectionOnSignalingConnecting.bind(this);
      this.peerConnection.onSignalingConnected =
        this.peerConnectionOnSignalingConnected.bind(this);
      this.peerConnection.onConnected =
        this.peerConnectionOnConnected.bind(this);
      this.peerConnection.onDisconnecting =
        this.peerConnectionOnDisconnecting.bind(this);
      this.peerConnection.onDisconnected =
        this.peerConnectionOnDisconnected.bind(this);
      this.peerConnection.onFailed = this.peerConnectionOnFailed.bind(this);
      this.peerConnection.onUtf8Message =
        this.peerConnectionOnUtf8Message.bind(this);
      this.peerConnection.onBinaryMessage =
        this.peerConnectionOnBinaryMessage.bind(this);
      this.peerConnection.onAddRemoteMediaStream =
        this.peerConnectionOnAddRemoteMediaStream.bind(this);
      this.peerConnection.onAddDataChannel =
        this.peerConnectionOnAddDataChannel.bind(this);

      this.localInputStream =
        this.peerConnection.createLocalInputStream('TestInputStream');
    } catch (ex) {
      this.logger.error(`Error creating peer connection: ${ex}`);
      this.handleFailed(
        Operation.Connect,
        WsErrorCodes.ERROR_PC_CREATE_FAILED,
        null,
        null,
        ex
      );
    }
  }

  // utility function to bind the input to the video
  // container provided that the other peer is connected
  // and attached the remote media stream
  private bindInputs() {
    if (!this.peerConnected) {
      this.logger.info('!peerConnected, not yet ready for binding input');
      return;
    }

    if (!this.remoteMediaStream) {
      this.logger.info(
        'remoteMediaStream is not ready yet, cannot bind input events'
      );
      return;
    }

    if (
      this.inputAdapter == null &&
      this.inputElement != null &&
      this.videoElement != null
    ) {
      this.logger.info('Creating inputAdapter');

      this.inputAdapter = WorkSpacesInputAdapter(
        this.inputElement,
        this.localInputStream,
        this.remoteMediaStream,
        this.videoElement,
        this.logger
      );

      this.inputAdapter.setIgnoreWinKey(false);

      if (this.keyboardType) {
        this.inputAdapter.setKeyboardType(this.keyboardType);
      }
    }

    this.logger.info('Binding inputs');
    this.inputAdapter?.bindEvents();
  }

  private peerConnectionOnSignalingConnecting() {
    this.logger.info('peerConnectionOnSignalingConnecting');
  }

  private peerConnectionOnSignalingConnected() {
    this.logger.info('peerConnectionOnSignalingConnected');

    if (this.timeMetricObjectSignal) {
      this.timeMetricObjectSignal.result = MetricResult.Success;
      metrics.emitMetricOperation(this.timeMetricObjectSignal);
    }

    this.streamingSubmitted = false;

    this.timeMetricObjectStream = metrics.embark(Operation.Streaming);

    this.setState(States.kStateAwaitingHostCapabilities);
  }

  // called when the other peer has successfully connected
  private peerConnectionOnConnected() {
    this.logger.info('received peerConnection.onConnected');

    // Only need to be called once.
    if (this.peerConnected) {
      this.logger.error('peerConnection.onConnected was already called');
      throw new Error('peerConnection.onConnected was already called.');
    }

    this.peerConnected = true;
    this.bindInputs();
  }

  private peerConnectionOnDisconnecting(reason: any) {
    this.logger.info(`peerConnectionOnDisconnecting reason: ${reason}`);
    this.setState(States.kStateDisconnecting);
    this.disconnect();
  }

  // called when the other peer has disconnected
  private peerConnectionOnDisconnected(reason: any) {
    // logger.log(logger.level.INFO, TAG, "received peerConnection.onDisconnected with reason: " + reason);
    this.logger.info(
      `received peerConnection.onDisconnected with reason: ${reason}`
    );
    this.setState(States.kStateStopped);
    this.disconnect();
  }

  private peerConnectionOnFailed(reason: string) {
    this.logger.info(`peerConnectionOnFailed with reason: ${reason}`);

    this.setState(States.kStateDisconnecting);

    if (!this.userInitiatedDisconnect) {
      this.disconnect();
      let onFailedErrorCode;
      if (this.connected) {
        this.logger.error('Lost peer connection after successful connection.');
        onFailedErrorCode = WsErrorCodes.ERROR_PC_FAILED;
      } else {
        this.logger.error('Lost peer connection during connection setup.');
        onFailedErrorCode = WsErrorCodes.ERROR_PC_SETUP_FAILED;
      }
      this.handleFailed(Operation.Streaming, onFailedErrorCode, reason);
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
    }
  }

  private peerConnectionOnUtf8Message(message: any) {
    this.logger.info(`peerConnectionOnUtf8Message: ${message}`);

    let value = null;

    try {
      value = JSON.parse(message);
    } catch (ex) {
      this.logger.error('Error parsing capabilities JSON message: ' + ex);
      this.handleFailed(
        Operation.Streaming,
        WsErrorCodes.ERROR_CAPEX_BAD_MSG,
        null,
        null,
        ex
      );
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(value, VERSION_FIELD)) {
      this.logger.error('message has no version: ' + message);
      this.handleFailed(Operation.Streaming, WsErrorCodes.ERROR_CAPEX_NO_VER);
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
      return;
    }

    const version = value[VERSION_FIELD];

    if (!CAPABILITIES_EXCHANGE_VERSIONS.includes(version)) {
      this.logger.error(
        'Unexpected capabilities exchange version. Expected on of: ' +
          CAPABILITIES_EXCHANGE_VERSIONS +
          '.  Received: ' +
          version
      );

      this.disconnect();
      this.handleFailed(Operation.Streaming, WsErrorCodes.ERROR_CAPEX_BAD_VER);
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
      return;
    }

    const config = value[PEER_CONNECTION_CONFIG_FIELD];

    if (!config) {
      this.logger.warn('Received message of unknown type.');
      this.handleFailed(
        Operation.Streaming,
        WsErrorCodes.ERROR_CAPEX_NO_CONFIG
      );
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
      return;
    }

    const peerName = config[PEER_NAME_FIELD];

    if (peerName !== 'host') {
      this.logger.warn('received unknown peerName: ' + peerName);
      this.handleFailed(Operation.Streaming, WsErrorCodes.ERROR_CAPEX_BAD_PEER);
      store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
      return;
    }

    if (version === 2) {
      this.keyboardType = config[KEYBOARD_TYPE_FIELD];
      this.logger.info('Received keyboardType: ' + this.keyboardType);
    }

    switch (this.state) {
      case States.kStateNew:
      case States.kStatePeerSignalerConnecting:
        this.logger.warn(
          'onPeerConnectionConfig() while in unexpected state: ' +
            statesToName(this.state)
        );
        break;
      case States.kStateAwaitingHostCapabilities:
        try {
          this.selectHostConfiguration(config);
          this.setState(States.kStateAwaitingAppliedHostConfiguration);
          this.peerConnectionConnect();
        } catch (ex: any) {
          this.logger.error(
            'onPeerConnectionConfig(): kStateAwaitingHostCapabilities handler failed: ' +
              ex.message
          );

          this.disconnect();
          this.handleFailed(
            Operation.Streaming,
            WsErrorCodes.ERROR_CAPEX_SELECT_FAILED,
            null,
            null,
            ex
          );
          store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
        }
        break;
      case States.kStateAwaitingSelectedHostConfiguration:
        try {
          this.applySelectedHostConfiguration(config);
          this.setState(States.kStatePeerConnectionConnecting);
          this.peerConnectionConnect();
        } catch (ex: any) {
          this.logger.error(
            'onPeerConnectionConfig(): kStateAwaitingSelectedHostConfiguration handler failed: ' +
              ex.message
          );

          this.disconnect();
          this.handleFailed(
            Operation.Streaming,
            WsErrorCodes.ERROR_CAPEX_APPLY_FAILED,
            null,
            null,
            ex
          );
          store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
        }
        break;
      case States.kStateAwaitingAppliedHostConfiguration:
        try {
          this.verifyAppliedHostConfiguration(config);
          this.setState(States.kStatePeerConnectionConnecting);
        } catch (ex: any) {
          this.logger.error(
            'onPeerConnectionConfig(): kStateAwaitingAppliedHostConfiguration handler failed: ' +
              ex.message
          );

          this.disconnect();

          this.handleFailed(
            Operation.Streaming,
            WsErrorCodes.ERROR_CAPEX_VERIFY_FAILED,
            null,
            null,
            ex
          );
          store.set(STREAMING_ON, STREAMING_ON_OPTIONS.OFF);
        }
        break;
      case States.kStatePeerConnectionConnecting:
      case States.kStatePeerConnectionConnected:
        this.verifyAppliedHostConfiguration(config);
        break;
      case States.kStateDisconnecting:
      case States.kStateStopped:
        this.logger.warn(
          'onPeerConnectionConfig() while disconnecting or stopped. Ignored.'
        );
        break;
      default:
        this.logger.warn(
          'onPeerConnectionConfig() called in unhandled state  ' +
            statesToName(this.state)
        );
        break;
    }
  }

  private peerConnectionOnBinaryMessage(message: any) {
    this.logger.info(
      'received peerConnectionOnBinaryMessage with length: ' +
        message.byteLength
    );
  }

  private peerConnectionOnAddRemoteMediaStream(stxhdRemoteMediaStream: any) {
    this.logger.info('received peerConnection.onAddRemoteMediaStream');

    this.remoteMediaStream = stxhdRemoteMediaStream;
    const mediaStream = stxhdRemoteMediaStream.getWebRtcMediaStream();

    try {
      if (this.videoElement != null) this.videoElement.srcObject = mediaStream;
    } catch (error) {
      if (this.videoElement != null)
        this.videoElement.src = window.URL.createObjectURL(mediaStream);
    }

    const videoPlayPromise = this.videoElement?.play();

    if (videoPlayPromise !== undefined) {
      videoPlayPromise
        .then((value: any) => {
          // Autoplay started!
          this.logger.info(`Autoplay started! Message: ${value}`);
        })
        .catch((error: any) => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          this.logger.error(`AutoPlay error: ${error.message}`);
          this.logger.info('Autoplay was prevented!');
          this.onPending?.();
        });
    }

    this.bindInputs();

    if (this.videoElement != null) {
      this.videoElement.onloadeddata = () => {
        this.logger.info('Received first frame of data.');
        store.set(STREAMING_ON, STREAMING_ON_OPTIONS.ON);
        this.connected = true;
      };
    }

    this.getStats(this.peerConnection.originalWebRTCPeerConnection);
  }

  // called when the remote peer has created a data channel
  private peerConnectionOnAddDataChannel(stxhdDataChannel: any) {
    stxhdDataChannel.onMessage = function (data: any) {
      this.logger.log(
        '(remote) stxhdDataChannel.onMessage(' + JSON.stringify(data) + ')'
      );
    };
  }

  // The first step for a peer-to-peer connection is Signaling.
  // Here the network path is established between peers and capabilities (such as resolution)
  // are exchanged
  private startSignaling() {
    try {
      this.peerConnected = false;
      this.setState(States.kStatePeerSignalerConnecting);
      this.timeMetricObjectSignal = metrics.embark(Operation.SSIG);
      this.peerSignaler.connect();
      this.peerSignaler.connectTimeout = setTimeout(
        this.peerSignalerOnTimeout.bind(this),
        3 * 60 * 1000
      );
    } catch (ex) {
      this.logger.error('Error starting signaling: ' + ex);
      this.handleFailed(
        Operation.SSIG,
        WsErrorCodes.ERROR_SIG_START_FAILED,
        null,
        null,
        ex
      );
    }
  }

  private peerSignalerOnTimeout() {
    this.logger.error('Timed out while waiting for peerSignaler to connect.');
    this.peerSignaler.disconnect();
    this.handleFailed(Operation.SSIG, WsErrorCodes.ERROR_SIG_TIMEOUT);
  }

  private setState(newState: States) {
    if (this.state === newState) {
      this.logger.info('State unchanged.  Ignored.');
    } else {
      this.logger.info(
        `setState. from: ${statesToName(this.state)}, to: ${statesToName(
          newState
        )}`
      );
      this.state = newState;
    }
  }

  private selectHostConfiguration(config: any) {
    this.logger.info(
      'selectHostConfiguration with config: ' + JSON.stringify(config)
    );

    this.config = config;

    // Here is where we will modify peerConnectionConfig to select
    // resolutions etc.

    // first make sure to sort them in ascending mode, width then height
    // https://w.amazon.com/index.php/Realtime_Protocol/Stxhd_API/Stxhd_Capabilities_Exchange#Application_Level_Messaging_Format
    let modes = config.videoSources.displays.primary.modes;

    this.logger.info('modes before sorting: ' + JSON.stringify(modes));

    modes.sort(function (a: any, b: any) {
      if (a.width < b.width) {
        return -1;
      } else if (a.width > b.width) {
        return 1;
      } else {
        if (a.height < b.height) {
          return -1;
        } else if (a.height > b.height) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    if (!isFullScreen()) {
      this.logger.info('Filtering out non-multiple of 8 resolutions.');

      // filter out non-multiple of 8 resolutions
      // since they cause aliasing artifacts
      // when in non-fullScreen mode
      modes = modes.filter(function (mode: any) {
        return mode.height % 8 === 0;
      });
    }

    this.logger.info('sorted/filtered modes are now: ' + JSON.stringify(modes));

    const targetResolution =
      config.mediaStreams[PRIMARY_MONITOR_MEDIA_STREAM_FIELD].config
        .currentMode;
    const workArea = getAvailableWorkArea();
    let candidateResolution;
    let candidateResolutions = [];
    let bestWidth = 0;

    // look for the candidate resolutions by looking at width
    // first
    for (let i = 0; i < modes.length; i++) {
      if (modes[i].width <= workArea.width) {
        if (modes[i].width > bestWidth && modes[i].height <= workArea.height) {
          // found a better than the best width, so set it and clear
          // the candidateResolutions array so that it contains
          // only resolutions that have this best width
          bestWidth = modes[i].width;
          candidateResolutions = [];
        }

        if (modes[i].height <= workArea.height) {
          candidateResolutions.push(modes[i]);
        }
      }
    }

    this.logger.info(
      'Candidate resolutions: ' + JSON.stringify(candidateResolutions)
    );

    // now that we have resolutions with the best width
    // go through them to find the one with the best height
    for (let j = 0; j < candidateResolutions.length; j++) {
      if (!candidateResolution) {
        candidateResolution = candidateResolutions[j];
      }

      if (
        candidateResolutions[j].height <= workArea.height &&
        candidateResolutions[j].height > candidateResolution.height
      ) {
        candidateResolution = candidateResolutions[j];
      }
    }

    if (
      candidateResolution &&
      workArea.width >= candidateResolution.width &&
      workArea.height >= candidateResolution.height
    ) {
      // we are big enough so change the resolution
      // otherwise leave it unchanged
      targetResolution.width = candidateResolution.width;
      targetResolution.height = candidateResolution.height;

      this.logger.info(
        'Found a good resolution that matches the available work area: ' +
          JSON.stringify(targetResolution)
      );
    } else {
      this.logger.error(
        'Could not find a resolution that will fit the available work area.'
      );
    }

    // everything in JS are references, so we either changed the desired resolution
    // or left it intact
    const message = {
      version: CAPABILITIES_EXCHANGE_VERSION,
      peerConnectionConfig: config,
    };

    // If we are the initiator, StxhdPeerConnection.connect()
    // will flush any messages for us, so just queue the message.
    this.peerConnectionSendMessage(message);
  }

  private peerConnectionSendMessage(message: any) {
    const messageString = JSON.stringify(message);

    this.logger.info('Sending peer connection message: ' + messageString);

    this.peerConnection.sendUtf8Message(messageString);
  }

  private peerConnectionConnect() {
    // webclient is always the initiator (the boolean arg)
    this.peerConnection.connect(true);
  }

  private applySelectedHostConfiguration(config: any) {
    this.logger.info('applySelectedHostConfiguration()');

    const message = {
      version: CAPABILITIES_EXCHANGE_VERSION,
      peerConnectionConfig: config,
    };

    this.peerConnectionQueueMessage(message);
  }

  private peerConnectionQueueMessage(message: any) {
    const messageString = JSON.stringify(message);

    this.logger.info('Queueing peer connection message: ' + messageString);

    this.peerConnection.queueUtf8Message(messageString);
  }

  private verifyAppliedHostConfiguration(config: any) {
    this.logger.info('verifyAppliedHostConfiguration()');

    this.config = config;

    // So, just apply the configuration selected by our peer.
    // if it's too big, scroll bars will appear
    const targetResolution =
      config.mediaStreams[PRIMARY_MONITOR_MEDIA_STREAM_FIELD].config
        .currentMode;

    this.logger.info('targetResolution: ' + JSON.stringify(targetResolution));

    if (!this.streamingSubmitted) {
      if (this.timeMetricObjectStream) {
        this.timeMetricObjectStream.result = MetricResult.Success;
        metrics.emitMetricOperation(this.timeMetricObjectStream);
      }
      this.streamingSubmitted = true;
    }

    // callback to notify result
    this.onConnected?.(targetResolution.width, targetResolution.height);
  }

  /**
   * TODO: this seems not really emitting any metrics
   *  We need to fix it to remove it
   * @param peerConnection
   */
  private getStats(peerConnection: any) {
    const DELAY = 30000; // emit metrics every 30 sec

    const emitMetrics = (stats: any) => {
      stats.forEach((stat: any) => {
        if (
          Object.prototype.hasOwnProperty.call(stat, 'currentRoundTripTime')
        ) {
          // const RttMs = 1000 * stat.currentRoundTripTime; // TODO: why do we need to create value here?
          // round trip time is not sent to be sent as per mapping doc
        }
      });
    };

    const receivers = peerConnection.getReceivers();
    let videoReceiver = null;
    for (let i = 0; i < receivers.length; i++) {
      if (receivers[i].track.kind === 'video') {
        videoReceiver = receivers[i];
        break;
      }
    }
    if (videoReceiver) {
      videoReceiver.getStats().then(emitMetrics);
    } else {
      this.logger.info('Video receiver not found.');
    }

    setTimeout(() => this.getStats(peerConnection), DELAY);
  }

  private buildException(
    wsErrorCode: WsErrorCodes,
    reason?: any,
    description?: any
  ): WsError {
    const wsError = new WsError(
      WsErrorTypes.ERROR_TYPE_CONNECTION,
      wsErrorCode,
      ClientErrorCode.PcoIPStreamingUnKnownError,
      JSON.stringify(reason)
    );
    wsError.message = description;
    return wsError;
  }
}
