import { PreSessionFactory } from '@/bridge/factory/PreSessionFactory';
import { IProvisionedResource } from '@/bridge/types/SessionTypes';
import useCustomQuery from '@hooks/useCustomQuery';
import { IRegion } from '@bridge/types/RegionTypes';

const sessionProvision = PreSessionFactory.getSessionProvisionModule();
export const useSessionProvisionQuery = (
  authToken: string | null,
  sessionId: string | null,
  registrationCode?: string,
  region?: IRegion
) => {
  return useCustomQuery<IProvisionedResource>(
    ['session-provision'],
    async () => {
      return await sessionProvision.provisionSession({
        authToken: authToken as string,
        sessionId: sessionId as string,
        regCode: registrationCode as string,
        region: region as IRegion,
      });
    },
    {
      enabled: !!authToken && !!sessionId && !!registrationCode,
    }
  );
};
