import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { WsErrorTypes } from '@/core/error/WsErrorTypes';
import { WsErrorCodes } from '@/core/error/WsErrorCodes';

export namespace WebInSessionError {
  export const DISCONNECT_STATUS_CODE_SEARCH_NAME = 'disconnect_status_code';
  export const DISCONNECT_ERROR_TYPE_SEARCH_NAME = 'disconnect_error_type';

  // This enum must be kept in sync with https://code.amazon.com/packages/DCV-web-sdk/blobs/5f5663829f6e4e4f727629612373539a6686d77b/--/dcvjs/dcv/errors.js#L3
  export enum WspErrorTypes {
    CLOSING_REASON = 'ClosingReason',
    CONNECTION = 'Connection',
  }
  export enum WspClosingReasonErrorCodes {
    NO_ERROR_FROM_MAXIBON_WEB_VIEWER = '-1', // Returned by MaxibonWebView in case of user initiated disconnect
    TRANSPORT_ERROR = '1',
    NO_ERROR = '2',
    GENERIC_ERROR = '3',
    INTERNAL_SERVER_ERROR = '4',
    PROTOCOL_ERROR = '5',
    AUTHORIZATION_DENIED = '6',
    AUTHORIZATION_REVOKED = '7',
    ACCESS_REJECTED = '8',
    IDLE_TIMEOUT_EXPIRED = '9',
    DISCONNECT_BY_OWNER = '10',
    DISCONNECT_BY_USER = '11',
    EVICTED = '12',
  }
  export enum WspConnectionErrorCodes {
    NO_ERROR_FROM_MAXIBON_WEB_VIEWER = '-1', // Returned by MaxibonWebView in case of user initiated disconnect
    ALREADY_OPEN = '1',
    INVALID_CONFIG = '2',
    INITIALIZATION_FAILED = '3',
    REJECTED = '4',
    MAIN_CHANNEL_ALREADY_OPEN = '5',
    GENERIC_ERROR = '6',
    INTERNAL_SERVER_ERROR = '7',
    AUTHENTICATION_FAILED = '8',
    PROTOCOL_ERROR = '9',
    INVALID_SESSION_ID = '10',
    INVALID_CONNECTION_ID = '11',
    CONNECTION_LIMIT_REACHED = '12',
    SERVER_UNREACHABLE = '13',
    GATEWAY_BUSY = '14',
    UNSUPPORTED_CREDENTIAL = '15',
    FALLBACK_LOGIN_REQUIRED = '100',
  }
  export type WspErrorCodes =
    | WspClosingReasonErrorCodes
    | WspConnectionErrorCodes;

  /**
   * No error
   * In some scenario, WSP in-session will return with error code but it actually means there is no error
   * After MaxibonWebViewer handling the information received from DCV Web SDK, it return NO_ERROR_FROM_MAXIBON_WEB_VIEWER if there is no error detected
   * When closing streaming session with no error DCV Web SDK will send code 2 back to MaxibonWebViewer and MaxibonWebViewer will pass it through along with
   *   its error type - "ClosingReason"
   */
  interface IWspErrorTypeToUserInitiatedErrorCodesMap {
    [WspErrorTypes.CLOSING_REASON]: WspClosingReasonErrorCodes[];
    [WspErrorTypes.CONNECTION]: WspConnectionErrorCodes[];
  }
  export const WspErrorTypeToUserInitiatedErrorCodesMap: IWspErrorTypeToUserInitiatedErrorCodesMap =
    {
      [WspErrorTypes.CLOSING_REASON]: [
        WspClosingReasonErrorCodes.NO_ERROR,
        WspClosingReasonErrorCodes.NO_ERROR_FROM_MAXIBON_WEB_VIEWER,
      ],
      [WspErrorTypes.CONNECTION]: [
        WspConnectionErrorCodes.NO_ERROR_FROM_MAXIBON_WEB_VIEWER,
      ],
    };

  // Error type (deprecating)
  export const WspErrorTypeToWsErrorTypeMap = new Map<
    WspErrorTypes,
    WsErrorTypes
  >([
    [WspErrorTypes.CLOSING_REASON, WsErrorTypes.ERROR_TYPE_STREAMING],
    [WspErrorTypes.CONNECTION, WsErrorTypes.ERROR_TYPE_CONNECTION],
  ]);

  // Error code (deprecating)
  type IWspClosingReasonErrorCodeToWsErrorCodeMap = {
    [key in WspClosingReasonErrorCodes]?: WsErrorCodes;
  };
  type IWspConnectionErrorCodeToWsErrorCodeMap = {
    [key in WspConnectionErrorCodes]?: WsErrorCodes;
  };
  interface IWspErrorTypeToWspErrorCodeToWsErrorCodeMap {
    [WspErrorTypes.CLOSING_REASON]: IWspClosingReasonErrorCodeToWsErrorCodeMap;
    [WspErrorTypes.CONNECTION]: IWspConnectionErrorCodeToWsErrorCodeMap;
  }
  export const WspErrorTypeToWspErrorCodeToWsErrorCodeMap: IWspErrorTypeToWspErrorCodeToWsErrorCodeMap =
    {
      [WspErrorTypes.CLOSING_REASON]: {
        [WspClosingReasonErrorCodes.TRANSPORT_ERROR]:
          WsErrorCodes.ERROR_STREAMING_FAILED,
        [WspClosingReasonErrorCodes.GENERIC_ERROR]:
          WsErrorCodes.ERROR_STREAMING_FAILED,
        [WspClosingReasonErrorCodes.INTERNAL_SERVER_ERROR]:
          WsErrorCodes.ERROR_STREAMING_FAILED,
        [WspClosingReasonErrorCodes.PROTOCOL_ERROR]:
          WsErrorCodes.ERROR_STREAMING_FAILED,
        [WspClosingReasonErrorCodes.AUTHORIZATION_DENIED]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_AUTH_DENIED,
        [WspClosingReasonErrorCodes.AUTHORIZATION_REVOKED]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_AUTH_REVOKED,
        [WspClosingReasonErrorCodes.ACCESS_REJECTED]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_ACCESS_REJECTED,
        [WspClosingReasonErrorCodes.IDLE_TIMEOUT_EXPIRED]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_IDLE_TIMEOUT,
        [WspClosingReasonErrorCodes.DISCONNECT_BY_OWNER]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_OWNER_DISCONNECT,
        [WspClosingReasonErrorCodes.DISCONNECT_BY_USER]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_USER_DISCONNECT,
        [WspClosingReasonErrorCodes.EVICTED]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_EVICTED,
      },
      [WspErrorTypes.CONNECTION]: {
        [WspConnectionErrorCodes.FALLBACK_LOGIN_REQUIRED]:
          WsErrorCodes.ERROR_FALLBACK_LOGIN_REQUIRED,
        [WspConnectionErrorCodes.UNSUPPORTED_CREDENTIAL]:
          WsErrorCodes.ERROR_WEB_MAXIBON_STREAMING_UNSUPPORTED_CREDENTIAL,
      },
    };

  // Client error code
  type IWspClosingReasonErrorCodeToClientErrorCodeMap = {
    [key in WspClosingReasonErrorCodes]?: ClientErrorCode;
  };
  type IWspConnectionErrorCodeToClientErrorCodeMap = {
    [key in WspConnectionErrorCodes]?: ClientErrorCode;
  };
  interface IWspErrorTypeToWspErrorCodeToClientErrorCodeMap {
    [WspErrorTypes.CLOSING_REASON]: IWspClosingReasonErrorCodeToClientErrorCodeMap;
    [WspErrorTypes.CONNECTION]: IWspConnectionErrorCodeToClientErrorCodeMap;
  }
  export const WspErrorTypeToWspErrorCodeToClientErrorCodeMap: IWspErrorTypeToWspErrorCodeToClientErrorCodeMap =
    {
      [WspErrorTypes.CLOSING_REASON]: {
        [WspClosingReasonErrorCodes.TRANSPORT_ERROR]:
          ClientErrorCode.MaxibonWebStreamingTransportError,
        [WspClosingReasonErrorCodes.GENERIC_ERROR]:
          ClientErrorCode.MaxibonStreamingUnKnownError,
        [WspClosingReasonErrorCodes.INTERNAL_SERVER_ERROR]:
          ClientErrorCode.MaxibonWebStreamingInternalServerError,
        [WspClosingReasonErrorCodes.PROTOCOL_ERROR]:
          ClientErrorCode.MaxibonWebStreamingProtocolError,
        [WspClosingReasonErrorCodes.AUTHORIZATION_DENIED]:
          ClientErrorCode.MaxibonWebStreamingAuthDeniedError,
        [WspClosingReasonErrorCodes.AUTHORIZATION_REVOKED]:
          ClientErrorCode.MaxibonWebStreamingAuthRevokedError,
        [WspClosingReasonErrorCodes.ACCESS_REJECTED]:
          ClientErrorCode.MaxibonWebStreamingAccessRejected,
        [WspClosingReasonErrorCodes.IDLE_TIMEOUT_EXPIRED]:
          ClientErrorCode.MaxibonWebStreamingIdleTimeoutExpired,
        [WspClosingReasonErrorCodes.DISCONNECT_BY_OWNER]:
          ClientErrorCode.MaxibonWebStreamingDisconnectByOwner,
        [WspClosingReasonErrorCodes.DISCONNECT_BY_USER]:
          ClientErrorCode.MaxibonWebStreamingDisconnectByUser,
        [WspClosingReasonErrorCodes.EVICTED]:
          ClientErrorCode.MaxibonWebStreamingEvicted,
      },
      [WspErrorTypes.CONNECTION]: {
        [WspConnectionErrorCodes.FALLBACK_LOGIN_REQUIRED]:
          ClientErrorCode.MaxibonWebStreamingFallbackLoginRequired,
        [WspConnectionErrorCodes.UNSUPPORTED_CREDENTIAL]:
          ClientErrorCode.MaxibonWebStreamingUnsupportedCredential,
      },
    };
}
