export interface IDeviceType {
  LoginMessage: {
    en_US: string;
  };
  LogoUrl: string;
  ForgotPasswordLink: string;
  SupportEmail: string;
  SupportLink: string;
}

export interface IBranding {
  DeviceTypeWeb: IDeviceType;
  DeviceTypeLinux: IDeviceType;
  DeviceTypeOsx: IDeviceType;
}

export enum ReconnectEnum {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum LogUploadEnum {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export interface IConfiguration {
  reconnectEnabled: ReconnectEnum;
  logUploadEnabled: LogUploadEnum;
}
export interface ICobrandingAssetResponse {
  Branding: IBranding;
  Configuration: IConfiguration;
}
