import ActionGroup from '@/components/ActionGroup';
import { useCustomTranslation } from '@/i18n';

const OEMOverlay = () => {
  const { t } = useCustomTranslation();
  return (
    <ActionGroup
      title={t('ws-oem-overlay-title')}
      titleDescription={t('ws-oem-overlay-description')}
      isLoading={true}
      actions={[]}
    />
  );
};

export default OEMOverlay;
