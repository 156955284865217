import { PropsWithChildren } from 'react';
import { useTheme } from '@/stores/theme';
import * as awsui from '@cloudscape-design/design-tokens';
import Footer from '@/components/Footer';

const CentralizedLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const backgroundMask = useTheme((state) => state.backgroundMask);
  const backgroundImage = useTheme((state) => state.backgroundImage);
  const backgroundColor = useTheme((state) => state.backgroundColor);
  const fontColor = useTheme((state) => state.fontColor);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: backgroundColor ?? awsui.colorBackgroundLayoutMain,
        backgroundImage,
        color: fontColor,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundImage: backgroundMask,
        }}
      >
        <div
          style={{
            minHeight: '2.2rem',
            margin: '2.4rem',
          }}
        ></div>
        {children}
        <Footer includeCopyright color={fontColor} />
      </div>
    </div>
  );
};

export default CentralizedLayout;
