import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSessionStore } from '@stores/session';
import { useRegistration } from '@/hooks/useRegistration';
import { generateUUID } from '@/bridge/utility';
import { Cookies } from 'react-cookie';
import { AppConstants } from '@bridge/constants/AppConstants';

const cookie = new Cookies();

/**
 * auth code exists
 *  reg code in url
 *   this is quicklink - register and authenticate directly
 * 	reg code not in url
 *   should not happen but we can redirect to auth page
 * No auth code
 *  reg code in url
 *    register and show login page
 *  reg code in store
 * 	  show login page
 * Nothing
 *  Go back to registration page
 */
const Main = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authCode = searchParams.get('auth_code');
  const regCodeInUrl = searchParams.get('registrationCode')?.replace(' ', '+');
  const regCodeInStore: string | undefined = useSessionStore(
    (state) => state.registrationCode
  );
  const { registered, register } = useRegistration();

  useEffect(() => {
    if (regCodeInUrl) {
      register(regCodeInUrl);
    } else if (authCode) {
      navigate(`/auth?${searchParams}`);
    } else if (regCodeInStore) {
      navigate(`/login?${searchParams}`);
    } else {
      navigate('/registration');
    }
  }, []);

  useEffect(() => {
    if (!registered) return;

    if (authCode) {
      const csrfToken = generateUUID();
      cookie.set(AppConstants.COOKIE_CSRF_TOKEN, csrfToken, { path: '/' });
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('auth_code', authCode);
      newSearchParams.set('state', csrfToken);
      navigate(`/auth?${newSearchParams}`);
    } else {
      searchParams.delete('registrationCode');
      navigate(`/login?${searchParams}`);
    }
  }, [registered]);

  return null;
};

export default Main;
