import { I18nKey } from '@/i18n/types';

export type RegPrefix =
  | 'sltest'
  | 'slbeta'
  | 'slbeta_pl'
  | 'frbeta'
  | 'sliad'
  | 'friad'
  | 'wsgamma'
  | 'wsgamma_pl'
  | 'frgamma'
  | 'wspdx'
  | 'wspdx_pl'
  | 'frpdx'
  | 'wsdub'
  | 'wsdub_pl'
  | 'wssyd'
  | 'wsnrt'
  | 'wssin'
  | 'wsfra'
  | 'wslhr'
  | 'wsyul'
  | 'wsgru'
  | 'wsicn'
  | 'wscpt'
  | 'wspdt'
  | 'frpdt'
  | 'wsbom'
  | 'wsosu'
  | 'frosu'
  | 'wstlv';

export interface IRegion extends Record<string, any> {
  marketplaceId: string;
  crashReportServiceEndpoint: string;
  endpoint: string;
  code: string;
  awsRegionCode: string;
  webClientEndpoint: string;
  wsBrokerEndpoint: EndPoint[];
  cobrandingEndpoint: EndPoint[];
  metricEndpoint: EndPoint[];
  localeKey: I18nKey;
  healthCheckHostname: EndPoint[];
  supportSAML: boolean;
  supportCBA: boolean;
  isWebPcoIPSupported: boolean;
  isSoloSupported: boolean;
}

export enum EndPointType {
  IPv6 = 'IPv6',
  IPv4 = 'IPv4',
}

export interface EndPoint {
  type: EndPointType;
  url: string;
}
