import { ISessionManager } from '@bridge/ISessionManager';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { ILogger } from '@bridge/ILogger';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import {
  IActionResultType,
  IAllocatedResource,
  IAllocatedResourceSuccess,
  IAllocateResourceRetryInfo,
  IProvisionedResource,
  IStreamingSessionContext,
  SessionProtocols,
  SessionProvisionInput,
} from '@/bridge/types/SessionTypes';
import {
  AuthProvider,
  IAllocateResourceSuccessResponse,
  IResource,
  IResourceType,
} from '@/core/wsbroker/types';
import { WSBrokerSessionProtocols } from '@/core/wsbroker/Constants';
import { IMetrics } from '@bridge/IMetrics';
import {
  ITimedMetricOperation,
  MetricName,
  Operation,
} from '@bridge/types/MetricTypes';
import { PlatformType, SessionState } from '@bridge/types/SoloRTCChannelTypes';
import { IDevice } from '@bridge/IDevice';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { IRegion } from '@bridge/types/RegionTypes';
import { DimensionSet } from '@core/metrics/types';
import { ServiceUtility } from '@core/wsbroker/ServiceUtility';
import {
  WorkSpaceState,
  WsBrokerOperationState,
} from '@bridge/types/WorkSpaceStateTypes';
import { SessionContextGenerator } from '@/presession/sessionprovision/utils/SessionContextGenerator';

interface GetResourceInput extends SessionProvisionInput {}
interface AllocateResourceInput extends SessionProvisionInput {
  resourceId: string;
  protocol: string;
  resourceType: IResourceType;
  resourceState: WsBrokerOperationState;
}

const DEFAULT_RETRY_TIMEOUT_SECONDS = 30;
const DEFAULT_RETRY_INTERVAL_SECONDS = 5;

export class SessionProvision {
  private readonly device: IDevice;
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly wsBrokerClient: WSBrokerService;
  private readonly metrics: IMetrics;
  private readonly sessionContextGenerator: SessionContextGenerator;

  constructor(
    device: IDevice,
    sessionManager: ISessionManager,
    logger: ILogger,
    wsBrokerClient: WSBrokerService,
    metrics: IMetrics
  ) {
    this.device = device;
    this.sessionManager = sessionManager;
    this.logger = logger;
    this.wsBrokerClient = wsBrokerClient;
    this.metrics = metrics;
    this.sessionContextGenerator = new SessionContextGenerator(logger);
  }

  async provisionSession({
    authToken,
    sessionId,
    regCode,
    region,
  }: SessionProvisionInput): Promise<IProvisionedResource> {
    this.validate(authToken, sessionId, regCode, region);

    this.sessionManager.set({ sessionState: SessionState.GET_RESOURCE });
    const resource = await this.getResources({
      authToken,
      sessionId,
      regCode,
      region,
    });

    const resourceType = resource?.ResourceType;
    const resourceState = resource?.State;
    const resourceName = resource?.ResourceDetails?.WorkSpaceName;
    const resourceIp = resource?.ResourceDetails?.IpAddress;
    const resourceComputeType = resource?.ResourceDetails?.ComputeType;
    const resourceId = resource.ResourceId;

    const protocol = this.retrieveProtocolFromResource(resource) as string;
    this.logger.info(
      `Updating session data and allocating session for resourceType:: ${resourceType} and resource::${resourceId}`
    );

    this.sessionManager.set({
      sessionState: SessionState.ALLOCATE_RESOURCE,
      resourceId,
      resourceName,
      resourceIp,
      resourceComputeType,
      resourceType,
    });

    const authProvider =
      this.sessionManager.get('sessionContext')?.AuthProvider;
    // Check pcoip support early using protocol returned by GetResources.
    this.validateSupportForPcoIPProtocol(protocol, authProvider, region);
    const allocatedResource = await this.allocateResourceWithRetry({
      authToken,
      sessionId,
      regCode,
      region,
      resourceId,
      protocol,
      resourceType,
      resourceState,
    });

    return {
      ...allocatedResource,
      resourceId,
      resourceName,
      resourceComputeType,
      resourceIp,
    };
  }

  async getResources({
    authToken,
    sessionId,
    regCode,
    region,
  }: GetResourceInput) {
    this.logger.info(`Getting all resources for ${regCode}`);
    const timedMetric = this.metrics.embark(Operation.GetResources);
    return await this.wsBrokerClient
      .getResources(authToken, sessionId, regCode, region)
      .then((response) => {
        const resource = response.Resources?.[0];
        if (!resource) {
          throw new WsError(
            WsErrorTypes.ERROR_TYPE_GETRESOURCES,
            WsErrorCodes.ERROR_RESOURCE_NOT_FOUND
          );
        }

        this.sessionManager.set({ resource });
        this.metrics.emitMetricOperation(timedMetric);

        return resource;
      })
      .catch((error) => {
        this.metrics.emitMetricOperation(timedMetric, error);
        this.logger.error(
          'Received error while calling WSBrokerClient::GetResources ',
          error
        );
        if (error instanceof WsError) {
          throw error;
        } else {
          throw new WsError(
            WsErrorTypes.ERROR_TYPE_GETRESOURCES,
            WsErrorCodes.ERROR_CM_REQUEST_FAILED
          );
        }
      });
  }

  async allocateResourceWithRetry(
    reqData: AllocateResourceInput
  ): Promise<IAllocatedResourceSuccess> {
    const allocateResourceOpTimedMetric = this.metrics.embark(
      Operation.Connect
    );
    let operationError, resource: any;
    let operationRetires = 1;
    let waited = 0;

    try {
      resource = await this.allocateResource(reqData);

      // Workflow is not complete as retry is needed.
      if (resource.resultType !== IActionResultType.SUCCESS) {
        const workSpaceResourceState = ServiceUtility.mapWorkSpaceState(
          reqData.resourceState
        );
        this.validateResourceStateBeforeRetry(
          workSpaceResourceState,
          reqData.resourceType
        );
        const sessionState = this.getSessionState(workSpaceResourceState);
        this.sessionManager.set({ sessionState });
        const {
          retryInfo: {
            allocationRetryTimeout: timeout,
            allocateRetryInterval: delay,
          },
        } = resource;
        do {
          operationRetires++;
          this.logger.info(
            `Retrying allocate-resource in ${delay} seconds. Retry count:${operationRetires}`
          );
          this.logger.info(
            `allocate-resource retries will time out in: ${
              timeout - waited
            } seconds.`
          );
          resource = await this.allocateResourceWithDelay(
            reqData,
            delay * 1000
          );
          waited += delay;
        } while (
          waited < timeout &&
          resource.resultType !== IActionResultType.SUCCESS
        );
      }
    } catch (error: any) {
      operationError = error;
      this.logger.error(
        `Failed to allocate resource with retry. Received error:${error?.message}`
      );
    } finally {
      if (
        !operationError &&
        resource?.resultType !== IActionResultType.SUCCESS
      ) {
        this.logger.error(`Exhausted all retries for allocate-resource`);
        // All retry attempts failed
        operationError = new WsError(
          WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
          WsErrorCodes.ERROR_CM_ALLOC_RETRY_TIMEOUT,
          ClientErrorCode.AllocateResourceTimeOut
        );
      } else if (
        !operationError &&
        resource?.resultType === IActionResultType.SUCCESS
      ) {
        // Set protocol name in memory so that metrics published here on will have protocol information.
        const allocatedResource = resource as IAllocatedResourceSuccess;
        this.sessionManager.set({
          allocatedResource: allocatedResource.RawResponse,
          resourceProtocol: allocatedResource.sessionProtocol,
          resourceSessionContext: allocatedResource.sessionContext,
        });
      }
    }

    this.publishAllocateResourceMetrics(
      allocateResourceOpTimedMetric,
      operationRetires,
      operationError
    );

    if (operationError) {
      throw operationError;
    }
    return resource as IAllocatedResourceSuccess;
  }

  async allocateResourceWithDelay(
    reqData: AllocateResourceInput,
    delayInMs: number
  ): Promise<IAllocatedResource> {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        this.allocateResource(reqData)
          .then((allocatedResource) => {
            resolve(allocatedResource);
          })
          .catch((error: any) => {
            reject(error);
          });
      }, delayInMs);
    });
  }

  async allocateResource({
    authToken,
    sessionId,
    regCode,
    resourceId,
    protocol,
    region,
    resourceType,
  }: AllocateResourceInput): Promise<IAllocatedResource> {
    const platformType = this.device.getPlatform();
    return await this.wsBrokerClient
      .allocateResource(
        authToken,
        sessionId,
        resourceId,
        protocol,
        regCode,
        platformType as PlatformType,
        region
      )
      .then((response) => {
        return this.handleAllocateResourceResponse(
          response,
          protocol,
          resourceType
        );
      })
      .catch((error) => {
        this.logger.error(
          'Received error while calling WSBrokerClient::AllocateResource ',
          error
        );
        if (error instanceof WsError) {
          throw error;
        } else {
          throw new WsError(
            WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
            WsErrorCodes.ERROR_CM_REQUEST_FAILED
          );
        }
      });
  }

  private publishAllocateResourceMetrics(
    allocateResourceOpTimedMetric: ITimedMetricOperation,
    retries: number,
    error?: any
  ) {
    if (retries > 1) {
      allocateResourceOpTimedMetric.operation = Operation.StartConnect;

      // Update SessionTimedMetric with MetricName as LoginWithStartTime because of retries
      const sessionTimedMetricTracker = this.metrics.embarkSessionMetric();
      sessionTimedMetricTracker.updateMetricName(true);
    }
    if (
      error &&
      error instanceof WsError &&
      error.clientErrorCode ===
        ClientErrorCode.AllocateResourceFallbackLoginRequired
    ) {
      this.metrics.emitWithValue(
        allocateResourceOpTimedMetric.operation,
        MetricName.AllocateResourceFallback,
        1
      );
    } else {
      this.metrics.emitWithValue(
        allocateResourceOpTimedMetric.operation,
        MetricName.StartAttempts,
        retries
      );
      this.metrics.emitMetricOperation(allocateResourceOpTimedMetric, error);
    }
  }

  private handleAllocateResourceResponse(
    response: IAllocateResourceSuccessResponse,
    protocolFromGetResource: string,
    resourceType: IResourceType
  ): IAllocatedResource {
    const resultType = response.ActionResult.ResultType;
    switch (resultType) {
      case IActionResultType.SUCCESS: {
        const sessionProtocol = this.generateSessionProtocol(
          protocolFromGetResource,
          response.ActionResult.ResultValues.sessionProtocol
        );
        return {
          RawResponse: response as any,
          resultType,
          sessionContext: this.generateSessionContext(
            response,
            sessionProtocol,
            resourceType
          ),
          sessionProtocol,
        };
      }
      case IActionResultType.RETRY: {
        this.logger.info('WorkSpace may be hibernating.');
        return {
          resultType,
          retryInfo: this.generateSessionRetryInfo(response),
        };
      }
      default:
        this.logger.error(
          `Received status of ${resultType} for allocation of resource ${JSON.stringify(
            response
          )} for resource protocol ${protocolFromGetResource}`
        );
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
          WsErrorCodes.ERROR_UNSUPPORTED_RESULT_TYPE
        );
    }
  }

  private generateSessionContext(
    response: IAllocateResourceSuccessResponse,
    sessionProtocol: SessionProtocols,
    resourceType: IResourceType
  ): IStreamingSessionContext {
    switch (sessionProtocol) {
      case SessionProtocols.PCOIP:
        if (this.device.getPlatform() === PlatformType.WEB) {
          return this.sessionContextGenerator.generatePcoIPWebSessionContext(
            response.ActionResult?.ResultValues
          );
        }
        return this.sessionContextGenerator.generatePcoIPSessionContext(
          response.ActionResult?.ResultValues
        );
      case SessionProtocols.MAXIBON:
        return this.sessionContextGenerator.generateMaxibonSessionContext(
          response.ActionResult?.ResultValues,
          resourceType
        );
      default:
        this.logger.error(
          `Invalid Protocol used to allocate resource protocol::${sessionProtocol} AllocateResourceResponse::${JSON.stringify(
            response
          )}`
        );
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
          WsErrorCodes.ERROR_UNSUPPORTED_PROTOCOL
        );
    }
  }

  private generateSessionRetryInfo(
    response: IAllocateResourceSuccessResponse
  ): IAllocateResourceRetryInfo {
    const {
      ActionResult: { ResultValues: resultValues },
    } = response;
    let allocationRetryTimeout;
    if (resultValues.retryTimeoutSeconds) {
      allocationRetryTimeout = parseInt(resultValues.retryTimeoutSeconds);
    } else {
      this.logger.info(
        `Allocation retry timeout has not been provided. Using the default value (${DEFAULT_RETRY_TIMEOUT_SECONDS})`
      );
      allocationRetryTimeout = DEFAULT_RETRY_TIMEOUT_SECONDS;
    }
    this.logger.info(
      `Setting allocationRetryTimeout to: ${allocationRetryTimeout}`
    );

    let allocateRetryInterval;
    if (resultValues.retryTimeSeconds) {
      allocateRetryInterval = parseInt(resultValues.retryTimeSeconds);
    } else if (resultValues.allocationRetryTime) {
      allocateRetryInterval = parseInt(resultValues.allocationRetryTime);
    } else {
      allocateRetryInterval = DEFAULT_RETRY_INTERVAL_SECONDS;
    }

    return {
      allocationRetryTimeout,
      allocateRetryInterval,
    };
  }

  /*
   * Protocols supported differ based on device type. currently only web client is different from other device types.
   * Solo:
   *     PcoIP workspaces - PCOIP
   *     Maxibon          - WSP
   * Web:
   *     PcoIP workspaces - PCOIP_WEB
   *     Maxibon          - WSP,WSP_WEB   [Confirmed this with DP team that any of these can be returned for Maxibon,WSPV1 based on agent type.
   *                                       Hence there is no way to know protocol until AllocateResource action is completed]
   * */
  private retrieveProtocolFromResource(resource: IResource): string | null {
    const supportedResourceProtocols = resource.ActionsAuthorized[0]
      ?.ActionUserInput?.Protocols?.Values as string[];

    let resourceProtocol = null;
    if (
      this.device.getPlatform() === PlatformType.WEB &&
      supportedResourceProtocols.includes(WSBrokerSessionProtocols.PCOIP_WEB)
    ) {
      resourceProtocol = WSBrokerSessionProtocols.PCOIP_WEB;
    } else if (
      supportedResourceProtocols.includes(WSBrokerSessionProtocols.PCOIP)
    ) {
      resourceProtocol = WSBrokerSessionProtocols.PCOIP;
    } else if (
      supportedResourceProtocols.includes(WSBrokerSessionProtocols.WSP) ||
      supportedResourceProtocols.includes(WSBrokerSessionProtocols.WSP_WEB)
    ) {
      resourceProtocol = WSBrokerSessionProtocols.WSP;
    } else {
      this.logger.error(
        `Retrieved invalid protocol for resource ${JSON.stringify(resource)}`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_GETRESOURCES,
        WsErrorCodes.ERROR_UNSUPPORTED_PROTOCOL
      );
    }
    return resourceProtocol;
  }

  // GetResource returns either pcoip or highlander. But based on AllocateResource, we determined pcoip, wsp or maxibon.
  // Going forward, web will not support WSP V1.
  private generateSessionProtocol(
    resourceProtocol: string,
    sessionProtocolFromServer?: string
  ): SessionProtocols {
    if (
      resourceProtocol === WSBrokerSessionProtocols.PCOIP ||
      resourceProtocol === WSBrokerSessionProtocols.PCOIP_WEB
    ) {
      return SessionProtocols.PCOIP;
    } else if (sessionProtocolFromServer === WSBrokerSessionProtocols.MAXIBON) {
      return SessionProtocols.MAXIBON;
    } else if (resourceProtocol === WSBrokerSessionProtocols.WSP) {
      this.publishUnsupportedProtocolMetrics(SessionProtocols.WSP);
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
        WsErrorCodes.ERROR_WSP_UNSUPPORTED_PROTOCOL,
        ClientErrorCode.WspProtocolUnsupportedOnPlatform
      );
    } else {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
        WsErrorCodes.ERROR_UNSUPPORTED_PROTOCOL
      );
    }
  }

  private validate(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion
  ) {
    if (authToken && sessionId && regCode && region) {
      return;
    }

    throw new WsError(
      WsErrorTypes.ERROR_TYPE_GETRESOURCES,
      WsErrorCodes.ERROR_INVALID_INPUT
    );
  }

  /*
   * Given we support both Solo and WebClient, PcoIP session protocol can be identified right after GetResources
   * To error out early in case of PcoIP workspaces, this function can be used.
   * */
  private validateSupportForPcoIPProtocol(
    protocolFromGetResources: string,
    authProvider: AuthProvider | undefined,
    region: IRegion
  ) {
    if (
      authProvider === AuthProvider.SAML_IAM &&
      protocolFromGetResources === WSBrokerSessionProtocols.PCOIP_WEB
    ) {
      this.logger.fatal(
        `Blocking session provisioning for PcoIP workspaces using SAML authentication`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_GETRESOURCES,
        WsErrorCodes.ERROR_UNSUPPORTED_PCOIP_FOR_SAML
      );
    } else if (
      authProvider === AuthProvider.IDC &&
      protocolFromGetResources === WSBrokerSessionProtocols.PCOIP_WEB
    ) {
      this.logger.fatal(
        `Blocking session provisioning for PcoIP workspaces using IDC authentication`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_GETRESOURCES,
        WsErrorCodes.ERROR_UNSUPPORTED_PCOIP_FOR_IDC
      );
    } else if (
      protocolFromGetResources === WSBrokerSessionProtocols.PCOIP ||
      protocolFromGetResources === WSBrokerSessionProtocols.PCOIP_WEB
    ) {
      this.logger.info(
        `Identified resource protocol as PcoIP. Checking support for PcoIP streaming`
      );
      if (
        !this.device.isCurrentPlatformSupported(
          [SessionProtocols.PCOIP],
          region
        )
      ) {
        this.logger.fatal(
          `PcoIP streaming is not supported in region ${
            region.endpoint
          } on platform ${this.device.getPlatform()}. Stopping session provisioning.`
        );
        throw this.generateErrorCodeBasedOnDeviceTypeForPcoIPProtocol();
      }
    }
  }

  private validateResourceStateBeforeRetry(
    workSpaceResourceState: WorkSpaceState,
    resourceType: IResourceType
  ) {
    if (resourceType === IResourceType.WORKSPACE_POOL) {
      this.logger.info('Skipping state check');
      return;
    }
    this.logger.info(
      `Validating current resource with state ${workSpaceResourceState} and type ${resourceType}`
    );

    const wsError = new WsError(
      WsErrorTypes.ERROR_TYPE_ALLOCATE_RESOURCE,
      WsErrorCodes.ERROR_CM_ALLOC_RETRY_TIMEOUT,
      undefined,
      `Stopped AllocateResource for resource state ${workSpaceResourceState}`,
      { useClientErrorCodeForLocalesOnly: true }
    );

    let clientErrorCode;
    switch (workSpaceResourceState) {
      case WorkSpaceState.WORKSPACE_STATE_MAINTENANCE:
        clientErrorCode = ClientErrorCode.GetResourceMaintenanceError;
        break;
      case WorkSpaceState.WORKSPACE_STATE_TERMINATED:
        clientErrorCode = ClientErrorCode.GetResourceTerminatedError;
        break;
      case WorkSpaceState.WORKSPACE_STATE_UNAVAILABLE:
        clientErrorCode = ClientErrorCode.GetResourceUnavailableError;
        break;
      case WorkSpaceState.WORKSPACE_STATE_ADMIN_MAINTENANCE:
        clientErrorCode = ClientErrorCode.GetResourceAdminMaintenanceError;
        break;
      case WorkSpaceState.WORKSPACE_STATE_UNKNOWN:
        clientErrorCode = ClientErrorCode.GetResourceUnhealthyError;
        break;
      case WorkSpaceState.WORKSPACE_STATE_UNHEALTHY:
        clientErrorCode = ClientErrorCode.GetResourceUnhealthyError;
        break;
      case WorkSpaceState.WORKSPACE_STATE_SUSPENDED:
        clientErrorCode = ClientErrorCode.GetResourceImagingError;
        break;
    }

    if (clientErrorCode) {
      this.logger.error(
        `Stopping resource allocation due to invalid resource status`
      );
      wsError.clientErrorCode = clientErrorCode;
      throw wsError;
    }
  }

  private getSessionState(
    workSpaceResourceState: WorkSpaceState
  ): SessionState {
    this.logger.info(
      `Identifying sessionState for resource with state ${workSpaceResourceState}`
    );
    if (workSpaceResourceState === WorkSpaceState.WORKSPACE_STATE_PENDING) {
      return SessionState.RESUME_PENDING;
    }
    return SessionState.RESUME;
  }

  private generateErrorCodeBasedOnDeviceTypeForPcoIPProtocol() {
    let wsErrorCode, clientErrorCode;

    if (this.device.getPlatform() === PlatformType.WEB) {
      wsErrorCode = WsErrorCodes.ERROR_PCOIP_UNSUPPORTED_PROTOCOL_IN_REGION;
      clientErrorCode = ClientErrorCode.ProtocolUnsupportedInRegion;
    } else {
      wsErrorCode = WsErrorCodes.ERROR_PCOIP_UNSUPPORTED_ON_PLATFORM;
      clientErrorCode = ClientErrorCode.GenericProtocolUnsupportedOnPlatform;
    }
    this.publishUnsupportedProtocolMetrics(SessionProtocols.PCOIP);
    return new WsError(
      WsErrorTypes.ERROR_TYPE_SESSION_PROVISION,
      wsErrorCode,
      clientErrorCode
    );
  }

  private publishUnsupportedProtocolMetrics(sessionProtocol: SessionProtocols) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const dimensions = {} as DimensionSet;
    dimensions.ProtocolName = sessionProtocol;
    this.metrics.emitWithValue(
      Operation.SessionProvision,
      MetricName.UnSupportedProtocolOnDevice,
      1,
      dimensions
    );
  }
}
