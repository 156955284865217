import create from 'zustand';
import { useMemo } from 'react';
import { TabIds } from '../types';

export enum SelfServiceActionStates {
  PENDING,
  PROCESSING,
  COMPLETED,
}

export interface SelfSericeState {
  status: SelfServiceActionStates;
  process: Function;
  complete: Function;
  reset: Function;
}

const useSelfServiceStoreInternal = create<SelfSericeState>((set) => ({
  status: SelfServiceActionStates.PENDING,
  activeTabId: TabIds.ABOUT,
  process: () => set({ status: SelfServiceActionStates.PROCESSING }),
  complete: () => set({ status: SelfServiceActionStates.COMPLETED }),
  reset: () => set({ status: SelfServiceActionStates.PENDING }),
}));

export const useSelfServiceStore = () => {
  const state = useSelfServiceStoreInternal((state) => ({ ...state }));
  const isPending = useMemo(
    () => state.status === SelfServiceActionStates.PENDING,
    [state.status]
  );
  const isCompleted = useMemo(
    () => state.status === SelfServiceActionStates.COMPLETED,
    [state.status]
  );
  const isProcessing = useMemo(
    () => state.status === SelfServiceActionStates.PROCESSING,
    [state.status]
  );

  return {
    ...state,
    isPending,
    isCompleted,
    isProcessing,
  };
};
