import { WsErrorTypes } from './WsErrorTypes';
import { WsErrorCodes } from './WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { NativeInSessionError } from '@/bridge/types/NativeInSessionError';
import { WebInSessionError } from '@/bridge/types/WebInSessionError';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import {
  ExternalAuthRedirectErrorCodes,
  PlatformType,
} from '@bridge/types/SoloRTCChannelTypes';

interface WsErrorOptions {
  useClientErrorCodeForLocalesOnly: boolean;
}

// ToDo: Remove dependence on WSErrorTypes & WSErrorCodes once i18n messages dependence is removed
// Note: This class is deprecated. Use ClientError class from now on
export class WsError extends Error {
  errorType: WsErrorTypes;
  errorCode: WsErrorCodes;
  clientErrorCode?: ClientErrorCode;
  clientErrorMessage?: string;
  stackTrace?: string;
  wsErrorOptions?: WsErrorOptions;

  constructor(
    errorType: WsErrorTypes,
    errorCode: WsErrorCodes,
    clientErrorCode?: ClientErrorCode,
    clientErrorMessage?: string,
    wsErrorOptions?: WsErrorOptions,
    stackTrace?: string
  ) {
    super(`Error type: ${errorType} Error code: ${errorCode}`);
    this.errorType = errorType;
    this.errorCode = errorCode;
    this.clientErrorCode = clientErrorCode;
    this.clientErrorMessage = clientErrorMessage;
    this.wsErrorOptions = wsErrorOptions;
    this.stackTrace = stackTrace;
  }

  toString() {
    if (this.stackTrace) {
      let result = `${this.clientErrorMessage}: ${this.message}`;
      if (this.stackTrace) {
        result += `\nStack trace:\n${this.stackTrace}`;
      }
      return result;
    } else {
      return `ErrorCode: ${this.clientErrorCode}, ErrorMessage: ${this.clientErrorMessage}, InnerException: ${this.message}`;
    }
  }

  setInnerException(e: any) {
    if (e instanceof Error) {
      this.name = e?.name;
      this.message = e?.message;
      this.cause = e?.cause;
      this.stack = e?.stack;
    }
  }

  static getUnknownError(): WsError {
    return new WsError(
      WsErrorTypes.ERROR_TYPE_UNKNOWN,
      WsErrorCodes.ERROR_UNKNOWN
    );
  }

  static withType(errorType: WsErrorTypes) {
    return (errorCode: WsErrorCodes) => new WsError(errorType, errorCode);
  }

  /*
   * This function can be used once in-session [Web/Solo] returns to disconnect page with appropriate error
   * after streaming failure.
   * */
  static buildDisconnectError(
    disconnectErrorTypeFromInSession: string | null,
    disconnectStatusCodeFromInSession: string | null,
    sessionProtocol: SessionProtocols,
    platformType?: PlatformType
  ) {
    // IMPORTANT: disconnectErrorTypeFromInSession is not required for PCoIP or Solo
    if (!disconnectStatusCodeFromInSession) {
      return undefined;
    }

    if (platformType === PlatformType.WEB) {
      return WsError.buildWebDisconnectError(
        disconnectErrorTypeFromInSession,
        disconnectStatusCodeFromInSession,
        sessionProtocol
      );
    } else {
      return WsError.buildNativeDisconnectError(
        disconnectStatusCodeFromInSession,
        sessionProtocol
      );
    }
  }

  private static buildNativeDisconnectError(
    disconnectStatusCodeFromInSession: string,
    sessionProtocol: SessionProtocols
  ) {
    if (
      NativeInSessionError.ProtocolToUserInitiatedErrorCodesMap.get(
        sessionProtocol
      )?.includes(disconnectStatusCodeFromInSession)
    ) {
      return undefined;
    } else if (
      disconnectStatusCodeFromInSession ===
      WebInSessionError.WspConnectionErrorCodes.FALLBACK_LOGIN_REQUIRED
    ) {
      return new WsError(
        WsErrorTypes.ERROR_TYPE_CONNECTION,
        WsErrorCodes.ERROR_FALLBACK_LOGIN_REQUIRED,
        ClientErrorCode.AllocateResourceFallbackLoginRequired
      );
    }

    return new WsError(
      WsErrorTypes.ERROR_TYPE_STREAMING,
      WsErrorCodes.ERROR_STREAMING_FAILED,
      sessionProtocol === SessionProtocols.PCOIP
        ? ClientErrorCode.PcoIPStreamingUnKnownError
        : ClientErrorCode.MaxibonStreamingUnKnownError,
      disconnectStatusCodeFromInSession
    );
  }

  private static buildWebDisconnectError(
    disconnectErrorTypeFromInSession: string | null,
    disconnectStatusCodeFromInSession: string,
    sessionProtocol: SessionProtocols
  ) {
    let wsErrorType, wsErrorCode, clientErrorCode;

    if (sessionProtocol === SessionProtocols.MAXIBON) {
      if (!disconnectErrorTypeFromInSession) {
        return undefined;
      }

      const userInitiatedErrorCodes: string[] =
        WebInSessionError.WspErrorTypeToUserInitiatedErrorCodesMap[
          disconnectErrorTypeFromInSession as WebInSessionError.WspErrorTypes
        ];
      if (
        userInitiatedErrorCodes?.includes(disconnectStatusCodeFromInSession)
      ) {
        return undefined;
      }
      wsErrorType = WsErrorTypes.ERROR_TYPE_STREAMING;
      const WspErrorCodeToWsErrorCodeMap =
        WebInSessionError.WspErrorTypeToWspErrorCodeToWsErrorCodeMap[
          disconnectErrorTypeFromInSession as WebInSessionError.WspErrorTypes
        ];
      wsErrorCode =
        WspErrorCodeToWsErrorCodeMap?.[
          disconnectStatusCodeFromInSession as keyof typeof WspErrorCodeToWsErrorCodeMap
        ] ?? WsErrorCodes.ERROR_STREAMING_FAILED;
      const WspErrorCodeToClientErrorCodeMap =
        WebInSessionError.WspErrorTypeToWspErrorCodeToClientErrorCodeMap[
          disconnectErrorTypeFromInSession as WebInSessionError.WspErrorTypes
        ];
      clientErrorCode =
        WspErrorCodeToClientErrorCodeMap?.[
          disconnectStatusCodeFromInSession as keyof typeof WspErrorCodeToClientErrorCodeMap
        ] ?? ClientErrorCode.MaxibonStreamingUnKnownError;
    } else {
      // Default to PCoIP streaming fault
      wsErrorType = WsErrorTypes.ERROR_TYPE_STREAMING;
      wsErrorCode = WsErrorCodes.ERROR_STREAMING_FAILED;
      clientErrorCode = ClientErrorCode.PcoIPStreamingUnKnownError;
    }

    return new WsError(
      wsErrorType,
      wsErrorCode,
      clientErrorCode,
      disconnectStatusCodeFromInSession
    );
  }

  /*
   * This function converts error codes returned by solo native client into ClientErrorCode
   * Used for WDMetric publish scenarios for now
   * */
  static buildErrorFromClientErrorCodes(
    clientErrorCodeAsString: string | null,
    soloErrorCodeAsString: string | null
  ) {
    if (clientErrorCodeAsString) {
      return WsError.buildExternalAuthRedirectFailureErrorForSolo(
        clientErrorCodeAsString,
        soloErrorCodeAsString
      );
    }
    return WsError.getUnknownError();
  }

  /*
   * This function is mainline used for solo scenarios. When WD redirection happens in solo context,
   * error during redirection will be passed back to web client and will be transformed to wsError here
   *
   * This function can be used for all external auth redirections i.e WD, SAML, SAML CBA redirections
   * */
  private static buildExternalAuthRedirectFailureErrorForSolo(
    clientErrorCodeAsString: string,
    soloErrorCode: string | null
  ) {
    const wsErrorType = WsErrorTypes.ERROR_TYPE_AUTHENTICATION;
    const wsErrorCode = WsErrorCodes.ERROR_UNKNOWN;
    if (
      clientErrorCodeAsString ===
        ClientErrorCode.AuthExternalWDRedirectUnknownError.toString() ||
      clientErrorCodeAsString ===
        ClientErrorCode.AuthExternalSamlRedirectUnknownError.toString()
    ) {
      return WsError.buildAuthRedirectFailureErrorForSolo(soloErrorCode);
    }
    return new WsError(wsErrorType, wsErrorCode);
  }

  private static buildAuthRedirectFailureErrorForSolo(
    soloErrorCode: string | null
  ) {
    const wsErrorType = WsErrorTypes.ERROR_TYPE_WARPDRIVE;
    let wsErrorCode = WsErrorCodes.ERROR_WARP_DRIVE_UNKNOWN;
    let clientErrorCode = ClientErrorCode.AuthExternalWDRedirectUnknownError;

    if (
      soloErrorCode ===
      ExternalAuthRedirectErrorCodes.AuthPortalLoadTimeOut.toString()
    ) {
      wsErrorCode = WsErrorCodes.ERROR_WARP_DRIVE_LOAD_TIMEOUT;
      clientErrorCode = ClientErrorCode.AuthExternalWDRedirectLoadTimeOutError;
    } else if (
      soloErrorCode ===
      ExternalAuthRedirectErrorCodes.AuthPortalUnavailable.toString()
    ) {
      wsErrorCode = WsErrorCodes.ERROR_WARP_DRIVE_UNAVAILABLE;
      clientErrorCode = ClientErrorCode.AuthExternalWDRedirectUnavailableError;
    } else if (
      soloErrorCode ===
      ExternalAuthRedirectErrorCodes.AuthPortalExternalRedirectError.toString()
    ) {
      wsErrorCode = WsErrorCodes.ERROR_SOLO_SYSTEM_BROWSER_UNAVAILABLE;
      clientErrorCode =
        ClientErrorCode.AuthExternalSamlRedirectSystemBrowserError;
    }
    return new WsError(wsErrorType, wsErrorCode, clientErrorCode);
  }
}
