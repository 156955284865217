import { useMemo } from 'react';
import {
  Box,
  ColumnLayout,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { useSessionStore } from '@/stores/session';
import { WorkSpaceStateUtil } from '../utils/WorkSpaceStateUtil';
import { ProtocolDisplayUtil } from '../utils/ProtocolDisplayUtil';
import { ServiceUtility } from '@core/wsbroker/ServiceUtility';

const PLACEHOLDER = '--';

const About = () => {
  const { t } = useCustomTranslation();
  const {
    registrationCode: regCode,
    resourceName,
    resourceId,
    resourceComputeType,
    resourceIp,
    resourceProtocol,
    region,
    resource,
  } = useSessionStore((state) => ({ ...state }));
  const wsState = useMemo(
    () => ServiceUtility.mapWorkSpaceState(resource?.State),
    [resource?.State]
  );
  const wsStatePretty = useMemo(
    () => WorkSpaceStateUtil.getPrettyString(wsState),
    [wsState]
  );
  const wsStateType = useMemo(
    () => WorkSpaceStateUtil.getStatusType(wsState),
    [wsState]
  );
  const displayProtocol =
    ProtocolDisplayUtil.getDisplayProtocol(resourceProtocol);

  return (
    <Box padding="m">
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="region"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-registration-code')}
              >
                {' '}
                {t('ws-my-workspace-about-registration-code')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="region"
              aria-live="assertive"
              aria-label={regCode ?? PLACEHOLDER}
            >
              {' '}
              {regCode ?? PLACEHOLDER}
            </div>
          </div>
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="region"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-region')}
              >
                {' '}
                {t('ws-my-workspace-about-region')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="region"
              aria-live="assertive"
              aria-label={
                region?.localeKey ? t(region?.localeKey) : PLACEHOLDER
              }
            >
              {region?.localeKey ? t(region?.localeKey) : PLACEHOLDER}
            </div>
          </div>
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="region"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-workspace-name')}
              >
                {' '}
                {t('ws-my-workspace-about-workspace-name')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="navigation"
              aria-live="assertive"
              aria-label={resourceName || PLACEHOLDER}
            >
              {resourceName || PLACEHOLDER}
            </div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="navigation"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-id')}
              >
                {' '}
                {t('ws-my-workspace-about-id')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="navigation"
              aria-live="assertive"
              aria-label={resourceId || PLACEHOLDER}
            >
              {resourceId || PLACEHOLDER}
            </div>
          </div>
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="navigation"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-compute-type')}
              >
                {' '}
                {t('ws-my-workspace-about-compute-type')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="navigation"
              aria-live="assertive"
              aria-label={resourceComputeType || PLACEHOLDER}
            >
              {resourceComputeType || PLACEHOLDER}
            </div>
          </div>
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="navigation"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-protocol')}
              >
                {' '}
                {t('ws-my-workspace-about-protocol')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="navigation"
              aria-live="assertive"
              aria-label={displayProtocol ?? PLACEHOLDER}
            >
              {displayProtocol ?? PLACEHOLDER}
            </div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="navigation"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-status')}
              >
                {' '}
                {t('ws-my-workspace-about-status')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="navigation"
              aria-live="assertive"
              aria-label={wsStatePretty}
            >
              <StatusIndicator type={wsStateType}>
                {wsStatePretty}
              </StatusIndicator>
            </div>
          </div>
          <div>
            <Box aria-live="assertive" variant="awsui-key-label">
              <div
                tabIndex={0}
                role="navigation"
                aria-live="assertive"
                aria-label={t('ws-my-workspace-about-workspace-ip-address')}
              >
                {' '}
                {t('ws-my-workspace-about-workspace-ip-address')}
              </div>
            </Box>
            <div
              tabIndex={0}
              role="navigation"
              aria-live="assertive"
              aria-label={resourceIp || PLACEHOLDER}
            >
              {resourceIp || PLACEHOLDER}
            </div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Box>
  );
};

export default About;
