import { CoreFactory } from '@bridge/factory/CoreFactory';
import { CheckboxProps } from '@cloudscape-design/components';
import { useMemo, useState, useEffect } from 'react';
import * as React from 'react';
import { useRegCodes } from '@stores/registration';
import KeepMeLoggedInComponent from './KeepMeLoggedInComponent';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';

interface KeepMeLoggedInBoxProps {
  regCode: string;
  onChange: (isRememberMe: boolean) => void;
}

const KeepMeLoggedInBox: React.FC<KeepMeLoggedInBoxProps> = ({
  regCode,
  onChange,
}) => {
  // i18n

  const sessionManager = CoreFactory.getSessionManager();
  const isNativeMode = sessionManager.isNativeMode();

  const getRememberMe = useRegCodes((state) => state.getRememberMeSetting);
  const isRememberMe = useMemo(() => {
    return getRememberMe(regCode) ?? false;
  }, [regCode]);
  const [rememberMeToggle, setRememberMeToggle] = useState(isRememberMe);

  const getRememberMeSettingAtDirectoryLevel = useRegCodes(
    (state) => state.getRememberMeSettingAtDirectoryLevel
  );

  const isRememberMeEnabledAtDirectorylevel = useMemo(() => {
    return getRememberMeSettingAtDirectoryLevel(regCode) ?? false;
  }, [regCode]);

  const isShowRememberMe = useMemo(() => {
    return isNativeMode && isRememberMeEnabledAtDirectorylevel;
  }, [isRememberMeEnabledAtDirectorylevel]);

  useEffect(() => {
    setRememberMeToggle(isRememberMe);
  }, [regCode]);

  const rememberMeCheckboxCallback: NonCancelableEventHandler<
    CheckboxProps.ChangeDetail
  > = ({ detail }) => {
    onChange(detail.checked);
    setRememberMeToggle(detail.checked);
  };

  if (!isShowRememberMe) {
    return null;
  }

  return (
    <KeepMeLoggedInComponent
      rememberMeCheckboxCallback={rememberMeCheckboxCallback}
      rememberMeToggle={rememberMeToggle}
    />
  );
};

export default KeepMeLoggedInBox;
