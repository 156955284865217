import { ICobranding } from '@/bridge/ICobranding';
import { Cobranding } from './Cobranding';
import { IBranding, IDeviceType } from './types';

export class LinuxCobranding extends Cobranding implements ICobranding {
  getDeviceType(branding?: IBranding): IDeviceType | undefined {
    const { DeviceTypeLinux: deviceTypeLinux } = branding ?? {};

    return deviceTypeLinux;
  }
}
