import { ICobrandingAsset } from '@/bridge/types/CobrandingTypes';
import { PreSessionFactory } from '@bridge/factory/PreSessionFactory';
import { useSessionStore } from '@/stores/session';
import useCustomQuery from '@hooks/useCustomQuery';
import { useEffect } from 'react';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { SessionState } from '@bridge/types/SoloRTCChannelTypes';
import { useRegCodes } from '@stores/registration';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { IAuthInfoStep } from '@/presession/authentication/types';

type ICustomizationResource = ICobrandingAsset;

const logger = CoreFactory.getLogger();
const customization = PreSessionFactory.getCustomizationModule();
const rtcChannel = CoreFactory.getRTCChannel();
const authManager = PreSessionFactory.getAuthenticationManagerModule();
const registration = PreSessionFactory.getRegistrationModule();

/*
 Once branding assets are retrieved, we must send notification to Solo
 to customize the forgot password link.
*/
const handleRTCBrandingUpdate = (cobrandingAssets: ICobrandingAsset) => {
  if (SoloNativeRTCChannel.isChannelAvailable()) {
    logger.info('Publishing branding state change update to RTCListener');
    // ToDo: Send encoded css page to solo
    const customizationRTCConfig = {
      CustomerBranding: {
        ForgotPasswordLink: cobrandingAssets?.forgotPasswordUrl,
      },
    };
    rtcChannel?.publishStateChangeUpdate(
      SessionState.CUSTOMIZATION,
      customizationRTCConfig
    );
  }
};

/*
 * If a registration code from a previous session is present in memory, we land directly in login page. This function
 * re-registers user to identify region and purges telemetry from previous session.
 * */
const registerIfNotRegistered = async (regCode: string | undefined) => {
  if (regCode && !registration.getIsRegistrationStarted()) {
    logger.info(
      `Registration code ${regCode} from a previous streaming session is present in memory. Completing registration before proceeding to next step`
    );
    await registration.register(regCode);
  }
};

const fetchAuthInfoAndCustomizationResource = async (
  regCode?: string
): Promise<ICustomizationResource> => {
  await registerIfNotRegistered(regCode);
  if (!registration.getIsRegistrationComplete()) {
    logger.info(`Skipping branding fetch until rendering is complete`);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as ICustomizationResource;
  }
  return await authManager.init().then(async (authInfoStep: IAuthInfoStep) => {
    const { payload } = authInfoStep;
    const {
      DomainProperties: { OrganizationName: orgName },
    } = payload;
    return await customization
      .fetchBrandingAssets(orgName)
      .then((cobrandingAssets: ICobrandingAsset) => {
        return { ...cobrandingAssets };
      });
  });
};

export const useLoginResourceQuery = () => {
  const regCode = useSessionStore((state) => state.registrationCode);
  const region = useSessionStore((state) => state.region);
  const setSession = useSessionStore((state) => state.setSession);
  const createOrUpdateRegCode = useRegCodes(
    (state) => state.createOrUpdateRegCode
  );
  const toggleRememberMeAtDirectoryLevel = useRegCodes(
    (state) => state.toggleRememberMeAtDirectoryLevel
  );

  const result = useCustomQuery<ICustomizationResource>(
    ['login-resource'],
    async () => {
      const cobrandingAssets = await fetchAuthInfoAndCustomizationResource(
        regCode
      );
      if (regCode && region) {
        createOrUpdateRegCode(regCode, region);
      }
      handleRTCBrandingUpdate(cobrandingAssets);
      return cobrandingAssets;
    },
    {
      enabled: false,
    }
  );

  const orgName = useSessionStore((state) => state.domainProperties)
    ?.OrganizationName as string;

  useEffect(() => {
    if (result.data) {
      setSession({
        logo: result.data.cobrandingLogo,
        legalText: result.data.legalText,
        isLogUploaderEnabledAtDirectoryLevel:
          result.data.isLogPublishingEnabled,
        isCobrandingEnabled: result.data.isCobrandingEnabled,
        supportUrl: result.data.supportUrl,
        resourcePreloaded: true,
      });
      toggleRememberMeAtDirectoryLevel(regCode, result.data.isReconnectEnabled);

      if (result?.data?.isLogPublishingEnabled) {
        logger.startLogUpload(orgName, region?.endpoint);
      }
    } else {
      logger.warn(
        'result.data not found while pulling directory level preferences.'
      );
    }
  }, [result.data]);

  return {
    ...result,
    fetchResource: result.refetch,
  };
};
