import { AuthStepDeterminer } from '../AuthStepDeterminer';
import { BaseStep } from './BaseStep';

export class IdcStep extends BaseStep {
  async perform() {
    const authResponse = await this.authentication.authenticate(
      this.authRequest.constructSSOAuthRequest('idc')
    );
    return AuthStepDeterminer.getNextStepFromAuthResponse(authResponse);
  }
}
