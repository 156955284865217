import {
  CLIENT_PLATFORM,
  COBRANDING_PATH,
  DEFAULT_COBRANDING_ASSETS,
} from './constants';
import {
  IBranding,
  ICobrandingAssetResponse,
  IDeviceType,
  LogUploadEnum,
  ReconnectEnum,
} from './types';
import { ICobrandingAsset } from '@/bridge/types/CobrandingTypes';

import { IHttpClient } from '@bridge/IHttpClient';
import { HttpClientConfig } from '@core/http/HttpClient';
import { ICobranding } from '@bridge/ICobranding';
import { IDevice } from '@/bridge/IDevice';
import { ILogger } from '@/bridge/ILogger';
import { ImageUtils } from '@/helpers/image-utils';
import { IMetrics } from '@bridge/IMetrics';
import { MetricName, MetricResult, Operation } from '@bridge/types/MetricTypes';
import { EndPointType, IRegion } from '@/bridge/types/RegionTypes';
import { ISessionManager } from '@bridge/ISessionManager';

export abstract class Cobranding implements ICobranding {
  private readonly httpClient: IHttpClient<ICobrandingAssetResponse>;
  private readonly browserPlatformDevice: IDevice;
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly sessionManager: ISessionManager;

  constructor(
    client: IHttpClient<ICobrandingAssetResponse>,
    device: IDevice,
    logger: ILogger,
    metrics: IMetrics,
    sessionManager: ISessionManager
  ) {
    this.httpClient = client;
    this.browserPlatformDevice = device;
    this.logger = logger;
    this.metrics = metrics;
    this.sessionManager = sessionManager;
  }

  abstract getDeviceType(branding?: IBranding): IDeviceType | undefined;

  async fetchCobrandingAssets(region: IRegion, organizationName: string) {
    const cobrandingPath = `${COBRANDING_PATH}${organizationName}`;
    const clientVersion = `${this.browserPlatformDevice.getProductVersion()}`;
    const useIPv6 =
      this.sessionManager.get('httpsSettings').clientServiceEndpointType ===
      EndPointType.IPv6;

    try {
      if (useIPv6) {
        let httpConfig = this.getHttpClientConfig(
          region,
          clientVersion,
          useIPv6
        );
        this.logger.info(
          `Requesting the co-branding assets using IPv6 endpoint: ${httpConfig.baseURL}`
        );
        try {
          return await this.fetchAssets(cobrandingPath, httpConfig);
        } catch (error) {
          this.logger.info(
            `Request for co-branding assets using IPv6 failed with error: ${error}`
          );

          this.logger.info('Retrying with IPv4 endpoint');
          this.metrics.emitWithValueAndReason(
            Operation.DescribeClientCustomization,
            MetricName.IPv6Fallback,
            1,
            error
          );
          this.sessionManager.set({
            httpsSettings: {
              ...this.sessionManager.get('httpsSettings'),
              clientServiceEndpointType: EndPointType.IPv4,
            },
          });
          httpConfig = this.getHttpClientConfig(region, clientVersion, false);
          return await this.fetchAssets(cobrandingPath, httpConfig);
        }
      } else {
        const httpConfig = this.getHttpClientConfig(
          region,
          clientVersion,
          false
        );
        this.logger.info(
          `Requesting co-branding assets using IPv4 endpoint: ${httpConfig.baseURL}`
        );
        return await this.fetchAssets(cobrandingPath, httpConfig);
      }
    } catch (error) {
      // We do not throw error in this case. And return default value after logging the error
      return await this.transformCobrandingAssetData(undefined);
    }
  }

  private async fetchAssets(
    cobrandingPath: string,
    httpConfig: HttpClientConfig
  ) {
    try {
      const { data } = await this.httpClient.get(cobrandingPath, httpConfig);
      this.logger.info(
        `Current workspace customized settings: ${JSON.stringify(data)}`
      );
      this.metrics.emit(
        Operation.DescribeClientCustomization,
        MetricResult.Success
      );
      return await this.transformCobrandingAssetData(data);
    } catch (error) {
      this.logger.error(`Branding retrieval failed with Error: ${error}`);
      if (
        this.sessionManager.get('httpsSettings').clientServiceEndpointType ===
        EndPointType.IPv4
      ) {
        this.metrics.emit(
          Operation.DescribeClientCustomization,
          MetricResult.Fault,
          error
        );
      }
      throw error;
    }
  }

  private async transformCobrandingAssetData(
    cobrandingAssetResponse?: ICobrandingAssetResponse
  ): Promise<ICobrandingAsset> {
    const { Branding: branding, Configuration: configuration } =
      cobrandingAssetResponse ?? {};
    const cobrandingAsset: ICobrandingAsset = { ...DEFAULT_COBRANDING_ASSETS };
    cobrandingAsset.isReconnectEnabled =
      configuration?.reconnectEnabled === ReconnectEnum.Enabled;

    cobrandingAsset.isLogPublishingEnabled = true;
    if (
      configuration?.logUploadEnabled &&
      configuration?.logUploadEnabled === LogUploadEnum.Disabled
    ) {
      cobrandingAsset.isLogPublishingEnabled = false;
    }

    const deviceType = this.getDeviceType(branding);
    if (!deviceType) return cobrandingAsset;

    const {
      LoginMessage: loginMessage,
      LogoUrl: logoUrl,
      ForgotPasswordLink: forgotPasswordLink,
      SupportEmail: supportEmail,
      SupportLink: supportLink,
    } = deviceType;

    const supportEmailTrim = supportEmail?.trim();
    const supportLinkTrim = supportLink?.trim();
    if (supportEmailTrim || supportLinkTrim) {
      cobrandingAsset.supportUrl =
        (supportEmailTrim && `mailto: ${supportEmailTrim}`) || supportLinkTrim;
    }
    if (loginMessage?.en_US) {
      cobrandingAsset.legalText = loginMessage.en_US;
    }
    if (logoUrl) {
      cobrandingAsset.cobrandingLogo = await ImageUtils.toBase64(
        logoUrl,
        'image/png'
      );
    }
    if (forgotPasswordLink) {
      cobrandingAsset.forgotPasswordUrl = forgotPasswordLink;
    }
    cobrandingAsset.isCobrandingEnabled = true;

    return cobrandingAsset;
  }

  private getHttpClientConfig(
    region: IRegion,
    clientVersion: string,
    useIpv6: boolean
  ): HttpClientConfig {
    const preferredIpVersion = useIpv6 ? EndPointType.IPv6 : EndPointType.IPv4;
    const preferredEndpoint = region.cobrandingEndpoint.find(
      (ep) => ep.type === preferredIpVersion
    );

    return {
      baseURL: preferredEndpoint?.url,
      params: {
        ClientPlatform: CLIENT_PLATFORM,
        ClientVersion: clientVersion,
      },
    };
  }
}
