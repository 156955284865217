import { Registration } from '@/presession/registration/Registration';
import { Customization } from '@/presession/customization/Customization';
import { SessionProvision } from '@/presession/sessionprovision/SessionProvision';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { HttpClientFactory } from '@bridge/factory/HttpClientFactory';
import { AuthManager } from '@/presession/authentication/AuthManager';
import { StreamInputGenerator } from '@/presession/sessionprovision/utils/nativeMode/StreamInputGenerator';

export class PreSessionFactory {
  private static registration: Registration | undefined;
  private static customization: Customization | undefined;
  private static authenticationManager: AuthManager | undefined;
  private static sessionProvision: SessionProvision | undefined;
  private static nativeModeStreamInputGenerator:
    | StreamInputGenerator
    | undefined;

  static getRegistrationModule(): Registration {
    if (this.registration == null) {
      this.registration = new Registration(
        CoreFactory.getLogger(),
        CoreFactory.getPersistentStorage(),
        CoreFactory.getSessionManager(),
        CoreFactory.getRegionResolver(),
        CoreFactory.getDevice(),
        CoreFactory.getRTCChannel()
      );
    }
    return this.registration;
  }

  static getCustomizationModule(): Customization {
    if (this.customization == null) {
      this.customization = new Customization(
        HttpClientFactory.getAuthInfoHttpClient(),
        CoreFactory.getDevice(),
        CoreFactory.getCobranding(),
        CoreFactory.getSessionManager(),
        CoreFactory.getLogger(),
        CoreFactory.getWsBrokerClient(),
        CoreFactory.getMetrics()
      );
    }
    return this.customization;
  }

  static getAuthenticationManagerModule(): AuthManager {
    if (this.authenticationManager == null) {
      this.authenticationManager = new AuthManager(
        CoreFactory.getSessionManager(),
        CoreFactory.getLogger(),
        CoreFactory.getMetrics(),
        CoreFactory.getDevice(),
        CoreFactory.getWsBrokerClient(),
        CoreFactory.getRTCChannel()
      );
    }
    return this.authenticationManager;
  }

  static getSessionProvisionModule(): SessionProvision {
    if (this.sessionProvision == null) {
      this.sessionProvision = new SessionProvision(
        CoreFactory.getDevice(),
        CoreFactory.getSessionManager(),
        CoreFactory.getLogger(),
        CoreFactory.getWsBrokerClient(),
        CoreFactory.getMetrics()
      );
    }
    return this.sessionProvision;
  }

  static getNativeStreamInputGenerator(): StreamInputGenerator {
    if (this.nativeModeStreamInputGenerator == null) {
      this.nativeModeStreamInputGenerator = new StreamInputGenerator(
        CoreFactory.getLogger(),
        CoreFactory.getDevice()
      );
    }
    return this.nativeModeStreamInputGenerator;
  }
}
