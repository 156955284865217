import { useCustomTranslation } from '@/i18n';
import {
  Box,
  SpaceBetween,
  Modal,
  Button,
} from '@cloudscape-design/components';
import * as React from 'react';
import { IRegCode } from '@stores/registration';

interface DeleteRegCodeModalProps {
  isVisible: boolean;
  regCode: IRegCode;
  onDeleteClick: () => void;
}

const DeleteRegCodeModal: React.FC<DeleteRegCodeModalProps> = ({
  isVisible,
  regCode,
  onDeleteClick,
}) => {
  // i18n
  const { t } = useCustomTranslation();
  const [visible, setVisible] = React.useState(isVisible);

  const getRegCodeName = (): string | undefined => {
    const aliasName = regCode.alias;
    if (aliasName === '') {
      return regCode.name;
    }
    return `"${regCode.name}" alias "${aliasName}"`;
  };

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel={t('ws-delete-reg-code-modal-aria-text')}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => setVisible(false)}
              ariaLabel={t('ws-cancel-button')}
            >
              {t('ws-cancel-button')}
            </Button>
            <Button
              variant="primary"
              onClick={onDeleteClick}
              ariaLabel={t('ws-delete-button')}
            >
              {t('ws-delete-button')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t('ws-reg-code-delete-name')}
    >
      {t('ws-delete-reg-code-modal-text')}
      <strong> {getRegCodeName()} </strong> ({t(regCode?.region?.localeKey)}) ?
    </Modal>
  );
};

export default DeleteRegCodeModal;
