import { Route, Routes } from 'react-router-dom';
import Auth from '.';
import { AuthRoutes } from '@/bridge/routes/AuthRoutes';
import WarpDrive from './strategies/WarpDrive';
import SAML from './strategies/SAML';
import Reauthenticate from './strategies/Reauthenticate';
import Reconnect from './strategies/Reconnect';
import IDC from './strategies/IDC';

const AuthRoute = () => {
  return (
    <Routes>
      <Route index element={<Auth />} />
      <Route
        path={AuthRoutes.RELATIVE_PATHS.WARP_DRIVE}
        element={<WarpDrive />}
      />
      <Route path={AuthRoutes.RELATIVE_PATHS.SAML} element={<SAML />} />
      <Route path={AuthRoutes.RELATIVE_PATHS.IDC} element={<IDC />} />
      <Route
        path={AuthRoutes.RELATIVE_PATHS.REAUTHENTICATE}
        element={<Reauthenticate />}
      />
      <Route
        path={AuthRoutes.RELATIVE_PATHS.RECONNECT}
        element={<Reconnect />}
      />
    </Routes>
  );
};

export default AuthRoute;
