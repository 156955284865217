import useCustomQuery from '@hooks/useCustomQuery';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { useSessionStore } from '@stores/session';

const metrics = CoreFactory.getMetrics();
const logger = CoreFactory.getLogger();

export const useTelemetryFlush = () => {
  const region = useSessionStore((state) => state.region);
  const isLogUploaderEnabledAtDirectoryLevel = useSessionStore(
    (state) => state.isLogUploaderEnabledAtDirectoryLevel
  );
  const orgName = useSessionStore((state) => state.domainProperties)
    ?.OrganizationName as string;

  const flushMetricsAndLogs = async () => {
    if (orgName && region?.endpoint) {
      logger.info('starting telemetry flush');
      if (isLogUploaderEnabledAtDirectoryLevel) {
        logger.startLogUpload(orgName, region.endpoint);
        logger.flush();
      } else {
        logger.info(
          'LogUpload is not enabled for current workspace. Skipping log flush'
        );
      }
      await metrics.flush();
    }
  };

  const result = useCustomQuery(
    ['flush-telemetry'],
    async () => {
      return await flushMetricsAndLogs();
    },
    {
      enabled: false,
    }
  );

  return {
    ...result,
    flushTelemetry: result.refetch,
  };
};
