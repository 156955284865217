import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import {
  Action,
  ActionType,
  HealthCheckResponse,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';

export class HealthCheckResponseHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor(logger: ILogger, metrics: IMetrics) {
    this.logger = logger;
    this.metrics = metrics;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    try {
      this.validate(msg);
      const response = msg.Payload;
      this.handleResponse(response);
      const healthCheckResponse = response as HealthCheckResponse;
      callback?.(healthCheckResponse);
    } catch (error: any) {
      this.logger.error(
        `Failed to handle settings update RTC notification`,
        error
      );
      this.metrics.emit(Operation.HealthCheck, MetricResult.Error, error);
    }
  }

  private handleResponse(payload: any) {
    this.logger.info('Handling health check response from RTC listener');
    if (payload && Object.prototype.hasOwnProperty.call(payload, 'ErrorCode')) {
      this.logger.warn(
        `Failed to propagate health check response to RTC listener`
      );
      const error = new WsError(
        WsErrorTypes.ERROR_TYPE_UNKNOWN,
        WsErrorCodes.ERROR_UNKNOWN,
        ClientErrorCode.HealthCheckResponseUnKnown
      );
      error.name = payload?.ErrorCode;
      error.message = payload?.ErrorMessage;
      this.metrics.emit(Operation.HealthCheck, MetricResult.Error, error);
    } else {
      this.metrics.emit(Operation.HealthCheck, MetricResult.Success);
    }
  }

  private validate(msg: RTCChannelMessage) {
    this.logger.info(
      `Received health check response details from Solo ${JSON.stringify(msg)}`
    );
    if (
      msg.Action !== Action.HEALTH_CHECK ||
      msg.ActionType !== ActionType.RESPONSE
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_UNKNOWN,
        WsErrorCodes.ERROR_UNKNOWN,
        ClientErrorCode.HealthCheckInvalidInput
      );
    }
  }
}
