enum AuthRelativePathKeys {
  WARP_DRIVE = 'WARP_DRIVE',
  SAML = 'SAML',
  IDC = 'IDC',
  REAUTHENTICATE = 'REAUTHENTICATE',
  RECONNECT = 'RECONNECT',
}
export type IAuthRelativePath =
  | 'warpdrive'
  | 'saml'
  | 'idc'
  | 'reauthenticate'
  | 'reconnect';
type IAuthRelativePaths = {
  [key in AuthRelativePathKeys]: IAuthRelativePath;
};
const RELATIVE_PATHS: IAuthRelativePaths = {
  WARP_DRIVE: 'warpdrive',
  SAML: 'saml',
  IDC: 'idc',
  REAUTHENTICATE: 'reauthenticate',
  RECONNECT: 'reconnect',
};

enum AuthRootPathKeys {
  AUTH = 'AUTH',
}
type AuthAbsolutePathKeys = AuthRootPathKeys | AuthRelativePathKeys;
export type IAuthAbsolutePath =
  | `/auth/${IAuthRelativePath}`
  | '/auth'
  | '/session';
type IAuthAbsolutePaths = {
  [key in AuthAbsolutePathKeys]: IAuthAbsolutePath;
};
const ABSOLUTE_PATHS: IAuthAbsolutePaths = {
  AUTH: '/auth',
  WARP_DRIVE: '/auth/warpdrive',
  SAML: '/auth/saml',
  IDC: '/auth/idc',
  REAUTHENTICATE: '/auth/reauthenticate',
  RECONNECT: '/auth/reconnect',
};

export const AuthRoutes = {
  RELATIVE_PATHS,
  ABSOLUTE_PATHS,
};
