import { useCustomTranslation } from '@/i18n';
import styles from './styles.module.scss';
import CentralizedLayout from '@/layout/CentralizedLayout';
import { useTheme } from '@/stores/theme';
import { Button } from '@cloudscape-design/components';

interface PlayVideoOverlayProps {
  playVideo: () => void;
}

const PlayVideoOverlay: React.FC<PlayVideoOverlayProps> = ({ playVideo }) => {
  const { t } = useCustomTranslation();
  const logo = useTheme((state) => state.logo);
  const fontColor = useTheme((state) => state.fontColor);

  return (
    <CentralizedLayout>
      <div
        id="overlay-play"
        className={styles['overlay-play']}
        style={{
          color: fontColor,
        }}
      >
        <img
          className={styles['logo-play']}
          src={logo}
          crossOrigin="anonymous"
          alt={t('ws-logo-alt')}
        />
        <p>{t('ws-play-description')}</p>
        <Button
          variant="primary"
          onClick={playVideo}
          className="styles_stx-play-link"
        >
          {t('ws-play-link')}
        </Button>
      </div>
    </CentralizedLayout>
  );
};

export default PlayVideoOverlay;
