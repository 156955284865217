import { ICobrandingAsset } from '@/bridge/types/CobrandingTypes';
import logo from '@img/ws-logo-dark.png';

export const COBRANDING_PATH: string = 'ClientCustomizations/ByOrgName/';
export const CLIENT_PLATFORM: string = 'Web';
export const DEFAULT_COBRANDING_ASSETS: ICobrandingAsset = {
  isCobrandingEnabled: false,
  cobrandingLogo: logo,
  supportUrl: '',
  forgotPasswordUrl: '',
  isReconnectEnabled: false,
  isLogPublishingEnabled: true,
};
