import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': 'Willkommen bei Amazon WorkSpaces.',
  'ws-options': 'Bitte wähle eine der folgenden Optionen:',
  'ws-login': 'Anmelden',
  'ws-remember-me': 'Angemeldet bleiben',
  'ws-new-reg-code': 'Registrierungscode ändern',
  'ws-back-login': 'Zurück zur Anmeldeseite',
  'ws-fallback-login': 'Melde dich mit den Anmeldeinformationen an',
  'ws-auth-reconnect': 'Wiederverbinden',
  'ws-disconnected-error':
    'Es ist ein Fehler aufgetreten. Wende dich an deinen WorkSpaces-Administrator, um Hilfe zu erhalten',
  'ws-get-started':
    'Gib zunächst den Registrierungscode ein, den du von deinem Administrator erhalten hast.',
  'ws-register': 'Registrieren',
  'ws-pin': 'Anheften',
  'ws-disconnect': 'Verbindung trennen',
  'ws-disconnected': 'Verbindung getrennt',
  'ws-send-cad': 'STRG-ALT-DEL senden',
  'ws-enter-full-screen': 'Vollbildmodus aktivieren',
  'ws-exit-full-screen': 'Vollbildmodus beenden',
  'ws-error': 'Fehler',
  'ws-console': 'WorkSpaces-Konsole',
  'ws-privacy': 'Datenschutz',
  'ws-site-terms': 'Nutzungsbedingungen der Website',
  'ws-cookie-pref': 'Cookie-Einstellungen',
  'ws-docs': 'Dokumentation',
  'ws-reserved': 'oder dessen verbundene Unternehmen. Alle Rechte vorbehalten.',
  'ws-neg-error': 'Beim Starten ist ein Fehler aufgetreten.',
  'ws-reg-error': 'Bei der Registrierung ist ein Fehler aufgetreten.',
  'ws-reg-format-error': 'Ungültiges Registrierungscode-Format.',
  'ws-reg-invalid-error': 'Ungültiger Registrierungscode.',
  'ws-reg-unsupported-by-platform':
    'Der angegebene Registrierungscode wird von dieser Version des Clients nicht unterstützt. Weitere Informationen erhältst du von deinem Administrator. ',
  'ws-reg-unknown-error': 'Unbekannter Registrierungscode.',
  'ws-unknown-error': 'Unbekannter Fehler',
  'ws-loading-error':
    'Beim Laden der Authentifizierungsseite ist ein Fehler aufgetreten. Bitte stelle sicher, dass dieses OS/diese Plattform in dem Verzeichnis, das die WorkSpaces enthält, aktiviert ist.',
  'ws-get-auth-info-error':
    'Beim Laden der Authentifizierungsseite ist ein Fehler aufgrund eines ungültigen Registrierungscodes aufgetreten.',
  'ws-auth-error': 'Bei der Authentifizierung ist ein Fehler aufgetreten.',
  'ws-auth-type-not-supported-error':
    'Der Authentifizierungstyp wird nicht unterstützt',
  'ws-user-auth-failure':
    'Möglicherweise gab es eine längere Inaktivität oder du hast ein neues Passwort. Bitte versuche es erneut.',
  'ws-device-auth-failure':
    'Dieses Gerät ist nicht für den Zugriff auf deinen WorkSpace autorisiert.',
  'ws-get-resources-error':
    'Beim Abrufen der Ressourcen ist ein Fehler aufgetreten.',
  'ws-resource-not-found':
    'Unter deinem Benutzernamen wurde kein WorkSpace gefunden. Wende dich an deinen Administrator, um einen anzufordern.',
  'ws-not-supported-error':
    'Webverbindung zum ausgewählten WorkSpace wird nicht unterstützt.',
  'ws-suspended-error': 'Der ausgewählte WorkSpace scheint gesperrt zu sein.',
  'ws-resource-maintenance-error':
    'Dein WorkSpace wird gerade gewartet. Dies kann einige Stunden dauern. Weitere Informationen erhältst du von deinem Administrator.',
  'ws-resource-terminated-error':
    'Dein WorkSpace wurde von deinem Administrator beendet. Weitere Informationen erhältst du von deinem Administrator.',
  'ws-resource-unavailable-error':
    'Unter deinem Benutzernamen wurde kein WorkSpace gefunden. Wende dich an deinen Administrator, um einen anzufordern.',
  'ws-resource-unhealthy-error':
    'Wir können derzeit keine Verbindung zu deinem WorkSpace herstellen. Dies kann durch aktuelle Updates verursacht werden. Bitte versuch es später erneut. Wenn dies erneut passiert, wende dich an den IT-Support.',
  'ws-resource-imaging-error':
    'Beim Starten deines WorkSpace ist ein Fehler aufgetreten. Versuche gleich erneut, die Verbindung herzustellen. Wenn dieses Problem weiterhin besteht, wende dich an deinen Administrator, um Hilfe zu erhalten.',
  'ws-try-again-error': 'Bitte versuche es später erneut.',
  'ws-starting-error':
    'Wir können derzeit keine Verbindung zu deinem WorkSpace herstellen.  Dies kann passieren, wenn dein WorkSpace gerade gestartet wird.',
  'ws-try-again-few-error': 'Versuche es in ein paar Minuten erneut.',
  'ws-connecting-error':
    'Bei der Verbindung mit deinem WorkSpace ist ein Fehler aufgetreten.',
  'ws-service-unavailable':
    'Wir konnten deinen WorkSpace nicht starten. Bitte versuche es erneut. Wenn du Hilfe benötigst, wende dich an deinen Administrator.',
  'ws-workspace-unavailable':
    'WorkSpace ist derzeit nicht verfügbar. Bitte versuche es später erneut. Wenn du Hilfe benötigst, wende dich an deinen Administrator.',
  'ws-workspace-connection-closed':
    'Die Workspace-Verbindung wurde aufgrund von Inaktivität geschlossen. Bitte stelle erneut eine Verbindung her.',
  'ws-cm-session-timeout': 'Sitzung abgelaufen.',
  'ws-not-supported-browser-error':
    'Dieses Gerät/dieser Browser wird derzeit nicht für den WorkSpaces-Webzugriff unterstützt.',
  'ws-wsp-not-supported-error':
    'Der DCV-WorkSpace, mit dem du eine Verbindung herstellst, verwendet eine überholte Agent-Version. Bitte starte deinen WorkSpace neu oder wende dich an deine Administration, um die Softwareversion zu aktualisieren.',
  'ws-pcoip-unsupported-in-region':
    'Der WorkSpace gehört zu einer Region, die derzeit kein Streaming mit dem PCoIP-Protokoll auf dieser Plattform unterstützt. Verwende stattdessen den WorkSpaces-Client unter Windows/macOS/Linux zum Streamen. Weitere Informationen erhältst du von deiner Administration.',
  'ws-pcoip-unsupported':
    'PCoIP-WorkSpace-Streaming wird auf dieser Plattform nicht unterstützt. Installiere diesen Client auf Ubuntu 20, um PCoIP-WorkSpaces zu streamen',
  'ws-protocol-not-supported-browser-error':
    'Dein Browser – {{browser}} wird für {{protocol}} nicht unterstützt',
  'ws-protocol-not-supported-browser-version-error':
    'Deine {{browser}}-Version – {{version}} ist niedriger als unsere niedrigste unterstützte Version – {{lowestSupportVersion}} für {{protocol}}.',
  'ws-try-chrome-error':
    'Bitte versuche es erneut von Chrome (v55 oder höher) oder Firefox (v48 oder höher) unter Windows, OSX oder Linux aus.',
  'ws-unhealthy-error':
    'Der ausgewählte WorkSpace befindet sich in einem fehlerhaften Zustand.',
  'ws-try-reboot-error':
    'Bitte versuche zuerst, deinen WorkSpace neu zu starten.',
  'ws-not-auth-device-error':
    'Dieses Gerät ist nicht für den Zugriff auf den WorkSpace autorisiert.',
  'ws-contact-admin-error':
    'Wenn das Problem weiterhin besteht, wende dich an deinen WorkSpaces-Administrator.',
  'ws-resumed':
    'Dein WorkSpace wird fortgesetzt. Es sollte dir in Kürze zur Verfügung stehen.',
  'ws-not-optimized':
    'Der Web-Client ist nicht vollständig für deine Hardware optimiert. Für ein besseres Streaming-Erlebnis verwende bitte',
  'ws-native': 'die WorkSpaces Native Clients.',
  'ws-reg-code': 'Registrierungscode',
  'ws-reg-codes': 'Registriercodes',
  'ws-os-error':
    'Dieses Betriebssystem / diese Plattform ist nicht autorisiert, auf deinen Workspace zuzugreifen.',
  'ws-connecting': 'Verbindung wird hergestellt...',
  'ws-authenticating': 'Authentifizierung läuft ...',
  'ws-getting-resource': 'Ressource wird abgerufen ...',
  'ws-allocating-resource': 'Ressourcen werden zugewiesen ...',
  'ws-sal-text': 'Melde dich an, um den Zugriff zu autorisieren',
  'ws-play-header': 'Anmeldung erfolgreich',
  'ws-play-description': 'Anmeldung erfolgreich. Weiter zu deinem WorkSpace.',
  'ws-play-link': 'Klicken, um fortzufahren',
  'ws-my-workspace': 'Mein WorkSpace',
  'ws-my-workspace-about': 'Zusammenfassung',
  'ws-my-workspace-restart': 'Neu starten',
  'ws-my-workspace-change-compute-type': 'Berechnungstyp ändern',
  'ws-my-workspace-increase-disk-size': 'Festplattengröße erhöhen',
  'ws-my-workspace-switch-running-mode': 'Betriebsmodus ändern',
  'ws-my-workspace-rebuild': 'Wiederherstellen',
  'ws-my-workspace-change-compute-type-title': 'Berechnungstyp',
  'ws-my-workspace-increase-disk-size-title': 'Festplattengröße',
  'ws-my-workspace-switch-running-mode-title': 'Betriebsmodus',
  'ws-my-workspace-restart-error-message':
    'Dein WorkSpace konnte nicht neu gestartet werden. Bitte versuche es später erneut.',
  'ws-my-workspace-rebuild-error-message':
    'Dein WorkSpace konnte nicht wiederhergestellt werden. Bitte versuche es später erneut.',
  'ws-my-workspace-change-compute-type-error-message':
    'Dein WorkSpace konnte den Berechnungstyp nicht ändern. Bitte versuche es später erneut.',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    'Du kannst deinen Berechnungstyp nur einmal innerhalb eines 6-Stunden-Fensters für das Hochskalieren und innerhalb 30 Tagen für das Herunterskalieren ändern.',
  'ws-my-workspace-increase-disk-size-error-message':
    'Dein WorkSpace konnte die Volumengröße nicht erhöhen. Bitte versuche es später erneut.',
  'ws-my-workspace-switch-running-mode-error-message':
    'Dein WorkSpace konnte den Betriebsmodus nicht wechseln. Bitte versuche es später erneut.',
  'ws-my-workspace-increase-disk-size-success-message':
    'Du hast die Festplattengröße erfolgreich erhöht.',
  'ws-my-workspace-switch-running-mode-success-message':
    'Du hast den Betriebsmodus erfolgreich gewechselt',
  'ws-my-workspace-restart-disconnect-confirm':
    'Ich verstehe, dass bei einem Neustart des WorkSpace die Benutzer von der aktuellen Sitzung getrennt werden.',
  'ws-my-workspace-rebuild-disconnect-confirm':
    'Ich verstehe, dass bei der Wiederherstellung meine Daten gelöscht, diese durch mein aktuelles Backup ersetzt und alle Benutzer von der aktuellen Sitzung getrennt werden. Dieser Vorgang kann nicht rückgängig gemacht werden.',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    'Ich verstehe, dass durch das Ändern des Berechnungstyps die Benutzer von der aktuellen Sitzung getrennt werden.',
  'ws-my-workspace-about-registration-code': 'Registrierungscode',
  'ws-my-workspace-about-region': 'Region',
  'ws-my-workspace-about-workspace-name': 'WorkSpace-Name',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': 'Berechnungstyp',
  'ws-my-workspace-about-status': 'Status',
  'ws-my-workspace-about-workspace-ip-address': 'WorkSpace-IP-Adresse',
  'ws-my-workspace-about-protocol': 'Protokoll',
  'ws-my-workspace-restart-confirm':
    'Möchtest du deinen WorkSpace wirklich neu starten?',
  'ws-my-workspace-restart-confirm-description':
    'Um Datenverlust zu vermeiden, speichere alle geöffneten Dokumente und Anwendungsdateien, bevor du den WorkSpace neu startest.',
  'ws-my-workspace-change-compute-type-current-key': 'Aktueller Berechnungstyp',
  'ws-my-workspace-change-compute-type-in-use': 'in Verwendung',
  'ws-my-workspace-change-compute-type-description':
    'Beachte, dass sich eine Änderung des Berechnungstyps auf die Kosten deines WorkSpace auswirkt.',
  'ws-my-workspace-change-compute-type-aria-label': 'Berechnungstyp wählen',
  'ws-my-workspace-increase-disk-size-current-key': 'Aktuelle Festplattengröße',
  'ws-my-workspace-increase-disk-size-description':
    'Beachte, dass sich eine Änderung der Festplattengröße auf die Kosten deines WorkSpace auswirkt.',
  'ws-my-workspace-increase-disk-size-info':
    'Erhöhe die Größe deines Laufwerks {{driveToIncrease}}: von {{currentVolumeSizeInGib}} GIB auf {{newVolumeSizeInGib}} GIB',
  'ws-my-workspace-increase-disk-size-max-reached':
    'Du hast die maximale Festplattengröße erreicht.',
  'ws-my-workspace-switch-running-mode-current-key': 'Aktueller Betriebsmodus',
  'ws-my-workspace-switch-running-mode-auto-stop': 'AutoStop',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    'Stundenweise in Rechnung gestellt. Der WorkSpace wird automatisch gestartet, wenn du dich anmeldest.',
  'ws-my-workspace-switch-running-mode-always-on': 'AlwaysOn',
  'ws-my-workspace-switch-running-mode-always-on-description':
    'Monatlich in Rechnung gestellt. Sofortiger Zugriff auf einen WorkSpace, der immer aktiv ist.',
  'ws-my-workspace-switch-running-mode-description':
    'Beachte, dass sich eine Änderung des Betriebsmodus auf die Kosten deines WorkSpace auswirkt.',
  'ws-my-workspace-rebuild-description':
    'Möchtest du deinen WorkSpace wirklich wiederherstellen? Wir empfehlen, zuerst deinen WorkSpace neu zu starten, um zu sehen, ob dadurch mögliche Probleme behoben werden. Bei der Wiederherstellung deines WorkSpace passiert folgendes',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    'Ersetzt, was sich derzeit auf deinem Laufwerk D: befindet, durch die Daten aus deinem letzten Backup.',
  'ws-my-workspace-rebuild-data-lost-warning-content':
    'Deine aktuellen Daten gehen verloren.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    'Die Wiederherstellung deines WorkSpace dauert bis zu einer Stunde.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    'Du kannst deinen WorkSpace erst nach Abschluss der Wiederherstellung verwenden oder Änderungen daran vornehmen.',
  'ws-region-name-iad': 'US-Osten (Nord-Virginia)',
  'ws-region-name-pdx': 'US-Westen (Oregon)',
  'ws-region-name-dub': 'EU (Irland)',
  'ws-region-name-syd': 'Asien-Pazifik (Sydney)',
  'ws-region-name-nrt': 'Asien-Pazifik (Tokio)',
  'ws-region-name-sin': 'Asien-Pazifik (Singapur)',
  'ws-region-name-fra': 'EU (Frankfurt)',
  'ws-region-name-lhr': 'EU (London)',
  'ws-region-name-yul': 'Kanada (Zentral)',
  'ws-region-name-gru': 'Südamerika (Sao Paulo)',
  'ws-region-name-icn': 'Asien-Pazifik (Seoul)',
  'ws-region-name-cpt': 'Afrika (Kapstadt)',
  'ws-region-name-pdt': 'AWS GovCloud (USA-West)',
  'ws-region-name-bom': 'Asien-Pazifik (Mumbai)',
  'ws-region-name-osu': 'AWS GovCloud (USA-Ost)',
  'ws-region-name-tlv': 'Israel (Tel Aviv)',
  'ws-unauthorized-action-error':
    'Du bist nicht autorisiert, diese Aktion durchzuführen. Wende dich an deinen Administrator.',
  'ws-support': 'Support',
  'ws-logo-alt': 'Amazon WorkSpaces-Logo',
  'ws-disconnect-icon-alt':
    'Ein gepunktetes Cloud-Symbol zum Trennen der Verbindung',
  'ws-menubar-logo-alt': 'Amazon WorkSpaces-Logo in der Menüleiste',
  'ws-menubar-full-screen-toggle-icon-alt':
    'Symbol zum Umschalten in den Vollbildmodus in der Menüleiste',
  'ws-menubar-send-cad-icon-alt':
    'Symbol zum Senden von CTRL-ALT-DEL in der Menüleiste',
  'ws-menubar-disconnect-icon-alt':
    'Symbol für die Taste zum Trennen der Verbindung in der Menüleiste',
  'ws-menubar-pin-icon-alt': 'Symbol für Anheften oder Lösen der Menüleiste',
  'ws-connect-status-label': 'WorkSpace-Status',
  'ws-connect-status-negotiation': 'Wird initialisiert',
  'ws-connect-status-registration': 'WorkSpaces wird registriert',
  'ws-connect-status-hello': 'AuthCapabilities werden abgerufen',
  'ws-connect-status-customization': 'Markendetails werden abgerufen',
  'ws-connect-status-warp-drive': 'Weiterleitung zum Auth Portal',
  'ws-connect-status-warp-drive-complete':
    'Die Auth Portal-Umleitung ist abgeschlossen',
  'ws-connect-status-saml': 'Umleitung zum externen Authentifizierungsanbieter',
  'ws-connect-status-authenticate': 'Wird authentifiziert',
  'ws-connect-status-get_resource': 'Ressource wird abgerufen',
  'ws-connect-status-allocate-resource': 'Ressourcen werden zugewiesen',
  'ws-connect-status-connect': 'Verbindung wird hergestellt',
  'ws-connect-status-resume': 'WorkSpaces initialisieren',
  'ws-connect-status-resume-pending': 'WorkSpaces starten',
  'ws-connect-status-streaming': 'Streaming-Sitzung',
  'ws-connect-status-disconnect': 'Sitzung getrennt',
  'ws-connect-status-stream-context-received':
    'Streaming-Anforderungen werden generiert ...',
  'ws-footer-aws-link-label': 'Link zur AWS-Hauptseite',
  'ws-language-label': 'Sprache',
  'ws-brand-description': 'Vollständig verwalteter Remote-Desktop-Service',
  'ws-registration-page-title': 'Erste Schritte',
  'ws-login-description': 'Melde dich an, um auf deinen WorkSpace zuzugreifen.',
  'ws-solo-saml-login-desc':
    'Wir leiten dich zu deinem Browser weiter und bringen dich hierher zurück, um die Anmeldung abzuschließen.',
  'ws-progress-bar': 'Ein Ladefortschrittsbalken',
  'ws-close-label': 'Modal schließen',
  'ws-spinner-no-error-message':
    'Es wurde kein Fehler gefunden. Weiterleitung zurück zur Registrierungsseite',
  'ws-spinner-login-error-message': 'Fehler bei der Anmeldung.',
  'ws-spinner-redirecting-message': 'Weiterleitung ...',
  'ws-spinner-connecting-message': 'Verbindung wird hergestellt',
  'ws-loglevel-label-info': 'Standardprotokollierung (Standard)',
  'ws-loglevel-label-debug': 'Erweiterte Protokollierung',
  'ws-log-settings-title': 'Diagnoseprotokollierung',
  'ws-device-id': 'Geräte-ID',
  'ws-log-setting-preference': 'Diagnoseprotokollierung',
  'ws-log-preference-title': 'Protokollebene',
  'ws-log-preference-description':
    'Wähle eine Präferenzstufe für die Protokollierung, die auf der Datenmenge basiert, die du in deinen Sitzungsprotokollen bereitstellen möchtest.',
  'ws-info-loglevel-description':
    'Generiert Protokolldateien, die Diagnoseinformationen zu deinem Gerät, Internetverbindungen und Streaming-Leistungskennzahlen enthalten.',
  'ws-debug-loglevel-description':
    'Generates log files that contain the same information as standard logging files, with added debugging-level details and verbose performance data. Note: Advanced logging is only supported on DCV Workspaces.',
  'ws-loguploader-description':
    'Enable diagnostic logging to allow the WorkSpaces client to automatically upload WorkSpaces logs. The information in the logs can help troubleshoot issues. These logs will contain information about your device and internet connection.',
  'ws-loguploader-enabled': 'Aktiviert',
  'ws-loguploader-disabled': 'Deaktiviert',
  'ws-modal-close': 'Schließen',
  'ws-loguploader-copy': 'Kopieren',
  'ws-settings': 'Einstellungen',
  'ws-close-settings-button-label': 'Modal schließen',
  'ws-close-settings-buuton-name': 'Schließen',
  'ws-save-reg-code': 'Registrierungscodes speichern',
  'ws-save-reg-code-toggle-description':
    '   Aktiviere oder deaktiviere WorkSpaces, um deine Registrierungscodes zu speichern.',
  'ws-enabled-save': 'Aktiviert',
  'ws-disabled-save': 'Deaktiviert',
  'ws-rename-description':
    'Benenne deinen WorkSpace um. Der Name ersetzt den Registrierungscode als Identifizierer.',
  'ws-custom-name-input-title': 'Name des WorkSpace',
  'ws-custom-name-input-placeholder': 'Name des WorkSpace',
  'ws-input-char-limit-description': 'Maximal 20 Zeichen',
  'ws-remember-me-settings':
    'Angemeldet bleiben, bis ich mich abmelde oder meine Sitzung abläuft',
  'ws-settings-save-button': 'Speichern',
  'ws-saved-reg-codes-title': 'Gespeicherte Registrierungscodes',
  'ws-default-title': '(Standard)',
  'ws-reg-code-settings-dropdown-label':
    'Einstellungen für den Registrierungscode',
  'ws-reg-code-delete-name': 'Löschen',
  'ws-set-as-default-name': 'Als Standard festlegen',
  'ws-disable-save-modal-message':
    'Wenn du WorkSpaces deaktivierst, um deine Registrierungscodes zu speichern, werden alle aktuellen Registrierungscodes gelöscht und es werden keine zukünftigen Registrierungscodes gespeichert. Möchtest du das Speichern von Registrierungscodes wirklich deaktivieren?',
  'ws-disable-save-modal-title': 'Registrierungscodes speichern deaktivieren',
  'ws-disable-save-modal-aria-label':
    'Fenster Deaktivieren speichern schließen',
  'ws-disable-alret-message':
    'Ermögliche WorkSpaces, deine Registrierungscodes zu speichern, damit du den Registrierungscode nicht jedes Mal eingeben musst, wenn du dich in deinem WorkSpace anmelden möchtest.',
  'ws-delete-reg-code-modal-text': 'Möchtest du wirklich löschen?',
  'ws-delete-reg-code-modal-aria-text':
    'Fenster Registrierungscode löschen schließen',
  'ws-reg-code-input-placeholder': 'Registrierungscode eingeben',
  'ws-reg-code-alias-error':
    'Ein WorkSpace mit dem Namen ist bereits vorhanden. Bitte gib einen anderen Namen ein.',
  'ws-autosuggest-no-mtaches-found': 'Keine Übereinstimmung gefunden',
  'ws-ok-button': 'OK',
  'ws-delete-button': 'Löschen',
  'ws-disable-button': 'Deaktivieren',
  'ws-cancel-button': 'Abbrechen',
  'ws-warning': 'Warnung',
  'ws-alert-text-keep-me-logged-in':
    ' Die Option „Eingeloggt bleiben" wird nach deinem nächsten Login aktiviert',
  'ws-no-reg-code-saved-description':
    'Es wurde kein Registrierungscode gespeichert. Nachdem du dich mit einem Registrierungscode angemeldet hast, wird er hier gespeichert.',
  'ws-healthcheck-tcp-fail':
    'Die Verbindung über TCP-Ports ist fehlgeschlagen. Überprüfe deine Einstellungen und versuche es erneut.',
  'ws-healthcheck-udp-fail':
    'Die Verbindung über UDP-Ports ist fehlgeschlagen. Überprüfe deine Einstellungen und versuche es erneut.',
  'ws-healthcheck-tcp-partial':
    'Verbindung über UDP-Port {{connectedPort}} hergestellt. Es konnte keine ausgehende Verbindung zum UDP-Port {{failedPort}} hergestellt werden.',
  'ws-healthcheck-udp-partial':
    'Verbindung über UDP-Port {{connectedPort}} hergestellt. Es konnte keine ausgehende Verbindung zum UDP-Port {{failedPort}} hergestellt werden.',
  'ws-healthcheck-tcp-roundtrip':
    'Die Roundtrip-Zeit für TCP beträgt {{tcpRoundTripTime}} ms. Deine Netzwerkgeschwindigkeit ist langsam, rechne mit Verzögerungen.',
  'ws-healthcheck-udp-roundtrip':
    'Die Roundtrip-Zeit für UDP beträgt {{udpRoundTripTime}} ms. Deine Netzwerkgeschwindigkeit ist langsam, rechne mit Verzögerungen.',
  'ws-healthcheck-connection-failed':
    'Die HTTPS-Verbindung konnte sich nicht mit dem WorkSpaces-Dienst in deiner Region {{healthcheckRegionName}} verbinden. Kontaktiere deinen IT-Administrator',
  'ws-unsupported-pcoip-for-saml':
    'WorkSpaces PCoIP wird für SAML im Webclient nicht unterstützt. Lade die nativen WorkSpaces-Anwendungen herunter und melde dich an.',
  'ws-unsupported-pcoip-for-idc':
    'WorkSpaces PCoIP is not supported for IDC in web client. Download the native WorkSpaces applications and sign in.',
  'ws-streaming-failed-generic-error':
    'Beim Streaming ist ein unerwarteter Fehler aufgetreten. Bitte versuche es später erneut.',
  'ws-warp-drive-load-timeout':
    'Umleitung zum Authentifizierungsportal nicht möglich. Der Vorgang ist aufgrund eines Zeitlimits fehlgeschlagen. Bitte versuche es nach einiger Zeit erneut und wende dich an deinen IT-Administrator, falls das Problem weiterhin besteht.',
  'ws-warp-drive-unavailable':
    'Umleitung zum Authentifizierungsportal nicht möglich. Überprüfe deine Netzwerk- und Firewall-Einstellungen und versuche es erneut. Wende dich an deinen IT-Administrator, falls das Problem weiterhin besteht.',
  'ws-warp-drive-unknown-error':
    'Umleitung zum Authentifizierungsportal nicht möglich. Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es nach einiger Zeit erneut und wende dich an deinen IT-Administrator, falls das Problem weiterhin besteht.',
  'ws-solo-system-browser-error':
    'Webbrowser kann nicht gestartet werden, um zum Authentifizierungsportal umzuleiten. Wende dich an deinen IT-Administrator, um sicherzustellen, dass du einen Browser installiert hast, der mit WorkSpaces URI Access kompatibel ist.',
  'ws-registration-page-main-title': 'Erste Schritte – Amazon WorkSpaces',
  'ws-login-page-main-title': 'Anmelden – Amazon WorkSpaces',
  'ws-disconnect-page-main-title':
    'Verbindung unterbrochen – Amazon WorkSpaces',
  'ws-name': 'Amazon WorkSpaces',
  'ws-web-maxibon-streaming-failed-auth-denied':
    'Beim Streaming ist ein unerwarteter Fehler aufgetreten. Die WorkSpaces-Streaming-Verbindung wurde vom Server geschlossen, da die Autorisierung für die aktuelle Sitzung verweigert wurde.',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    'Beim Streaming ist ein unerwarteter Fehler aufgetreten. Die WorkSpaces-Streaming-Verbindung wurde vom Server geschlossen, da die Autorisierung für die aktuelle Sitzung nicht mehr gültig ist.',
  'ws-web-maxibon-streaming-failed-access-rejected':
    'Beim Streaming ist ein unerwarteter Fehler aufgetreten. Bitte versuche es später erneut.',
  'ws-web-maxibon-streaming-failed-idle-timeout':
    'Deine Verbindung wurde aufgrund von Inaktivität getrennt.',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    'Die WorkSpace-Streaming-Verbindung wurde vom Server geschlossen.',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'Die WorkSpace-Streaming-Verbindung wurde geschlossen, da der WorkSpace in einer anderen Clientsitzung gestreamt wird.',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    'Die WorkSpace-Streaming-Verbindung wurde aufgrund des Starts einer neuen Verbindung geschlossen.',
  'ws-oem-overlay-title': 'Beginnt ...',
  'ws-oem-overlay-description': 'Fahre mit der Authentifizierung fort',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    'Bei der Autorisierung deines WorkSpace ist ein Problem aufgetreten. Mit deinen Unternehmensanmeldedaten anmelden',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    'Beim Starten deines Workspace ist ein unerwarteter Fehler aufgetreten. Melde dich mit deinen Unternehmensanmeldedaten an.',
  'ws-authenticate-invalid-fallback-method':
    'Die Ausweichmethode ist ungültig. Der Versuch, auf die Anmeldung mit deinen Zugangsdaten des Unternehmens zurückzugreifen, ist fehlgeschlagen.',
  'ws-error-alert-icon': 'Symbol für Fehlerwarnung',
  'ws-save-reg-codes-enabled': 'Registrierungscodes speichern, Aktiviert',
  'ws-save-reg-code-disabled': 'Registrierungscodes speichern, Deaktiviert',
  'ws-pool-insufficient-capacity':
    'Für deine Sitzung sind keine Streaming-Ressourcen verfügbar. Versuche es in ein paar Minuten noch einmal.',
  'ws-session-cleanup-in-progress':
    'Deine vorherige Sitzung ist noch nicht beendet. Bitte wähle nach ein paar Minuten WIEDERHOLEN aus, um erneut zu versuchen, eine Verbindung herzustellen.',
  'ws-region-unsupported-by-stack':
    'Der verwendete Registrierungscode wird auf diesem Workspaces Web Client-Endpunkt nicht unterstützt. Verwende den Produktionsendpunkt, um Produktionsressourcen zu streamen.',
};

export default translation;
