// https://tools.ietf.org/html/rfc4648#section-5
const base64Url = (s: string) => {
  return btoa(s).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const sha256 = async (s: string) => {
  const charCodes = Array.from(s).map((c) => c.charCodeAt(0));
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new Uint8Array(charCodes)
  );
  return String.fromCharCode.apply(
    null,
    new Uint8Array(digest) as unknown as number[]
  );
};

const generateCodeVerifier = (size: number = 43) => {
  const rawCode = Array.prototype.map
    .call(crypto.getRandomValues(new Uint8Array(size)), (number) =>
      String.fromCharCode(number)
    )
    .join('');
  return base64Url(rawCode).substring(0, size);
};

const generateCodeChallenge = async (codeVerifier: string) =>
  base64Url(await sha256(codeVerifier));

const generatePkceCodes = async () => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  return { codeVerifier, codeChallenge };
};

export const CryptoUtils = {
  generatePkceCodes,
};
