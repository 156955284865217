import { useCustomTranslation } from '@/i18n';
import styles from './styles.module.scss';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import Copyright from '../Copyright';
import LinkableTerms from '../LinkableTerms';

const isChannelAvailable = SoloNativeRTCChannel.isChannelAvailable();

interface FooterProps {
  includeCopyright?: boolean;
  color?: string;
}

const Footer: React.FC<FooterProps> = ({ includeCopyright, color }) => {
  const { i18n } = useCustomTranslation();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className={styles['footer-container']} lang={i18n.language}>
        {!isChannelAvailable && <LinkableTerms color={color} />}
        {includeCopyright && <Copyright color={color} />}
      </div>
    </div>
  );
};

export default Footer;
