import { CoreFactory } from '@bridge/factory/CoreFactory';
import { ILogger } from '@/bridge/ILogger';
import { v4 as uuidv4 } from 'uuid';
import { IRegion } from '@/bridge/types/RegionTypes';
import { IHttpClient } from '@/bridge/IHttpClient';

export class CrashReporter {
  private static readonly XContentType = 'CrashReport';
  private static readonly ContentType = 'text/plain';

  public static async reportUnhandledException(
    errormsg: string,
    region: IRegion,
    httpClient: IHttpClient<any>,
    isSolo: boolean,
    logger: ILogger
  ) {
    const device = CoreFactory.getDevice();
    const version = device.getProductVersion();
    const crashTime = Date.now() - Date.UTC(1970, 0, 1) / 1000;
    const deviceUUID = device.getDeviceUUID();
    const marketplaceId = region?.marketplaceId;
    const awsRegion = region?.code;
    const hostOSVersion = device.getHostOSVersion();
    const osBuild =
      hostOSVersion !== undefined ? `${hostOSVersion}` : 'not defined';
    const uniqueId = uuidv4();
    let DeviceType = '';

    if (isSolo) {
      DeviceType = 'AQXIW2Y1EYY4K';
    } else {
      // TODO : Once we get DeviceType for web-client update the type here and send metric to crashbaord
      return;
    }

    const body =
      `[Metadata] \n` +
      `osBuildNumber:${osBuild}\n` +
      `process:com.amazon.workspaces\n` +
      `version:${version}\n` +
      `crashType:windows_app_crash\n` +
      `errorDomain:unhandledException\n` +
      `awsRegion:${awsRegion}\n` +
      `marketplaceId:${marketplaceId}\n` +
      `crashTimeUtc:${crashTime}\n` +
      `\n[Events] \n${errormsg}`;

    const RequestBodyHeader = `MFBS/1.0 1\nContent-Encoding: text\nContent-Length: ${body.length}\n\n`;

    try {
      const HTTP_CONFIG = {
        method: 'POST',
        params: { key: uniqueId },
        headers: {
          'X-DeviceType': DeviceType,
          'X-Content-Type': CrashReporter.XContentType,
          'X-DSN': deviceUUID,
          'Content-Type': CrashReporter.ContentType,
        },
      };

      const response = await httpClient.post(
        region.crashReportServiceEndpoint,
        RequestBodyHeader + body,
        HTTP_CONFIG
      );

      const statusCode = response.status;

      if (statusCode !== 200) {
        logger.info('Crash data failed.');
      } else {
        logger.info('Crash data uploaded.');
      }
    } catch (error) {
      logger.error(`Error occurred: ${error}`);
    }
  }
}
