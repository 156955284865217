export interface IStxhd {
  getLogger: () => any;
  getBrowserStats: () => any;
  createPeerSignaler: (credentials: unknown) => any;
  createPeerConnection: (peerSignaler: unknown) => any;
}

export const loadStxRtcClient = async (): Promise<IStxhd> => {
  const StxhdModule = await import('./pcoip-sdk/StxRtcClient.min.js');
  const Stxhd = StxhdModule.default;

  return new Stxhd();
};
