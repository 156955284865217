import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from '@stores/session';
import { useRegistration } from '@/hooks/useRegistration';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import logo from '@img/ws-logo.png';
import RegistrationComponent from './Registration';
import HorizontalSplitLayout from '@/layout/HorizontalSplitLayout';
import FadeAnimation from '@/components/FadeAnimation';
import { useLoginResourceQuery } from '@/hooks/useLoginResourceQuery';
import { useFadeAnimation } from '@/hooks/useFadeAnimation';
import { useRegCodes } from '@stores/registration';

const logger = CoreFactory.getLogger();

const Registration = () => {
  const navigate = useNavigate();
  const regCode = useSessionStore((state) => state.registrationCode);
  const regCodes = useRegCodes((state) => state.registrationCodes);
  const getRegCodeDetails = useRegCodes((state) => state.getRegCodeDetails);
  const setSession = useSessionStore((state) => state.setSession);
  const resetSession = useSessionStore((state) => state.resetSession);
  const purgeSession = useSessionStore((state) => state.purgeSession);
  const [error, setError] = useState<unknown>();
  const { publishLeavePageEvent } = useFadeAnimation();

  // Registration
  const {
    error: regError,
    clearError: clearResError,
    registered,
    isLoading,
    register,
  } = useRegistration();

  const getInputFieldName = (regCode: string) => {
    let code: string = '';
    const regCodeDetails = getRegCodeDetails(regCode);
    if (regCodeDetails?.alias) {
      code = regCodeDetails.alias;
    } else {
      code = regCode ?? '';
    }
    return code;
  };

  const [inputRegCode, setInputRegCode] = useState(
    getInputFieldName(regCode ?? '')
  );

  const onRegistration = () => {
    const regCodeWithoutSpace = regCode?.trim();
    register(regCodeWithoutSpace, false);
    setInputRegCode(getInputFieldName(regCodeWithoutSpace ?? ''));
  };

  const onRegCodeChange = (registrationCode: string) => {
    error && setError(undefined);
    setSession({ registrationCode });
    setInputRegCode(getInputFieldName(registrationCode ?? ''));
  };

  // Preloading resource data for login page
  const {
    isSuccess: isResReady,
    error: resError,
    fetchResource,
  } = useLoginResourceQuery();

  // Initialize page with clean up session
  useLayoutEffect(() => resetSession(), []);

  // Resource fetching
  useEffect(() => {
    registered && fetchResource();
  }, [registered]);

  // Go to login page after resource is preloaded
  useEffect(() => {
    if (isResReady) {
      logger.info('Navigating to Login page');
      publishLeavePageEvent().then(() => navigate('/login'));
    }
  }, [isResReady]);

  // Error
  useEffect(() => {
    if (regError ?? resError) {
      setError(regError ?? resError);
      clearResError();
      purgeSession();
    }
  }, [regError, resError]);
  return (
    <HorizontalSplitLayout logo={logo} isShowHealthCheckStatus={false}>
      <FadeAnimation>
        <RegistrationComponent
          regCodeLastUsed={inputRegCode}
          regCodes={regCodes}
          onRegCodeChange={onRegCodeChange}
          onRegistration={onRegistration}
          isLoading={isLoading || registered}
          error={error}
        />
      </FadeAnimation>
    </HorizontalSplitLayout>
  );
};

export default Registration;
