import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import {
  Link,
  ButtonDropdown,
  ButtonDropdownProps,
} from '@cloudscape-design/components';
import { CancelableEventHandler } from '@cloudscape-design/components/internal/events';
import Settings from '@/components/Settings';
import { LngOptions } from '@/i18n';
import { HeaderConstant } from './constants';

interface HeaderComponentProps {
  supportUrl: string;
  isNiva: boolean;
  isNativeMode: boolean;
  localeItems: HeaderConstant.ILocaleItem[];
  displayName?: string;
  onLngChange: (lng: LngOptions) => void;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  supportUrl,
  isNiva,
  isNativeMode,
  localeItems,
  onLngChange,
  displayName,
}) => {
  const { t } = useTranslation();

  const onLngChangeWrapper: CancelableEventHandler<
    ButtonDropdownProps.ItemClickDetails
  > = ({ detail }) => {
    const lng = detail.id as LngOptions;
    onLngChange(lng);
  };

  return (
    <div className={styles.container}>
      {isNiva ? null : (
        <Link href={supportUrl} target="_blank" rel="noreferrer" variant="info">
          <span className={styles.support}>{t('ws-support')}</span>
        </Link>
      )}
      {<Settings />}
      {!isNativeMode && (
        <ButtonDropdown
          items={localeItems}
          onItemClick={onLngChangeWrapper}
          ariaLabel={t('ws-language-label')}
        >
          {displayName}
        </ButtonDropdown>
      )}
    </div>
  );
};

export default HeaderComponent;
