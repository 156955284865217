import { ISessionStreamer } from '@bridge/ISessionStreamer';

export class MaxibonSessionStreamer implements ISessionStreamer {
  streamWorkspace(props: any) {
    return '';
  }

  disconnect() {}

  changeResolution() {}

  sendCtrlAltDel() {}
}
