import { useEffect, useState, useMemo } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useSessionStore } from '@stores/session';
import { useErrorHandler } from '@hooks/useErrorHandler';
import { useLoginResourceQuery } from '@hooks/useLoginResourceQuery';
import LoginComponent from './Login';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import HorizontalSplitLayout from '@/layout/HorizontalSplitLayout';
import FadeAnimation from '@/components/FadeAnimation';
import { SessionState } from '@/bridge/types/SoloRTCChannelTypes';
import { useFadeAnimation } from '@/hooks/useFadeAnimation';
import { useAuthManager } from '@/hooks/useAuthManager';
import { useRegCodes } from '@stores/registration';
import { useTelemetryFlush } from '@hooks/useTelemetryFlush';
import OEMOverlay from './OEMOverlay';
import { AuthProvider } from '@/core/wsbroker/types';

const sessionManager = CoreFactory.getSessionManager();
const isNativeMode = sessionManager.isNativeMode();
const metrics = CoreFactory.getMetrics();
const device = CoreFactory.getDevice();

const Login = () => {
  const navigate = useNavigate();
  const { showError } = useErrorHandler();
  const regCode = useSessionStore((state) => state.registrationCode);
  const getRememberMe = useRegCodes((state) => state.getRememberMeSetting);
  const isRememberMe = useMemo(() => {
    return getRememberMe(regCode) ?? false;
  }, [regCode]);
  const [rememberMeToggle, setRememberMeToggle] = useState(isRememberMe);

  const legalText = useSessionStore((state) => state.legalText);
  const supportUrl = useSessionStore((state) => state.supportUrl);
  const resourcePreloaded = useSessionStore((state) => state.resourcePreloaded);
  const [preloaded] = useState(resourcePreloaded);

  const getRememberMeSettingAtDirectoryLevel = useRegCodes(
    (state) => state.getRememberMeSettingAtDirectoryLevel
  );
  const toggleRememberMe = useRegCodes((state) => state.toggleRememberMe);
  const isRememberMeEnabledAtDirectorylevel = useMemo(() => {
    return getRememberMeSettingAtDirectoryLevel(regCode) ?? false;
  }, [regCode]);
  const isShowRememberMe = isNativeMode && isRememberMeEnabledAtDirectorylevel;
  const setSession = useSessionStore((state) => state.setSession);
  const [showLoading, setShowLoading] = useState(true);
  const {
    isLoading: isCoBrandingLoading,
    isSuccess: isCoBrandingLoadSuccess,
    error,
    fetchResource,
  } = useLoginResourceQuery();
  const { isLoading: isTelemetryFlushInProgress, flushTelemetry } =
    useTelemetryFlush();
  const isPreparing =
    !preloaded &&
    (showLoading || isCoBrandingLoading || isTelemetryFlushInProgress);
  const { publishLeavePageEvent } = useFadeAnimation();
  const { navigateToAuthUrl } = useAuthManager();
  const [isLeaving, setIsLeaving] = useState(false);
  const isWarpDrive = useSessionStore(
    (state) => state.nextAuthMethod?.AuthProvider === AuthProvider.WARP_DRIVE
  );
  const [searchParams] = useSearchParams();
  const skipBranding = searchParams.get('skipBranding') === 'true';

  /*
   * Go to registration page if:
   * 1. User wants to enter a new reg code
   * 2. (Automatic) No reg code in memory
   */
  const navigateToRegistration = () => {
    publishLeavePageEvent().then(() => navigate('/registration'));
  };

  const processLogin = () => {
    setIsLeaving(true);
    navigateToAuthUrl();
  };

  useEffect(() => {
    const handleCheckboxValueChange: EventListener = (event) => {
      const customEvent = event as CustomEvent<boolean>;
      setRememberMeToggle(customEvent.detail);
    };
    window.addEventListener(
      'rememberMeValueChanged',
      handleCheckboxValueChange
    );
    return () => {
      window.removeEventListener(
        'rememberMeValueChanged',
        handleCheckboxValueChange
      );
    };
  }, []);

  const rememberMeCheckboxCallback = (isRememberMe: boolean) => {
    // persist the remember me settings of user
    toggleRememberMe(regCode, isRememberMe);
    setRememberMeToggle(isRememberMe);
    window.dispatchEvent(
      new CustomEvent('rememberMeValueChanged', { detail: isRememberMe })
    );
  };

  // Initialization
  useEffect(() => {
    setSession({
      sessionState: SessionState.NEGOTIATION,
    });

    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Reset resource preloaded flag
  useEffect(() => {
    if (resourcePreloaded) {
      // clean up status
      setSession({ resourcePreloaded: false });
    }
  }, [resourcePreloaded]);

  // Fetch resource if resource was not preloaded
  useEffect(() => {
    preloaded || (regCode && fetchResource());
  }, [preloaded, regCode]);

  /*
   Flush telemetry once resource fetch is complete or if resources are already preloaded
   Also emit app launch metric after flushing logs from previous session. to ensure we only emit this metric once for all
   scenarios.
  */
  useEffect(() => {
    if (regCode && (preloaded || isCoBrandingLoadSuccess)) {
      flushTelemetry();
      metrics.emitApplicationLaunch();
    }
  }, [preloaded, regCode, isCoBrandingLoadSuccess]);

  // Error
  useEffect(() => {
    error && showError(error);
  }, [error]);

  // Skip branding
  useEffect(() => {
    skipBranding && !isPreparing && processLogin();
  }, [skipBranding, isPreparing]);

  if (!regCode) {
    return <Navigate to="/registration" replace />;
  }

  if (error) {
    return null;
  }

  const isOemOverlayNeeded = device.getIsOemHandshaked();
  return (
    <HorizontalSplitLayout
      brandDescription={isPreparing ? undefined : legalText}
      supportUrl={isPreparing ? undefined : supportUrl}
      isShowHealthCheckStatus={isNativeMode}
    >
      <FadeAnimation>
        {!isOemOverlayNeeded ? (
          <LoginComponent
            onClickLogin={processLogin}
            onChangeRegistrationCode={navigateToRegistration}
            isShowRememberMe={isShowRememberMe}
            isRememberMe={rememberMeToggle}
            onIsRememberMeChecked={rememberMeCheckboxCallback}
            isPreparing={isPreparing}
            isLeaving={isLeaving}
            isWarpDrive={isWarpDrive}
          />
        ) : (
          <OEMOverlay />
        )}
      </FadeAnimation>
    </HorizontalSplitLayout>
  );
};

export default Login;
