import { InSessionFactory } from '@/bridge/factory/InSessionFactory';
import { AuthProvider } from '@/core/wsbroker/types';
import { useSessionStore } from '@/stores/session';
import { useEffect, useState } from 'react';
import { useErrorHandler } from './useErrorHandler';
import { CoreFactory } from '@/bridge/factory/CoreFactory';

const heartbeat = InSessionFactory.getHeartBeat();
const logger = CoreFactory.getLogger();

export const useHeartBeatInfo = () => {
  const regCode = useSessionStore((state) => state.registrationCode);
  const region = useSessionStore((state) => state.region);
  const [isSuccess, setIsSuccess] = useState(false);
  const { showError } = useErrorHandler();
  const authProvider = useSessionStore(
    (state) => state.sessionContext?.AuthProvider
  );

  const getHeartBeatInfo = async () => {
    try {
      logger.info('Send getHeartBeatInfo Request');
      await heartbeat.getHeartBeatInfo(regCode, region);
      setIsSuccess(true);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (
      authProvider !== AuthProvider.SAML_IAM &&
      authProvider !== AuthProvider.IDC
    ) {
      setIsSuccess(true);
    } else {
      getHeartBeatInfo();
    }
  }, [authProvider]);

  return {
    isSuccess,
  };
};
