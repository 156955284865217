import { useSessionStore } from '@stores/session';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import PCoIP from '@views/Stream/PCoIP';
import Maxibon from '@views/Stream/Maxibon';

// TODO: if missing the proper session data, redirect to registration step
// TODO: session store need to have one or more expire dates (session connect, streaming connect)
const BrowserMode: React.FC = () => {
  const protocol = useSessionStore((state) => state.resourceProtocol);

  switch (protocol) {
    case SessionProtocols.PCOIP:
      return <PCoIP />;
    case SessionProtocols.MAXIBON:
      return <Maxibon />;
    default:
      // Parent component ensures we never reach here.
      return null;
  }
};
export default BrowserMode;
