import React, { useCallback, useEffect, useState } from 'react';
import { useCustomTranslation } from '@/i18n';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { isFullScreen } from '@views/Stream/utils';

import styles from './styles.module.scss';
import menuLogo from '@img/logo_menu.png';
import fullScreenImg from '@img/full_screen.svg';
import cadImg from '@img/cad.svg';
import disconnectImg from '@img/disconnect.svg';
import pinImg from '@img/pin.svg';

const logger = CoreFactory.getLogger();
interface MenuProps {
  onDisconnect: () => void;
  toggleFullScreen: () => void;
  sendCtrlAltDel: () => void;
}

const Menu: React.FC<MenuProps> = ({
  onDisconnect,
  toggleFullScreen,
  sendCtrlAltDel,
}) => {
  const { t } = useCustomTranslation();

  const [pinMenu, setPinMenu] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [fullScreenText, setFullScreenText] = useState<string>(
    t('ws-enter-full-screen')
  );
  const menuOn = useCallback(() => setShowMenu(true), []);
  const menuOff = useCallback(() => setShowMenu(false), []);
  const menuMouseLeave = (): void => {
    !pinMenu && menuOff();
  };
  const menuMouseEnter = (): void => {
    menuOn();
  };
  const togglePinMenu = (): void => {
    setPinMenu((prevPinMenu) => !prevPinMenu);
  };

  useEffect(() => {
    const onFullScreenChange = (): void => {
      logger.info('Full screen changed');

      if (isFullScreen()) {
        setFullScreenText(t('ws-exit-full-screen'));
      } else {
        setFullScreenText(t('ws-enter-full-screen'));
      }
    };
    document.onfullscreenchange = onFullScreenChange;

    return () => {
      document.onfullscreenchange = null;
    };
  }, []);

  return (
    <>
      <div
        id="menu-hover"
        data-testid="menu-hover"
        className={styles['menu-hover']}
        onMouseEnter={menuMouseEnter}
      ></div>
      <div
        id="menu-container"
        data-testid="menu-container"
        className={`${styles['menu-container']} ${styles['ws-menu-fade']} ${
          styles[showMenu ? 'show' : 'hide']
        }`}
        onMouseLeave={menuMouseLeave}
        onMouseEnter={menuMouseEnter}
      >
        <img
          src={menuLogo}
          alt={t('ws-menubar-logo-alt')}
          id="menu-logo"
          className={styles['menu-logo']}
          crossOrigin="anonymous"
        />
        <img
          className={styles['menu-img']}
          src={fullScreenImg}
          alt={t('ws-menubar-full-screen-toggle-icon-alt')}
          crossOrigin="anonymous"
        />
        <button
          id="toggle-fullscreen-button"
          className={styles['menu-item']}
          onClick={toggleFullScreen}
        >
          {fullScreenText}
        </button>
        <img
          className={styles['menu-img']}
          src={cadImg}
          alt={t('ws-menubar-send-cad-icon-alt')}
          crossOrigin="anonymous"
        />
        <button
          id="ctrl-alt-del-button"
          className={styles['menu-item']}
          onClick={sendCtrlAltDel}
        >
          {t('ws-send-cad')}
        </button>
        <img
          className={styles['menu-img']}
          src={disconnectImg}
          alt={t('ws-menubar-disconnect-icon-alt')}
          crossOrigin="anonymous"
        />
        <button
          id="disconnect-button"
          className={styles['menu-item']}
          onClick={onDisconnect}
        >
          {t('ws-disconnect')}
        </button>
        <img
          className={`${styles['menu-img']}${
            pinMenu ? ' ' + styles.pinned : ''
          }`}
          src={pinImg}
          alt={t('ws-menubar-pin-icon-alt')}
          crossOrigin="anonymous"
        />
        <button className={styles['menu-item']} onClick={togglePinMenu}>
          {t('ws-pin')}
        </button>
      </div>
    </>
  );
};

export default Menu;
