import { useCallback, useEffect, RefObject } from 'react';

import styles from './styles.module.scss';

interface VideoProps {
  inputElement: RefObject<HTMLDivElement>;
  videoElement: RefObject<HTMLVideoElement>;
  canvasElement: RefObject<HTMLCanvasElement>;
  layCanvasOntop: () => void;
  videoContainerWidth: number;
  videoContainerHeight: number;
  videoWidth: number;
  videoHeight: number;
  videoLeft: string;
  videoTop: string;
  videoTransform: string;
  videoZIndex: number;
  canvasZIndex: number;
  showCanvas: boolean;
  showContainer: boolean;
}

// TODO: if missing the proper session data, redirect to registration step
// TODO: session store need to have one or more expire dates (session connect, streaming connect)
const Video: React.FC<VideoProps> = ({
  inputElement,
  videoElement,
  canvasElement,
  layCanvasOntop,
  videoWidth,
  videoHeight,
  videoLeft,
  videoTop,
  videoTransform,
  videoZIndex,
  canvasZIndex,
  showCanvas,
  showContainer,
}) => {
  // focus the key events to the div containing the video element
  const focusVideoContainer = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // divs need focus to handle to key press events
      event.currentTarget.focus();
    },
    []
  );

  useEffect(() => {
    const videoContainer = inputElement.current;
    if (videoContainer === null) return;

    const fullScreenListener = (): void => layCanvasOntop();
    videoContainer.addEventListener(
      'webkitfullscreenchange',
      fullScreenListener,
      false
    );
    videoContainer.addEventListener(
      'mozfullscreenchange',
      fullScreenListener,
      false
    );
    videoContainer.addEventListener(
      'fullscreenchange',
      fullScreenListener,
      false
    );

    return () => {
      videoContainer.removeEventListener(
        'webkitfullscreenchange',
        fullScreenListener
      );
      videoContainer.removeEventListener(
        'mozfullscreenchange',
        fullScreenListener
      );
      videoContainer.removeEventListener(
        'fullscreenchange',
        fullScreenListener
      );
    };
  }, [inputElement, layCanvasOntop]);

  return (
    <div
      id="video-container"
      data-testid="video-container"
      ref={inputElement}
      className={styles['video-container']}
      tabIndex={0}
      onClick={focusVideoContainer}
      style={{
        display: showContainer ? 'block' : 'none',
      }}
    >
      <video
        id="stx-video"
        ref={videoElement}
        autoPlay
        className={styles['stx-video']}
        width={videoWidth}
        height={videoHeight}
        style={{
          width: videoWidth,
          height: videoHeight,
          zIndex: videoZIndex,
          left: videoLeft,
          top: videoTop,
          transform: videoTransform,
        }}
      ></video>
      <canvas
        id="stx-canvas"
        data-testid="stx-canvas"
        ref={canvasElement}
        className={styles['stx-canvas']}
        width={videoWidth}
        height={videoHeight}
        style={{
          width: videoWidth,
          height: videoHeight,
          zIndex: canvasZIndex,
          left: videoLeft,
          top: videoTop,
          transform: videoTransform,
          display: showCanvas ? 'block' : 'none',
        }}
      ></canvas>
    </div>
  );
};
export default Video;
