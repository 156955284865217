import { ISessionManager } from '@bridge/ISessionManager';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { IDevice } from '@bridge/IDevice';
import { IRTCChannel } from '@bridge/IRTCChannel';
import { IAuthStep } from './IAuthStep';
import { GetAuthInfoStep } from './authSteps/GetAuthInfoStep';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import { IAuthManager } from './IAuthManager';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { AuthRoutes, IAuthRelativePath } from '@/bridge/routes/AuthRoutes';
import { WarpDriveStep } from './authSteps/WarpDriveStep';
import { SamlStep } from './authSteps/SamlStep';
import { ReauthenticateStep } from './authSteps/ReauthenticateStep';
import { ReconnectStep } from './authSteps/ReconnectStep';
import { AuthStepDeterminer } from './AuthStepDeterminer';
import { ExternalAuthUrlDirector } from './authUrls/ExternalAuthUrlDirector';
import { AuthProvider } from '@/core/wsbroker/types';
import {
  IAuthCompleteStep,
  IAuthExternalStep,
  IAuthInfoStep,
  IAuthInternalStep,
} from './types';
import { Authentication } from './Authentication';
import { AuthStepProps } from './authSteps/BaseStep';
import { SessionProtocols } from '@/bridge/types/SessionTypes';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { AppConstants } from '@bridge/constants/AppConstants';
import { IdcStep } from '@/presession/authentication/authSteps/IdcStep';

/**
 * AuthManager will handle the different types of authentication strategies for web client in the future
 * Currently we support WarpDrive and SAML authentication for web client.
 * We support WarpDrive and Reconnect for Solo Linux client.
 */
export class AuthManager implements IAuthManager {
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly getAuthInfoStep: IAuthStep;
  private readonly warpDriveStep: IAuthStep;
  private readonly samlStep: IAuthStep;
  private readonly idcStep: IAuthStep;
  private readonly reauthenticateStep: IAuthStep;
  private readonly reconnectStep: IAuthStep;
  private readonly authStepDeterminer: AuthStepDeterminer;
  private readonly externalAuthUrlDirector: ExternalAuthUrlDirector;

  constructor(
    sessionManager: ISessionManager,
    logger: ILogger,
    metrics: IMetrics,
    device: IDevice,
    wsBrokerClient: WSBrokerService,
    rtcChannel: IRTCChannel | null
  ) {
    this.sessionManager = sessionManager;
    this.logger = logger;
    this.metrics = metrics;
    const authentication = new Authentication({
      wsBrokerClient,
      sessionManager,
      metrics,
      logger,
      device,
    });
    const authStepProps: AuthStepProps = {
      sessionManager,
      authentication,
      metrics,
      logger,
      rtcChannel,
    };
    this.getAuthInfoStep = new GetAuthInfoStep(authStepProps);
    this.warpDriveStep = new WarpDriveStep(authStepProps);
    this.samlStep = new SamlStep(authStepProps);
    this.idcStep = new IdcStep(authStepProps);
    this.reauthenticateStep = new ReauthenticateStep(authStepProps);
    this.reconnectStep = new ReconnectStep(authStepProps);
    this.authStepDeterminer = new AuthStepDeterminer({
      sessionManager,
      logger,
      device,
    });
    this.externalAuthUrlDirector = new ExternalAuthUrlDirector();
  }

  async init() {
    return (await this.getAuthInfoStep.perform()) as IAuthInfoStep;
  }

  reconnect() {
    this.logger.info('Attempting user reconnection');
    this.reconnectValidate();

    /*
     We start the counter for Session TimedOperation which will be finally emitted after streaming starts:
     SoloContext : Streaming started after Streaming RTC response is successful
     BrowserContext : WSP starts streaming
    */
    const sessionTimeMetricTracker = this.metrics.embarkSessionMetric();
    const protocol = this.sessionManager.get('resourceProtocol');
    if (
      protocol === SessionProtocols.MAXIBON ||
      protocol === SessionProtocols.WSP
    ) {
      sessionTimeMetricTracker.start(true);
      const nextStep: IAuthCompleteStep = {
        complete: true,
        external: false,
      };
      return nextStep;
    } else if (protocol === SessionProtocols.PCOIP) {
      sessionTimeMetricTracker.start(true);
      const nextStep: IAuthInternalStep = {
        complete: false,
        external: false,
        url: '/auth/reauthenticate',
      };
      return nextStep;
    }

    throw new WsError(
      WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
      WsErrorCodes.ERROR_UNSUPPORTED_PROTOCOL,
      ClientErrorCode.AuthUnknown
    );
  }

  fallback() {
    const fallbackAuthMethod = this.sessionManager.get('fallbackAuthMethod');
    if (
      !fallbackAuthMethod ||
      fallbackAuthMethod.AuthProvider !== AuthProvider.WARP_DRIVE ||
      !fallbackAuthMethod.Url
    ) {
      this.logger.error(
        'Missing fallback auth method or fallback auth method is invalid'
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_FALLBACK_METHOD,
        ClientErrorCode.AuthInvalidFallbackMethod
      );
    }

    this.sessionManager.set({
      nextAuthMethod: fallbackAuthMethod,
    });

    const nextStep: IAuthExternalStep = {
      complete: false,
      external: true,
      url: fallbackAuthMethod.Url,
    };

    return nextStep;
  }

  determineAuthStep(): IAuthInternalStep {
    return this.authStepDeterminer.getInternalAuthStepFromSession();
  }

  async performAuthStep(step: IAuthRelativePath) {
    switch (step) {
      case AuthRoutes.RELATIVE_PATHS.WARP_DRIVE:
        this.metrics.embarkSessionMetric().start(false);
        return await this.warpDriveStep.perform();
      case AuthRoutes.RELATIVE_PATHS.SAML:
        return await this.samlStep.perform();
      case AuthRoutes.RELATIVE_PATHS.IDC:
        return await this.idcStep.perform();
      case AuthRoutes.RELATIVE_PATHS.REAUTHENTICATE:
        return await this.reauthenticateStep.perform();
      case AuthRoutes.RELATIVE_PATHS.RECONNECT:
        return await this.reconnectStep.perform();
      default:
        this.logger.error('auth type is not supported by performing auth step');
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
          WsErrorCodes.ERROR_UNSUPPORTED_AUTH_TYPE
        );
    }
  }

  buildExternalAuthUrl(baseUrl?: string) {
    let url = baseUrl;
    if (!url) {
      url = this.sessionManager.get('nextAuthMethod')?.Url;
    }
    if (!url) {
      this.logger.error(
        'Missing authenticate url. Unable to proceed to authentication'
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_WARPDRIVE,
        WsErrorCodes.ERROR_INVALID_INPUT,
        ClientErrorCode.AuthInfoInvalidResponse
      );
    }

    if (this.authStepDeterminer.isAuthProvider(AuthProvider.WARP_DRIVE)) {
      return this.externalAuthUrlDirector.buildWarpDriveUrl(url);
    } else if (this.authStepDeterminer.isAuthProvider(AuthProvider.SAML_IAM)) {
      return this.externalAuthUrlDirector.buildSamlUrl(url);
    } else if (this.authStepDeterminer.isAuthProvider(AuthProvider.IDC)) {
      return this.externalAuthUrlDirector.buildIdcUrl(url);
    } else {
      this.logger.error(
        'unexpected auth provider for building external auth url'
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_UNSUPPORTED_AUTH_TYPE
      );
    }
  }

  buildExternalAuthRedirectFailedErrorHandlerUrl() {
    const redirectPort = window.location.port ? `:${window.location.port}` : '';
    const authRedirectFailHandlerUrl = new URL(
      `https://${window.location.hostname}${redirectPort}/error`
    );

    let clientErrorCode = ClientErrorCode.AuthExternalRedirectUnknownError;
    if (this.authStepDeterminer.isAuthProvider(AuthProvider.WARP_DRIVE)) {
      clientErrorCode = ClientErrorCode.AuthExternalWDRedirectUnknownError;
    } else if (this.authStepDeterminer.isAuthProvider(AuthProvider.SAML_IAM)) {
      clientErrorCode = ClientErrorCode.AuthExternalSamlRedirectUnknownError;
    } else if (this.authStepDeterminer.isAuthProvider(AuthProvider.IDC)) {
      clientErrorCode = ClientErrorCode.AuthExternalIdcRedirectUnknownError;
    }

    authRedirectFailHandlerUrl.searchParams.set(
      AppConstants.ERROR_URL_CLIENT_ERROR_CODE_SEARCH_PARAM_KEY,
      JSON.stringify(clientErrorCode)
    );
    authRedirectFailHandlerUrl.searchParams.set(
      AppConstants.ERROR_URL_SOLO_ERROR_CODE_SEARCH_PARAM_KEY,
      ''
    );

    return authRedirectFailHandlerUrl;
  }

  private reconnectValidate() {
    if (!this.authStepDeterminer.isEligibleForReconnection()) {
      this.logger.error('Platform is not supported for reconnection');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_UNSUPPORTED_PLATFORM,
        ClientErrorCode.AuthUnknown
      );
    }

    if (!this.authStepDeterminer.doesResourceExistForReconnection()) {
      this.logger.error('Resource not found in memory for reconnection');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }

    if (!this.authStepDeterminer.isTokenValidForReconnection()) {
      this.logger.error('User token has expired for reconnection');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_TOKEN_EXPIRED,
        ClientErrorCode.AuthTokenExpired
      );
    }
  }
}
