import { useMemo } from 'react';
import { Alert } from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { I18nError } from '@/lib/I18nError';

interface ErrorAlertProps {
  error: I18nError;
  onDismiss: () => void;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, onDismiss }) => {
  const { t } = useCustomTranslation();
  const errorMessage = useMemo(
    () => error?.localeKeys.map((k) => t(k)).join(' '),
    [error]
  );

  return (
    <Alert
      statusIconAriaLabel={t('ws-error-alert-icon')}
      dismissAriaLabel={t('ws-modal-close')}
      dismissible
      type="error"
      onDismiss={onDismiss}
    >
      {errorMessage}
    </Alert>
  );
};

export default ErrorAlert;
