import { useEffect } from 'react';
import { useAuthManager } from '@/hooks/useAuthManager';
import ConnectStatus from '@components/ConnectStatus';

/**
 * This is the entrance of all unknown auth situation. Our auth manager will decide our next step and redirect us there.
 * Once redirected, the sub path will run the proper step to continue the authtentication
 * Right now for web client we support:
 *  1. WarpDrive;
 *  2. SAML;
 * For solo linux client we support:
 *  1. WarpDrive;
 *  2. Reconnect (on partial platforms);
 *
 * ToDo: Investigate common react cred masking procedures to handle such sensitive information while passing them to
 *  other functions
 * */
const Auth = () => {
  const { goToNextAuthStep } = useAuthManager();

  useEffect(() => {
    goToNextAuthStep();
  }, []);

  return (
    <>
      <ConnectStatus />
    </>
  );
};

export default Auth;
