import { AuthStepDeterminer } from '../AuthStepDeterminer';
import { BaseStep } from './BaseStep';

export class ReauthenticateStep extends BaseStep {
  async perform() {
    const authResponse = await this.authentication.authenticate(
      this.authRequest.constructReauthenticateAuthRequest(),
      'reauthenticate'
    );
    return AuthStepDeterminer.getNextStepFromAuthResponse(authResponse);
  }
}
