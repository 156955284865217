import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionFailure,
  ActionType,
  GetPlatformDetailsResponse,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

export class PlatformDetailsHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor(logger: ILogger, metrics: IMetrics) {
    this.logger = logger;
    this.metrics = metrics;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    try {
      this.validate(msg);
      const response = msg.Payload;
      if ('ErrorCode' in response) {
        this.handleErrorResponse(response);
        return;
      }
      const platformDetails = response as GetPlatformDetailsResponse;
      callback?.(platformDetails);
    } catch (error) {
      this.metrics.emit(Operation.Negotiation, MetricResult.Fault, error);
    }
  }

  private handleErrorResponse(response: ActionFailure) {
    this.logger.error(
      `Failed to get WebView Platform details from native client`
    );
    const error = new WsError(
      WsErrorTypes.ERROR_TYPE_NEGOTIATION,
      WsErrorCodes.ERROR_INVALID_INPUT,
      ClientErrorCode.PlatformDetailsUnKnown
    );
    error.name = response?.ErrorCode;
    error.message = response?.ErrorMessage;
    this.metrics.emit(Operation.Negotiation, MetricResult.Fault, error);
  }

  private validate(msg: RTCChannelMessage) {
    this.logger.info(
      `Received Platform details from Solo ${JSON.stringify(msg)}`
    );
    const response = msg.Payload;
    if (
      msg.Action !== Action.GET_PLATFORM_DETAILS ||
      msg.ActionType !== ActionType.RESPONSE ||
      !('ErrorCode' in response || 'AuthCapabilities' in response)
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_NEGOTIATION,
        WsErrorCodes.ERROR_INVALID_INPUT,
        ClientErrorCode.PlatformDetailsInvalidInput
      );
    }
  }
}
