import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  usePcoipStreaming,
  States,
} from '@/views/Stream/hooks/usePcoipStreaming';
import { toggleFullScreen } from '@views/Stream/utils';
import { CoreFactory } from '@/bridge/factory/CoreFactory';

import PlayVideoOverlay from '../components/PlayVideoOverlay';
import Menu from '../components/Menu';
import Video from '../components/Video';
import ConnectStatus from '@/components/ConnectStatus';
import { useReportHeartbeatTask } from '@/hooks/useReportHeartbeatTask';

interface StreamingProps {}

const logger = CoreFactory.getLogger();

// TODO: if missing the proper session data, redirect to registration step
// TODO: session store need to have one or more expire dates (session connect, streaming connect)
const PCoIP: React.FC<StreamingProps> = () => {
  const navigate = useNavigate();

  const parentElement = useRef<HTMLDivElement>(null);
  const inputElement = useRef<HTMLDivElement>(null);
  const videoElement = useRef<HTMLVideoElement>(null);
  const canvasElement = useRef<HTMLCanvasElement>(null);

  const {
    changeResolution,
    sendCtrlAltDel,
    status,
    setStatus,
    disconnect,
    layCanvasOntop,
    videoContainerWidth,
    videoContainerHeight,
    videoWidth,
    videoHeight,
    videoLeft,
    videoTop,
    videoTransform,
    videoZIndex,
    canvasZIndex,
    showCanvas,
  } = usePcoipStreaming(inputElement, videoElement, canvasElement);

  const { startReportingHeartbeat } = useReportHeartbeatTask();
  const onDisconnect = useCallback(() => {
    disconnect();
    navigate('/disconnect');
  }, [disconnect, navigate]);

  const onToggleFullScreen = useCallback(
    () => parentElement.current && toggleFullScreen(parentElement.current),
    []
  );

  useEffect(() => {
    status === States.connected && startReportingHeartbeat();
  }, [status]);

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout;
    const resizeListner = () => {
      logger.info('Window resize requested');
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        logger.info('Window resize finished.');

        layCanvasOntop();
        changeResolution();
      }, 200);
    };
    window.addEventListener('resize', resizeListner);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', resizeListner);
    };
  }, [changeResolution, layCanvasOntop]);

  const playVideo = useCallback(() => {
    if (videoElement.current == null) return;

    videoElement.current.play();
    setStatus(States.connected);
  }, [videoElement, setStatus]);

  return (
    <div id="parent-container" ref={parentElement} style={{ height: '100%' }}>
      {status === States.connecting && <ConnectStatus />}
      {status === States.pending && <PlayVideoOverlay playVideo={playVideo} />}
      {status === States.connected && (
        <Menu
          onDisconnect={onDisconnect}
          toggleFullScreen={onToggleFullScreen}
          sendCtrlAltDel={sendCtrlAltDel}
        />
      )}
      <Video
        inputElement={inputElement}
        videoElement={videoElement}
        canvasElement={canvasElement}
        layCanvasOntop={layCanvasOntop}
        videoContainerWidth={videoContainerWidth}
        videoContainerHeight={videoContainerHeight}
        videoWidth={videoWidth}
        videoHeight={videoHeight}
        videoLeft={videoLeft}
        videoTop={videoTop}
        videoTransform={videoTransform}
        videoZIndex={videoZIndex}
        canvasZIndex={canvasZIndex}
        showCanvas={showCanvas}
        showContainer={status === States.connected}
      />
    </div>
  );
};
export default PCoIP;
