import { SessionState } from '@bridge/types/SoloRTCChannelTypes';
import { I18nKey } from '@/i18n/types';

export type ISessionStateToUIMessage = {
  [key in SessionState]: I18nKey;
};

export const SessionStateToUIMessage: ISessionStateToUIMessage = {
  [SessionState.NEGOTIATION]: 'ws-connect-status-negotiation',
  [SessionState.REGISTRATION]: 'ws-connect-status-registration',
  [SessionState.HELLO]: 'ws-connect-status-hello',
  [SessionState.CUSTOMIZATION]: 'ws-connect-status-customization',
  [SessionState.WARP_DRIVE_REDIRECTION_START]: 'ws-connect-status-warp-drive',
  [SessionState.WARP_DRIVE_REDIRECTION_COMPLETE]:
    'ws-connect-status-warp-drive-complete',
  [SessionState.SAML_IAM_REDIRECTION_START]: 'ws-connect-status-saml',
  [SessionState.IDC_IAM_REDIRECTION_START]: 'ws-connect-status-saml',
  [SessionState.AUTHENTICATE]: 'ws-connect-status-authenticate',
  [SessionState.GET_RESOURCE]: 'ws-connect-status-get_resource',
  [SessionState.ALLOCATE_RESOURCE]: 'ws-connect-status-allocate-resource',
  [SessionState.CONNECT]: 'ws-connect-status-connect',
  [SessionState.RESUME]: 'ws-connect-status-resume',
  [SessionState.RESUME_PENDING]: 'ws-connect-status-resume-pending',
  [SessionState.STREAMING]: 'ws-connect-status-streaming',
  [SessionState.DISCONNECT]: 'ws-connect-status-disconnect',
  [SessionState.STREAM_CONTEXT_RECEIVED]:
    'ws-connect-status-stream-context-received',
};
