import { useCustomTranslation } from '@/i18n';
import { SessionStateToUIMessage } from './types';
import { useSessionStore } from '@/stores/session';
import { ProgressIndicator } from '@amzn/aws-euc-ui';
import { useRegCodes } from '@stores/registration';
import { useMemo } from 'react';

const ConnectStatusWithoutLogoComponent: React.FC = () => {
  const { t } = useCustomTranslation();
  const sessionState = useSessionStore((state) => state.sessionState);
  const statusDetailLocaleKey = SessionStateToUIMessage[sessionState];
  const regCode = useSessionStore((state) => state.registrationCode);
  const getWSName = useRegCodes((state) => state.getWSName);
  const wsAlias = useMemo(
    // fetchs the alias name of the WS if exists else returns the actual reg code.
    () => getWSName(regCode ?? ''),
    [regCode]
  );
  const message = regCode
    ? `${t(statusDetailLocaleKey)}: ${wsAlias}`
    : t(statusDetailLocaleKey);

  return (
    <ProgressIndicator
      indicatorStyle={{
        width: '260px',
      }}
      determinate={false}
      footer={message ?? 'Loading'}
      space={'xl'}
      ariaLabel={t('ws-progress-bar')}
    />
  );
};

export default ConnectStatusWithoutLogoComponent;
