import { IDevice } from '@/bridge/IDevice';
import { ISessionManager } from '@/bridge/ISessionManager';
import { Dimension, MetricName, Operation } from '@bridge/types/MetricTypes';
import { MetricConstants } from '@core/constants/MetricConstants';
import { DimensionSet, MetricPoint, MetricSet } from './types';
import { MetricsPublisher } from './MetricsPublisher';
import { CrashReporter } from '../crashReporter/CrashReporter';
import { IRegion } from '@/bridge/types/RegionTypes';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { IHttpClient } from '@bridge/IHttpClient';

export class MetricsManager {
  private readonly _device: IDevice;
  private readonly _sessionManager: ISessionManager;
  private readonly _metricsPublisher: MetricsPublisher;
  private readonly httpClient: IHttpClient<any>;

  constructor(
    device: IDevice,
    sessionManager: ISessionManager,
    metricsPublisher: MetricsPublisher,
    httpClient: IHttpClient<any>
  ) {
    this._device = device;
    this._sessionManager = sessionManager;
    this._metricsPublisher = metricsPublisher;
    this.httpClient = httpClient;
  }

  public record(
    operation: Operation,
    metric: MetricName,
    value: number,
    dimensions: DimensionSet
  ) {
    const metrics: MetricSet = {
      [metric]: value.toString(),
    };
    this.recordMetrics(operation, metrics, dimensions);
  }

  public publishException(error: any) {
    const region = this._sessionManager.get('region');
    const errorMsg = error?.toString() as string;
    console.log(errorMsg);
    CrashReporter.reportUnhandledException(
      errorMsg,
      region as IRegion,
      this.httpClient,
      true,
      CoreFactory.getLogger()
    );
  }

  public async flush() {
    return await this._metricsPublisher.flush();
  }

  private recordMetrics(
    operation: Operation,
    metrics: MetricSet,
    dimensions: DimensionSet
  ) {
    const dateTimeNow = Date.now();
    const metricPoint = this.createMetricPoint(
      operation,
      metrics,
      dateTimeNow,
      dimensions
    );
    this._metricsPublisher.publish(metricPoint);
  }

  private createMetricPoint(
    operation: Operation,
    metrics: MetricSet,
    recordTime: number,
    dimensions: DimensionSet
  ): MetricPoint {
    dimensions = this.addMandatoryDimensions(dimensions, operation);
    const normalizedDimensions = this.normalizeDimensions(dimensions);
    return {
      op: operation.toString(),
      recordTime,
      metrics,
      dimensions: normalizedDimensions,
    };
  }

  private normalizeDimensions(dimensions: DimensionSet): DimensionSet {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const result = {} as DimensionSet;
    for (const key in dimensions) {
      result[key as Dimension] = this.normalizeDimensionValue(
        dimensions[key as Dimension]
      );
    }
    return result;
  }

  private normalizeDimensionValue(value: string) {
    if (value) {
      return value.substring(
        0,
        Math.min(value.length, MetricConstants.MAX_METRIC_DIMENSION_LENGTH)
      );
    }
    return value;
  }

  // ToDo: Improve this code in future to move dimensionSet updates to new function
  private addMandatoryDimensions(
    dimensions: DimensionSet,
    operation: Operation
  ): DimensionSet {
    if (!dimensions[Dimension.AppName]) {
      dimensions[Dimension.AppName] = this._device.getPlatformAppName();
    }
    if (!dimensions[Dimension.Operation]) {
      dimensions[Dimension.Operation] = operation.toString();
    }
    if (!dimensions[Dimension.Version]) {
      dimensions[Dimension.Version] =
        this._device.getProductVersion() ?? MetricConstants.UNDEFINED;
    }
    if (!dimensions[Dimension.LoginType]) {
      dimensions[Dimension.LoginType] =
        this._sessionManager.get('loginType') ?? MetricConstants.UNDEFINED;
    }
    // The dimensions below generally wont be passed by callers
    dimensions[Dimension.Region] =
      this._sessionManager.get('region')?.endpoint ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.DeviceId] =
      this._device.getDeviceUUID() ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.HostOs] =
      this._device.getHostOS() ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.HostOsVersion] =
      this._device.getHostOSVersion() ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.ProxyType] = this._device.getProxyType();
    dimensions[Dimension.OrgName] =
      this._sessionManager.get('domainProperties')?.OrganizationName ??
      MetricConstants.UNDEFINED;
    dimensions[Dimension.DirectoryId] =
      this._sessionManager.get('directoryId') ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.SessionId] =
      this._sessionManager.get('sessionContext')?.SessionId ??
      MetricConstants.UNDEFINED;
    dimensions[Dimension.Is64BitClient] = String(this._device.is64BitClient());
    dimensions[Dimension.ProtocolName] =
      this._sessionManager.get('resourceProtocol') ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.ResourceId] =
      this._sessionManager.get('resourceId') ?? '';
    dimensions[Dimension.ProtocolVersion] =
      this._device.getProtocolVersion() ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.IsSoloClient] = JSON.stringify(
      this._sessionManager.isNativeMode()
    );
    dimensions[Dimension.PlatformName] =
      this._device.getPlatformName() ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.PlatformVersion] =
      this._device.getPlatformVersion() ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.ResourceType] =
      this._sessionManager.get('resourceType') ?? MetricConstants.UNDEFINED;
    dimensions[Dimension.AuthProvider] =
      this._sessionManager.get('primaryAuthMethod')?.AuthProvider ??
      MetricConstants.UNDEFINED;
    dimensions[Dimension.SUPPORTS_BP_V2] =
      this._device.supportsBrokerProtocolV2() ? '1' : '0';
    dimensions[Dimension.USES_BP_V2] =
      this._sessionManager.isUseBrokerProtocolV2() ? '1' : '0';

    return dimensions;
  }
}
