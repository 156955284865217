import { useEffect, useRef } from 'react';
import { useSessionProvisionQuery } from '@/hooks/useSessionProvisionQuery';
import { useSessionStore } from '@/stores/session';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { useNavigate } from 'react-router-dom';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import ConnectStatus from '@/components/ConnectStatus';
import { WsError } from '@/bridge/WsError';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { WebInSessionError } from '@/bridge/types/WebInSessionError';
import { useAuthManager } from '@/hooks/useAuthManager';

interface SessionProps {}
const logger = CoreFactory.getLogger();

const Session: React.FC<SessionProps> = () => {
  const resourceHandled = useRef(false);
  const navigate = useNavigate();
  const setSession = useSessionStore((state) => state.setSession);
  const sessionContext = useSessionStore((state) => state.sessionContext);
  const sessionId = sessionContext?.SessionId;
  const authToken = useSessionStore((state) => state.authToken);
  const registrationCode = useSessionStore((state) => state.registrationCode);
  const region = useSessionStore((state) => state.region);
  const { disconnect } = useAuthManager();

  const { data: resources, error } = useSessionProvisionQuery(
    authToken?.Value as string,
    sessionId as string,
    registrationCode,
    region
  );
  const { showError } = useErrorHandler();

  useEffect(() => {
    if (!resourceHandled.current && resources != null) {
      resourceHandled.current = true;
      navigate('/stream');
    }
  }, [resources, navigate, setSession]);

  useEffect(() => {
    if (!authToken || !sessionId || !registrationCode) {
      // redirect if missing auth code in query params
      logger.error(
        'Missing parameters. Redirecting back to Registration from Session page'
      );
      navigate('/registration');
    }
  }, [navigate, authToken, sessionId, registrationCode]);

  useEffect(() => {
    if (error) {
      if (
        error instanceof WsError &&
        error.clientErrorCode ===
          ClientErrorCode.AllocateResourceFallbackLoginRequired
      ) {
        // TODO: Switch from WebInSessionErrorCodes to general error codes
        disconnect(
          WebInSessionError.WspErrorTypes.CONNECTION,
          WebInSessionError.WspConnectionErrorCodes.FALLBACK_LOGIN_REQUIRED
        );
      } else {
        showError(error);
      }
    }
  }, [showError, disconnect, error]);

  return <ConnectStatus />;
};

export default Session;
