import { useEffect, useState } from 'react';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import {
  HealthCheckPorts,
  HealthCheckProtocol,
} from '@/bridge/constants/HealthCheckConstants';
import { useSessionStore } from '@/stores/session';
import { t } from 'i18next';
import {
  HealthCheckRequest,
  HealthCheckResponse,
  NetworkConnectivityResult,
} from '@/bridge/types/SoloRTCChannelTypes';
import { MetricName, Operation } from '@bridge/types/MetricTypes';
import { EndPointType } from '@bridge/types/RegionTypes';
import { HttpsSettings } from '@bridge/types/SessionTypes';

export interface HealthCheckData {
  message: String;
  onDismiss: () => void;
}

const logger = CoreFactory.getLogger();
const metrics = CoreFactory.getMetrics();
const MaximumWarningRoundTripTimeMillis = 375;
const sessionManager = CoreFactory.getSessionManager();

const useHealthCheck = () => {
  const [healthCheckData, setHealthCheckData] = useState<HealthCheckData[]>([]);
  const region = useSessionStore((state) => state.region);
  const regionLocalKey = region?.localeKey;
  const healthcheckRegionName = t(regionLocalKey!);
  const healthCheckHostname = region?.healthCheckHostname;
  const rtcChannel = CoreFactory.getRTCChannel();
  const [healthCheckResponse, setHealthCheckResponse] =
    useState<HealthCheckResponse>();
  const [httpsResponse, setHttpsResponse] = useState('');
  const [isAlertShown, setIsAlertShown] = useState<Boolean>(false);

  useEffect(() => {
    if (region) {
      setHealthCheckData([]);
      requestHealthCheckStatus();
      fetchHttpData();
    }
  }, [region]);

  useEffect(() => {
    if (healthCheckResponse && !isAlertShown) {
      setIsAlertShown(true);
      const tcpResult = checkPortHealth(
        healthCheckResponse.TcpConnectivity,
        HealthCheckProtocol.TCP
      );
      const udpResult = checkPortHealth(
        healthCheckResponse.UdpConnectivity,
        HealthCheckProtocol.UDP
      );
      const tcpRoundTripTime = healthCheckResponse.TcpRoundTrip.toString();
      const udpRoundTripTime = healthCheckResponse.UdpRoundTrip.toString();
      if (tcpResult.areAnyPortsUnhealthy)
        addHealthCheckStatus(tcpResult.alertStatus);
      if (udpResult.areAnyPortsUnhealthy)
        addHealthCheckStatus(udpResult.alertStatus);
      if (healthCheckResponse.TcpRoundTrip > MaximumWarningRoundTripTimeMillis)
        addHealthCheckStatus(
          t('ws-healthcheck-tcp-roundtrip', { tcpRoundTripTime })
        );
      if (healthCheckResponse.UdpRoundTrip > MaximumWarningRoundTripTimeMillis)
        addHealthCheckStatus(
          t('ws-healthcheck-udp-roundtrip', { udpRoundTripTime })
        );
    }
  }, [healthCheckResponse]);

  useEffect(() => {
    if (httpsResponse && httpsResponse !== 'healthy') {
      addHealthCheckStatus(
        t('ws-healthcheck-connection-failed', { healthcheckRegionName })
      );
    }
  }, [httpsResponse]);

  const requestHealthCheckStatus = async () => {
    if (
      rtcChannel &&
      healthCheckHostname &&
      Array.isArray(healthCheckHostname)
    ) {
      const device = CoreFactory.getDevice();
      const ipv6Endpoint = healthCheckHostname.find(
        (ep) => ep.type === EndPointType.IPv6
      )?.url;
      const ipv4Endpoint = healthCheckHostname.find(
        (ep) => ep.type === EndPointType.IPv4
      )?.url;

      const hostname = device.getIsSupportIPv4Fallback()
        ? ipv6Endpoint
        : ipv4Endpoint;

      logger.info('Requesting Health Check using endpoint: ' + hostname);

      const healthCheckRequest = createHealthCheckRequest(hostname!);
      const healthCheckResult = new Promise<HealthCheckResponse>(
        (resolve, reject) => {
          rtcChannel.requestHealthCheckStatus(
            healthCheckRequest,
            async (payload) => {
              const rtcHealthCheckResponse = payload as HealthCheckResponse;

              const tcpResult = checkPortHealth(
                rtcHealthCheckResponse.TcpConnectivity,
                HealthCheckProtocol.TCP
              );
              const udpResult = checkPortHealth(
                rtcHealthCheckResponse.UdpConnectivity,
                HealthCheckProtocol.UDP
              );

              if (
                hostname === ipv6Endpoint &&
                (tcpResult.areAnyPortsUnhealthy ||
                  udpResult.areAnyPortsUnhealthy)
              ) {
                reject(rtcHealthCheckResponse);
              } else {
                resolve(rtcHealthCheckResponse);
              }
            }
          );
        }
      );

      await healthCheckResult.then(
        (response) => {
          setHealthCheckResponse(response);
        },
        async () => {
          const fallbackHostname = ipv4Endpoint;
          if (fallbackHostname) {
            logger.warn(
              'Solo Reporting Unhealthy State for Health Check using Dual Stack endpoint. Falling back to legacy endpoint...'
            );
            metrics.emitWithValue(
              Operation.HealthCheck,
              MetricName.IPv6Fallback,
              1
            );
            await new Promise<HealthCheckResponse>((resolve, reject) => {
              const fallbackHealthCheckRequest =
                createHealthCheckRequest(fallbackHostname);
              rtcChannel.requestHealthCheckStatus(
                fallbackHealthCheckRequest,
                async (payload) => {
                  const rtcHealthCheckResponse = payload as HealthCheckResponse;
                  setHealthCheckResponse(rtcHealthCheckResponse);
                  resolve(rtcHealthCheckResponse);
                }
              );
            });
          } else {
            logger.warn(
              'Cannot fallback to legacy endpoint as none is defined!'
            );
          }
        }
      );
    }
  };

  const checkPortHealth = (
    networkConnectivityResult: NetworkConnectivityResult[],
    protocol: string
  ) => {
    let areAnyPortsUnhealthy: boolean = false;
    let alertStatus: string = '';
    const unhealthyPorts: string[] = [];
    const healthyPorts: string[] = [];
    networkConnectivityResult.forEach((networkConnectivity) => {
      if (!networkConnectivity.IsHealthy) {
        areAnyPortsUnhealthy = true;
        unhealthyPorts.push(networkConnectivity.Port.toString());
      } else {
        healthyPorts.push(networkConnectivity.Port.toString());
      }
    });

    if (unhealthyPorts.length === 2 && protocol === HealthCheckProtocol.TCP)
      alertStatus = t('ws-healthcheck-tcp-fail');
    if (unhealthyPorts.length === 2 && protocol === HealthCheckProtocol.UDP)
      alertStatus = t('ws-healthcheck-udp-fail');

    if (unhealthyPorts.length === 1) {
      const connectedPort = healthyPorts.join(', ');
      const failedPort = unhealthyPorts.join(', ');
      if (protocol === 'tcp')
        alertStatus = t('ws-healthcheck-tcp-partial', {
          connectedPort,
          failedPort,
        });
      if (protocol === 'udp')
        alertStatus = t('ws-healthcheck-udp-partial', {
          connectedPort,
          failedPort,
        });
    }

    return { areAnyPortsUnhealthy, alertStatus, protocol };
  };

  const httpHelper = async (endpoint: string | undefined) => {
    if (!endpoint) {
      throw new Error('Cannot call broker healthcheck for undefined endpoint!');
    }

    const res = await fetch(endpoint + '/ping');
    const data = await res.text();
    if (typeof data === 'string') {
      setHttpsResponse(data);
    } else {
      throw new Error('Invalid response');
    }
  };

  const fetchHttpData = async () => {
    if (!region?.wsBrokerEndpoint || !Array.isArray(region.wsBrokerEndpoint)) {
      logger.error('Invalid or missing wsBrokerEndpoint');
      return;
    }

    const ipv6Endpoint = region.wsBrokerEndpoint.find(
      (ep) => ep.type === EndPointType.IPv6
    );
    const ipv4Endpoint = region.wsBrokerEndpoint.find(
      (ep) => ep.type === EndPointType.IPv4
    );

    const endpoints = [ipv6Endpoint?.url, ipv4Endpoint?.url].filter(
      Boolean
    ) as string[];

    for (const endpoint of endpoints) {
      try {
        await httpHelper(endpoint);
        break;
      } catch (error) {
        if (endpoint === ipv6Endpoint?.url) {
          logger.info(
            'Unhealthy State for Broker Health Check using IPv6 endpoint. Falling back to IPv4 endpoint...'
          );
          metrics.emitWithValueAndReason(
            Operation.HealthCheck,
            MetricName.IPv6Fallback,
            1,
            error
          );

          const currentHttpsSettings = sessionManager.get('httpsSettings');
          const updatedHttpsSettings: HttpsSettings = {
            ...currentHttpsSettings,
            clientServiceEndpointType: EndPointType.IPv4,
          };
          sessionManager.set({ httpsSettings: updatedHttpsSettings });

          continue;
        } else {
          logger.error(
            `Error during health check for endpoint ${endpoint}:`,
            error instanceof Error ? error.message : String(error)
          );
        }
      }
    }

    if (endpoints.length === 0) {
      logger.error('No valid broker endpoints found');
    }
  };

  const addHealthCheckStatus = (message: String) => {
    setHealthCheckData((previousHealthCheckData) => [
      ...previousHealthCheckData,
      {
        message,
        onDismiss: () => {},
      },
    ]);
  };

  const onDismissAlert = (message: String) => {
    setHealthCheckData((prevData) =>
      prevData.filter((data) => data.message !== message)
    );
  };

  const createHealthCheckRequest = (
    healthCheckHostname: string
  ): HealthCheckRequest => {
    const request: HealthCheckRequest = {
      Hostname: healthCheckHostname,
      NetworkConfig: [
        {
          Protocol: HealthCheckProtocol.TCP,
          Ports: [HealthCheckPorts.PORT_PCOIP, HealthCheckPorts.PORT_WSP],
        },
        {
          Protocol: HealthCheckProtocol.UDP,
          Ports: [HealthCheckPorts.PORT_PCOIP, HealthCheckPorts.PORT_WSP],
        },
      ],
    };
    return request;
  };

  return { healthCheckData, onDismissAlert };
};

export default useHealthCheck;
