import { Cookies } from 'react-cookie';
import { generateUUID } from '@bridge/utility';
import { AppConstants } from '@/bridge/constants/AppConstants';
import { IAuthRelativePath } from '@/bridge/routes/AuthRoutes';

const cookie = new Cookies();

export class ExternalAuthUrlBuilder {
  private readonly base: string;
  private url: URL;

  constructor(base: string) {
    this.base = base;
    this.url = new URL(this.base);
  }

  reset() {
    this.url = new URL(this.base);
  }

  setRedirectUri(strategy: IAuthRelativePath) {
    const port = window.location.port ? `:${window.location.port}` : '';
    this.url.searchParams.set(
      'redirect_uri',
      `https://${window.location.hostname}${port}/auth/${strategy}`
    );
  }

  setRelayStateUrl(relayStateName: string, relayStateUrl: string) {
    this.url.searchParams.set(relayStateName, relayStateUrl);
  }

  setLocale() {
    const language = cookie.get('language');
    this.url.searchParams.set('locale', language);
  }

  setCsrfToken() {
    const csrfToken = generateUUID();
    cookie.set(AppConstants.COOKIE_CSRF_TOKEN, csrfToken, { path: '/' });
    this.url.searchParams.set('state', csrfToken);
  }

  getUrl() {
    return this.url;
  }
}
