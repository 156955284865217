import { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Form,
  Button,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { useSessionStore } from '@/stores/session';
import {
  DriveToIncrease,
  SelfServiceActionTypes,
} from '@/bridge/types/SelfService';
import { useSelfServiceActions } from '../hooks/useSelfServiceActions';
import ErrorAlert from '../components/ErrorAlert';
import SuccessAlert from '../components/SuccessAlert';

// TODO: handle errors
const IncreaseDiskSize = () => {
  const { t } = useCustomTranslation();
  const { resource } = useSessionStore((state) => state);
  const resourceDetails = useMemo(() => resource?.ResourceDetails, [resource]);
  const rootVolumeSizeInGiB = resourceDetails?.RootVolumeSizeInGiB as string;
  const userVolumeSizeInGiB = resourceDetails?.UserVolumeSizeInGiB as string;
  const rootVolumeLabel = 'C drive: ' + rootVolumeSizeInGiB + 'GIB';
  const userVolumeLabel = 'D drive: ' + userVolumeSizeInGiB + 'GIB';
  const increaseDiskSizeInfo = resource?.ActionsAuthorized?.find(
    ({ ActionType }) =>
      ActionType === SelfServiceActionTypes.INCREASE_VOLUME_SIZE
  );
  const newUserVolumeSizeInGib = increaseDiskSizeInfo?.ActionUserInput
    ?.NewUserVolumeSizeInGib?.Values?.[0] as string;
  const newRootVolumeSizeInGib = increaseDiskSizeInfo?.ActionUserInput
    ?.NewRootVolumeSizeInGib?.Values?.[0] as string;

  const driveToIncrease =
    rootVolumeSizeInGiB !== newRootVolumeSizeInGib
      ? DriveToIncrease.C
      : DriveToIncrease.D;

  const currentVolumeSizeInGib =
    driveToIncrease === DriveToIncrease.C
      ? rootVolumeSizeInGiB
      : userVolumeSizeInGiB;

  const newVolumeSizeInGib =
    driveToIncrease === DriveToIncrease.C
      ? newRootVolumeSizeInGib
      : newUserVolumeSizeInGib;

  const {
    error,
    cleanError,
    isCompleted,
    isPending,
    isProcessing,
    reset,
    increaseVolumeSize,
  } = useSelfServiceActions();
  const [showSuccess, setShowSuccess] = useState(false);
  const maxReached = useMemo(
    () => currentVolumeSizeInGib === newVolumeSizeInGib,

    [currentVolumeSizeInGib, newVolumeSizeInGib]
  );
  const onActionButtonClick = () => {
    cleanError();
    setShowSuccess(false);
    increaseVolumeSize(driveToIncrease, newVolumeSizeInGib);
  };

  useEffect(() => {
    if (isCompleted) {
      setShowSuccess(true);
      reset();
    }
  }, [isCompleted]);

  return (
    <Box padding="m">
      <Form
        actions={
          !maxReached && (
            <Button
              disabled={!isPending}
              variant="primary"
              onClick={onActionButtonClick}
              ariaLabel={
                isProcessing
                  ? 'Processing'
                  : t('ws-my-workspace-increase-disk-size')
              }
            >
              {isProcessing ? (
                <Spinner />
              ) : (
                t('ws-my-workspace-increase-disk-size')
              )}
            </Button>
          )
        }
      >
        <SpaceBetween size="m">
          {error && <ErrorAlert error={error} onDismiss={cleanError} />}
          {showSuccess && (
            <SuccessAlert
              message={t('ws-my-workspace-increase-disk-size-success-message')}
              onDismiss={() => () => setShowSuccess(false)}
            />
          )}
          <div>
            <Box variant="awsui-key-label">
              <div
                tabIndex={0}
                aria-live="assertive"
                role="navigation"
                aria-label={t('ws-my-workspace-increase-disk-size-current-key')}
              >
                {t('ws-my-workspace-increase-disk-size-current-key')}:
              </div>
            </Box>
            <div
              tabIndex={0}
              aria-live="assertive"
              role="navigation"
              aria-label={rootVolumeLabel}
            >
              C:drive {rootVolumeSizeInGiB}GIB
            </div>
            <div
              tabIndex={0}
              aria-live="assertive"
              role="navigation"
              aria-label={userVolumeLabel}
            >
              D:drive {userVolumeSizeInGiB}GIB
            </div>
          </div>
          {maxReached ? (
            <>
              <Box variant="awsui-key-label">
                <div
                  aria-live="assertive"
                  role="navigation"
                  aria-label={t(
                    'ws-my-workspace-increase-disk-size-max-reached'
                  )}
                >
                  {t('ws-my-workspace-increase-disk-size-max-reached')}
                </div>
              </Box>
            </>
          ) : (
            <>
              <Box variant="awsui-key-label">
                <div
                  tabIndex={0}
                  aria-live="assertive"
                  role="navigation"
                  aria-label={t('ws-my-workspace-increase-disk-size-info', {
                    driveToIncrease,
                    currentVolumeSizeInGib,
                    newVolumeSizeInGib,
                  })}
                >
                  {t('ws-my-workspace-increase-disk-size-info', {
                    driveToIncrease,
                    currentVolumeSizeInGib,
                    newVolumeSizeInGib,
                  })}
                </div>
              </Box>
              <p
                tabIndex={0}
                aria-live="assertive"
                role="navigation"
                aria-label={t('ws-my-workspace-increase-disk-size-description')}
              >
                {t('ws-my-workspace-increase-disk-size-description')}
              </p>
            </>
          )}
        </SpaceBetween>
      </Form>
    </Box>
  );
};

export default IncreaseDiskSize;
