import {
  Checkbox,
  Button,
  FormField,
  Input,
  Autosuggest,
  AutosuggestProps,
  InputProps,
  CheckboxProps,
  Spinner,
  Flashbar,
} from '@cloudscape-design/components';
import {
  CancelableEventHandler,
  NonCancelableEventHandler,
} from '@cloudscape-design/components/internal/events';
import styles from './styles.module.scss';
import { I18nError } from '@/lib/I18nError';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

export interface ActionProps {
  id: string;
  onClick: () => void;
  label: string;
  isLoading?: boolean;
}

export interface UserInputProps {
  id: string;
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  onEnter: () => void;
  error?: any;
  isLoading?: boolean;
}

export interface UserCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  isLoading?: boolean;
}

export interface UserAutoSuggestProps {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  options: AutosuggestProps.Options;
  onChange: (newValue: string) => void;
  onEnter: () => void;
  error?: any;
  isLoading?: boolean;
}

export interface ActionGroupProps {
  title: string;
  titleDescription: string;
  checkbox?: UserCheckboxProps;
  input?: UserInputProps;
  autosuggest?: UserAutoSuggestProps;
  actions: ActionProps[];
  isLoading?: boolean;
  fieldRef?: any;
}

const ActionGroup: React.FC<ActionGroupProps> = ({
  title,
  titleDescription,
  checkbox,
  input,
  autosuggest,
  actions,
  isLoading,
  fieldRef,
}) => {
  const { t } = useTranslation();
  const [mainAction, ...otherActions] = actions;
  const checkboxOnChangeWrapper: NonCancelableEventHandler<
    CheckboxProps.ChangeDetail
  > = ({ detail }) => {
    checkbox?.onChange(detail.checked);
  };
  const inputOnChangeWrapper: NonCancelableEventHandler<
    InputProps.ChangeDetail
  > = ({ detail }) => {
    input?.onChange(detail.value);
  };
  const autosuggestOnChangeWrapper: NonCancelableEventHandler<
    AutosuggestProps.ChangeDetail
  > = ({ detail }) => {
    autosuggest?.onChange(detail.value);
  };
  const onKeyDown: CancelableEventHandler<InputProps.KeyDetail> = (event) =>
    event.detail.key === 'Enter' && input?.onEnter();

  return (
    <div className={styles.container}>
      {autosuggest?.error && (
        <div aria-live="assertive" className={styles.header}>
          <Flashbar
            items={[
              {
                content: I18nError.getI18nError(autosuggest.error)
                  .getConciseLocaleKeys()
                  .map(t)
                  .join(' '),
                type: 'error',
                dismissible: false,
                id: 'registration_error_message',
                statusIconAriaLabel: t('ws-error'),
              },
            ]}
          />
        </div>
      )}

      <div className={styles.header}>
        <h1 tabIndex={0} aria-live="assertive" role="region" aria-label={title}>
          {title}
        </h1>

        <div
          style={{
            fontSize: '14px',
            fontWeight: 400,
            marginBottom: '14px',
          }}
          tabIndex={0}
          aria-live="assertive"
          role="region"
          aria-label={titleDescription}
        >
          {titleDescription}
        </div>
      </div>
      {isLoading ? (
        <div
          style={{ padding: '2rem' }}
          data-testid="action-group-loading-spinner"
        >
          <Spinner size="large" />
        </div>
      ) : (
        <div className={styles['options-container']}>
          {input && (
            <div className={styles['form-wrapper']}>
              <FormField label={input.label}>
                <Input
                  controlId={input.id}
                  value={input.value}
                  onChange={inputOnChangeWrapper}
                  onKeyDown={onKeyDown}
                  disabled={input.isLoading}
                />
              </FormField>
            </div>
          )}
          {autosuggest && (
            <div className={styles['form-wrapper']}>
              <FormField label={autosuggest.label}>
                <Autosuggest
                  controlId={autosuggest.id}
                  onChange={autosuggestOnChangeWrapper}
                  value={autosuggest.value}
                  options={autosuggest.options}
                  enteredTextLabel={(value) => `Use: "${value}"`}
                  ariaLabel={autosuggest.label}
                  ariaRequired
                  autoFocus
                  placeholder={autosuggest.placeholder}
                  empty="No matches found"
                  ref={fieldRef}
                />
              </FormField>
            </div>
          )}
          {checkbox && (
            <div className={styles['checkbox-wrapper']}>
              <Checkbox
                checked={checkbox.checked}
                onChange={checkboxOnChangeWrapper}
                disabled={checkbox.isLoading}
              >
                {checkbox.label}
              </Checkbox>
            </div>
          )}
          <div className={styles['btn-wrapper']}>
            <Button
              id={mainAction.id}
              className="styles_ws-button"
              variant="primary"
              onClick={mainAction.onClick}
              disabled={mainAction.isLoading}
            >
              <span className={styles['main-btn']}>
                {mainAction.isLoading && (
                  <span style={{ paddingRight: '1rem' }}>
                    <Spinner />
                  </span>
                )}
                {mainAction.label}
              </span>
            </Button>
            {otherActions.length > 0 && (
              <div className={styles['other-btn-wrapper']}>
                {otherActions.map((action) => (
                  <Button
                    id={action.id}
                    key={action.id}
                    variant="link"
                    disabled={action.isLoading}
                    onClick={action.onClick}
                  >
                    {action.label}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionGroup;
