import {
  ITimedMetricOperation,
  MetricName,
  MetricResult,
  Operation,
} from '@bridge/types/MetricTypes';
import { Timer } from '@core/utility/Timer';
import { DimensionSet } from '@core/metrics/types';

export class TimedMetricOperation implements ITimedMetricOperation {
  dimensions: DimensionSet;
  metricName: MetricName;
  operation: Operation;
  result: MetricResult;
  private readonly timer: Timer;

  constructor(operation: Operation, enableTimer: boolean) {
    this.operation = operation;
    this.timer = new Timer();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    this.dimensions = {} as DimensionSet;
    this.metricName = MetricName.OpTime;
    this.result = MetricResult.Success;
    if (enableTimer) {
      this.timer.start();
    }
  }

  startTimer(): void {
    this.timer.start();
  }

  getValue(): number {
    this.timer.stop();
    return this.timer.getElapsedTime()
      ? (this.timer.getElapsedTime() as number)
      : 0;
  }

  getStartTime(): number | undefined {
    return this.timer.getTimerStartTime();
  }
}
