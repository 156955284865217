import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocaleConfig, LngOptions } from '@/i18n';
import { CookieCompliance } from '@bridge/CookieCompliance';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { Localization } from '@core/localization/Localization';
import { LanguageOptions } from '@bridge/types/SoloRTCChannelTypes';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import HeaderComponent from './Header';
import { HeaderConstant } from './constants';

const DEFAULT_SUPPORT_LINK =
  'https://docs.aws.amazon.com/workspaces/latest/userguide/amazon-workspaces-clients.html';

interface HeaderProps {
  supportUrl?: string;
}
const sessionManager = CoreFactory.getSessionManager();
const device = CoreFactory.getDevice();
const isNativeMode = sessionManager.isNativeMode();
const isChannelAvailable = SoloNativeRTCChannel.isChannelAvailable();
const rtcChannel = CoreFactory.getRTCChannel();

const Header: React.FC<HeaderProps> = ({ supportUrl }) => {
  const { i18n } = useTranslation();

  const [selectedLng, setSelectedLng] = useState<LngOptions>(
    (Localization.getCurrentLocale() as LngOptions) ?? LngOptions.EN_US
  );

  const localeItems: HeaderConstant.ILocaleItem[] = useMemo(
    () =>
      Object.entries(LocaleConfig).map(([lng, localeConfig]) => ({
        id: lng,
        text: localeConfig.displayName,
      })),
    []
  );

  useEffect(() => {
    const language = getLanguageCode() as LngOptions;
    if (isNativeMode) updateLanguageSettings(language);
  }, []);

  const getLanguageCode = () => {
    const language = navigator.language;
    const splitLanguageInputs = language
      .replace('_', '-')
      .toLowerCase()
      .split('-');

    // In cases of pt-br or zh-cn, we'd want to append the language code and the region code.
    // And for anything else, we're just returning the language codes.
    if (
      splitLanguageInputs[0].startsWith('pt') ||
      splitLanguageInputs[0].startsWith('zh')
    ) {
      return `${
        splitLanguageInputs[0]
      }_${splitLanguageInputs[1].toUpperCase()}`;
    }
    return splitLanguageInputs[0];
  };

  const updateLanguageSettings = (language: LngOptions) => {
    setSelectedLng(language);
    i18n.changeLanguage(language);
    document.documentElement.lang = language;
    updateLocalAndNotifyRTCListener(language);

    CookieCompliance.clear();
  };

  const updateLocalAndNotifyRTCListener = (locale: LngOptions) => {
    Localization.updateLocale(locale);
    try {
      if (isChannelAvailable) {
        console.info(
          `RTCChannel is open. Sending locale update to RTC listener ${locale}`
        );
        rtcChannel?.publishSettingsChange({
          Locale: locale as unknown as LanguageOptions,
        });
      }
    } catch (error: any) {
      console.warn(
        `Failed to update language change to RTC listener ${error?.message}`
      );
    }
  };

  const finalSupportLink = useMemo(
    () => (supportUrl && supportUrl !== '' ? supportUrl : DEFAULT_SUPPORT_LINK),
    [supportUrl]
  );

  return (
    <HeaderComponent
      supportUrl={finalSupportLink}
      isNiva={device.isNiva()}
      isNativeMode={isNativeMode}
      localeItems={localeItems}
      displayName={LocaleConfig[selectedLng]?.displayName}
      onLngChange={updateLanguageSettings}
    />
  );
};

export default Header;
