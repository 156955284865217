import { IAuthStep } from '../IAuthStep';
import { Cookies } from 'react-cookie';
import { ISessionManager } from '@bridge/ISessionManager';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { IRTCChannel } from '@/bridge/IRTCChannel';
import { AuthRequest } from '../authRequests/AuthRequest';
import { Authentication } from '../Authentication';

export interface AuthStepProps {
  sessionManager: ISessionManager;
  authentication: Authentication;
  logger: ILogger;
  metrics: IMetrics;
  rtcChannel: IRTCChannel | null;
}

export abstract class BaseStep implements IAuthStep {
  readonly sessionManager: ISessionManager;
  readonly authentication: Authentication;
  readonly logger: ILogger;
  readonly metrics: IMetrics;
  readonly rtcChannel: IRTCChannel | null;
  readonly authRequest: AuthRequest;
  readonly cookie: Cookies;

  constructor({
    sessionManager,
    logger,
    metrics,
    authentication,
    rtcChannel,
  }: AuthStepProps) {
    this.sessionManager = sessionManager;
    this.authentication = authentication;
    this.logger = logger;
    this.metrics = metrics;
    this.rtcChannel = rtcChannel;
    this.authRequest = new AuthRequest({
      sessionManager,
      rtcChannel,
      logger,
      metrics,
    });
    this.cookie = new Cookies();
  }

  abstract perform(): Promise<any>;
}
