import { useState } from 'react';
import { useCustomTranslation } from '@/i18n';
import {
  Button,
  Toggle,
  Box,
  SpaceBetween,
  RadioGroup,
} from '@cloudscape-design/components';
import { useLogUploaderPreferenceStore } from '@stores/loguploader';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { useSessionStore } from '@stores/session';

const logger = CoreFactory.getLogger();
const device = CoreFactory.getDevice();

const LogUploaderSettings = () => {
  const { t } = useCustomTranslation();

  const orgName = useSessionStore((state) => state.domainProperties)
    ?.OrganizationName as string;
  const region = useSessionStore((state) => state.region);
  const deviceId: string = device.getDeviceUUID()!;

  const isLoggingEnabledByUser = useLogUploaderPreferenceStore(
    (state) => state.isLoggingEnabledByUser
  );
  const updateIsLoggingEnabledByUser = useLogUploaderPreferenceStore(
    (state) => state.updateIsLoggingEnabledByUser
  );
  const logLevelPreference = useLogUploaderPreferenceStore(
    (state) => state.logLevelPreference
  );
  const updateLogLevelPreference = useLogUploaderPreferenceStore(
    (state) => state.updateLogLevelPreference
  );

  const [toggleText, setToggleText] = useState(() =>
    setInitialLogUploadToggleText()
  );

  function setInitialLogUploadToggleText() {
    if (isLoggingEnabledByUser) {
      return t('ws-loguploader-enabled');
    } else {
      return t('ws-loguploader-disabled');
    }
  }

  function updateLogLevelSetting(logLevelValue: string) {
    updateLogLevelPreference(logLevelValue);
    logger.setLogLevel(logLevelValue);
  }

  function updateLogUploadToggle(isToggleEnabled: boolean) {
    updateIsLoggingEnabledByUser(isToggleEnabled);
    if (isToggleEnabled) {
      logger.startLogUpload(orgName, region?.endpoint);
      setToggleText(t('ws-loguploader-enabled'));
    } else {
      logger.pauseLogUpload();
      updateLogLevelSetting('INFO');
      setToggleText(t('ws-loguploader-disabled'));
    }
  }

  return (
    <SpaceBetween size="m" direction="vertical">
      <p
        tabIndex={0}
        role="navigation"
        aria-live="assertive"
        aria-label={t('ws-loguploader-description')}
      >
        {t('ws-loguploader-description')}
      </p>

      <Box>
        <p
          tabIndex={0}
          role="navigation"
          aria-live="assertive"
          aria-label={t('ws-device-id')}
        >
          <strong>{t('ws-device-id')}</strong>
        </p>
        <p
          tabIndex={0}
          role="navigation"
          aria-live="assertive"
          aria-label={deviceId}
        >
          {deviceId}
          <Button
            variant="inline-icon"
            ariaLabel={t('ws-loguploader-copy')}
            iconName="copy"
            onClick={() => {
              navigator.clipboard.writeText(deviceId);
            }}
          />
        </p>
      </Box>

      <Box variant="awsui-key-label">
        <div
          tabIndex={0}
          role="navigation"
          aria-live="assertive"
          aria-label={t('ws-log-setting-preference')}
        >
          {t('ws-log-setting-preference')}
        </div>
        <Toggle
          onChange={({ detail }) => updateLogUploadToggle(detail.checked)}
          checked={isLoggingEnabledByUser}
          ariaLabel={toggleText}
        >
          {toggleText}
        </Toggle>
      </Box>

      <Box variant="awsui-key-label">
        <div
          tabIndex={0}
          role="navigation"
          aria-live="assertive"
          aria-label={t('ws-log-preference-title')}
        >
          {t('ws-log-preference-title')}
        </div>

        <Box>
          <p
            tabIndex={0}
            role="navigation"
            aria-live="assertive"
            aria-label={t('ws-log-preference-description')}
          >
            <small>{t('ws-log-preference-description')}</small>
          </p>
        </Box>
        <RadioGroup
          onChange={({ detail }) => updateLogLevelSetting(detail.value)}
          value={logLevelPreference}
          items={[
            {
              value: 'INFO',
              label: t('ws-loglevel-label-info'),
              disabled: !isLoggingEnabledByUser,
              description: t('ws-info-loglevel-description'),
            },
            {
              value: 'DEBUG',
              label: t('ws-loglevel-label-debug'),
              disabled: !isLoggingEnabledByUser,
              description: t('ws-debug-loglevel-description'),
            },
          ]}
        />
      </Box>
    </SpaceBetween>
  );
};

export default LogUploaderSettings;
