import { useRef } from 'react';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { MigrateRegistrationCodesResponse } from '@/bridge/types/SoloRTCChannelTypes';
import { RegistrationState, useRegCodes } from '@/stores/registration';

const rtcChannel = CoreFactory.getRTCChannel();
const logger = CoreFactory.getLogger();

const useMigrateRegistrationCodes = () => {
  const isMigrationDataRequested = useRef(false);
  const setStatusOfRegistrationCodesMigration = useRegCodes(
    (state) => state.setStatusOfRegistrationCodesMigration
  );

  const migrateSoloRegCodesToWeb = useRegCodes(
    (state) => state.migrateSoloRegCodesToWeb
  );
  const saveLocalStorageRegStateToCookies = useRegCodes(
    (state) => state.saveRegistrationStateToCookies
  );
  const allRegistrationCodes = useRegCodes((state) => state.getAllRegCodes);

  const areRegistrationCodesMigratedToWebClientFromSolo = useRegCodes(
    (state) => state.areRegistrationCodesMigrated
  );

  const requestRegCodeMigrationFromSoloToWeb = async () => {
    if (rtcChannel && !isMigrationDataRequested.current) {
      isMigrationDataRequested.current = true;

      await new Promise<MigrateRegistrationCodesResponse>((resolve, reject) => {
        rtcChannel.requestMigrateRegistrationCodes((payload) => {
          const migrateRegistrationCodesResponse =
            payload as MigrateRegistrationCodesResponse;
          resolve(migrateRegistrationCodesResponse);
        });
      }).then((response) => {
        if (response?.RegistrationCodes) {
          const allRegCodesCount = allRegistrationCodes()?.length;
          if (allRegCodesCount !== undefined && allRegCodesCount === 0) {
            migrateSoloRegCodesToWeb(response);
          }
        }
        setStatusOfRegistrationCodesMigration();
      });
    }
  };

  const migrateWebRegCodesToCookies = () => {
    const loginInformationFromLocalStorage =
      localStorage.getItem('registrationCodes');
    try {
      if (loginInformationFromLocalStorage) {
        const loginInfoState = JSON.parse(
          loginInformationFromLocalStorage ?? ''
        )?.state as RegistrationState;
        logger.info('Saving User login settings');
        saveLocalStorageRegStateToCookies(loginInfoState);
        localStorage.removeItem('registrationCodes');
      }
    } catch (e: any) {
      logger.error(
        `Encountered error while storing user settings ${e?.message}`
      );
    }
  };

  const requestRegistrationCodesMigration = async () => {
    migrateWebRegCodesToCookies();

    if (!areRegistrationCodesMigratedToWebClientFromSolo) {
      await requestRegCodeMigrationFromSoloToWeb();
    }
  };

  return { requestRegistrationCodesMigration };
};

export default useMigrateRegistrationCodes;
