import { ILogger } from '@bridge/ILogger';
import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionType,
  DisconnectRequest,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { NativeInSessionError } from '@/bridge/types/NativeInSessionError';

export class DisconnectRequestHandler implements IActionHandler {
  private readonly logger: ILogger;

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    if (!this.validate(msg)) {
      return;
    }
    const disconnectRequest = msg.Payload as DisconnectRequest;
    if (callback) {
      callback(disconnectRequest);
    } else {
      const redirectPort = window.location.port
        ? `:${window.location.port}`
        : '';
      window.location.assign(
        `https://${window.location.hostname}${redirectPort}/disconnect?${NativeInSessionError.DISCONNECT_STATUS_CODE_SEARCH_NAME}=${disconnectRequest?.DisconnectCode}`
      );
    }
  }

  private validate(msg: RTCChannelMessage): boolean {
    try {
      this.logger.info(
        `Received Disconnect Request from Solo ${JSON.stringify(msg)}`
      );
      const response = msg.Payload;
      if (
        msg.Action === Action.DISCONNECT &&
        msg.ActionType === ActionType.REQUEST &&
        msg.Payload &&
        'DisconnectCode' in response
      ) {
        return true;
      }
    } catch (e) {}
    return false;
  }
}
