import { useState } from 'react';
import { useErrorHandler } from '@hooks/useErrorHandler';
import { PreSessionFactory } from '@bridge/factory/PreSessionFactory';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { useSessionStore } from '@stores/session';

const logger = CoreFactory.getLogger();
const registration = PreSessionFactory.getRegistrationModule();

export const useRegistration = () => {
  const { showError } = useErrorHandler();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const regCodeInStore: string | undefined = useSessionStore(
    (state) => state.registrationCode
  );

  const clearError = () => {
    setError(undefined);
    setIsLoading(false);
    setRegistered(false);
  };

  const register = (
    registrationCode: string | undefined,
    errorRedirect: boolean = true
  ) => {
    // before setting up data or calling API, load spinner
    // this can also prevent double click issue
    logger.info('Loading Registration');
    setIsLoading(true);

    registration
      .register(registrationCode)
      .then(() => {
        setRegistered(registration.getIsRegistrationComplete());
        setIsLoading(false);
      })
      .catch((err) => {
        registration.reset();
        setError(err);
        if (errorRedirect) {
          showError(err);
        }
        setIsLoading(false);
      });
  };

  /*
   * In case web client needs to ensure its using correct end point before performing any task on given path
   * This is used for Solo cases where solo loads web URL after SAML external auth step is complete and Solo
   * launches {region}.webclient.amazonworkspaces.com/auth/saml?code=XXXX&state=YYYY , web client must make sure
   * we are navigating to the correct end point before completing auth.
   * */
  const redirectToRegionalEndpointIfNecessary = async (
    path: string,
    isExcludeSearchParams: boolean
  ) => {
    try {
      logger.info(
        `Initiated navigation from ${window.location.hostname} for ${path} for regCode ${regCodeInStore}`
      );
      setIsLoading(true);
      const webClientRegionalEndpointUrl =
        await registration.getWebClientRegionEndpointByCode(
          regCodeInStore,
          isExcludeSearchParams,
          path
        );

      if (webClientRegionalEndpointUrl.hostname !== window.location.hostname) {
        logger.info(
          `Navigated to  ${webClientRegionalEndpointUrl.toString()} from ${
            window.location.hostname
          }`
        );
        window.location.assign(webClientRegionalEndpointUrl.toString());
        return true;
      } else {
        logger.info(
          `Skipping redirect to regional end point for ${path} from ${window.location.hostname}`
        );
      }
    } catch (e: any) {
      logger.error(`Encountered error during navigation ${e?.message}`);
      setIsLoading(false);
      showError(e);
    }
    return false;
  };

  return {
    error,
    clearError,
    registered,
    isLoading,
    register,
    redirectToRegionalEndpointIfNecessary,
  };
};
