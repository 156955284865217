import { useState, useEffect } from 'react';
import {
  Box,
  Form,
  Checkbox,
  SpaceBetween,
  Button,
  Spinner,
} from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { useSelfServiceActions } from '../hooks/useSelfServiceActions';
import ErrorAlert from '../components/ErrorAlert';

// TODO: handle errors
const Rebuild = () => {
  const { t } = useCustomTranslation();
  const [confirmed, setConfirmed] = useState(false);
  const { error, cleanError, isPending, isProcessing, rebuildWorkSpace } =
    useSelfServiceActions();
  const onActionButtonClick = () => {
    cleanError();
    rebuildWorkSpace();
  };

  useEffect(() => {
    if (error) {
      setConfirmed(false);
    }
  }, [error]);

  return (
    <Box padding="m">
      <Form
        actions={
          <Button
            disabled={!confirmed || !isPending}
            variant="primary"
            onClick={onActionButtonClick}
            ariaLabel={
              isProcessing ? 'Processing' : t('ws-my-workspace-rebuild')
            }
          >
            {isProcessing ? <Spinner /> : t('ws-my-workspace-rebuild')}
          </Button>
        }
      >
        <SpaceBetween size="m">
          {error && <ErrorAlert error={error} onDismiss={cleanError} />}
          <p
            tabIndex={0}
            aria-live="assertive"
            role="navigation"
            aria-label={t('ws-my-workspace-rebuild-description')}
          >
            {t('ws-my-workspace-rebuild-description')}:
          </p>
          <div
            tabIndex={0}
            aria-live="assertive"
            role="navigation"
            aria-label={t('ws-my-workspace-rebuild-data-lost-warning-label')}
          >
            <Box variant="awsui-key-label">
              {t('ws-my-workspace-rebuild-data-lost-warning-label')}
            </Box>
            <p
              tabIndex={0}
              aria-live="assertive"
              role="navigation"
              aria-label={t(
                'ws-my-workspace-rebuild-data-lost-warning-content'
              )}
            >
              {t('ws-my-workspace-rebuild-data-lost-warning-content')}
            </p>
          </div>
          <div
            tabIndex={0}
            aria-live="assertive"
            role="navigation"
            aria-label={t(
              'ws-my-workspace-rebuild-user-drive-recreate-warning-label'
            )}
          >
            <Box variant="awsui-key-label">
              {t('ws-my-workspace-rebuild-user-drive-recreate-warning-label')}
            </Box>
            <p
              tabIndex={0}
              aria-live="assertive"
              role="navigation"
              aria-label={t(
                'ws-my-workspace-rebuild-user-drive-recreate-warning-content'
              )}
            >
              {t('ws-my-workspace-rebuild-user-drive-recreate-warning-content')}
            </p>
          </div>

          <Checkbox
            onChange={({ detail }) => setConfirmed(detail.checked)}
            checked={confirmed}
            disabled={!isPending}
            ariaLabel={t('ws-my-workspace-rebuild-disconnect-confirm')}
          >
            <strong>{t('ws-my-workspace-rebuild-disconnect-confirm')}</strong>
          </Checkbox>
        </SpaceBetween>
      </Form>
    </Box>
  );
};

export default Rebuild;
