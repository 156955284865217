import { I18nResource } from '@/i18n/types';

const translation: I18nResource = {
  'ws-welcome': 'Te damos la bienvenida a Amazon WorkSpaces.',
  'ws-options': 'Elige una de las siguientes opciones:',
  'ws-login': 'Iniciar sesión',
  'ws-remember-me': 'Mantener mi sesión iniciada',
  'ws-new-reg-code': 'Cambiar código de registro',
  'ws-back-login': 'Volver a la página de inicio de sesión',
  'ws-fallback-login': 'Iniciar sesión con credenciales',
  'ws-auth-reconnect': 'Volver a conectar',
  'ws-disconnected-error':
    'Se ha producido un error. Ponte en contacto con el administrador de WorkSpaces para obtener ayuda',
  'ws-get-started':
    'Para empezar, introduce el código de registro que te proporcionó el administrador.',
  'ws-register': 'Registrarse',
  'ws-pin': 'PIN',
  'ws-disconnect': 'Desconectar',
  'ws-disconnected': 'Desconectado',
  'ws-send-cad': 'Enviar Ctrl + Alt + Supr',
  'ws-enter-full-screen': 'Activar el modo de pantalla completa',
  'ws-exit-full-screen': 'Salir de pantalla completa',
  'ws-error': 'Error',
  'ws-console': 'Consola de WorkSpaces',
  'ws-privacy': 'Privacidad',
  'ws-site-terms': 'Condiciones del sitio',
  'ws-cookie-pref': 'Preferencias de cookies',
  'ws-docs': 'Documentación',
  'ws-reserved': 'o sus filiales. Todos los derechos reservados.',
  'ws-neg-error': 'Se ha producido un error al iniciar.',
  'ws-reg-error': 'Se ha producido un error durante el registro.',
  'ws-reg-format-error': 'El formato de código de registro no es válido.',
  'ws-reg-invalid-error': 'El código de registro no es válido.',
  'ws-reg-unsupported-by-platform':
    'El código de registro proporcionado no es compatible con esta versión de cliente. Ponte en contacto con tu administrador para obtener más detalles. ',
  'ws-reg-unknown-error': 'Código de registro desconocido.',
  'ws-unknown-error': 'Error desconocido',
  'ws-loading-error':
    'Se ha producido un error al cargar la página de autenticación. Asegúrate de que este sistema operativo/plataforma esté habilitado en el directorio que contiene los workSpaces.',
  'ws-get-auth-info-error':
    'Se ha producido un error al cargar la página de autenticación debido a un código de registro no válido.',
  'ws-auth-error': 'Se ha producido un error durante la autenticación.',
  'ws-auth-type-not-supported-error': 'No se admite el tipo de autenticación',
  'ws-user-auth-failure':
    'Es posible que hayas tenido mucho tiempo de inactividad o que tengas una contraseña nueva. Inténtalo de nuevo.',
  'ws-device-auth-failure':
    'Este dispositivo no está autorizado a acceder a tu WorkSpace.',
  'ws-get-resources-error': 'Se ha producido un error al obtener los recursos.',
  'ws-resource-not-found':
    'No se ha encontrado ningún WorkSpace con tu nombre de usuario. Ponte en contacto con tu administrador para solicitar uno.',
  'ws-not-supported-error':
    'No se puede utilizar la conexión web al WorkSpace seleccionado.',
  'ws-suspended-error': 'El WorkSpace seleccionado parece estar suspendido.',
  'ws-resource-maintenance-error':
    'Tu WorkSpace está en mantenimiento. Esto puede llevar unas horas. Ponte en contacto con tu administrador para obtener más información.',
  'ws-resource-terminated-error':
    'Tu administrador canceló tu WorkSpace. Ponte en contacto con tu administrador para obtener más información.',
  'ws-resource-unavailable-error':
    'No se ha encontrado ningún WorkSpace con tu nombre de usuario. Ponte en contacto con tu administrador para solicitar uno.',
  'ws-resource-unhealthy-error':
    'No podemos conectarnos a tu WorkSpace en este momento. Esto puede deberse a las actualizaciones actuales. Inténtalo de nuevo más tarde. Si esto vuelve a ocurrir, ponte en contacto con el servicio de soporte de TI.',
  'ws-resource-imaging-error':
    'Se ha producido un error al iniciar tu WorkSpace. Intenta volver a conectarte en un momento. Si el problema persiste, ponte en contacto con el administrador para obtener ayuda.',
  'ws-try-again-error': 'Inténtalo de nuevo más tarde.',
  'ws-starting-error':
    'No podemos conectarnos a tu WorkSpace en este momento.  Esto puede suceder si tu WorkSpace se está iniciando.',
  'ws-try-again-few-error': 'Inténtalo de nuevo dentro de unos minutos.',
  'ws-connecting-error':
    'Se ha producido un error al conectarse a tu WorkSpace.',
  'ws-service-unavailable':
    'No hemos podido lanzar tu WorkSpace. Inténtalo de nuevo. Si necesitas ayuda, ponte en contacto con tu administrador.',
  'ws-workspace-unavailable':
    'El WorkSpace no está disponible en este momento. Inténtalo de nuevo más tarde. Si necesitas ayuda, ponte en contacto con tu administrador.',
  'ws-workspace-connection-closed':
    'La conexión con el WorkSpace se cerró por inactividad. Vuelve a conectarte.',
  'ws-cm-session-timeout': 'Se ha agotado el tiempo de espera de la sesión.',
  'ws-not-supported-browser-error':
    'Actualmente, este dispositivo o navegador no se puede utilizar para el acceso a WorkSpaces mediante Internet.',
  'ws-wsp-not-supported-error':
    'El espacio de trabajo de DCV al que te estás conectando utiliza una versión desactualizada del agente. Reinicia tu espacio de trabajo o ponte en contacto con tu administrador para actualizar la versión del software.',
  'ws-pcoip-unsupported-in-region':
    'El espacio de trabajo pertenece a una región que actualmente no admite el streaming mediante el protocolo PCoIP en esta plataforma. Utiliza el cliente del espacio de trabajo en Windows/macOS/Linux para transmitir. Ponte en contacto con tu administrador para obtener más detalles',
  'ws-pcoip-unsupported':
    'El streaming de espacios de trabajo PCoIP no es compatible con esta plataforma. Instala este cliente en Ubuntu 20 para transmitir espacios de trabajo PCoIP',
  'ws-protocol-not-supported-browser-error':
    'Tu navegador {{browser}} no es compatible con {{protocol}}',
  'ws-protocol-not-supported-browser-version-error':
    'Tu versión de {{browser}} ({{version}}) es anterior a nuestra versión admitida más baja ({{lowestSupportVersion}}) de {{protocol}}.',
  'ws-try-chrome-error':
    'Vuelve a intentarlo desde Chrome (versión 55 o posterior) o Firefox (versión 48 o posterior) en Windows, OSX o Linux.',
  'ws-unhealthy-error':
    'El WorkSpace seleccionado no se encuentra en buen estado.',
  'ws-try-reboot-error': 'Intenta reiniciar tu WorkSpace primero.',
  'ws-not-auth-device-error':
    'Este dispositivo no está autorizado a acceder al WorkSpace.',
  'ws-contact-admin-error':
    'Si el problema persiste, ponte en contacto con el administrador de WorkSpaces.',
  'ws-resumed':
    'Se está reanudando tu WorkSpace. Debería estar disponible dentro de poco.',
  'ws-not-optimized':
    'El cliente web no está totalmente optimizado para tu hardware. Para una mejor experiencia de reproducción, utiliza',
  'ws-native': 'los clientes nativos de WorkSpaces.',
  'ws-reg-code': 'Código de registro',
  'ws-reg-codes': 'Códigos de registro',
  'ws-os-error':
    'Este sistema operativo o la plataforma no tiene autorización para acceder a tu WorkSpace.',
  'ws-connecting': 'Conectando…',
  'ws-authenticating': 'Autenticando...',
  'ws-getting-resource': 'Obteniendo recurso...',
  'ws-allocating-resource': 'Asignando recurso...',
  'ws-sal-text': 'Inicia sesión para autorizar el acceso',
  'ws-play-header': 'Inicio de sesión correcto',
  'ws-play-description':
    'Se ha iniciado sesión correctamente. Continúa a tu WorkSpace.',
  'ws-play-link': 'Haz clic para continuar',
  'ws-my-workspace': 'Mi WorkSpace',
  'ws-my-workspace-about': 'Resumen',
  'ws-my-workspace-restart': 'Reiniciar',
  'ws-my-workspace-change-compute-type': 'Cambiar el tipo de computación',
  'ws-my-workspace-increase-disk-size': 'Aumentar el tamaño del disco',
  'ws-my-workspace-switch-running-mode': 'Cambiar el modo de ejecución',
  'ws-my-workspace-rebuild': 'Volver a compilar',
  'ws-my-workspace-change-compute-type-title': 'Tipo de computación',
  'ws-my-workspace-increase-disk-size-title': 'Tamaño de disco',
  'ws-my-workspace-switch-running-mode-title': 'Modo de ejecución',
  'ws-my-workspace-restart-error-message':
    'No se ha podido reiniciar tu WorkSpace. Inténtalo de nuevo más tarde.',
  'ws-my-workspace-rebuild-error-message':
    'No se ha podido volver a compilar tu WorkSpace. Inténtalo de nuevo más tarde.',
  'ws-my-workspace-change-compute-type-error-message':
    'No se ha podido cambiar el tipo de computación de tu WorkSpace. Inténtalo de nuevo más tarde.',
  'ws-my-workspace-change-compute-type-time-window-error-message':
    'Solo puedes cambiar el tipo de procesamiento una vez en un período de 6 horas para la ampliación y de 30 días para la reducción.',
  'ws-my-workspace-increase-disk-size-error-message':
    'No se ha podido aumentar el tamaño del volumen de tu WorkSpace. Inténtalo de nuevo más tarde.',
  'ws-my-workspace-switch-running-mode-error-message':
    'No se ha podido cambiar el modo de ejecución de tu WorkSpace. Inténtalo de nuevo más tarde.',
  'ws-my-workspace-increase-disk-size-success-message':
    'Has aumentado correctamente el tamaño del disco.',
  'ws-my-workspace-switch-running-mode-success-message':
    'Has cambiado el modo de ejecución correctamente',
  'ws-my-workspace-restart-disconnect-confirm':
    'Entiendo que el reinicio del WorkSpace desconecta a los usuarios de la sesión actual.',
  'ws-my-workspace-rebuild-disconnect-confirm':
    'Entiendo que con la nueva compilación se borrarán mis datos, se reemplazarán por mi copia de seguridad más reciente y se desconectará a todos los usuarios de la sesión actual. Esta acción no se puede deshacer.',
  'ws-my-workspace-change-compute-type-disconnect-confirm':
    'Soy consciente de que cambiar el tipo de computación desconecta a los usuarios de la sesión actual.',
  'ws-my-workspace-about-registration-code': 'Código de registro',
  'ws-my-workspace-about-region': 'Región',
  'ws-my-workspace-about-workspace-name': 'Nombre del WorkSpace',
  'ws-my-workspace-about-id': 'ID',
  'ws-my-workspace-about-compute-type': 'Tipo de computación',
  'ws-my-workspace-about-status': 'Estado',
  'ws-my-workspace-about-workspace-ip-address': 'Dirección IP del WorkSpace',
  'ws-my-workspace-about-protocol': 'Protocolo',
  'ws-my-workspace-restart-confirm':
    '¿Seguro que quieres reiniciar tu WorkSpace?',
  'ws-my-workspace-restart-confirm-description':
    'Para evitar la pérdida de datos, guarda todos los documentos y archivos de aplicaciones abiertos antes de reiniciar el WorkSpace.',
  'ws-my-workspace-change-compute-type-current-key':
    'Tipo de computación actual',
  'ws-my-workspace-change-compute-type-in-use': 'en uso',
  'ws-my-workspace-change-compute-type-description':
    'Ten en cuenta que cambiar el tipo de computación afecta al coste de tu WorkSpace.',
  'ws-my-workspace-change-compute-type-aria-label':
    'Seleccionar el tipo de computación',
  'ws-my-workspace-increase-disk-size-current-key': 'Tamaño de disco actual',
  'ws-my-workspace-increase-disk-size-description':
    'Ten en cuenta que cambiar el tamaño del disco afecta al coste de tu WorkSpace.',
  'ws-my-workspace-increase-disk-size-info':
    'Aumenta el tamaño de tu {{driveToIncrease}}: unidad de {{currentVolumeSizeInGib}} GIB a {{newVolumeSizeInGib}} GIB',
  'ws-my-workspace-increase-disk-size-max-reached':
    'Has alcanzado el tamaño máximo del disco.',
  'ws-my-workspace-switch-running-mode-current-key': 'Modo de ejecución actual',
  'ws-my-workspace-switch-running-mode-auto-stop': 'AutoStop',
  'ws-my-workspace-switch-running-mode-auto-stop-description':
    'Se factura por horas. El WorkSpace se inicia automáticamente al iniciar sesión.',
  'ws-my-workspace-switch-running-mode-always-on': 'AlwaysOn',
  'ws-my-workspace-switch-running-mode-always-on-description':
    'Se factura con periodicidad mensual. Acceso instantáneo a un WorkSpace que siempre está ejecutándose.',
  'ws-my-workspace-switch-running-mode-description':
    'Ten en cuenta que cambiar el modo de ejecución afecta al coste de tu WorkSpace.',
  'ws-my-workspace-rebuild-description':
    '¿Seguro que quieres volver a compilar tu WorkSpace? Te recomendamos que reinicies tu WorkSpace primero para comprobar si así se resuelven los problemas potenciales. Mediante la nueva compilación de tu WorkSpace se lleva a cabo lo siguiente',
  'ws-my-workspace-rebuild-data-lost-warning-label':
    'Sustituye lo que está actualmente en la unidad D: por los datos de la copia de seguridad más reciente.',
  'ws-my-workspace-rebuild-data-lost-warning-content':
    'Se perderán tus datos actuales.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-label':
    'La nueva compilación de tu WorkSpace puede tardar hasta una hora en efectuarse.',
  'ws-my-workspace-rebuild-user-drive-recreate-warning-content':
    'No puedes usar tu WorkSpace ni hacer cambios en él hasta que se complete la nueva compilación.',
  'ws-region-name-iad': 'EE.UU. Este (Virginia del Norte)',
  'ws-region-name-pdx': 'Oeste de los Estados Unidos (Oregón)',
  'ws-region-name-dub': 'U.E. (Irlanda)',
  'ws-region-name-syd': 'Asia-Pacífico (Sídney)',
  'ws-region-name-nrt': 'Asia Pacífico (Tokio)',
  'ws-region-name-sin': 'Asia-Pacífico (Singapur)',
  'ws-region-name-fra': 'Europa (Fráncfort)',
  'ws-region-name-lhr': 'Europa (Londres)',
  'ws-region-name-yul': 'Canadá (Central)',
  'ws-region-name-gru': 'Sudamérica (Sao Paulo)',
  'ws-region-name-icn': 'Asia-Pacífico (Seúl)',
  'ws-region-name-cpt': 'África (Ciudad del Cabo)',
  'ws-region-name-pdt': 'AWS GovCloud (Oeste de EE. UU.)',
  'ws-region-name-bom': 'Asia Pacífico (Bombay)',
  'ws-region-name-osu': 'AWS GovCloud (Oeste de EE. UU.)',
  'ws-region-name-tlv': 'Israel (Tel Aviv)',
  'ws-unauthorized-action-error':
    'No tienes autorización para realizar esta acción. Ponte en contacto con tu administrador.',
  'ws-support': 'Soporte',
  'ws-logo-alt': 'Logotipo de Amazon WorkSpaces',
  'ws-disconnect-icon-alt':
    'Un icono de nube con silueta discontinua para la desconexión',
  'ws-menubar-logo-alt': 'Logotipo de Amazon WorkSpaces de la barra de menús',
  'ws-menubar-full-screen-toggle-icon-alt':
    'Icono para acceder a pantalla completa en la barra de menús',
  'ws-menubar-send-cad-icon-alt':
    'Icono para enviar Ctrl + Alt + Supr en la barra de menús',
  'ws-menubar-disconnect-icon-alt':
    'Icono del botón de desconexión de la barra de menús',
  'ws-menubar-pin-icon-alt': 'Icono para anclar o desanclar la barra de menús',
  'ws-connect-status-label': 'Estado del WorkSpace',
  'ws-connect-status-negotiation': 'Iniciando',
  'ws-connect-status-registration': 'Registrando WorkSpaces',
  'ws-connect-status-hello': 'Obteniendo capacidades de autenticación',
  'ws-connect-status-customization': 'Recuperando los detalles de marca',
  'ws-connect-status-warp-drive': 'Redirigiendo al portal de autenticación',
  'ws-connect-status-warp-drive-complete':
    'Se ha completado la redirección del portal de autenticación',
  'ws-connect-status-saml':
    'Redirigiendo al proveedor de autenticación externo',
  'ws-connect-status-authenticate': 'Autenticando',
  'ws-connect-status-get_resource': 'Obteniendo recurso',
  'ws-connect-status-allocate-resource': 'Asignando recurso',
  'ws-connect-status-connect': 'Estableciendo conexión',
  'ws-connect-status-resume': 'Inicialización de WorkSpaces',
  'ws-connect-status-resume-pending': 'Lanzamiento de WorkSpaces',
  'ws-connect-status-streaming': 'Sesión de streaming',
  'ws-connect-status-disconnect': 'Se ha desconectado la sesión',
  'ws-connect-status-stream-context-received':
    'Generando los requisitos de streaming...',
  'ws-footer-aws-link-label': 'Enlace a la página principal de AWS',
  'ws-language-label': 'Idioma',
  'ws-brand-description':
    'Servicio de escritorio remoto totalmente administrado',
  'ws-registration-page-title': 'Comenzar',
  'ws-login-description': 'Inicia sesión para acceder a tu WorkSpace.',
  'ws-solo-saml-login-desc':
    'Te llevaremos a tu navegador y te traeremos de vuelta a aquí para completar el inicio de sesión.',
  'ws-progress-bar': 'Una barra de progreso de carga',
  'ws-close-label': 'Cerrar ventana modal',
  'ws-spinner-no-error-message':
    'No se ha encontrado ningún error. Redirigiendo de nuevo a la página de registro',
  'ws-spinner-login-error-message': 'Error de identificación.',
  'ws-spinner-redirecting-message': 'Redirigiendo...',
  'ws-spinner-connecting-message': 'Estableciendo conexión',
  'ws-loglevel-label-info': 'Registro estándar (predeterminado)',
  'ws-loglevel-label-debug': 'Registro avanzado',
  'ws-log-settings-title': 'Registro de diagnóstico',
  'ws-device-id': 'ID del dispositivo',
  'ws-log-setting-preference': 'Registro de diagnóstico',
  'ws-log-preference-title': 'Nivel de registro',
  'ws-log-preference-description':
    'Elige un nivel de preferencias de registro en función de la cantidad de datos que quieras proporcionar en los registros de sesión.',
  'ws-info-loglevel-description':
    'Genera archivos de registro que contienen información de diagnóstico sobre tu dispositivo, conexiones a Internet y métricas de rendimiento de streaming.',
  'ws-debug-loglevel-description':
    'Generates log files that contain the same information as standard logging files, with added debugging-level details and verbose performance data. Note: Advanced logging is only supported on DCV Workspaces.',
  'ws-loguploader-description':
    'Enable diagnostic logging to allow the WorkSpaces client to automatically upload WorkSpaces logs. The information in the logs can help troubleshoot issues. These logs will contain information about your device and internet connection.',
  'ws-loguploader-enabled': 'Habilitado',
  'ws-loguploader-disabled': 'Deshabilitado',
  'ws-modal-close': 'Cerrar',
  'ws-loguploader-copy': 'Copiar',
  'ws-settings': 'Configuración',
  'ws-close-settings-button-label': 'Cerrar ventana modal',
  'ws-close-settings-buuton-name': 'Cerrar',
  'ws-save-reg-code': 'Guardar códigos de registro',
  'ws-save-reg-code-toggle-description':
    '   Activa o desactiva WorkSpaces para guardar los códigos de registro.',
  'ws-enabled-save': 'Habilitado',
  'ws-disabled-save': 'Deshabilitado',
  'ws-rename-description':
    'Cambia el nombre de tu WorkSpace. El nombre reemplazará al código de registro como identificador.',
  'ws-custom-name-input-title': 'Nombre de los WorkSpaces',
  'ws-custom-name-input-placeholder': 'Nombre de los WorkSpaces',
  'ws-input-char-limit-description': 'Máximo de 20 caracteres',
  'ws-remember-me-settings':
    'Mantener mi sesión iniciada hasta que salga o venza mi sesión',
  'ws-settings-save-button': 'Guardar',
  'ws-saved-reg-codes-title': 'Códigos de registro guardados',
  'ws-default-title': '(valor predeterminado)',
  'ws-reg-code-settings-dropdown-label': 'Configuración del código de registro',
  'ws-reg-code-delete-name': 'Eliminar',
  'ws-set-as-default-name': 'Establecer como predeterminado',
  'ws-disable-save-modal-message':
    'Al desactivar WorkSpaces para guardar los códigos de registro, se eliminarán todos los códigos de registro actuales y no se guardará ningún código futuro. ¿Seguro que quieres desactivar la opción de guardar los códigos de registro?',
  'ws-disable-save-modal-title': 'Desactivar Guardar códigos de registro',
  'ws-disable-save-modal-aria-label':
    'Cerrar cuadro de diálogo Desactivar guardar',
  'ws-disable-alret-message':
    'Activa WorkSpaces para guardar tus códigos de registro y no tener que escribirlos cada vez que inicies sesión en tu espacio de trabajo.',
  'ws-delete-reg-code-modal-text': '¿Seguro que quieres eliminarlo?',
  'ws-delete-reg-code-modal-aria-text':
    'Cerrar cuadro de dialogo Eliminar código de registro',
  'ws-reg-code-input-placeholder': 'Introduce el código de registro',
  'ws-reg-code-alias-error':
    'Ya existe un WorkSpace con ese nombre. Introduce un nombre distintos.',
  'ws-autosuggest-no-mtaches-found': 'No se ha encontrado ninguna coincidencia',
  'ws-ok-button': 'ACEPTAR',
  'ws-delete-button': 'Eliminar',
  'ws-disable-button': 'Desactivar',
  'ws-cancel-button': 'Cancelar',
  'ws-warning': 'Advertencia',
  'ws-alert-text-keep-me-logged-in':
    ' La opción “Mantener mi sesión iniciada” se habilitará después de tu siguiente inicio de sesión',
  'ws-no-reg-code-saved-description':
    'No se ha guardado ningún código de registro. Después de iniciar sesión con un código de registro, se guardará aquí.',
  'ws-healthcheck-tcp-fail':
    'Se ha producido un error de conexión mediante los puertos TCP. Comprueba la configuración e inténtalo de nuevo.',
  'ws-healthcheck-udp-fail':
    'Se ha producido un error de conexión mediante los puertos UDP. Comprueba la configuración e inténtalo de nuevo.',
  'ws-healthcheck-tcp-partial':
    'Se ha establecido la conexión a través del puerto UDP {{connectedPort}}. No se ha podido establecer una conexión de salida al puerto UDP {{failedPort}}.',
  'ws-healthcheck-udp-partial':
    'Se ha establecido la conexión a través del puerto UDP {{connectedPort}}. No se ha podido establecer una conexión de salida al puerto UDP {{failedPort}}.',
  'ws-healthcheck-tcp-roundtrip':
    'El tiempo de ida y vuelta para TCP es de {{tcpRoundTripTime}} minutos. La velocidad de tu red es lenta. Ten previstos retrasos.',
  'ws-healthcheck-udp-roundtrip':
    'El tiempo de ida y vuelta para UDP es de {{udpRoundTripTime}} minutos. La velocidad de tu red es lenta. Ten previstos retrasos.',
  'ws-healthcheck-connection-failed':
    'La conexión mediante HTTPS no ha podido conectarse al servicio de WorkSpaces de tu región, {{healthcheckRegionName}}. Ponte en contacto con tu administrador de recursos informáticos',
  'ws-unsupported-pcoip-for-saml':
    'WorkSpaces PCoIP no es compatible con SAML en el cliente web. Descarga las aplicaciones nativas de WorkSpaces e inicia sesión.',
  'ws-unsupported-pcoip-for-idc':
    'WorkSpaces PCoIP is not supported for IDC in web client. Download the native WorkSpaces applications and sign in.',
  'ws-streaming-failed-generic-error':
    'Se ha producido un error inesperado durante el streaming. Inténtalo de nuevo más tarde.',
  'ws-warp-drive-load-timeout':
    'No se puede redirigir al portal de autenticación. La operación falló debido a que se agotó el tiempo de espera. Vuelve a intentarlo después de un tiempo y ponte en contacto con tu administrador de sistemas si el problema persiste',
  'ws-warp-drive-unavailable':
    'No se puede redirigir al portal de autenticación. Revisa la configuración de la red y del firewall e inténtalo de nuevo. Ponte en contacto con tu administrador de sistemas si el problema persiste',
  'ws-warp-drive-unknown-error':
    'No se puede redirigir al portal de autenticación. Se ha producido un error desconocido. Vuelve a intentarlo después de un tiempo y ponte en contacto con tu administrador de sistemas si el problema persiste',
  'ws-solo-system-browser-error':
    'No se puede iniciar el navegador web para redirigir al portal de autenticación. Ponte en contacto con tu administrador de IT para asegurarte de que tienes instalado un navegador compatible con WorkSpaces URI Access.',
  'ws-registration-page-main-title': 'Introducción - Amazon WorkSpaces',
  'ws-login-page-main-title': 'Iniciar sesión - Amazon WorkSpaces',
  'ws-disconnect-page-main-title': 'Desconectado - Amazon WorkSpaces',
  'ws-name': 'Amazon WorkSpaces',
  'ws-web-maxibon-streaming-failed-auth-denied':
    'Se ha producido un error inesperado durante el streaming. El servidor cerró la conexión de streaming de WorkSpaces porque se denegó la autorización para la sesión actual',
  'ws-web-maxibon-streaming-failed-auth-revoked':
    'Se ha producido un error inesperado durante el streaming. El servidor cerró la conexión de streaming de WorkSpaces porque la autorización ya no es válida para la sesión actual',
  'ws-web-maxibon-streaming-failed-access-rejected':
    'Se ha producido un error inesperado durante el streaming. Inténtalo de nuevo más tarde.',
  'ws-web-maxibon-streaming-failed-idle-timeout':
    'Has sido desconectado por inactividad.',
  'ws-web-maxibon-streaming-failed-owner-disconnect':
    'El servidor cerró la conexión de streaming de WorkSpace.',
  'ws-web-maxibon-streaming-failed-user-disconnect':
    'La conexión de streaming de WorkSpace se cerró porque el WorkSpace se está reproduciendo en otra sesión de cliente.',
  'ws-web-maxibon-streaming-failed-streaming-evicted':
    'La conexión de streaming de WorkSpace se ha cerrado debido al inicio de una nueva conexión.',
  'ws-oem-overlay-title': 'Empezando...',
  'ws-oem-overlay-description': 'Continuar con la autenticación',
  'ws-web-maxibon-streaming-failed-streaming-unsupported-credential':
    'Se ha producido un problema al autorizar tu WorkSpace. Inicia sesión con tus credenciales corporativas',
  'ws-web-maxibon-streaming-failed-streaming-fallback-login-required':
    'Se ha producido un error inesperado al iniciar tu WorkSpace. Inicia sesión con tus credenciales corporativas.',
  'ws-authenticate-invalid-fallback-method':
    'El método alternativo no es válido. Se ha producido un error al intentar volver a iniciar sesión con tus credenciales corporativas.',
  'ws-error-alert-icon': 'Icono de alerta de error',
  'ws-save-reg-codes-enabled': 'Guardar códigos de registro, habilitado',
  'ws-save-reg-code-disabled': 'Guardar códigos de registro, deshabilitado',
  'ws-pool-insufficient-capacity':
    'No hay recursos en streaming disponibles para tu sesión. Vuelve a intentarlo dentro de unos minutos.',
  'ws-session-cleanup-in-progress':
    'Tu sesión anterior aún no ha finalizado. Selecciona REINTENTAR transcurridos unos minutos para intentar conectarte de nuevo.',
  'ws-region-unsupported-by-stack':
    'El código de registro utilizado no es compatible con este punto final de cliente web de Workspaces. Utiliza el punto final de producción para transmitir recursos de producción.',
};

export default translation;
