import { useCustomTranslation } from '@/i18n';
import {
  Box,
  SpaceBetween,
  Modal,
  Button,
} from '@cloudscape-design/components';
import * as React from 'react';

interface DisableSaveRegCodesModalprops {
  isVisible: boolean;
  onDisableClick: () => void;
  onCancelClick: () => void;
}

const DisableSaveRegCodesModal: React.FC<DisableSaveRegCodesModalprops> = ({
  isVisible,
  onDisableClick,
  onCancelClick,
}) => {
  // i18n
  const { t } = useCustomTranslation();
  const [visible, setVisible] = React.useState(isVisible);
  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel={t('ws-disable-save-modal-aria-label')}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={onCancelClick}
              ariaLabel={t('ws-cancel-button')}
            >
              {t('ws-cancel-button')}
            </Button>
            <Button
              variant="primary"
              onClick={onDisableClick}
              ariaLabel={t('ws-disable-button')}
            >
              {t('ws-disable-button')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t('ws-disable-save-modal-title')}
    >
      {t('ws-disable-save-modal-message')}
    </Modal>
  );
};

export default DisableSaveRegCodesModal;
