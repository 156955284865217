import { IPersistentStorage } from '@bridge/IPersistentStorage';

export class BrowserPersistentStreamStorage {
  private readonly KEY_COUNTER_START_SUFFIX = '_COUNTER_START';
  private readonly KEY_COUNTER_END_SUFFIX = '_COUNTER_END';
  private readonly KEY_DELIMITER = '_';
  private readonly BASE_COUNTER = 0;

  private readonly _storage: IPersistentStorage;

  constructor(storage: IPersistentStorage) {
    this._storage = storage;
  }

  push(key: string, value: string) {
    const endCounter = this.getEndCounter(key);
    this.setItem(key, value, endCounter);
    this.updateEndCounter(key, endCounter + 1);
  }

  pop(key: string): string | undefined {
    let poppedValue;
    const startCounter = this.getStartCounter(key);
    const value = this.popItem(key, startCounter);
    if (value) {
      poppedValue = value;
      this.updateStartCounter(key, startCounter + 1);
    }
    return poppedValue;
  }
  /*
  batchPop(key: string, batchMaxSizeInBytes: number) {
    const values = [];
    let currentBatchSizeInBytes = 0;
    let currentCounter = this.getStartCounter(key);
    const endCounter = this.getEndCounter(key);
    let currentValue = this.popItem(key, currentCounter);
    while (
      currentBatchSizeInBytes + this.getStringByteSize(currentValue) <=
        batchMaxSizeInBytes &&
      currentCounter < endCounter &&
      currentValue
    ) {
      values.push(currentValue);
      currentBatchSizeInBytes += this.getStringByteSize(currentValue);
      ++currentCounter;
      currentValue = this.popItem(key, currentCounter);
    }
    if (values.length > 0) {
      this.updateStartCounter(key, currentCounter);
    }
    return values;
  }
  */

  batchPop(key: string, batchMaxSizeInBytes: number) {
    const values = [];
    let currentBatchSizeInBytes = 0;
    let currentCounter = this.getStartCounter(key);
    const endCounter = this.getEndCounter(key);
    let currentValue = this.getItem(key, currentCounter);
    while (
      currentBatchSizeInBytes + this.getStringByteSize(currentValue) <=
        batchMaxSizeInBytes &&
      currentCounter < endCounter &&
      currentValue
    ) {
      // Once value is pushed to buffer remove it from memory
      values.push(currentValue);
      this.popItem(key, currentCounter);
      currentBatchSizeInBytes += this.getStringByteSize(currentValue);
      ++currentCounter;
      currentValue = this.getItem(key, currentCounter);
    }
    if (values.length > 0) {
      this.updateStartCounter(key, currentCounter);
    }
    return values;
  }

  private getEndCounter(keyPrefix: string): number {
    const endCounterKey = this.getEndCounterKey(keyPrefix);
    return this.getCounter(endCounterKey);
  }

  private getStartCounter(keyPrefix: string): number {
    const startCounter = this.getStartCounterKey(keyPrefix);
    return this.getCounter(startCounter);
  }

  private updateEndCounter(keyPrefix: string, updatedCounter: number) {
    const endCounterKey = this.getEndCounterKey(keyPrefix);
    this.setItem(endCounterKey, updatedCounter);
  }

  private updateStartCounter(keyPrefix: string, updatedCounter: number) {
    const startCounterKey = this.getStartCounterKey(keyPrefix);
    this.setItem(startCounterKey, updatedCounter);
  }

  private getEndCounterKey(keyPrefix: string): string {
    return keyPrefix + this.KEY_COUNTER_END_SUFFIX;
  }

  private getStartCounterKey(keyPrefix: string): string {
    return keyPrefix + this.KEY_COUNTER_START_SUFFIX;
  }

  private setItem(key: string, value: string | number, keySuffix?: number) {
    if (keySuffix != null) {
      key = key + this.KEY_DELIMITER + keySuffix.toString();
    }
    this._storage.set(key, value.toString());
  }

  private getItem(key: string, keySuffix?: number) {
    if (keySuffix != null) {
      key = key + this.KEY_DELIMITER + keySuffix.toString();
    }
    return this._storage.get(key);
  }

  private popItem(key: string, keySuffix?: number) {
    if (keySuffix != null) {
      key = key + this.KEY_DELIMITER + keySuffix.toString();
    }
    const val = this._storage.get(key);
    this._storage.remove(key);
    return val;
  }

  private getCounter(key: string) {
    const counterString = this.getItem(key);
    if (counterString) {
      const counter = parseInt(counterString);
      if (!isNaN(counter)) {
        return counter;
      }
    }
    return this.BASE_COUNTER;
  }

  private getStringByteSize(value: string | undefined) {
    if (value) {
      return new Blob([value]).size;
    }
    return 0;
  }
}
