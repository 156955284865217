import { ICobranding } from '@bridge/ICobranding';
import { IDevice } from '@bridge/IDevice';
import { BrowserPersistentStreamStorage } from '@core/storage/BrowserPersistentStreamStorage';
import { BrowserPersistentStorage } from '@core/storage/BrowserPersistentStorage';
import { ISessionManager } from '@bridge/ISessionManager';
import { ISessionStorage } from '@bridge/ISessionStorage';
import { BrowserSessionStorage } from '@core/storage/BrowserSessionStorage';
import { SessionManager } from '@core/session/SessionManager';
import { IPersistentStorage } from '@bridge/IPersistentStorage';
import { HttpClientFactory } from '@/bridge/factory/HttpClientFactory';
import { IMetrics } from '@bridge/IMetrics';
import { MetricsPublisher } from '@core/metrics/MetricsPublisher';
import { MetricsManager } from '@core/metrics/MetricsManager';
import { Metrics } from '@core/metrics/Metrics';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import { IRTCChannel } from '@bridge/IRTCChannel';
import { ICoreFactory } from './ICoreFactory';
import { RegionResolver } from '@core/region/RegionResolver';
import { ILogger } from '@bridge/ILogger';

export abstract class BaseCoreFactory implements ICoreFactory {
  protected _cobranding?: ICobranding;
  protected _device?: IDevice;
  protected _logger?: ILogger;
  protected _metrics?: IMetrics;
  protected _sessionManager?: ISessionManager;
  protected _sessionStorage?: ISessionStorage;
  protected _persistentStorage?: IPersistentStorage;
  protected _wsBrokerClient?: WSBrokerService | undefined;
  protected _rtcChannel?: IRTCChannel;
  protected _regionResolver?: RegionResolver;

  abstract getDevice(): IDevice;
  abstract getRTCChannel(): IRTCChannel | null;
  abstract getCobranding(): ICobranding;
  abstract getLogger(): ILogger;

  getSessionManager() {
    if (!this._sessionManager) {
      this._sessionManager = new SessionManager(
        this.getSessionStorage(),
        this.getPersistentStorage()
      );
    }
    return this._sessionManager;
  }

  getSessionStorage() {
    if (!this._sessionStorage) {
      this._sessionStorage = new BrowserSessionStorage();
    }
    return this._sessionStorage;
  }

  getPersistentStorage() {
    if (!this._persistentStorage) {
      this._persistentStorage = new BrowserPersistentStorage();
    }
    return this._persistentStorage;
  }

  getMetrics() {
    if (!this._metrics) {
      const httpClient = HttpClientFactory.getMetricsHttpClient();
      const persistentStorage = this.getPersistentStorage();
      const browserStreamStorage = new BrowserPersistentStreamStorage(
        persistentStorage
      );
      const metricsPublisher = new MetricsPublisher(
        browserStreamStorage,
        httpClient,
        this.getSessionManager(),
        this.getLogger()
      );
      const metricsManager = new MetricsManager(
        this.getDevice(),
        this.getSessionManager(),
        metricsPublisher,
        HttpClientFactory.getHttpClient()
      );
      this._metrics = new Metrics(metricsManager);
    }
    return this._metrics;
  }

  getWsBrokerClient() {
    if (this._wsBrokerClient == null) {
      this._wsBrokerClient = new WSBrokerService(
        HttpClientFactory.getHttpClient(),
        this.getLogger(),
        this.getDevice(),
        this.getMetrics(),
        this.getSessionManager()
      );
    }
    return this._wsBrokerClient;
  }

  getRegionResolver() {
    if (this._regionResolver == null) {
      this._regionResolver = new RegionResolver(
        this.getLogger(),
        this.getMetrics(),
        HttpClientFactory.getHttpClient(),
        this.getRTCChannel()
      );
    }
    return this._regionResolver;
  }
}
