import { ISessionManager } from '@/bridge/ISessionManager';
import { AuthDetailsBuilder } from './AuthDetailsBuilder';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import {
  AuthMethodAuthType,
  AuthMethodMethodType,
  AuthMethodStructure,
  IAuthenticateRequest,
} from '@/core/wsbroker/types';
import { WSBrokerServiceConstants } from '@/core/wsbroker/Constants';
import { IRTCChannel } from '@/bridge/IRTCChannel';
import {
  AuthContextRequestType,
  AuthContextResponse,
} from '@/bridge/types/SoloRTCChannelTypes';
import { SessionProtocols } from '@/bridge/types/SessionTypes';
import { ILogger } from '@/bridge/ILogger';
import { IMetrics } from '@/bridge/IMetrics';
import { MetricResult, Operation } from '@/bridge/types/MetricTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

interface AuthRequestProps {
  sessionManager: ISessionManager;
  rtcChannel: IRTCChannel | null;
  logger: ILogger;
  metrics: IMetrics;
}

export class AuthRequest {
  private readonly sessionManager: ISessionManager;
  private readonly rtcChannel: IRTCChannel | null;
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor({
    sessionManager,
    rtcChannel,
    logger,
    metrics,
  }: AuthRequestProps) {
    this.sessionManager = sessionManager;
    this.rtcChannel = rtcChannel;
    this.logger = logger;
    this.metrics = metrics;
  }

  constructWarpDriveAuthRequest(): IAuthenticateRequest {
    const searchParams = new URLSearchParams(window.location.search);
    const authCode = searchParams.get('auth_code');
    if (!authCode) {
      this.logger.error(
        'Illegal invocation of broker authentication due to missing authCode'
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT,
        ClientErrorCode.AuthWDPageRedirectMissingAuthCode
      );
    }
    this.metrics.emit(Operation.WdUserAuth, MetricResult.Success);
    const authDetailsBuilder = new AuthDetailsBuilder();
    const authMethod = this.sessionManager.get('nextAuthMethod');
    authDetailsBuilder.withMethodType(authMethod?.MethodType);
    authDetailsBuilder.withAuthType(authMethod?.AuthType);
    authDetailsBuilder.withAuthProvider(authMethod?.AuthProvider);
    authDetailsBuilder.withToken(authCode);

    return this.buildAuthRequest(authDetailsBuilder.build());
  }

  constructSSOAuthRequest(strategy: string): IAuthenticateRequest {
    const searchParams = new URLSearchParams(window.location.search);
    const ssoAuthCode = searchParams.get('code');
    if (!ssoAuthCode) {
      this.logger.error(`missing sso token in ${strategy} auth`);
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    const state = searchParams.get('state');
    if (!state) {
      this.logger.error(`missing state ${strategy} auth`);
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    this.metrics.emit(Operation.SSOUserAuth, MetricResult.Success);
    const authDetailsBuilder = new AuthDetailsBuilder();
    const authMethod = this.sessionManager.get('nextAuthMethod');
    const codeVerifier = this.sessionManager.get('codeVerifier');
    authDetailsBuilder.withMethodType(authMethod?.MethodType);
    authDetailsBuilder.withAuthType(authMethod?.AuthType);
    authDetailsBuilder.withAuthProvider(authMethod?.AuthProvider);
    authDetailsBuilder.withToken(ssoAuthCode);
    authDetailsBuilder.withState(state);
    authDetailsBuilder.withPKCECodeVerifier(codeVerifier);

    return this.buildAuthRequest(authDetailsBuilder.build());
  }

  constructReauthenticateAuthRequest(): IAuthenticateRequest {
    const authToken = this.sessionManager.get('authToken')?.Value;
    if (!authToken) {
      this.logger.error('missing auth token in reauthenticate');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }

    const authDetailsBuilder = new AuthDetailsBuilder();
    authDetailsBuilder.withMethodType(AuthMethodMethodType.REAUTHENTICATE);
    authDetailsBuilder.withAuthType(AuthMethodAuthType.TOKEN);
    authDetailsBuilder.withToken(authToken);

    return this.buildAuthRequest(authDetailsBuilder.build());
  }

  async constructReconnectAuthRequest(): Promise<IAuthenticateRequest> {
    const authToken = this.sessionManager.get('authToken')?.Value;
    if (!authToken) {
      this.logger.error('missing auth token in reconnection');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    const authDetailsBuilder = new AuthDetailsBuilder();
    const authMethod = this.sessionManager.get('nextAuthMethod');
    const authContext = await this.requestAuthContextFromRtcChannel();
    authDetailsBuilder.withMethodType(authMethod?.MethodType);
    authDetailsBuilder.withAuthType(authMethod?.AuthType);
    authDetailsBuilder.withToken(authToken);
    authDetailsBuilder.withUsername(authContext.UserName);
    authDetailsBuilder.withPassword(authContext.Password);

    return this.buildAuthRequest(authDetailsBuilder.build());
  }

  private buildAuthRequest(
    authDetails: AuthMethodStructure
  ): IAuthenticateRequest {
    const sessionContext = this.sessionManager.get('sessionContext');
    if (!sessionContext) {
      this.logger.error('missing session context in authentication call');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }

    return {
      SessionContext: sessionContext,
      AuthDetails: authDetails,
      Version: WSBrokerServiceConstants.ApiVersion,
    };
  }

  private async requestAuthContextFromRtcChannel() {
    return await new Promise<AuthContextResponse>((resolve, _reject) => {
      if (!this.rtcChannel) {
        throw WsError.getUnknownError();
      }

      this.rtcChannel.requestAuthContext(
        AuthContextRequestType.ReconnectionContext,
        {
          SessionProtocol: SessionProtocols.PCOIP,
        },
        (payload) => {
          resolve(payload);
        }
      );
    });
  }
}
