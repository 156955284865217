import { ILogger } from '@bridge/ILogger';
import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import { IMetrics } from '@bridge/IMetrics';
import {
  Action,
  ActionType,
  MigrateRegistrationCodesResponse,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';

export class MigrateRegistrationCodesHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor(logger: ILogger, metrics: IMetrics) {
    this.logger = logger;
    this.metrics = metrics;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    if (!this.validate(msg)) {
      return;
    }
    try {
      const response = msg.Payload;
      const migrateRegistationCodesResponse =
        response as MigrateRegistrationCodesResponse;
      this.metrics.emit(
        Operation.MigrateRegistrationCodes,
        MetricResult.Success
      );
      callback?.(migrateRegistationCodesResponse);
    } catch (error: any) {
      this.logger.error(
        `Failed to handle settings update RTC notification`,
        error
      );
      this.metrics.emit(
        Operation.MigrateRegistrationCodes,
        MetricResult.Error,
        error
      );
    }
  }

  private validate(msg: RTCChannelMessage): boolean {
    this.logger.info(
      `Sending Registation codes migration request to Solo ${JSON.stringify(
        msg
      )}`
    );
    if (
      msg.Action !== Action.MIGRATE_REGISTRATION_CODES ||
      msg.ActionType !== ActionType.RESPONSE
    ) {
      return false;
    }
    return true;
  }
}
