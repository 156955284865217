import { useTranslation } from 'react-i18next';
import ActionGroup from '@/components/ActionGroup';
import { AutosuggestProps } from '@cloudscape-design/components';
import * as React from 'react';
import { IRegCode } from '@/stores/registration';
import { useRef } from 'react';

interface RegistrationComponentProps {
  regCodeLastUsed?: string;
  inputValue?: string;
  regCodes?: IRegCode[];
  onRegCodeChange: (newRegCode: string) => void;
  onRegistration: () => void;
  isLoading: boolean;
  error: any;
}

const RegistrationComponent: React.FC<RegistrationComponentProps> = ({
  regCodeLastUsed,
  regCodes,
  onRegCodeChange,
  onRegistration,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();
  const fieldRef = useRef<HTMLInputElement | null>(null);

  const onRegCodeChangeWrapper = (newValue: string) => {
    onRegCodeChange(newValue);
  };

  if (error) {
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  }

  const regCodeOptions: AutosuggestProps.Option[] = regCodes
    ? regCodes
        .map((value) => ({
          value:
            value.alias === '' || value.alias === undefined
              ? value.name
              : value.alias,
          description: t(value?.region?.localeKey),
        }))
        .reverse()
    : [];

  return (
    <ActionGroup
      title={t('ws-registration-page-title')}
      titleDescription={t('ws-get-started')}
      autosuggest={{
        id: 'registrationTxt',
        placeholder: t('ws-reg-code-input-placeholder'),
        label: t('ws-reg-code'),
        value: regCodeLastUsed ?? '',
        options: regCodeOptions,
        onChange: onRegCodeChangeWrapper,
        onEnter: onRegistration,
        error,
        isLoading,
      }}
      actions={[
        {
          id: 'registrationBtn',
          label: t('ws-register'),
          onClick: onRegistration,
          isLoading,
        },
      ]}
      fieldRef={fieldRef}
    />
  );
};

export default RegistrationComponent;
