import { InSessionFactory } from '@/bridge/factory/InSessionFactory';
import { SessionState } from '@/bridge/types/SoloRTCChannelTypes';
import { SoloNativeRTCChannel } from '@/core/rtcChannel/SoloNativeRTCChannel';
import { useSessionStore } from '@/stores/session';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const reportHeartbeatTask = InSessionFactory.getReportHeartbeatTask();

export const useReportHeartbeatTask = () => {
  const navigate = useNavigate();
  const heartBeatEndpoint = useSessionStore((state) => state.heartBeatEndpoint);
  const sessionState = useSessionStore((state) => state.sessionState);
  const [runTask, setRunTask] = useState(false);

  const startReportingHeartbeat = () => {
    setRunTask(true);
  };

  const onError = (_error: unknown) => {
    navigate('/disconnect'); // TODO: disconnect should be able to show error
  };

  useEffect(() => {
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      if (sessionState === SessionState.STREAMING) {
        setRunTask(true);
      } else if (sessionState === SessionState.DISCONNECT) {
        setRunTask(false);
      }
    }
  }, [sessionState]);

  useEffect(() => {
    if (runTask && heartBeatEndpoint) {
      reportHeartbeatTask.start({ onError });
    } else {
      reportHeartbeatTask.stop();
    }

    return () => {
      reportHeartbeatTask.stop();
    };
  }, [runTask, heartBeatEndpoint]);

  return {
    startReportingHeartbeat,
  };
};
