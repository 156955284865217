import { PcoipSessionStreamer } from '@/streaming/pcoip/PcoipSessionStreamer';
import { MaxibonSessionStreamer } from '@/streaming/maxibon/MaxibonSessionStreamer';
import { ISessionStreamer } from '../ISessionStreamer';
import { SessionProtocols } from '@/bridge/types/SessionTypes';

import { CoreFactory } from '@/bridge/factory/CoreFactory';

const logger = CoreFactory.getLogger();

export class StreamFactory {
  private static pcoipSessionStreamer: ISessionStreamer;
  private static maxibonSessionStreamer: ISessionStreamer;

  static getSessionStreamer(
    protocol: SessionProtocols
  ): ISessionStreamer | undefined {
    switch (protocol) {
      case SessionProtocols.MAXIBON:
        if (!this.maxibonSessionStreamer) {
          this.maxibonSessionStreamer = new MaxibonSessionStreamer();
        }
        return this.maxibonSessionStreamer;
      case SessionProtocols.PCOIP:
        if (!this.pcoipSessionStreamer) {
          this.pcoipSessionStreamer = new PcoipSessionStreamer(logger);
        }
        return this.pcoipSessionStreamer;
      default:
        return undefined;
    }
  }
}
