import { useCustomTranslation } from '@/i18n';
import { Alert } from '@cloudscape-design/components';

const DisableSaveAlert = () => {
  // i18n
  const { t } = useCustomTranslation();

  return <Alert type="info">{t('ws-disable-alret-message')}</Alert>;
};

export default DisableSaveAlert;
