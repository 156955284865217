import { SessionProtocols } from '@/bridge/types/SessionTypes';

export enum DisplayProtocols {
  PCoIP = 'PCoIP',
  WSP = 'DCV',
}

const ProtocolMap = new Map<SessionProtocols, DisplayProtocols>([
  [SessionProtocols.PCOIP, DisplayProtocols.PCoIP],
  [SessionProtocols.MAXIBON, DisplayProtocols.WSP],
  [SessionProtocols.WSP, DisplayProtocols.WSP],
]);

const getDisplayProtocol = (
  sessionProtocol?: SessionProtocols | ''
): DisplayProtocols | undefined =>
  ProtocolMap.get(sessionProtocol as SessionProtocols);

export const ProtocolDisplayUtil = {
  getDisplayProtocol,
};
