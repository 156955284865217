import { useCustomTranslation } from '@/i18n';
import {
  ButtonDropdown,
  ButtonDropdownProps,
} from '@cloudscape-design/components';
import { IRegCode, useRegCodes } from '@stores/registration';
import { useState } from 'react';
import DeleteRegCodeModal from './DeleteRegCodeModal';
import { CancelableEventHandler } from '@cloudscape-design/components/internal/events';

interface RegistrationCodeSettingsComponentProps {
  userSelectedRegCode: IRegCode;
}

const RegistrationCodeSettingsComponent: React.FC<
  RegistrationCodeSettingsComponentProps
> = ({ userSelectedRegCode }) => {
  // i18n
  const { t } = useCustomTranslation();

  const setRegCodeAsDefault = useRegCodes((state) => state.setRegCodeAsDefault);
  const deleteRegCode = useRegCodes((state) => state.deleteRegCode);

  const handleDeleteClick = () => {
    deleteRegCode(userSelectedRegCode.name);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleRegCodeSettings: CancelableEventHandler<
    ButtonDropdownProps.ItemClickDetails
  > = ({ detail }) => {
    const selectedOption = detail.id;
    switch (selectedOption) {
      case 'delete':
        setShowDeleteModal(true);
        break;
      case 'set-as-default':
        setRegCodeAsDefault(userSelectedRegCode.name);
        break;
    }
  };

  return (
    <div>
      <div>
        <ButtonDropdown
          items={[
            { id: 'delete', text: t('ws-reg-code-delete-name') },
            { id: 'set-as-default', text: t('ws-set-as-default-name') },
          ]}
          onItemClick={handleRegCodeSettings}
          ariaLabel={t('ws-reg-code-settings-dropdown-label')}
          variant="icon"
        />
        {showDeleteModal && (
          <DeleteRegCodeModal
            isVisible={showDeleteModal}
            regCode={userSelectedRegCode}
            onDeleteClick={handleDeleteClick}
          />
        )}
      </div>
    </div>
  );
};

export default RegistrationCodeSettingsComponent;
