import { useState, useMemo } from 'react';
import ManageLoginInfo from './ManageLoginInformation';
import LogUploaderSettings from './LogUploader/LogUploaderSettings';
import { TabIds } from './types';
import { useCustomTranslation } from '@/i18n';
import {
  Button,
  Modal,
  SpaceBetween,
  Tabs,
  Box,
} from '@cloudscape-design/components';
import { useSessionStore } from '@stores/session';

interface TabProps {
  label: string;
  id: string;
  content: JSX.Element;
  disabled: boolean;
}

const Settings = () => {
  const { t } = useCustomTranslation();
  const [activeTabId, setActiveTabId] = useState(TabIds.MANAGE_LOGIN_INFO);
  const [visible, setVisible] = useState(false);
  const isLogUploaderEnabledAtDirectoryLevel = useSessionStore(
    (state) => state.isLogUploaderEnabledAtDirectoryLevel
  );

  let defaultTabs = useMemo(
    () => [
      {
        label: t('ws-reg-codes'),
        id: TabIds.MANAGE_LOGIN_INFO,
        content: <ManageLoginInfo />,
        disabled: false,
      },
    ],
    [activeTabId]
  );

  if (isLogUploaderEnabledAtDirectoryLevel) {
    defaultTabs = defaultTabs.concat({
      label: t('ws-log-settings-title'),
      id: TabIds.LOG_UPLOADER,
      content: <LogUploaderSettings />,
      disabled: false,
    });
  }

  const openSettings = () => {
    setVisible(true);
  };

  const closeSettings = () => {
    setVisible(false);
  };

  const tabs: TabProps[] = useMemo(() => [...defaultTabs], [defaultTabs]);

  return (
    <div>
      <Button variant="link" id="ws-settings-button" onClick={openSettings}>
        <span>{t('ws-settings')}</span>
      </Button>
      <Modal
        size="large"
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel={t('ws-close-settings-button-label')}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeSettings}>
                {t('ws-close-settings-buuton-name')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t('ws-settings')}
      >
        <Tabs
          variant="default"
          tabs={tabs}
          onChange={({ detail }) =>
            setActiveTabId(detail.activeTabId as TabIds)
          }
        />
      </Modal>
    </div>
  );
};

export default Settings;
