import { useFadeAnimation } from '@/hooks/useFadeAnimation';
import { PropsWithChildren, useEffect } from 'react';

const FadeAnimation: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    style,
    fadeIn,
    fadeOut,
    subscribeOnLeavePage,
    unsubscribeOnLeavePage,
  } = useFadeAnimation();

  useEffect(() => {
    const timer = setTimeout(fadeIn, 300);

    subscribeOnLeavePage(() => {
      fadeOut();
    });

    return () => {
      unsubscribeOnLeavePage(() => {});
      clearTimeout(timer);
    };
  }, []);

  return <div style={style}>{children}</div>;
};

export default FadeAnimation;
