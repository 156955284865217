import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import {
  AuthMethodAuthType,
  AuthMethodMethodType,
  AuthMethodStructure,
  AuthProvider,
} from '@/core/wsbroker/types';

export class AuthDetailsBuilder {
  private methodType?: AuthMethodMethodType;
  private authType?: AuthMethodAuthType;
  private token?: string;
  private state?: string;
  private username?: string;
  private password?: string;
  private authProvider?: AuthProvider;
  private pkceCodeVerifier?: string;

  withMethodType(methodType?: AuthMethodMethodType) {
    this.methodType = methodType;
  }

  withAuthType(authType?: AuthMethodAuthType) {
    this.authType = authType;
  }

  withToken(token?: string) {
    this.token = token;
  }

  withState(state?: string) {
    this.state = state;
  }

  withUsername(username?: string) {
    this.username = username;
  }

  withPassword(password?: string) {
    this.password = password;
  }

  withAuthProvider(authProvider?: AuthProvider) {
    this.authProvider = authProvider;
  }

  withPKCECodeVerifier(pkceCodeVerifier?: string) {
    this.pkceCodeVerifier = pkceCodeVerifier;
  }

  build(): AuthMethodStructure {
    if (!this.methodType || !this.authType) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }

    return {
      MethodType: this.methodType,
      AuthType: this.authType,
      Token: this.token,
      State: this.state,
      Username: this.username,
      Password: this.password,
      AuthProvider: this.authProvider,
      PKCECodeVerifier: this.pkceCodeVerifier,
    };
  }
}
