import { Localization, I18nResource } from '@bridge/Localization';
import { I18nKey } from '@/i18n/types';
import enUSLocales from './locales/en-US';
import frFRLocales from './locales/fr-FR';
import deDELocales from './locales/de-DE';
import esESLocales from './locales/es-ES';
import jaJPLocales from './locales/ja-JP';
import koKRLocales from './locales/ko-KR';
import ptBRLocales from './locales/pt-BR';
import zhCNLocales from './locales/zh-CN';
import { useTranslation } from 'react-i18next';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';

export enum LngOptions {
  EN_US = 'en',
  FR_FR = 'fr',
  DE_DE = 'de',
  ES_ES = 'es',
  JA_JP = 'ja',
  KO_KR = 'ko',
  PT_BR = 'pt_BR',
  ZH_CN = 'zh_CN',
}

export enum LngDisplayNameOptions {
  EN_US = 'English',
  FR_FR = 'Français',
  DE_DE = 'Deutsch',
  ES_ES = 'Español',
  JA_JP = '日本語',
  KO_KR = '한국어',
  PT_BR = 'Português',
  ZH_CN = '中文',
}

type ILocaleConfig = {
  [key in LngOptions]?: {
    displayName: LngDisplayNameOptions;
    resources: I18nResource;
  };
};

export const LocaleConfig: ILocaleConfig =
  SoloNativeRTCChannel.isChannelAvailable()
    ? {
        [LngOptions.EN_US]: {
          displayName: LngDisplayNameOptions.EN_US,
          resources: {
            translation: enUSLocales,
          },
        },
        [LngOptions.FR_FR]: {
          displayName: LngDisplayNameOptions.FR_FR,
          resources: {
            translation: frFRLocales,
          },
        },
        [LngOptions.DE_DE]: {
          displayName: LngDisplayNameOptions.DE_DE,
          resources: {
            translation: deDELocales,
          },
        },
        [LngOptions.ES_ES]: {
          displayName: LngDisplayNameOptions.ES_ES,
          resources: {
            translation: esESLocales,
          },
        },
        [LngOptions.JA_JP]: {
          displayName: LngDisplayNameOptions.JA_JP,
          resources: {
            translation: jaJPLocales,
          },
        },
        [LngOptions.KO_KR]: {
          displayName: LngDisplayNameOptions.KO_KR,
          resources: {
            translation: koKRLocales,
          },
        },
        [LngOptions.PT_BR]: {
          displayName: LngDisplayNameOptions.PT_BR,
          resources: {
            translation: ptBRLocales,
          },
        },
        [LngOptions.ZH_CN]: {
          displayName: LngDisplayNameOptions.ZH_CN,
          resources: {
            translation: zhCNLocales,
          },
        },
      }
    : {
        [LngOptions.EN_US]: {
          displayName: LngDisplayNameOptions.EN_US,
          resources: {
            translation: enUSLocales,
          },
        },
        [LngOptions.FR_FR]: {
          displayName: LngDisplayNameOptions.FR_FR,
          resources: {
            translation: frFRLocales,
          },
        },
      };

type IResource = {
  [key in LngOptions]?: I18nResource;
};
const initResources: IResource = {};
export const resources = Object.entries(LocaleConfig).reduce(
  (result, [lng, LocaleConfig]) => {
    result[lng as LngOptions] = LocaleConfig.resources;
    return result;
  },
  initResources
);

export const i18nInit = () => Localization.init(resources);

export const useCustomTranslation = () => {
  const { t, i18n } = useTranslation();
  const translate = (key: I18nKey, options?: { [key: string]: string }) =>
    t(key, options);

  return { t: translate, i18n };
};
