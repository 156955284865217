import { useErrorHandler } from '@hooks/useErrorHandler';
import { useNativeStreaming } from '@views/Stream/hooks/useNativeStreaming';
import { useCallback, useEffect } from 'react';
import ConnectStatus from '@components/ConnectStatus';
import { useSessionStore } from '@stores/session';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { useNavigate } from 'react-router-dom';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import {
  Action,
  DisconnectRequest,
  SessionState,
} from '@bridge/types/SoloRTCChannelTypes';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { NativeInSessionError } from '@/bridge/types/NativeInSessionError';

const rtcChannel = CoreFactory.getRTCChannel();
const NativeMode: React.FC = () => {
  const { showError } = useErrorHandler();
  const navigate = useNavigate();
  const allocatedResource = useSessionStore((state) => state.allocatedResource);
  const protocol = useSessionStore((state) => state.resourceProtocol);
  const domainName = useSessionStore((state) => state.domainName);
  const setSession = useSessionStore((state) => state.setSession);

  const { isStreamSuccess, error } = useNativeStreaming(
    protocol as SessionProtocols,
    domainName,
    allocatedResource
  );

  const disconnectEventCallback = useCallback(
    (disconnectReq: DisconnectRequest) => {
      navigate(
        `/disconnect?${NativeInSessionError.DISCONNECT_STATUS_CODE_SEARCH_NAME}=${disconnectReq?.DisconnectCode}`
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (!allocatedResource) {
      showError(
        new WsError(
          WsErrorTypes.ERROR_TYPE_CONNECTION,
          WsErrorCodes.ERROR_INVALID_INPUT
        )
      );
    }
  }, [allocatedResource]);

  useEffect(() => {
    error && showError(error);
  }, [showError, error]);

  useEffect(() => {
    if (rtcChannel && isStreamSuccess) {
      rtcChannel.subscribeToActionEvent(
        Action.DISCONNECT,
        disconnectEventCallback
      );
      setSession({ sessionState: SessionState.STREAMING });
      navigate('/self-service');
    }
  }, [isStreamSuccess, navigate]);

  return (
    <>
      <ConnectStatus />
    </>
  );
};

export default NativeMode;
