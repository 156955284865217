import { AppConstants } from '@/bridge/constants/AppConstants';
import { Cookies } from 'react-cookie';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { AuthStepDeterminer } from '../AuthStepDeterminer';
import { BaseStep } from './BaseStep';

const cookie = new Cookies();

export class WarpDriveStep extends BaseStep {
  async perform() {
    this.validateCsrfToken();
    const authResponse = await this.authentication.authenticate(
      this.authRequest.constructWarpDriveAuthRequest()
    );
    return AuthStepDeterminer.getNextStepFromAuthResponse(authResponse);
  }

  private validateCsrfToken() {
    const searchParams = new URLSearchParams(window.location.search);
    const csrfToken = searchParams.get('state');
    const storedCsrfToken = cookie.get(AppConstants.COOKIE_CSRF_TOKEN);
    cookie.set(AppConstants.COOKIE_CSRF_TOKEN, '', { path: '/' });
    const csrfTokenValid = storedCsrfToken && csrfToken === storedCsrfToken;
    if (!csrfTokenValid) {
      this.logger.error('received invalid csrf token');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
  }
}
