import { CSSProperties, useState } from 'react';

const ON_LEAVE_PAGE_EVENT_NAME = 'OnLeavePageEvent';

const FADE_IN_STYLE: CSSProperties = {
  transition: '500ms all linear',
};

const FADE_OUT_STYLE: CSSProperties = {
  opacity: 0,
  transform: 'translated3d(0, -60px, 0)',
  transition: '500ms all linear',
};

export interface FadeAnimationHookProps {
  skipFadeIn?: boolean;
}

export const useFadeAnimation = (props?: FadeAnimationHookProps) => {
  const [style, setStyle] = useState(
    props?.skipFadeIn ? FADE_IN_STYLE : FADE_OUT_STYLE
  );
  const fadeIn = () => setStyle(FADE_IN_STYLE);
  const fadeOut = () => setStyle(FADE_OUT_STYLE);

  const subscribeOnLeavePage = (listener: () => void) => {
    document.addEventListener(ON_LEAVE_PAGE_EVENT_NAME, listener);
  };

  const unsubscribeOnLeavePage = (listener: () => void) => {
    document.removeEventListener(ON_LEAVE_PAGE_EVENT_NAME, listener);
  };

  const publishLeavePageEvent = async (delay?: number) =>
    await new Promise((resolve) => {
      const event = new CustomEvent(ON_LEAVE_PAGE_EVENT_NAME);
      document.dispatchEvent(event);

      setTimeout(resolve, delay ?? 300);
    });

  return {
    style,
    fadeIn,
    fadeOut,
    subscribeOnLeavePage,
    unsubscribeOnLeavePage,
    publishLeavePageEvent,
  };
};
