import { ILogger } from '@bridge/ILogger';
import { LogLevel } from '@core/constants/LoggerConstants';
import { IDevice } from '@bridge/IDevice';
import { WebLogUploader } from '@amzn/euc-web-log-uploader';
import {
  LogFileType,
  Region,
} from '@amzn/euc-web-log-uploader/dist/types/LogUploaderTypes';
import { useLogUploaderPreferenceStore } from '@stores/loguploader';

/*
 * This class uses WebLogUploader to save logs to indexDB. During initialization, uploader class is initialized
 * depending only on user configuration (on by default). But the actual uploading of logs is done in UI code
 * which checks if log uploader is enabled at directory level. This can be simplified by moving the
 * isUploaderEnabledAtDirectoryLevel to log uploader store. Adding this as a ToDo for now.
 * */
export class WebLogger implements ILogger {
  private readonly device: IDevice;
  private logLevel: string;
  private isSaveLogsToUpload: boolean;
  private uploader?: WebLogUploader;
  private static readonly LogUploaderSupportedRegions = [
    'beta.iad',
    'fips.beta.iad',
    'prod.iad',
    'fips.prod.iad',
    'gamma.pdx',
    'fips.gamma.pdx',
    'prod.pdx',
    'fips.prod.pdx',
    'prod.dub',
    'prod.syd',
    'prod.nrt',
    'prod.sin',
    'prod.fra',
    'prod.lhr',
    'prod.yul',
    'prod.gru',
    'prod.icn',
    'prod.cpt',
    'prod.bom',
    'prod.tlv',
  ];

  constructor(device: IDevice) {
    this.device = device;
    this.logLevel = 'INFO';
    this.isSaveLogsToUpload = false;
    this.setupLogUploader();
  }

  setLogLevel(logLevel: string) {
    this.logLevel = logLevel;
  }

  startLogUpload(organizationName: string, endPoint: string | undefined) {
    if (!this.isLogUploaderSupported(endPoint)) {
      this.info(
        `Log collection is not supported for current region ${endPoint}`
      );
      return;
    }

    if (!this.uploader) {
      this.setupLogUploader();
    }
    try {
      this.uploader?.startUpload(organizationName, endPoint as Region);
    } catch (e) {
      this.error(
        `Failed to start log uploader for ${organizationName} and ${endPoint}`
      );
    }
  }

  pauseLogUpload() {
    this.isSaveLogsToUpload = false;
    this.uploader?.pauseUpload();
    this.uploader = undefined;
  }

  flush() {
    if (this.uploader) {
      this.info('Flushing all logs... ');
      this.uploader.flush();
    }
  }

  info(message: string): void {
    const log = this.generateLogAndSaveToDevice(LogLevel.Info, message);
    this.saveLogsToLogUploader(log);
  }

  warn(message: string): void {
    const log = this.generateLogAndSaveToDevice(LogLevel.Warn, message);
    this.saveLogsToLogUploader(log);
  }

  error(message: string, exceptionMessage?: string, stackTrace?: string): void {
    const log = this.generateLogAndSaveToDevice(
      LogLevel.Error,
      this.concatExceptionMessage(message, exceptionMessage, stackTrace)
    );
    this.saveLogsToLogUploader(log);
  }

  fatal(message: string, exceptionMessage?: string, stackTrace?: string): void {
    const log = this.generateLogAndSaveToDevice(
      LogLevel.Fatal,
      this.concatExceptionMessage(message, exceptionMessage, stackTrace)
    );
    this.saveLogsToLogUploader(log);
  }

  uploadLogFile(logName: string, logFileData: string) {}

  /*
   LogUploader needs deviceID during initialization. Ideally client will always have deviceID.
   But in case of Solo, there is a possibility of Negotiation failing in which case deviceID will
   not be set. Hence leaving this inside try for now.
  */
  private setupLogUploader() {
    try {
      const logUploaderSavedState = useLogUploaderPreferenceStore.getState();
      this.isSaveLogsToUpload = logUploaderSavedState.isLoggingEnabledByUser;
      this.logLevel = logUploaderSavedState.logLevelPreference;

      if (this.isSaveLogsToUpload) {
        this.uploader = new WebLogUploader({
          applicationName: 'WorkspacesWebClient',
          deviceId: this.device.getDeviceUUID() as string,
          logFileType: LogFileType.client,
        });
      }

      this.info(
        `User Log settings for device::${this.device.getDeviceUUID()} are UserLogsEnabled:${
          this.isSaveLogsToUpload
        } LogLevel:${this.logLevel}`
      );
    } catch (e: any) {
      console.error(e);
      this.error(
        `Failed to initialize log uploader for device:${this.device.getDeviceUUID()}`,
        e.message
      );
    }
  }

  private saveLogsToLogUploader(log: string) {
    if (this.isSaveLogsToUpload) {
      this?.uploader?.uploadLog(log);
    }
  }

  private generateLogAndSaveToDevice(
    logLevel: LogLevel,
    message: string
  ): string {
    const time = new Date().toISOString();
    this.device.saveLog(message, logLevel);
    return time + '>[' + logLevel + ']' + message;
  }

  private concatExceptionMessage(
    message: string,
    exceptionMessage?: string,
    stackTrace?: string
  ): string {
    let exceptionLog = message;
    if (exceptionMessage) {
      exceptionLog += `\n[ExceptionMessage]${exceptionMessage}`;
    }

    if (stackTrace) {
      exceptionLog += `\n[StackTrace]${stackTrace}`;
    }
    return exceptionLog;
  }

  isLogUploaderSupported(region: string | undefined) {
    if (region && WebLogger.LogUploaderSupportedRegions.includes(region)) {
      return true;
    }
    return false;
  }
}
