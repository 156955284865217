import { ILogger } from '@bridge/ILogger';
import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionType,
  LogUploadData,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsError } from '@core/error/WsError';

export class LogUploadPublishHandler implements IActionHandler {
  private readonly logger: ILogger;

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  handleMessage(
    msg: RTCChannelMessage,
    callback?: OnSuccessRTCCallback | undefined
  ): void {
    try {
      LogUploadPublishHandler.validate(msg);
      const logUploadDataPayload = msg?.Payload as LogUploadData;
      callback?.(logUploadDataPayload);
    } catch (error) {
      this.logger.error(
        `Error uploading Solo native client log:: ${JSON.stringify(msg)}`
      );
    }
  }

  private static validate(msg: RTCChannelMessage) {
    if (
      msg.Action !== Action.LOG_UPLOAD_PUBLISH ||
      msg.ActionType !== ActionType.RESPONSE ||
      !msg.Payload
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_UNKNOWN,
        WsErrorCodes.ERROR_UNKNOWN,
        ClientErrorCode.LogUploaderInvalidInputError
      );
    }
  }
}
