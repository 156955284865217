type ImageType = 'image/png';

const toBase64 = async (
  imageUrl: string,
  imageType: ImageType
): Promise<string> =>
  await new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx?.drawImage(img, 0, 0);

        resolve(canvas.toDataURL(imageType));
      } catch (error) {
        reject(error);
      }
    };

    img.src = imageUrl;
  });

export const ImageUtils = {
  toBase64,
};
