import { useCallback, useState } from 'react';
import { InSessionFactory } from '@/bridge/factory/InSessionFactory';
import { useSelfServiceStore } from '@/views/SelfService/hooks/useSelfServiceStore';
import {
  RunningModes,
  SelfServiceActionResult,
} from '@/bridge/types/SelfService';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { useSessionStore } from '@/stores/session';
import { Operation } from '@/bridge/types/MetricTypes';
import { I18nError } from '@/lib/I18nError';
import { DisconnectRequest } from '@bridge/types/SoloRTCChannelTypes';
import { useNavigate } from 'react-router-dom';
import { IRegion } from '@bridge/types/RegionTypes';
import { IResource } from '@core/wsbroker/types';

const logger = CoreFactory.getLogger();
const metrics = CoreFactory.getMetrics();
const selfServiceClient = InSessionFactory.getSelfServiceClient();
const rtcChannel = CoreFactory.getRTCChannel();

export const useSelfServiceActions = () => {
  const [error, setError] = useState<I18nError>();
  const navigate = useNavigate();
  const cleanError = () => setError(undefined);
  const {
    status,
    isPending,
    isCompleted,
    isProcessing,
    process,
    complete,
    reset,
  } = useSelfServiceStore();
  const authToken = useSessionStore((state) => state.authToken)
    ?.Value as string;
  const sessionContext = useSessionStore((state) => state.sessionContext);
  const sessionId = sessionContext?.SessionId as string;
  const regCode = useSessionStore((state) => state.registrationCode) as string;
  const region = useSessionStore((state) => state.region) as IRegion;

  const disconnectEventCallback = useCallback(
    (disconnectReq: DisconnectRequest) => {
      navigate('/disconnect', {
        state: disconnectReq?.DisconnectCode,
      });
    },
    [navigate]
  );

  const onSuccess = (requestDisconnect: boolean) => {
    complete();
    if (rtcChannel && requestDisconnect) {
      rtcChannel.requestClientDisconnect(disconnectEventCallback);
    }
  };

  const actionHandler = (
    actionPerformer: () => Promise<SelfServiceActionResult>,
    metricOperation: Operation,
    resourceRefresher?: () => Promise<IResource>
  ) => {
    cleanError();
    process();
    logger.info(`Performing SelfService action - ${metricOperation}`);
    const timedMetric = metrics.embark(metricOperation);
    actionPerformer()
      .then((result) => {
        metrics.emitMetricOperation(timedMetric);
        if (resourceRefresher) {
          logger.info(
            `Successfully completed SelfService action. Initiating resource update`
          );
          return resourceRefresher();
        } else {
          Promise.resolve(result);
        }
      })
      .then(() => {
        const isRefreshNeeded = !!resourceRefresher;
        onSuccess(!isRefreshNeeded);
      })
      .catch((err) => {
        logger.error(
          `SelfService action ${metricOperation} with error stack: ${err.stack}`
        );
        metrics.emitMetricOperation(timedMetric, err);
        setError(I18nError.getI18nError(err));
        reset();
      });
  };

  const rebootWorkSpace = () => {
    actionHandler(
      async () => await selfServiceClient.rebootWorkSpace(),
      Operation.Reboot
    );
  };

  const rebuildWorkSpace = () => {
    actionHandler(
      async () => await selfServiceClient.rebuildWorkSpace(),
      Operation.Rebuild
    );
  };

  const downgradeComputeType = (newComputeType: string) => {
    actionHandler(
      async () => await selfServiceClient.downgradeComputeType(newComputeType),
      Operation.ChangeCompute
    );
  };

  const upgradeComputeType = (newComputeType: string) => {
    actionHandler(
      async () => await selfServiceClient.upgradeComputeType(newComputeType),
      Operation.ChangeCompute
    );
  };

  const increaseVolumeSize = (
    driveToIncrease: string,
    newVolumeSizeInGib: string
  ) => {
    actionHandler(
      async () =>
        await selfServiceClient.increaseVolumeSize(
          driveToIncrease,
          newVolumeSizeInGib
        ),
      Operation.IncreaseVolume,
      async () =>
        await selfServiceClient.refreshResource(
          authToken,
          sessionId,
          regCode,
          region
        )
    );
  };

  const modifyRunningMode = (newRunningMode: RunningModes) => {
    actionHandler(
      async () => await selfServiceClient.modifyRunningMode(newRunningMode),
      Operation.ModifyRunningMode,
      async () =>
        await selfServiceClient.refreshResourceAfterRunningModeSwitch(
          authToken,
          sessionId,
          regCode,
          region,
          newRunningMode
        )
    );
  };

  return {
    status,
    isPending,
    isCompleted,
    isProcessing,
    error,
    cleanError,
    rebootWorkSpace,
    rebuildWorkSpace,
    downgradeComputeType,
    upgradeComputeType,
    increaseVolumeSize,
    modifyRunningMode,
    reset,
  };
};
