import { Mode, applyMode } from '@cloudscape-design/global-styles';
import create from 'zustand';
import lightLogo from '@img/ws-logo.png';
import darkLogo from '@img/ws-logo-dark.png';
import { CSSProperties } from 'react';
import { CoreFactory } from '@/bridge/factory/CoreFactory';

const sessionManager = CoreFactory.getSessionManager();

interface ThemeState {
  mode: Mode;
  backgroundMask: string;
  backgroundImage: string;
  backgroundColor: string | undefined;
  fontColor: string | undefined;
  isMobile: boolean;
  logo: string;
  isCobranding: boolean;
  leftPanelStyle: CSSProperties | undefined;
}

interface ThemeAction {
  updateTheme: (mode: Mode, isCobranding?: boolean, isMobile?: boolean) => void;
}

const DESKTOP_LIGHT_THEME_BG_MASK =
  'linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(136.27deg, rgba(31, 115, 213, 0.9) -38.28%, rgba(255, 61, 0, 0.9) 103.36%)';
const DESKTOP_LIGHT_THEME: ThemeState = {
  mode: Mode.Light,
  backgroundMask: DESKTOP_LIGHT_THEME_BG_MASK,
  backgroundImage: 'url(/background.jpg)',
  backgroundColor: undefined,
  fontColor: '#ffffff',
  isMobile: false,
  logo: lightLogo,
  isCobranding: false,
  leftPanelStyle: {
    boxShadow: undefined,
    backgroundImage: DESKTOP_LIGHT_THEME_BG_MASK,
    color: '#ffffff',
  },
};

const DESKTOP_DAKR_THEME_BG_MASK =
  'linear-gradient(88.52deg, rgba(225, 103, 74, 0.9) -44.57%, rgba(15, 28, 45, 0.9) 53.89%)';
const DESKTOP_DARK_THEME: ThemeState = {
  mode: Mode.Dark,
  backgroundMask: DESKTOP_DAKR_THEME_BG_MASK,
  backgroundImage: 'url(/background.jpg)',
  backgroundColor: undefined,
  fontColor: '#ffffff',
  isMobile: false,
  logo: lightLogo,
  isCobranding: false,
  leftPanelStyle: {
    boxShadow: undefined,
    backgroundImage: DESKTOP_DAKR_THEME_BG_MASK,
    color: '#ffffff',
  },
};

const DESKTOP_COBRANDING_THEME_BG_MASK =
  'linear-gradient(341.26deg, rgba(245, 245, 245, 0) 81.91%, rgba(183, 201, 226, 1) 111.23%), linear-gradient(163.34deg, rgba(255, 255, 255, 0) 59.48%, rgba(226, 71, 26, 0.23) 112.82%)';
const DESKTOP_COBRANDING_THEME = {
  backgroundMask: DESKTOP_COBRANDING_THEME_BG_MASK,
  addBackgroundImage: false,
  backgroundImage: '',
  backgroundColor: '#ffffff',
  fontColor: '#212121',
  isMobile: false,
  logo: darkLogo,
  spinnerDefaultLogo: darkLogo,
  isCobranding: true,
  leftPanelStyle: {
    boxShadow: undefined,
    backgroundImage: DESKTOP_COBRANDING_THEME_BG_MASK,
    color: '#212121',
  },
};

const DESKTOP_COBRANDING_LIGHT_THEME: ThemeState = {
  ...DESKTOP_COBRANDING_THEME,
  mode: Mode.Light,
  leftPanelStyle: {
    ...DESKTOP_COBRANDING_THEME.leftPanelStyle,
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.1)',
  },
};

const DESKTOP_COBRANDING_DARK_THEME: ThemeState = {
  ...DESKTOP_COBRANDING_THEME,
  mode: Mode.Dark,
};

const MOBILE_LIGHT_THEME_BG_MASK =
  'linear-gradient(159.41deg, rgba(255, 255, 255, 0.15) 45.12%, rgba(31, 115, 213, 0.15) 74.59%, rgba(255, 61, 0, 0.15) 96.27%), linear-gradient(197.81deg, rgba(255, 255, 255, 0.2) 45.72%, rgba(255, 173, 151, 0.2) 67.45%)';
const MOBILE_LIGHT_THEME: ThemeState = {
  mode: Mode.Light,
  backgroundMask: MOBILE_LIGHT_THEME_BG_MASK,
  backgroundImage: MOBILE_LIGHT_THEME_BG_MASK,
  backgroundColor: '#ffffff',
  fontColor: undefined,
  isMobile: true,
  logo: darkLogo,
  isCobranding: false,
  leftPanelStyle: {},
};

const MOBILE_DARK_THEME_BG_MASK =
  'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(136.27deg, rgba(31, 115, 213, 0.9) -38.28%, rgba(255, 61, 0, 0.9) 103.36%)';
const MOBILE_DARK_THEME: ThemeState = {
  mode: Mode.Dark,
  backgroundMask: MOBILE_DARK_THEME_BG_MASK,
  backgroundImage: `${MOBILE_DARK_THEME_BG_MASK}, url(/background.jpg)`,
  backgroundColor: undefined,
  fontColor: '#ffffff',
  isMobile: true,
  logo: lightLogo,
  isCobranding: false,
  leftPanelStyle: {},
};

const MOBILE_COBRANDING_THEME: ThemeState = {
  backgroundMask:
    'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
  backgroundColor: '#ffffff',
  backgroundImage: '',
  fontColor: '#212121',
  mode: Mode.Light,
  isMobile: true,
  logo: darkLogo,
  isCobranding: true,
  leftPanelStyle: {
    boxShadow: undefined,
  },
};

export const useTheme = create<ThemeState & ThemeAction>((set) => ({
  ...DESKTOP_LIGHT_THEME,
  updateTheme: (mode: Mode, isCobranding?: boolean, isMobile?: boolean) => {
    let theme: ThemeState;
    if (isCobranding) {
      if (isMobile) {
        theme = {
          ...MOBILE_COBRANDING_THEME,
          logo: sessionManager.get('logo'),
        };
      } else {
        theme = {
          ...(mode === Mode.Dark
            ? DESKTOP_COBRANDING_DARK_THEME
            : DESKTOP_COBRANDING_LIGHT_THEME),
          logo: sessionManager.get('logo'),
        };
      }
    } else {
      if (isMobile) {
        theme = mode === Mode.Dark ? MOBILE_DARK_THEME : MOBILE_LIGHT_THEME;
      } else {
        theme = mode === Mode.Dark ? DESKTOP_DARK_THEME : DESKTOP_LIGHT_THEME;
      }
    }

    applyMode(theme.mode);
    set(theme);
  },
}));
