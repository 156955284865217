import { useEffect } from 'react';
import { useSessionStore } from '@/stores/session';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import ConnectStatus from '@/components/ConnectStatus';
import { useLogUploaderPreferenceStore } from '@stores/loguploader';
import { buildMaxibonInSessionMetricsConfigInput } from '@views/Stream/utils';

interface StreamingProps {}

const logger = CoreFactory.getLogger();
const device = CoreFactory.getDevice();

const Maxibon: React.FC<StreamingProps> = () => {
  const sessionContext = useSessionStore(
    (state) => state.resourceSessionContext
  );
  const resourceId = useSessionStore((state) => state.resourceId);
  const resourceName = useSessionStore((state) => state.resourceName);
  const resourceComputeType = useSessionStore(
    (state) => state.resourceComputeType
  );
  const resourceIp = useSessionStore((state) => state.resourceIp);
  const regCode = useSessionStore((state) => state.registrationCode);
  const orgName = useSessionStore((state) => state.domainProperties)
    ?.OrganizationName as string;
  const region = useSessionStore((state) => state.region);
  const isLogUploaderEnabledAtDirectoryLevel = useSessionStore(
    (state) => state.isLogUploaderEnabledAtDirectoryLevel
  );

  const isLogUploaderEnabledByUser = useLogUploaderPreferenceStore(
    (state) => state.isLoggingEnabledByUser
  );
  const logUploaderLogLevel = useLogUploaderPreferenceStore(
    (state) => state.logLevelPreference
  );

  const sessionToken = useSessionStore(
    (state) => state.sessionContext?.AuthToken?.Value
  );
  const heartbeatSessionId = useSessionStore(
    (state) => state.heartBeatSessionId
  );
  const heartbeatEndpoint = useSessionStore((state) => state.heartBeatEndpoint);

  const brokerSessionId = useSessionStore(
    (state) => state.sessionContext?.SessionId
  );
  const resourceType = useSessionStore((state) => state.resourceType);

  useEffect(() => {
    const wspContext = {
      broker_session_id: brokerSessionId,
      session_id: sessionContext.SessionId,
      auth_token: sessionContext.AuthToken,
      server_url: sessionContext.ServerUrl,
      extended_server_endpoints: sessionContext.ExtendedServerUrls,
      extension_data: sessionContext.ExtensionData,
      device_id: device.getDeviceUUID(),
      is_log_uploader_enabled:
        isLogUploaderEnabledByUser && isLogUploaderEnabledAtDirectoryLevel,
      log_uploader_log_level: logUploaderLogLevel,
      log_uploader_region: region?.endpoint as string,
      org_name: orgName,
      session_token: sessionToken,
      eucsso_report_heartbeat_endpoint: heartbeatEndpoint,
      eucsso_report_heartbeat_session_id: heartbeatSessionId,
      metrics_config_input: buildMaxibonInSessionMetricsConfigInput(),
      resource_type: resourceType,
    };

    logger.info('Starting WSP session streaming');
    window.sessionStorage.setItem(
      'wsp_context',
      btoa(JSON.stringify(wspContext))
    );
    const redirectPort = window.location.port ? `:${window.location.port}` : '';
    setInSessionUtilityParams(
      redirectPort,
      resourceId,
      resourceName,
      resourceComputeType,
      resourceIp,
      regCode as string
    );

    window.location.assign(
      `https://${window.location.hostname}${redirectPort}/wsp.html`
    );
  }, [sessionContext]);

  return <ConnectStatus />;
};

/*
 This function sets the workspace details in storage. This will be used in session for the toolbar: 'About My Workspace'
*/
const setInSessionUtilityParams = (
  port: string,
  id: string,
  name: string,
  type: string,
  ip: string,
  regCode: string
) => {
  window.sessionStorage.setItem(
    'return_url',
    `https://${window.location.hostname}${port}/disconnect`
  );
  window.localStorage.setItem('workspaceId', id);
  window.localStorage.setItem('workspaceName', name);
  window.localStorage.setItem('workspaceComputeType', type);
  window.localStorage.setItem('workspaceIp', ip);
  window.localStorage.setItem('registrationCode', regCode);
};

export default Maxibon;
