import { ICobranding } from '@/bridge/ICobranding';
import { Cobranding } from './Cobranding';
import { IBranding, IDeviceType } from './types';

export class MacCobranding extends Cobranding implements ICobranding {
  getDeviceType(branding?: IBranding): IDeviceType | undefined {
    const { DeviceTypeOsx: deviceTypeOsx } = branding ?? {};

    return deviceTypeOsx;
  }
}
