import { ILogger } from '@/bridge/ILogger';
import { IHeartBeat } from '@/bridge/IHeartBeat';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { ISessionManager } from '@/bridge/ISessionManager';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { ReportHearbeatResponse } from '@/bridge/types/HeartbeatTypes';
import {
  IReportHeartbeatTask,
  IReportHeartbeatTaskProps,
  OnErrorFn,
} from '@/bridge/IReportHeartbeatTask';
import { generateUUID } from '@/bridge/utility';

interface ReportHeartbeatTaskDeps {
  logger: ILogger;
  heartbeat: IHeartBeat;
  sessionManager: ISessionManager;
}

const REPORT_HEARTBEAT_MAX_RETRY = 5;
const REPORT_HEARTBEAT_RETRY_DELAY = 1000; // 1 second
const TOKEN_VALID_TIME = 20 * 60 * 1000; // 20 minutes

export class ReportHeartbeatTask implements IReportHeartbeatTask {
  private readonly logger: ILogger;
  private readonly heartbeat: IHeartBeat;
  private readonly sessionManager: ISessionManager;

  private onError?: OnErrorFn;

  private taskId: string | null;

  constructor({ logger, heartbeat, sessionManager }: ReportHeartbeatTaskDeps) {
    this.logger = logger;
    this.heartbeat = heartbeat;
    this.sessionManager = sessionManager;

    this.taskId = null;
  }

  start({ onError }: IReportHeartbeatTaskProps) {
    this.taskId = generateUUID();
    this.onError = onError;
    this.reportHeartbeatTask(this.taskId);
  }

  stop() {
    this.taskId = null;
    this.onError = undefined;
  }

  private async reportHeartbeatTask(taskId: string) {
    const sessionContext = this.sessionManager.get('sessionContext')!;

    try {
      const {
        sessionToken,
        heartBeatContext: { intervalInSeconds },
      } = await this.reportHeartbeatWithRetry(taskId);
      const newAuthToken = {
        Value: sessionToken,
        ExpirationTimestamp: Date.now() + TOKEN_VALID_TIME,
      };
      this.sessionManager.set({
        authToken: newAuthToken,
        sessionContext: {
          ...sessionContext,
          AuthToken: newAuthToken,
        },
      });
      if (this.shouldContinue(taskId)) {
        setTimeout(() => {
          this.reportHeartbeatTask(taskId);
        }, intervalInSeconds * 1000);
      }
    } catch (error) {
      this.onError?.(error);
      this.stop();
    }
  }

  private async reportHeartbeatWithRetry(taskId: string, retries: number = 0) {
    const heartBeatEndpoint = this.sessionManager.get('heartBeatEndpoint');

    if (!heartBeatEndpoint) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_HEARTBEAT,
        WsErrorCodes.ERROR_MISSING_HEARTBEAT_ENDPOINT,
        ClientErrorCode.ReportHeartbeatMissingEndpoint
      );
    }

    try {
      return await this.heartbeat.reportHeartBeat(heartBeatEndpoint);
    } catch (error: unknown) {
      if (
        error instanceof WsError &&
        error.clientErrorCode ===
          ClientErrorCode.ReportHeartbeatRetriableError &&
        this.shouldContinue(taskId) &&
        retries < REPORT_HEARTBEAT_MAX_RETRY
      ) {
        this.logger.info(
          `Retriable error happened for ReportHeartbeat. Retry count: ${retries}`
        );
        const retryResult = await new Promise((resolve, reject) => {
          setTimeout(() => {
            this.reportHeartbeatWithRetry(taskId, retries + 1)
              .then(resolve)
              .catch(reject);
          }, REPORT_HEARTBEAT_RETRY_DELAY * 2 ** retries);
        });
        return retryResult as ReportHearbeatResponse;
      }

      throw error;
    }
  }

  private shouldContinue(taskId: string) {
    return this.taskId === taskId;
  }
}
