import { useCustomTranslation } from '@/i18n';
import { useMemo } from 'react';
import * as awsui from '@cloudscape-design/design-tokens';

interface CopyrightProps {
  color?: string;
}

const Copyright: React.FC<CopyrightProps> = ({ color }) => {
  const { t } = useCustomTranslation();
  const fontColor = color ?? awsui.colorTextBodySecondary;

  const currentYear = useMemo(() => {
    const year = new Date().getFullYear();

    if (year > 2006) {
      return ` - ${year}`;
    }

    return '';
  }, []);
  const copyRightYear = `2006`;
  const copyRightName = `Amazon Web Services, Inc.`;
  const copyRightText = `${copyRightYear} + ${currentYear}, ${copyRightName} ${t(
    'ws-reserved'
  )}`;

  return (
    <p
      data-testid="copyright"
      style={{
        textAlign: 'center',
        margin: 0,
        color: fontColor,
        padding: '0 16px',
      }}
      aria-live="assertive"
      tabIndex={0}
      role="navigation"
      aria-label={copyRightText}
    >
      © {copyRightYear} {currentYear}, {copyRightName} {t('ws-reserved')}
    </p>
  );
};

export default Copyright;
