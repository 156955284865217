import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import { Action } from '@bridge/types/SoloRTCChannelTypes';
import { PlatformDetailsHandler } from '@core/rtcChannel/handlers/PlatformDetailsHandler';
import { ISessionManager } from '@bridge/ISessionManager';
import { StreamContextHandler } from '@core/rtcChannel/handlers/StreamContextHandler';
import { StreamResponseHandler } from '@core/rtcChannel/handlers/StreamResponseHandler';
import { DisconnectRequestHandler } from '@core/rtcChannel/handlers/DisconnectRequestHandler';
import { AuthContextHandler } from '@core/rtcChannel/handlers/AuthContextHandler';
import { MetricPublishRequestHandler } from '@core/rtcChannel/handlers/MetricPublishRequestHandler';
import { SettingsUpdateHandler } from '@core/rtcChannel/handlers/SettingsUpdateHandler';
import { HealthCheckResponseHandler } from '@core/rtcChannel/handlers/HealthCheckResponseHandler';
import { DNSLookUpResponseHandler } from '@core/rtcChannel/handlers/DNSLookUpResponseHandler';
import { MigrateRegistrationCodesHandler } from '@core/rtcChannel/handlers/MigrateRegistrationCodesHandler';
import { LogUploadPublishHandler } from '@core/rtcChannel/handlers/LogUploadPublishHandler';
import { LogUploadTriggerHandler } from '@core/rtcChannel/handlers/LogUploadTriggerHandler';
import { DisplayWarpDriveUrlHandler } from '@core/rtcChannel/handlers/DisplayWarpDriveUrlHandler';
import { BrokerAuthenticationResultHandler } from '@core/rtcChannel/handlers/BrokerAuthenticationResultHandler';

export class ActionHandlerFactory {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly sessionManager: ISessionManager;

  constructor(
    logger: ILogger,
    metrics: IMetrics,
    sessionManager: ISessionManager
  ) {
    this.logger = logger;
    this.metrics = metrics;
    this.sessionManager = sessionManager;
  }

  getHandler(action: Action): IActionHandler | undefined {
    switch (action) {
      case Action.GET_PLATFORM_DETAILS: {
        return new PlatformDetailsHandler(this.logger, this.metrics);
      }
      case Action.STREAM_CONTEXT: {
        return new StreamContextHandler(
          this.logger,
          this.metrics,
          this.sessionManager
        );
      }
      case Action.STREAM: {
        return new StreamResponseHandler(
          this.logger,
          this.metrics,
          this.sessionManager
        );
      }
      case Action.DISCONNECT: {
        return new DisconnectRequestHandler(this.logger);
      }
      case Action.AUTH_CONTEXT: {
        return new AuthContextHandler(
          this.logger,
          this.metrics,
          this.sessionManager
        );
      }
      case Action.PUBLISH_METRIC: {
        return new MetricPublishRequestHandler(this.logger, this.metrics);
      }
      case Action.SETTINGS_UPDATE: {
        return new SettingsUpdateHandler(this.logger, this.metrics);
      }
      case Action.HEALTH_CHECK: {
        return new HealthCheckResponseHandler(this.logger, this.metrics);
      }
      case Action.RESOLVE_DNS_RECORD: {
        return new DNSLookUpResponseHandler(this.logger);
      }
      case Action.MIGRATE_REGISTRATION_CODES: {
        return new MigrateRegistrationCodesHandler(this.logger, this.metrics);
      }
      case Action.TRIGGER_LOG_UPLOAD: {
        return new LogUploadTriggerHandler(this.logger);
      }
      case Action.LOG_UPLOAD_PUBLISH: {
        return new LogUploadPublishHandler(this.logger);
      }
      case Action.DISPLAY_WARPDRIVE_URL: {
        return new DisplayWarpDriveUrlHandler(this.logger, this.metrics);
      }
      case Action.BROKER_AUTHENTICATION_RESULT: {
        return new BrokerAuthenticationResultHandler(this.logger, this.metrics);
      }
      default: {
        return undefined;
      }
    }
  }
}
