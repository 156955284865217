import {
  useState,
  useMemo,
  useEffect,
  useRef,
  RefObject,
  useCallback,
} from 'react';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { StreamFactory } from '@/bridge/factory/StreamFactory';
import { SessionProtocols } from '@/bridge/types/SessionTypes';
import { useSessionStore } from '@/stores/session';
import { getAvailableWorkArea, isFullScreen } from '@views/Stream/utils';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { WsError } from '@/bridge/WsError';

export enum States {
  connecting,
  pending,
  connected,
  disconnecting,
  disconnected,
  failed,
}

interface UsePcoipStreamingResult {
  status: States;
  setStatus: (status: States) => void;
  disconnect: () => void;
  sendCtrlAltDel: () => void;
  changeResolution: () => void;
  layCanvasOntop: () => void;
  videoContainerWidth: number;
  videoContainerHeight: number;
  videoWidth: number;
  videoHeight: number;
  videoLeft: string;
  videoTop: string;
  videoTransform: string;
  videoZIndex: number;
  canvasZIndex: number;
  showCanvas: boolean;
}

const logger = CoreFactory.getLogger();

export const usePcoipStreaming = (
  inputElement: RefObject<HTMLDivElement>,
  videoElement: RefObject<HTMLVideoElement>,
  canvasElement: RefObject<HTMLCanvasElement>
): UsePcoipStreamingResult => {
  const streamer = useMemo(
    () => StreamFactory.getSessionStreamer(SessionProtocols.PCOIP),
    []
  );
  const { showError } = useErrorHandler();
  const isStreaming = useRef(false);
  const canvasRenderInterval = useRef<NodeJS.Timer>();
  const [status, setStatus] = useState<States>(States.connecting);
  const [videoContainerWidth, setVideoContainerWidth] = useState(0);
  const [videoContainerHeight, setVideoContainerHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoZIndex, setVideoZIndex] = useState(0);
  const [canvasZIndex, setCanvasZIndex] = useState(1);
  const [showCanvas, setShowCanvas] = useState(false);

  // we don't fit so remove centering styles
  const containerTooSmall = useMemo(
    () =>
      videoContainerWidth < videoWidth || videoContainerHeight < videoHeight,
    [videoContainerWidth, videoContainerHeight, videoWidth, videoHeight]
  );
  const videoLeft = useMemo(
    () => (containerTooSmall ? '0%' : '50%'),
    [containerTooSmall]
  );
  const videoTop = useMemo(
    () => (containerTooSmall ? '0%' : '50%'),
    [containerTooSmall]
  );
  const videoTransform = useMemo(
    () => (containerTooSmall ? 'translate(0%,0%)' : 'translate(-50%, -50%)'),
    [containerTooSmall]
  );

  const sessionContext = useSessionStore(
    (state) => state.resourceSessionContext
  );

  const canvasRender = useCallback(() => {
    if (videoElement.current == null || canvasElement.current == null) return;

    if (
      window.devicePixelRatio > 1 &&
      !videoElement.current.paused &&
      !videoElement.current.ended &&
      videoElement.current.readyState === videoElement.current.HAVE_ENOUGH_DATA
    ) {
      // HighDPI screen - rendering on CANVAS + video
      setShowCanvas(true);
      const context = canvasElement.current.getContext('2d');
      context?.drawImage(
        videoElement.current,
        0,
        0,
        videoElement.current.videoWidth,
        videoElement.current.videoHeight
      );
    } else {
      // Rendering on video only
      setShowCanvas(false);
    }
  }, [videoElement, canvasElement]);

  const onConnected = useCallback(
    (rWidth: number, rHeight: number) => {
      logger.info(
        `Successfully connected, resolution: width ${rWidth}, height ${rHeight}`
      );
      setStatus((prevState) =>
        prevState === States.pending ? States.pending : States.connected
      );
      const fullScreen = isFullScreen();
      const { width: wWidth, height: wHeight } = getAvailableWorkArea();
      setVideoContainerWidth(wWidth);
      setVideoContainerHeight(wHeight);
      setVideoWidth(fullScreen ? wWidth : rWidth);
      setVideoHeight(fullScreen ? wHeight : rHeight);
      if (canvasRenderInterval.current != null) {
        clearInterval(canvasRenderInterval.current);
      }
      canvasRenderInterval.current = setInterval(canvasRender, 50);
    },
    [canvasRender]
  );
  const onFailed = useCallback(
    (error: WsError) => {
      logger.info(
        'StreamingService disconnected with error: ' + JSON.stringify(error)
      );
      setStatus(States.failed);
      if (canvasRenderInterval.current) {
        clearInterval(canvasRenderInterval.current);
      }
      showError(error);
      // logger.publishLog();
    },
    [showError]
  );
  const onPending = useCallback(() => setStatus(States.pending), []);
  const disconnect = useCallback(() => {
    setStatus(States.disconnected);
    streamer?.disconnect(true);
  }, []);
  const changeResolution = useCallback(() => streamer?.changeResolution(), []);
  const sendCtrlAltDel = useCallback(() => streamer?.sendCtrlAltDel(), []);
  const layCanvasOntop = useCallback(() => {
    setCanvasZIndex(2);
    setVideoZIndex((prevVideoZIndex) => (prevVideoZIndex === 0 ? 1 : 0));
  }, []);

  useEffect(() => {
    if (
      inputElement.current != null &&
      videoElement.current != null &&
      !isStreaming.current
    ) {
      isStreaming.current = true;

      streamer?.streamWorkspace({
        sessionContext,
        inputElement: inputElement.current,
        videoElement: videoElement.current,
        onConnected,
        onFailed,
        onPending,
      });
    }
  }, [
    sessionContext,
    inputElement,
    videoElement,
    onConnected,
    onFailed,
    onPending,
  ]);

  return {
    status,
    setStatus,
    disconnect,
    sendCtrlAltDel,
    changeResolution,
    layCanvasOntop,
    videoContainerWidth,
    videoContainerHeight,
    videoWidth,
    videoHeight,
    videoLeft,
    videoTop,
    videoTransform,
    videoZIndex,
    canvasZIndex,
    showCanvas,
  };
};
