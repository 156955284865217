import { ISelfServiceClient } from '@/bridge/ISelfServiceClient';
import { ISessionManager } from '@/bridge/ISessionManager';
import {
  BaseActionRequest,
  ChangeComputeTypeRequest,
  DriveToIncrease,
  IncreaseVolumeSizeRequest,
  IPerformResourceActionRequest,
  ModifyRunningModeRequest,
  RebootWorkSpaceRequest,
  RebuildWorkSpaceRequest,
  RunningModes,
  SelfServiceActionTypes,
} from '@/bridge/types/SelfService';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { IRegion } from '@bridge/types/RegionTypes';
import { SessionProvision } from '@/presession/sessionprovision/SessionProvision';
import { SelfServiceConstants } from '@/insession/SelfService/Constants';
import { IResource } from '@core/wsbroker/types';
import { ILogger } from '@bridge/ILogger';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

type IActionToErrorMap = {
  [key in SelfServiceActionTypes]: WsErrorCodes;
};
enum ChangeComputeTypeMode {
  DOWNGRADE,
  UPGRADE,
}
const VERSION = '1.0';
const RESOURCE_TYPE = 'WORKSPACE';
const ACTION_TO_ERROR_MAP: IActionToErrorMap = {
  [SelfServiceActionTypes.REBOOT]: WsErrorCodes.ERROR_SELF_SERVICE_RESTART,
  [SelfServiceActionTypes.REBUILD]: WsErrorCodes.ERROR_SELF_SERVICE_REBUILD,
  [SelfServiceActionTypes.CHANGE_COMPUTE_TYPE]:
    WsErrorCodes.ERROR_SELF_SERVICE_CHANGE_COMPUTE_TYPE,
  [SelfServiceActionTypes.INCREASE_VOLUME_SIZE]:
    WsErrorCodes.ERROR_SELF_SERVICE_INCREASE_VOLUME_SIZE,
  [SelfServiceActionTypes.MODIFY_RUNNING_MODE_PROPERTIES]:
    WsErrorCodes.ERROR_SELF_SERVICE_SWITCH_RUNNING_MODE,
};

export class SelfServiceClient implements ISelfServiceClient {
  private readonly wsBroker: WSBrokerService;
  private readonly sessionManager: ISessionManager;
  private readonly sessionProvision: SessionProvision;
  private readonly logger: ILogger;

  constructor(
    wsBroker: WSBrokerService,
    sessionManager: ISessionManager,
    sessionProvision: SessionProvision,
    logger: ILogger
  ) {
    this.wsBroker = wsBroker;
    this.sessionManager = sessionManager;
    this.sessionProvision = sessionProvision;
    this.logger = logger;
  }

  async rebootWorkSpace() {
    return await this.performResourceAction(SelfServiceActionTypes.REBOOT, () =>
      this.buildRebootRequestBody()
    );
  }

  async rebuildWorkSpace() {
    return await this.performResourceAction(
      SelfServiceActionTypes.REBUILD,
      () => this.buildRebuildRequestBody()
    );
  }

  async modifyRunningMode(newRunningMode: RunningModes) {
    return await this.performResourceAction(
      SelfServiceActionTypes.MODIFY_RUNNING_MODE_PROPERTIES,
      () => this.buildModifyRunningModeRequestBody(newRunningMode)
    );
  }

  async upgradeComputeType(newComputeType: string) {
    return await this.performResourceAction(
      SelfServiceActionTypes.CHANGE_COMPUTE_TYPE,
      () =>
        this.buildChangeComputeTypeRequestBody(
          ChangeComputeTypeMode.UPGRADE,
          newComputeType
        )
    );
  }

  async downgradeComputeType(newComputeType: string) {
    return await this.performResourceAction(
      SelfServiceActionTypes.CHANGE_COMPUTE_TYPE,
      () =>
        this.buildChangeComputeTypeRequestBody(
          ChangeComputeTypeMode.DOWNGRADE,
          newComputeType
        )
    );
  }

  async increaseVolumeSize(
    driveToIncrease: string,
    newVolumeSizeInGib: string
  ) {
    return await this.performResourceAction(
      SelfServiceActionTypes.INCREASE_VOLUME_SIZE,
      () =>
        this.buildIncreaseVolumeSizeRequestBody(
          driveToIncrease,
          newVolumeSizeInGib
        )
    );
  }

  async refreshResource(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion
  ) {
    return await this.sessionProvision.getResources({
      authToken,
      sessionId,
      regCode,
      region,
    });
  }

  async refreshResourceAfterRunningModeSwitch(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion,
    newRunningMode: RunningModes
  ) {
    for (
      let runIndex = 0;
      runIndex < SelfServiceConstants.SwitchRunningMode.PollLimit;
      runIndex++
    ) {
      this.logger.info(
        `Initiating refresh resource attempt ${SelfServiceConstants.SwitchRunningMode.PollLimit} with a delay of ${SelfServiceConstants.SwitchRunningMode.PollInterval}`
      );
      const updatedResource = await this.refreshResourceWithDelay(
        authToken,
        sessionId,
        regCode,
        region,
        SelfServiceConstants.SwitchRunningMode.PollInterval
      );
      this.logger.info(
        `Completed refresh resource attempt ${SelfServiceConstants.SwitchRunningMode.PollLimit} with running mode as ${updatedResource?.ResourceDetails?.RunningMode}`
      );
      if (updatedResource?.ResourceDetails?.RunningMode === newRunningMode) {
        return updatedResource;
      }
    }
    this.logger.error(
      `Completed all retries for refreshing resource after switching running mode`
    );
    throw new WsError(
      WsErrorTypes.ERROR_TYPE_SELF_SERVICE_ACTIONS,
      WsErrorCodes.ERROR_SELF_SERVICE_SWITCH_RUNNING_MODE,
      ClientErrorCode.SwitchRunningModeRefreshResourceTimeOutFailure
    );
  }

  private async refreshResourceWithDelay(
    authToken: string,
    sessionId: string,
    regCode: string,
    region: IRegion,
    delayInMs: number
  ): Promise<IResource> {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        this.refreshResource(authToken, sessionId, regCode, region)
          .then((response) => {
            resolve(response);
          })
          .catch((error: any) => {
            reject(error);
          });
      }, delayInMs);
    });
  }

  private async performResourceAction(
    actionType: SelfServiceActionTypes,
    bodyBuilder: () => IPerformResourceActionRequest
  ) {
    this.validate();

    return await this.wsBroker
      .performResourceAction(
        this.sessionManager.get('registrationCode') as string,
        this.sessionManager.get('region') as IRegion,
        bodyBuilder()
      )
      .then(({ ActionResult: { ResultType: status } }) => ({ status }))
      .catch((error) => {
        if (error instanceof WsError) {
          throw error;
        } else {
          throw new WsError(
            WsErrorTypes.ERROR_TYPE_SELF_SERVICE_ACTIONS,
            ACTION_TO_ERROR_MAP[actionType]
          );
        }
      });
  }

  private validate() {
    const sessionId = this.sessionManager.get('sessionContext')?.SessionId;
    const authToken = this.sessionManager.get('authToken');
    const resourceId = this.sessionManager.get('resourceId');
    if (sessionId && authToken && resourceId) return;

    throw new WsError(
      WsErrorTypes.ERROR_TYPE_SELF_SERVICE_ACTIONS,
      WsErrorCodes.ERROR_CM_SESSION_TIMEOUT
    );
  }

  private buildCommonRequestBody(): BaseActionRequest {
    const sessionId = this.sessionManager.get('sessionContext')
      ?.SessionId as string;
    const authToken = this.sessionManager.get('authToken');
    const resourceId = this.sessionManager.get('resourceId');

    return {
      Version: VERSION,
      SessionContext: {
        SessionId: sessionId,
      },
      AuthToken: {
        Value: authToken?.Value as string,
      },
      Resource: {
        ResourceType: RESOURCE_TYPE,
        ResourceId: resourceId,
      },
    };
  }

  private buildRebootRequestBody(): RebootWorkSpaceRequest {
    return {
      ...this.buildCommonRequestBody(),
      Action: {
        ActionType: SelfServiceActionTypes.REBOOT,
      },
    };
  }

  private buildRebuildRequestBody(): RebuildWorkSpaceRequest {
    return {
      ...this.buildCommonRequestBody(),
      Action: {
        ActionType: SelfServiceActionTypes.REBUILD,
      },
    };
  }

  private buildModifyRunningModeRequestBody(
    newRunningMode: RunningModes
  ): ModifyRunningModeRequest {
    return {
      ...this.buildCommonRequestBody(),
      Action: {
        ActionType: SelfServiceActionTypes.MODIFY_RUNNING_MODE_PROPERTIES,
        ActionUserInput: {
          NewRunningMode: newRunningMode,
        },
      },
    };
  }

  private buildChangeComputeTypeRequestBody(
    changeType: ChangeComputeTypeMode,
    newComputeType: string
  ): ChangeComputeTypeRequest {
    const useInput =
      changeType === ChangeComputeTypeMode.DOWNGRADE
        ? { NewComputeDowngradeType: newComputeType }
        : { NewComputeUpgradeType: newComputeType };

    return {
      ...this.buildCommonRequestBody(),
      Action: {
        ActionType: SelfServiceActionTypes.CHANGE_COMPUTE_TYPE,
        ActionUserInput: {
          ...useInput,
        },
      },
    };
  }

  private buildIncreaseVolumeSizeRequestBody(
    driveToIncrease: string,
    newVolumeSizeInGib: string
  ): IncreaseVolumeSizeRequest {
    return {
      ...this.buildCommonRequestBody(),
      Action: {
        ActionType: SelfServiceActionTypes.INCREASE_VOLUME_SIZE,
        ActionUserInput: {
          [driveToIncrease === DriveToIncrease.C
            ? 'NewRootVolume'
            : 'NewUserVolume']: newVolumeSizeInGib,
        },
      },
    };
  }
}
