import { BrowserPlatformDevice } from '@core/device/BrowserPlatformDevice';
import { IDevice } from '@bridge/IDevice';
import { ICoreFactory } from './ICoreFactory';
import { BaseCoreFactory } from './BaseCoreFactory';
import { WebCobranding } from '@/core/cobranding/WebCobranding';
import { HttpClientFactory } from '../HttpClientFactory';
import { ICobranding } from '@/bridge/ICobranding';
import { WebLogger } from '@core/logger/WebLogger';
import { ILogger } from '@bridge/ILogger';

export class WebCoreFactory extends BaseCoreFactory implements ICoreFactory {
  getDevice(): IDevice {
    if (!this._device) {
      this._device = new BrowserPlatformDevice(this.getPersistentStorage());
    }
    return this._device;
  }

  getLogger(): ILogger {
    if (!this._logger) {
      const device = this.getDevice();
      this._logger = new WebLogger(device);
    }
    return this._logger;
  }

  getRTCChannel() {
    return null;
  }

  getCobranding(): ICobranding {
    if (!this._cobranding) {
      this._cobranding = new WebCobranding(
        HttpClientFactory.getCobrandingHttpClient(),
        this.getDevice(),
        this.getLogger(),
        this.getMetrics(),
        this.getSessionManager()
      );
    }
    return this._cobranding;
  }
}
