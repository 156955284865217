import create from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { BrowserPersistentStorage } from '@/core/storage/BrowserPersistentStorage';
import { IRegion, RegPrefix } from '@/bridge/types/RegionTypes';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { MigrateRegistrationCodesResponse } from '@/bridge/types/SoloRTCChannelTypes';
import { RegionUtils } from '@/bridge/utility/RegionUtils';
import { Cookies } from 'react-cookie';

const persistStore = CoreFactory.getPersistentStorage();
const currentUrlParentDomain = location?.hostname
  ?.split('.')
  .reverse()
  .splice(0, 2)
  .reverse()
  .join('.');
const manageLoginInfoCookie = new Cookies();

export interface IRegCode {
  name: string;
  alias: string;
  region: IRegion;
  isRememberMe: boolean;
  isRememberMeEnabledAtDirectorylevel: boolean;
}

export interface RegistrationState {
  registrationCodes: IRegCode[] | undefined;
  regCodeLastUsed: string;
  isSaveEnabled: boolean;
  areRegistrationCodesMigrated: boolean;
}

export interface LegacyStorageRegistrationAction {
  getRegistrationStateFromLocalStorage: () => RegistrationState | undefined;
  deleteRegistrationStateFromLocalStorage: () => void;
}

export interface RegistrationAction {
  addNewRegCode: (newRegCode: string, region: IRegion) => void;
  pushRegCodeToLast: (regCode: string) => void;
  createOrUpdateRegCode: (regCode: string, region: IRegion) => void;
  updateWSAliasName: (regCode: string, wsAliasName: string) => void;
  toggleSavedRegCodesOption: (isEnabled: boolean) => void;
  toggleRememberMe: (regCode: string | undefined, toggle: boolean) => void;
  toggleRememberMeAtDirectoryLevel: (
    regCode: string | undefined,
    toggle: boolean
  ) => void;
  getRegCodeForAlias: (alias: string) => string | undefined;
  getRegCodeDetails: (regCode: string) => IRegCode | undefined;
  getAllRegCodes: () => IRegCode[] | undefined;
  getAllRegCodesAlias: () => string[];
  getWSName: (regCode: string) => string | undefined;
  getRememberMeSetting: (regCode: string | undefined) => boolean | undefined;
  getRememberMeSettingAtDirectoryLevel: (
    regCode: string | undefined
  ) => boolean | undefined;
  deleteRegCode: (regCode: string) => void;
  clearAllSavedRegCodes: () => void;
  setRegCodeAsDefault: (regCode: string) => void;
  saveRegistrationStateToCookies: (
    registrationState?: RegistrationState
  ) => void;
  migrateSoloRegCodesToWeb: (
    registrationCodes: MigrateRegistrationCodesResponse
  ) => boolean;
  setStatusOfRegistrationCodesMigration: () => void;
}

// Store to persist the Registration Codes entered by user in the default local storage after validation.
export const useRegCodes = create(
  persist<RegistrationState & RegistrationAction>(
    (set, get) => ({
      registrationCodes: undefined,
      regCodeLastUsed: '',
      isSaveEnabled: true,
      areRegistrationCodesMigrated: false,

      // Add the new reg code in the existing list of reg codes
      addNewRegCode: (newRegCode: string, region: IRegion) => {
        const existingRegCodes = [...(get().registrationCodes ?? [])];
        existingRegCodes.push({
          name: newRegCode,
          alias: '',
          region,
          isRememberMe: false,
          isRememberMeEnabledAtDirectorylevel: false,
        });

        set({ registrationCodes: existingRegCodes });
      },

      // Make the last used reg code the most recent in the list
      pushRegCodeToLast: (regCode: string) => {
        const existingRegCodes = [...(get().registrationCodes ?? [])];
        const codeIndex = existingRegCodes.findIndex(
          (code) => code.name === regCode
        );
        const code = existingRegCodes?.find((code) => code.name === regCode);

        if (codeIndex !== -1 && regCode !== '') {
          existingRegCodes.splice(codeIndex, 1);
          set({ registrationCodes: existingRegCodes });
        }
        if (code) {
          existingRegCodes.push(code);
        }
        set({ registrationCodes: existingRegCodes });
      },

      createOrUpdateRegCode: (regCode: string, region: IRegion) => {
        // persist registration codes & last used reg code after auth code validation successful.
        const isSaveEnabled = get().isSaveEnabled;
        const getRegCodeDetails = get().getRegCodeDetails;
        const addNewRegCode = get().addNewRegCode;
        const pushRegCodeToLast = get().pushRegCodeToLast;
        // persist only if the save registration codes option is true.
        if (isSaveEnabled) {
          if (getRegCodeDetails(regCode) === undefined) {
            addNewRegCode(regCode, region); // add new reg code to the store if it already doesn't exist
          } else {
            pushRegCodeToLast(regCode); // move reg code to last to maintain the last recently used order
          }

          persistStore.set('regCodeLastUsed', regCode);
        }
      },

      // Update the alias name eneterd by user for the respective workspace reg-code
      updateWSAliasName: (regCode: string, wsAliasName: string) => {
        const existingRegCodes = [...(get().registrationCodes ?? [])];
        const codeIndex = existingRegCodes.findIndex(
          (code) => code.name === regCode
        );

        if (codeIndex !== -1 && regCode !== '') {
          existingRegCodes[codeIndex].alias = wsAliasName;
          set({ registrationCodes: existingRegCodes });
        }
      },

      // Toggle the save registration code save option
      toggleSavedRegCodesOption: (isEnabled: boolean) => {
        set({ isSaveEnabled: isEnabled });
      },

      // Check or Uncheck the remember me option for the individual registration code
      toggleRememberMe: (regCode: string | undefined, toggle: boolean) => {
        if (regCode) {
          const existingRegCodes = [...(get().registrationCodes ?? [])];
          const codeIndex = existingRegCodes.findIndex(
            (code) => code.name === regCode
          );

          if (codeIndex !== -1 && regCode !== '') {
            existingRegCodes[codeIndex].isRememberMe = toggle;
            set({ registrationCodes: existingRegCodes });
          }
        }
      },
      // Check or Uncheck the remember me option at direcrory level from console.
      toggleRememberMeAtDirectoryLevel: (
        regCode: string | undefined,
        toggle: boolean
      ) => {
        if (regCode) {
          const existingRegCodes = [...(get().registrationCodes ?? [])];
          const codeIndex = existingRegCodes.findIndex(
            (code) => code.name === regCode
          );

          if (codeIndex !== -1 && regCode !== '') {
            existingRegCodes[codeIndex].isRememberMeEnabledAtDirectorylevel =
              toggle;
            set({ registrationCodes: existingRegCodes });
          }
        }
      },
      // Get the regCode for any given alias
      getRegCodeForAlias: (alias: string) => {
        if (alias === '') {
          return undefined;
        }
        const existingRegCodes = get().registrationCodes ?? [];

        const regCode = existingRegCodes?.find(
          (regCode) => regCode.alias === alias
        );

        return regCode?.name ?? undefined;
      },

      // Get RegCode details
      getRegCodeDetails: (regCode: string) => {
        const existingRegCodes = get().registrationCodes ?? [];
        return existingRegCodes?.find((code) => code.name === regCode);
      },

      // Get all the reg codes
      getAllRegCodes: () => {
        const existingRegCodes = get().registrationCodes ?? [];
        return existingRegCodes;
      },

      // Get all the reg codes
      getAllRegCodesAlias: () => {
        const existingRegCodes: IRegCode[] = get().registrationCodes ?? [];
        const aliases = existingRegCodes?.map((regCode) => regCode.alias) ?? [];
        return aliases;
      },

      // Gets the alias name of the workspace if exists else the regcode
      getWSName: (regCode: string) => {
        const getDetails = get().getRegCodeDetails;
        const aliasName = getDetails(regCode)?.alias;
        if (aliasName === '' || aliasName === undefined) {
          return regCode;
        }
        return aliasName;
      },

      // Gets the rememberMe setting of the registration code
      getRememberMeSetting: (regCode: string | undefined) => {
        if (regCode) {
          const details = get().getRegCodeDetails;
          return details(regCode)?.isRememberMe;
        }
        return false;
      },

      // Gets the rememberMe setting of the registration code
      getRememberMeSettingAtDirectoryLevel: (regCode: string | undefined) => {
        if (regCode) {
          const details = get().getRegCodeDetails;
          return details(regCode)?.isRememberMeEnabledAtDirectorylevel;
        }
        return false;
      },

      // Sets the status of the Registration Codes migration to true (to ensure, migration doesn't happen again)
      setStatusOfRegistrationCodesMigration: () => {
        set({ areRegistrationCodesMigrated: true });
      },

      // Delete specific workspace reg-code
      deleteRegCode: (regCode: string) => {
        const existingRegCodes = get().registrationCodes ?? [];
        const updatedRegCodes = existingRegCodes.filter(
          (code) => code.name !== regCode
        );
        set({ registrationCodes: updatedRegCodes });
      },

      // Delete all the saved reg-codes
      clearAllSavedRegCodes: () => {
        // clear all the saved reg codes
        set({ registrationCodes: [] });
      },

      // Setting the reg-code as the default or last frequently used
      setRegCodeAsDefault: (defaultRegCode: string) => {
        const existingRegCodes = [...(get().registrationCodes ?? [])];
        if (existingRegCodes) {
          const existingRegCodeIndex = existingRegCodes.findIndex(
            (code) => code.name === defaultRegCode
          );

          if (existingRegCodeIndex !== -1) {
            const existingRegCode = existingRegCodes[existingRegCodeIndex];

            // Move the existingRegCode to the last index
            existingRegCodes.splice(existingRegCodeIndex, 1);
            existingRegCodes.push(existingRegCode);

            const persistStore = new BrowserPersistentStorage();
            persistStore.set('regCodeLastUsed', defaultRegCode);

            set({ registrationCodes: existingRegCodes });
            // Update the state with the modified registrationCodes array
            set({ registrationCodes: existingRegCodes });
          }
        }
      },

      saveRegistrationStateToCookies: (
        registrationState?: RegistrationState
      ) => {
        set({
          registrationCodes: registrationState?.registrationCodes,
          regCodeLastUsed: registrationState?.regCodeLastUsed ?? '',
          isSaveEnabled: registrationState?.isSaveEnabled ?? true,
          areRegistrationCodesMigrated:
            registrationState?.areRegistrationCodesMigrated ?? false,
        });
      },

      migrateSoloRegCodesToWeb: (
        registrationCodes: MigrateRegistrationCodesResponse
      ) => {
        const existingRegCodes = [...(get().registrationCodes ?? [])];
        registrationCodes.RegistrationCodes.map((registrationCode) => {
          const regCodeRegion = RegionUtils.getRegionFromPrefix(
            registrationCode.RegionKey as RegPrefix
          );
          existingRegCodes.push({
            name: registrationCode.Name,
            alias: registrationCode.Alias,
            region: regCodeRegion as IRegion,
            isRememberMe: registrationCode.IsRememberMeAdminSetting,
            isRememberMeEnabledAtDirectorylevel:
              registrationCode.IsRememberMeLocalSetting,
          });
          return registrationCode;
        });
        set({ registrationCodes: existingRegCodes });
        return true;
      },
    }),
    {
      name: 'registrationCodes',
      storage: createJSONStorage(() => ({
        getItem: (name) => {
          // Zustand expects a string value
          return manageLoginInfoCookie.get(name, { doNotParse: true });
        },
        setItem: (name, newValue) => {
          /*
           Sets the cookie expiry to 1 year. This gets updated everytime the cookie gets updated.
           There doesnt seem to be a way to mark the cookie to be persistent forever.
          */
          manageLoginInfoCookie.set(name, newValue, {
            domain: currentUrlParentDomain,
            maxAge: 31104000,
          });
        },
        removeItem: (name) => manageLoginInfoCookie.remove(name),
      })),
    }
  )
);
