import { HttpClient, HttpClientConfig } from '@core/http/HttpClient';
import { IHttpClient } from '@bridge/IHttpClient';
import {
  IAuthInfoResponse,
  IAuthenticateResponse,
} from '@/core/wsbroker/types';
import { ICobrandingAssetResponse } from '@core/cobranding';
import { IMetricPublishResponse } from '@core/metrics/types';

export enum HttpClientTypes {
  base,
  authInfo,
  authentication,
  cobranding,
  metrics,
}

export class HttpClientFactory {
  private static readonly httpClients = new Map<
    HttpClientTypes,
    IHttpClient<any>
  >();

  static getHttpClient() {
    return this._getHttpClient<unknown>(HttpClientTypes.base);
  }

  static getAuthenticationHttpClient() {
    return this._getHttpClient<IAuthenticateResponse>(
      HttpClientTypes.authentication
    );
  }

  static getAuthInfoHttpClient() {
    return this._getHttpClient<IAuthInfoResponse>(HttpClientTypes.authInfo);
  }

  static getCobrandingHttpClient() {
    return this._getHttpClient<ICobrandingAssetResponse>(
      HttpClientTypes.cobranding
    );
  }

  static getMetricsHttpClient() {
    return this._getHttpClient<IMetricPublishResponse>(HttpClientTypes.metrics);
  }

  private static _getHttpClient<T>(
    clientType: HttpClientTypes,
    config: HttpClientConfig = {}
  ) {
    if (this.httpClients.get(clientType) == null) {
      this.httpClients.set(clientType, new HttpClient<T>(config));
    }

    return this.httpClients.get(clientType)!;
  }
}
