export class Timer {
  private startTime: number | undefined;
  private endTime: number | undefined;
  private isRunning: boolean = false;

  start() {
    this.startTime = Date.now();
    this.isRunning = true;
  }

  stop() {
    if (this.isRunning) {
      this.endTime = Date.now();
      this.isRunning = false;
    }
  }

  getTimerStartTime(): number | undefined {
    return this.startTime;
  }

  getElapsedTime(): number | undefined {
    if (!this.isRunning && this.startTime && this.endTime) {
      return this.endTime - this.startTime;
    }
    return undefined;
  }
}
