import { useCustomTranslation } from '@/i18n';
import { Checkbox, CheckboxProps } from '@cloudscape-design/components';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';

interface KeepMeLoggedInBoxProps {
  rememberMeCheckboxCallback: NonCancelableEventHandler<CheckboxProps.ChangeDetail>;
  rememberMeToggle: boolean;
}

const KeepMeLoggedInComponent: React.FC<KeepMeLoggedInBoxProps> = ({
  rememberMeCheckboxCallback,
  rememberMeToggle,
}) => {
  const { t } = useCustomTranslation();

  return (
    <Checkbox
      aria-live="assertive"
      onChange={rememberMeCheckboxCallback}
      checked={rememberMeToggle}
    >
      <div tabIndex={0} aria-live="assertive">
        {' '}
        {t('ws-remember-me-settings')}
      </div>
    </Checkbox>
  );
};

export default KeepMeLoggedInComponent;
