import { Alert } from '@cloudscape-design/components';
import './styles.css';
import FadeAnimation from '@/components/FadeAnimation';
import useHealthCheck from '@/hooks/useHealthCheckHandler';

const HealthCheckStatus = (): JSX.Element => {
  const { healthCheckData, onDismissAlert } = useHealthCheck();

  return (
    <>
      {healthCheckData.map((data, index) => (
        <div className={'health-check-alert'} key={index}>
          <FadeAnimation data-testid="health-check-fade-animation">
            <Alert
              data-testid={`health-check-status-${index}`}
              type="warning"
              dismissible={true}
              onDismiss={() => onDismissAlert(data.message)}
            >
              <p>{data.message}</p>
            </Alert>
          </FadeAnimation>
        </div>
      ))}
    </>
  );
};

export default HealthCheckStatus;
