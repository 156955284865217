export enum WsBrokerOperationState {
  WORKSPACE_OPERATIONAL_STATE_PENDING = 'PENDING',
  WORKSPACE_OPERATIONAL_STATE_AVAILABLE = 'AVAILABLE',
  WORKSPACE_OPERATIONAL_STATE_IMPAIRED = 'IMPAIRED',
  WORKSPACE_OPERATIONAL_STATE_UNHEALTHY = 'UNHEALTHY',
  WORKSPACE_OPERATIONAL_STATE_REBOOTING = 'REBOOTING',
  WORKSPACE_OPERATIONAL_STATE_STARTING = 'STARTING',
  WORKSPACE_OPERATIONAL_STATE_REBUILDING = 'REBUILDING',
  WORKSPACE_OPERATIONAL_STATE_MAINTENANCE = 'MAINTENANCE',
  WORKSPACE_OPERATIONAL_STATE_ADMIN_MAINTENANCE = 'ADMIN_MAINTENANCE',
  WORKSPACE_OPERATIONAL_STATE_TERMINATING = 'TERMINATING',
  WORKSPACE_OPERATIONAL_STATE_TERMINATED = 'TERMINATED',
  WORKSPACE_OPERATIONAL_STATE_SUSPENDED = 'SUSPENDED',
  WORKSPACE_OPERATIONAL_STATE_UPDATING = 'UPDATING',
  WORKSPACE_OPERATIONAL_STATE_STOPPING = 'STOPPING',
  WORKSPACE_OPERATIONAL_STATE_STOPPED = 'STOPPED',
  WORKSPACE_OPERATIONAL_STATE_ERROR = 'ERROR',
  WORKSPACE_OPERATIONAL_STATE_UNKNOWN = 'UNKNOWN',
  WORKSPACE_OPERATIONAL_STATE_UNAVAILABLE = 'UNAVAILABLE',
}

export enum WorkSpaceState {
  WORKSPACE_STATE_UNKNOWN = 'UNKNOWN',
  WORKSPACE_STATE_PENDING = 'PENDING',
  WORKSPACE_STATE_HEALTHY = 'HEALTHY',
  WORKSPACE_STATE_UNHEALTHY = 'UNHEALTHY',
  WORKSPACE_STATE_SUSPENDED = 'SUSPENDED',
  WORKSPACE_STATE_RESUMING = 'RESUMING',
  WORKSPACE_STATE_SUSPENDING = 'SUSPENDING',
  WORKSPACE_STATE_TERMINATED = 'TERMINATED',
  WORKSPACE_STATE_UNAVAILABLE = 'UNAVAILABLE',
  WORKSPACE_STATE_ADMIN_MAINTENANCE = 'ADMIN_MAINTENANCE',
  WORKSPACE_STATE_MAINTENANCE = 'MAINTENANCE',
  WORKSPACE_STATE_REBUILDING = 'REBUILDING',
  WORKSPACE_STATE_REBOOTING = 'REBOOTING',
  WORKSPACE_STATE_UPDATING = 'UPDATING',
}

type IWorkSpaceStateMap = {
  [key in WsBrokerOperationState]: WorkSpaceState;
};

export const WorkSpaceStateMap: IWorkSpaceStateMap = {
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_PENDING]:
    WorkSpaceState.WORKSPACE_STATE_PENDING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_AVAILABLE]:
    WorkSpaceState.WORKSPACE_STATE_HEALTHY,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_IMPAIRED]:
    WorkSpaceState.WORKSPACE_STATE_UNHEALTHY,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_UNHEALTHY]:
    WorkSpaceState.WORKSPACE_STATE_UNHEALTHY,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_STARTING]:
    WorkSpaceState.WORKSPACE_STATE_RESUMING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_REBUILDING]:
    WorkSpaceState.WORKSPACE_STATE_REBUILDING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_MAINTENANCE]:
    WorkSpaceState.WORKSPACE_STATE_MAINTENANCE,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_ADMIN_MAINTENANCE]:
    WorkSpaceState.WORKSPACE_STATE_ADMIN_MAINTENANCE,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_TERMINATING]:
    WorkSpaceState.WORKSPACE_STATE_TERMINATED,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_TERMINATED]:
    WorkSpaceState.WORKSPACE_STATE_TERMINATED,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_SUSPENDED]:
    WorkSpaceState.WORKSPACE_STATE_SUSPENDED,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_STOPPING]:
    WorkSpaceState.WORKSPACE_STATE_SUSPENDING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_STOPPED]:
    WorkSpaceState.WORKSPACE_STATE_RESUMING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_REBOOTING]:
    WorkSpaceState.WORKSPACE_STATE_REBOOTING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_UPDATING]:
    WorkSpaceState.WORKSPACE_STATE_UPDATING,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_UNAVAILABLE]:
    WorkSpaceState.WORKSPACE_STATE_UNAVAILABLE,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_ERROR]:
    WorkSpaceState.WORKSPACE_STATE_UNKNOWN,
  [WsBrokerOperationState.WORKSPACE_OPERATIONAL_STATE_UNKNOWN]:
    WorkSpaceState.WORKSPACE_STATE_UNKNOWN,
};
