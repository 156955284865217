import { SessionProtocols } from '@bridge/types/SessionTypes';
import { StreamContextResponse } from '@bridge/types/SoloRTCChannelTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { ILogger } from '@bridge/ILogger';
import { IDevice } from '@bridge/IDevice';
import { MaxibonStreamInputGenerator } from '@/presession/sessionprovision/utils/nativeMode/MaxibonStreamInputGenerator';

export class StreamInputGenerator {
  private readonly logger: ILogger;
  private readonly device: IDevice;
  private readonly maxibonStreamInputGenerator;

  constructor(logger: ILogger, device: IDevice) {
    this.logger = logger;
    this.device = device;
    this.maxibonStreamInputGenerator = new MaxibonStreamInputGenerator(
      logger,
      device
    );
  }

  generateNativeClientStreamInputs(
    protocol: SessionProtocols,
    allocatedResource: any,
    streamContext: StreamContextResponse
  ) {
    // Add support for PcoIP in future
    switch (protocol) {
      case SessionProtocols.MAXIBON:
      case SessionProtocols.WSP:
        return this.maxibonStreamInputGenerator.generateWspNativeClientStreamInputs(
          allocatedResource,
          streamContext
        );
      case SessionProtocols.PCOIP:
        /*
          PcoIP stream is handled by singularity module included with solo. Unlike WSP streaming,
          Web Client is not expected to send protocol module command line inputs to limit solo involvement.
        */
        return '';
      default:
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_CONNECTION,
          WsErrorCodes.ERROR_UNSUPPORTED_PROTOCOL
        );
    }
  }
}
