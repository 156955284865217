import { MacCoreFactory } from './MacCoreFactory';
import { WebCoreFactory } from './WebCoreFactory';
import { LinuxCoreFactory } from './LinuxCoreFactory';
import { SoloNativeRTCChannel } from '@/core/rtcChannel/SoloNativeRTCChannel';
import { PlatformType } from '@/bridge/types/SoloRTCChannelTypes';

let factory;
if (SoloNativeRTCChannel.isChannelAvailable()) {
  if (SoloNativeRTCChannel.getPlatform() === PlatformType.MACOS) {
    factory = new MacCoreFactory();
  } else {
    factory = new LinuxCoreFactory();
  }
} else {
  factory = new WebCoreFactory();
}

export const CoreFactory = factory;
