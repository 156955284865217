import { ICoreFactory } from './ICoreFactory';
import { ICobranding } from '@/bridge/ICobranding';
import { MacCobranding } from '@/core/cobranding/MacCobranding';
import { HttpClientFactory } from '../HttpClientFactory';
import { SoloCoreFactory } from './SoloCoreFactory';

export class MacCoreFactory extends SoloCoreFactory implements ICoreFactory {
  getCobranding(): ICobranding {
    if (!this._cobranding) {
      this._cobranding = new MacCobranding(
        HttpClientFactory.getCobrandingHttpClient(),
        this.getDevice(),
        this.getLogger(),
        this.getMetrics(),
        this.getSessionManager()
      );
    }
    return this._cobranding;
  }
}
