export const MetricConstants = {
  PUBLISHER_INTERVAL: 60000,
  PUBLISHER_WAIT_TIME: 10000,
  MAX_METRIC_PUBLISHER_SIZE_BYTES: 4000,
  MAX_METRIC_DIMENSION_LENGTH: 512,
  MAX_METRIC_PUBLISH_RETRY: 2,

  BROWSER_PLATFORM_APP_NAME: 'WorkSpacesWebClient',
  NIVA_PLATFORM_APP_NAME: 'AmazonWorkSpacesThinClient',
  NATIVE_PLATFORM_APP_NAME: 'WorkSpacesLinuxClient',
  NATIVE_PLATFORM_CANARY_APP_NAME: 'WorkSpacesLinuxCanary',
  BROWSER_METRICS_REQUEST_API_VERSION: 1,
  BROWSER_METRICS_REQUEST_API_URL_PATH: '/put-metrics',

  UNDEFINED: 'UnKnown',

  BROWSER_METRICS_STORAGE_KEY: 'WORKSPACES_METRICS_STRING',
  BROWSER_METRICS_STORAGE_SIZE_KEY: 'WORKSPACES_METRICS_STRING_SIZE',
  BROWSER_METRICS_STORAGE_VALUE_DELIMITER: '#$@',

  METRIC_PUBLISH_HTTP_CONFIG: {
    method: 'POST',
    headers: {
      'x-codec-version': '1.0',
      'Content-Type': 'application/octect-stream',
      'x-codec-format': 'ProtocolBuffers',
    },
  },
};
