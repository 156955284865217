import { WsError } from '@/core/error/WsError';
import { WsErrorTypes } from '@/core/error/WsErrorTypes';
import { WsErrorCodes } from '@/core/error/WsErrorCodes';
import { BaseStep } from './BaseStep';
import { IAuthInfoStep } from '../types';
import { IRegion } from '@bridge/types/RegionTypes';

export class GetAuthInfoStep extends BaseStep {
  async perform() {
    this.validate();
    const regCode = this.sessionManager.get('registrationCode') as string;
    const region = this.sessionManager.get('region') as IRegion;
    const authStep: IAuthInfoStep = {
      complete: false,
      payload: await this.authentication.fetchAuthInfo(regCode, region),
    };
    return authStep;
  }

  // At this point we must have valid regCode
  private validate() {
    if (!this.sessionManager.get('registrationCode')) {
      this.logger.fatal('Missing registration code');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_INVALID_REG_CODE
      );
    }
  }
}
