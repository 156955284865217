import { IAuthRelativePath } from '@/bridge/routes/AuthRoutes';
import ConnectStatus from '@/components/ConnectStatus';
import { useAuthManager } from '@/hooks/useAuthManager';
import { useEffect, useRef } from 'react';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { useRegistration } from '@hooks/useRegistration';

interface BaseStrategyProps {
  authStep: IAuthRelativePath;
}

const logger = CoreFactory.getLogger();
const rtcChannel = CoreFactory.getRTCChannel();
const BaseStrategy: React.FC<BaseStrategyProps> = ({ authStep }) => {
  const performed = useRef(false);
  const { performAuthStep } = useAuthManager();
  const { redirectToRegionalEndpointIfNecessary } = useRegistration();

  useEffect(() => {
    if (!performed.current) {
      performed.current = true;

      /*
       * Solo today launches webclient.amazonworkspaces.com/auth/idc?code=XXX&state=YYY.
       * To ensure, web client redirects to correct endpoint before performing auth step.
       * Call redirect function which carry overs all searchParams and path to correct regional endpoint url
       * For IDC [Linux client 2024.5*] , solo client uses the correct end point anyways.
       * */
      if (rtcChannel && authStep === 'saml') {
        logger.info(
          `Initiating navigation to auth end point before performing next step for native client from ${window.location.hostname}`
        );
        redirectToRegionalEndpointIfNecessary(
          window.location.pathname,
          false
        ).then((isRedirectionInitiated) => {
          if (!isRedirectionInitiated) {
            logger.info(
              `Initiating SAML auth for client using ${window.location.hostname}`
            );
            performAuthStep(authStep);
          }
        });
      } else {
        performAuthStep(authStep);
      }
    }
  }, []);

  return <ConnectStatus />;
};

export default BaseStrategy;
