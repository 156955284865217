import { AuthStepDeterminer } from '../AuthStepDeterminer';
import { BaseStep } from './BaseStep';

export class SamlStep extends BaseStep {
  async perform() {
    const authResponse = await this.authentication.authenticate(
      this.authRequest.constructSSOAuthRequest('saml')
    );
    return AuthStepDeterminer.getNextStepFromAuthResponse(authResponse);
  }
}
