import { SelfServiceClient } from '@/insession/SelfService/SelfServiceClient';
import { HeartBeat } from '@/insession/HeartBeat/HeartBeat';
import { ISelfServiceClient } from '@/bridge/ISelfServiceClient';
import { IHeartBeat } from '@/bridge/IHeartBeat';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { HttpClientFactory } from './HttpClientFactory';
import { IReportHeartbeatTask } from '../IReportHeartbeatTask';
import { ReportHeartbeatTask } from '@/insession/HeartBeat/ReportHeartbeatTask';
import { PreSessionFactory } from '@bridge/factory/PreSessionFactory';

export class InSessionFactory {
  private static selfServiceClient: ISelfServiceClient;
  private static heartBeat: IHeartBeat;
  private static reportHeartbeatTask: IReportHeartbeatTask;

  static getSelfServiceClient(): ISelfServiceClient {
    if (!this.selfServiceClient) {
      this.selfServiceClient = new SelfServiceClient(
        CoreFactory.getWsBrokerClient(),
        CoreFactory.getSessionManager(),
        PreSessionFactory.getSessionProvisionModule(),
        CoreFactory.getLogger()
      );
    }

    return this.selfServiceClient;
  }

  static getHeartBeat(): IHeartBeat {
    if (!this.heartBeat) {
      this.heartBeat = new HeartBeat(
        CoreFactory.getWsBrokerClient(),
        CoreFactory.getSessionManager(),
        HttpClientFactory.getHttpClient(),
        CoreFactory.getLogger(),
        CoreFactory.getMetrics()
      );
    }

    return this.heartBeat;
  }

  static getReportHeartbeatTask(): IReportHeartbeatTask {
    if (!this.reportHeartbeatTask) {
      this.reportHeartbeatTask = new ReportHeartbeatTask({
        heartbeat: this.getHeartBeat(),
        logger: CoreFactory.getLogger(),
        sessionManager: CoreFactory.getSessionManager(),
      });
    }

    return this.reportHeartbeatTask;
  }
}
