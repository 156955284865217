import { useEffect, useState } from 'react';
import {
  Box,
  Form,
  Checkbox,
  SpaceBetween,
  Button,
  Spinner,
} from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { useSelfServiceActions } from '../hooks/useSelfServiceActions';
import ErrorAlert from '../components/ErrorAlert';

// TODO: handle errors
const Restart = () => {
  const { t } = useCustomTranslation();
  const [confirmed, setConfirmed] = useState(false);
  const { error, cleanError, isPending, isProcessing, rebootWorkSpace } =
    useSelfServiceActions();
  const onActionButtonClick = () => {
    cleanError();
    rebootWorkSpace();
  };

  useEffect(() => {
    if (error) {
      setConfirmed(false);
    }
  }, [error]);

  return (
    <Box padding="m">
      <Form
        actions={
          <Button
            disabled={!confirmed || !isPending}
            variant="primary"
            ariaLabel={
              isProcessing ? 'Processing' : t('ws-my-workspace-restart')
            }
            onClick={onActionButtonClick}
          >
            {isProcessing ? <Spinner /> : t('ws-my-workspace-restart')}
          </Button>
        }
      >
        <SpaceBetween size="m">
          {error && <ErrorAlert error={error} onDismiss={cleanError} />}
          <p
            tabIndex={0}
            aria-live="assertive"
            role="navigation"
            aria-label={t('ws-my-workspace-restart-confirm')}
          >
            {t('ws-my-workspace-restart-confirm')}
          </p>
          <p
            tabIndex={0}
            aria-live="assertive"
            role="navigation"
            aria-label={t('ws-my-workspace-restart-confirm-description')}
          >
            {t('ws-my-workspace-restart-confirm-description')}
          </p>
          <Checkbox
            onChange={({ detail }) => setConfirmed(detail.checked)}
            checked={confirmed}
            disabled={!isPending}
            ariaLabel={t('ws-my-workspace-restart-disconnect-confirm')}
          >
            <strong>{t('ws-my-workspace-restart-disconnect-confirm')}</strong>
          </Checkbox>
        </SpaceBetween>
      </Form>
    </Box>
  );
};

export default Restart;
