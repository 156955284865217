import { Alert } from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';

interface SuccessAlertProps {
  message: string;
  onDismiss: () => void;
}

const SuccessAlert: React.FC<SuccessAlertProps> = ({ message, onDismiss }) => {
  const { t } = useCustomTranslation();

  return (
    <Alert
      dismissAriaLabel={t('ws-modal-close')}
      dismissible
      type="success"
      onDismiss={onDismiss}
    >
      {message}
    </Alert>
  );
};

export default SuccessAlert;
