import { AWSCShortbread } from '@amzn/shortbread';
import i18n from 'i18next';
import { I18nUtils } from '@/i18n/utils';

export class CookieCompliance {
  public static load(): void {
    this.create();
    window.shortbread?.checkForCookieConsent();
  }

  public static reload(): void {
    this.clear();
    this.load();
  }

  public static clear(): void {
    const existingShortbreadEl = document.getElementById('awsccc-sb-ux-c');
    existingShortbreadEl?.remove();

    window.shortbread = undefined;
  }

  public static customize(): void {
    if (window.shortbread == null) this.reload();
    window.shortbread?.customizeCookies();
  }

  private static create(): void {
    window.shortbread = AWSCShortbread({
      domain: window.location.hostname,
      language: I18nUtils.mapToAWSCShortbreadLocale(i18n.language),
      registry: {
        language: { category: 'essential' },
        'csrf-token': { category: 'essential' },
      },
    });
  }
}
