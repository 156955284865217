import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionType,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { ILogger } from '@bridge/ILogger';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

export class DNSLookUpResponseHandler implements IActionHandler {
  private readonly logger: ILogger;

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    try {
      this.validate(msg);
      const payload = msg.Payload;
      if ('ErrorCode' in payload) {
        this.handleErrorResponse(payload);
      }
      callback?.(payload);
    } catch (error: any) {
      this.logger.error(
        'Error parsing DNS lookup response from rtc channel',
        error?.message
      );
    }
  }

  private validate(msg: RTCChannelMessage) {
    this.logger.info(`Received Auth context details from Solo`);
    if (
      msg.Action !== Action.RESOLVE_DNS_RECORD ||
      msg.ActionType !== ActionType.RESPONSE
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_UNKNOWN,
        ClientErrorCode.ResolveDNSDomainLookUPInvalidInput
      );
    }
  }

  private handleErrorResponse(response: any) {
    this.logger.error(`Received error for auth context from native client`);
    const error = new WsError(
      WsErrorTypes.ERROR_TYPE_REGISTRATION,
      WsErrorCodes.ERROR_UNKNOWN,
      ClientErrorCode.ResolveDNSDomainLookUPUnknown
    );
    error.name = response?.ErrorCode;
    error.message = response?.ErrorMessage;
    throw error;
  }
}
