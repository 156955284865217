import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionStore } from '@/stores/session';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';

const device = CoreFactory.getDevice();

interface PlatformBoundaryProps {
  children: JSX.Element;
}

// Note: This is a top level boundary for os and platform/browser checking
const PlatformBoundary: React.FC<PlatformBoundaryProps> = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const { pathname } = useLocation();
  const { showError } = useErrorHandler();
  const skipPlatformCheck = useSessionStore((state) => state.skipPlatformCheck);
  const isErrorPage = useMemo(() => pathname === '/error', [pathname]);

  useEffect(() => {
    if (isErrorPage) {
      return;
    }

    if (skipPlatformCheck) {
      setChecked(true);
      return;
    }

    if (device.isCurrentPlatformSupported() && device.isCurrentOSSupported()) {
      setChecked(true);
      return;
    }

    showError(
      new WsError(
        WsErrorTypes.ERROR_TYPE_UNSUPPORTED_PLATFORM,
        WsErrorCodes.ERROR_UNSUPPORTED_PLATFORM
      )
    );
  }, [showError, pathname]);

  if (checked || isErrorPage) {
    return children;
  } else {
    return null;
  }
};

export default PlatformBoundary;
