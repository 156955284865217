import { RegionConstants } from '../constants/RegionConstants';
import { RegPrefix } from '../types/RegionTypes';

// ToDo: Remove this class in future. Rely only on RegionResolver to support CRR connection aliases or use region SessionData.
const hasPrefix = (prefix?: string): prefix is RegPrefix =>
  RegionConstants.PREFIX_TO_REGION_MAP.has(prefix as RegPrefix);
const getRegionFromPrefix = (prefix: RegPrefix) =>
  RegionConstants.PREFIX_TO_REGION_MAP.get(prefix);
const getPrefixFromRegCode = (regCode?: string) =>
  regCode?.split('+')[0].toLocaleLowerCase();
const getRegionFromRegCode = (regCode?: string) =>
  getRegionFromPrefix(getPrefixFromRegCode(regCode) as RegPrefix);

export const RegionUtils = {
  hasPrefix,
  getRegionFromPrefix,
  getPrefixFromRegCode,
  getRegionFromRegCode,
};
