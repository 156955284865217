import { IRegion, RegPrefix } from '@bridge/types/RegionTypes';
import { RegionConstants } from '@bridge/constants/RegionConstants';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { IHttpClient } from '@bridge/IHttpClient';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { MetricName, MetricResult, Operation } from '@bridge/types/MetricTypes';
import { WsError } from '@core/error/WsError';
import { DNSRecordType } from '@bridge/types/SoloRTCChannelTypes';
import { IRTCChannel } from '@bridge/IRTCChannel';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { DimensionSet } from '@core/metrics/types';

export class RegionResolver {
  /*
    Note: Singularity supports the regex: (?=^.{4,253}\\.?$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\\.)+[a-zA-Z]{2,63}\\.?$)
    But WebKitGtk in solo does not support look behind operations like in group3 matcher above. This is a corner case
    expression which is not generally used, though it is allowed.
    ToDo: Evaluate if regExp needs to be changed to accommodate corner case above in case of customer issues.
  */
  private static readonly ConnectionAliasRegExp =
    '(?=^.{4,253}\\.?$)(^((?!-)[a-zA-Z0-9-]{1,63}\\.)+[a-zA-Z]{2,63}\\.?$)';

  private static readonly WebDomainLookUpQueryPath = 'region-resolve';

  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly httpClient: IHttpClient<any>;
  private readonly rtcChannel: IRTCChannel | null;

  constructor(
    logger: ILogger,
    metrics: IMetrics,
    httpsClient: IHttpClient<any>,
    rtcChannel: IRTCChannel | null
  ) {
    this.logger = logger;
    this.metrics = metrics;
    this.httpClient = httpsClient;
    this.rtcChannel = rtcChannel;
  }

  static isConnectionAlias(code: string | null) {
    const regExp = new RegExp(RegionResolver.ConnectionAliasRegExp);
    return !!code && regExp.test(code);
  }

  // Scope Prod stack to prod reg codes only. ToDo: Scope gamma stack once gamma-pdx is running
  static isRegCodeSupportedByCurrentStack(region: IRegion) {
    const currentUrl = window.location.hostname?.toLowerCase();
    const isProdRegCode = region.endpoint.includes('prod');

    if (
      RegionConstants.IS_BETA_ENDPOINT(currentUrl) ||
      RegionConstants.IS_LOCAL_TEST_ENDPOINT(currentUrl) ||
      RegionConstants.IS_GAMMA_ENDPOINT(currentUrl)
    ) {
      return true;
    }

    if (RegionConstants.IS_PROD_ENDPOINT(currentUrl) && isProdRegCode) {
      return true;
    }

    return false;
  }

  /*
    Must only be used for normal regCodes. CRR reg codes will return undefined. Only used for tests.
    For production code use getRegionFromRegCode instead
  */
  static getRegionForVanillaRegCode(regCode: string) {
    if (RegionResolver.isConnectionAlias(regCode)) {
      return undefined;
    }
    const regCodePrefix = RegionResolver.getPrefixFromRegCode(regCode);
    return regCodePrefix
      ? RegionResolver.getRegionFromPrefix(regCodePrefix as RegPrefix)
      : undefined;
  }

  static getWebClientLoginRedirectEndPointFromRegion(region: IRegion) {
    const currentUrl = window.location.hostname?.toLowerCase();
    const regionCode = region.code;
    const awsRegionCode = region.awsRegionCode;

    if (!region) {
      return currentUrl;
    } else if (
      currentUrl?.includes(RegionConstants.WEB_CLIENT_BETA_HOSTNAME_SUB_STR)
    ) {
      return RegionResolver.buildBetaWebClientEndpoint(
        awsRegionCode,
        regionCode
      );
    } else if (
      currentUrl?.includes(RegionConstants.WEB_CLIENT_GAMMA_HOSTNAME_SUB_STR)
    ) {
      return RegionResolver.buildGammaWebClientEndpoint(
        awsRegionCode,
        regionCode
      );
    } else if (
      currentUrl?.includes(RegionConstants.WEB_CLIENT_PROD_HOSTNAME_SUB_STR)
    ) {
      return region?.webClientEndpoint;
    } else {
      // localhost or skylight.local return the same url back
      return `https://${currentUrl}`;
    }
  }

  async getRegionFromRegCode(regCode?: string) {
    if (!regCode) {
      return undefined;
    } else if (RegionResolver.isConnectionAlias(regCode)) {
      return await this.getConnectionAliasRegion(regCode);
    } else {
      return RegionResolver.getRegionFromPrefix(
        RegionResolver.getPrefixFromRegCode(regCode) as RegPrefix
      );
    }
  }

  private async getConnectionAliasRegion(connectionAlias: string) {
    this.logger.info(`Identified registration code as connection alias`);
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      return await this.getConnectionAliasRegionForSolo(connectionAlias);
    } else {
      return await this.getConnectionAliasRegionForWeb(connectionAlias);
    }
  }

  private async getConnectionAliasRegionForWeb(connectionAlias: string) {
    this.logger.info(
      `Retrieving region details for connection alias for web client`
    );

    const redirectPort = window.location.port ? `:${window.location.port}` : '';
    const currentUrl = `https://${window.location.hostname}${redirectPort}/${RegionResolver.WebDomainLookUpQueryPath}?domain=${connectionAlias}`;

    try {
      const response = await this.httpClient.get(currentUrl);
      return this.handleWebDnsLookUpResponseAndPublishMetrics(
        connectionAlias,
        response
      );
    } catch (e: any) {
      this.logger.fatal(
        `DNS lookup for web client failed for ${connectionAlias} with error`,
        e
      );
      this.publishDnsLookUpMetric(ClientErrorCode.DnsLookUpError, e);
    }
  }

  private async getConnectionAliasRegionForSolo(connectionAlias: string) {
    this.logger.info(
      `Retrieving region details for connection alias for native client`
    );
    let error;
    let clientErrorCode = ClientErrorCode.RTCChannelTornDown;
    try {
      if (this.rtcChannel) {
        const response = await this.rtcChannel.requestDnsDomainRecords(
          connectionAlias,
          DNSRecordType.TXT
        );
        return this.handleSoloDnsLookUpResponseAndPublishMetrics(
          connectionAlias,
          response?.RawRecords
        );
      }
    } catch (e: any) {
      clientErrorCode = ClientErrorCode.DnsLookUpError;
      error = e;
    }
    this.logger.fatal(
      `DNS lookup through rtc channel failed for ${connectionAlias} with error ${clientErrorCode}`
    );
    this.publishDnsLookUpMetric(clientErrorCode, error);
  }

  private handleWebDnsLookUpResponseAndPublishMetrics(
    connectionAlias: string,
    response?: any
  ) {
    if (!response || response.status !== 200) {
      this.logger.fatal(
        `Received error response:${JSON.stringify(
          response
        )} during Web DNS lookup`
      );
      const httpError = new Error(JSON.stringify(response?.status));
      this.publishDnsLookUpMetric(ClientErrorCode.DnsLookUpError, httpError);
      return;
    }

    const responseData = response?.data;
    const records = responseData ?? [[]];
    if (!records || !records?.[0] || !records[0]?.[0]) {
      this.logger.fatal(
        `Received empty dns txt records for web DNS lookup with domain:${connectionAlias}`
      );
      this.publishDnsLookUpMetric(ClientErrorCode.DnsNoTxtEntries);
      return;
    }

    const dnsTxtRecord = records[0][0] as string;
    if (
      !RegionConstants.PREFIX_TO_REGION_MAP.has(
        dnsTxtRecord.toLowerCase() as RegPrefix
      )
    ) {
      this.logger.fatal(
        `Connection alias:${connectionAlias}, Web DNS lookup records:${JSON.stringify(
          records
        )} does not contain valid region`
      );
      this.publishDnsLookUpMetric(ClientErrorCode.DnsInvalidEntries);
      return;
    }

    this.publishDnsLookUpMetric(undefined, undefined, records?.length);
    return RegionResolver.getRegionFromPrefix(
      dnsTxtRecord.toLowerCase() as RegPrefix
    );
  }

  private handleSoloDnsLookUpResponseAndPublishMetrics(
    connectionAlias: string,
    records?: string[]
  ) {
    if (!records || records.length < 1) {
      this.logger.fatal(
        `Received empty dns txt records during Native DNS lookup for ${connectionAlias}`
      );
      this.publishDnsLookUpMetric(ClientErrorCode.DnsNoTxtEntries);
      return;
    }

    const connectionAliasPrefix = this.transformSoloDnsResponse(
      records[0],
      connectionAlias
    );
    if (!connectionAliasPrefix) {
      this.logger.fatal(
        `Connection alias:${connectionAlias}, Native DNS lookup records:${JSON.stringify(
          records
        )} does not contain valid region`
      );
      this.publishDnsLookUpMetric(ClientErrorCode.DnsInvalidEntries);
      return;
    }

    this.publishDnsLookUpMetric(undefined, undefined, records?.length);
    return RegionResolver.getRegionFromPrefix(
      connectionAliasPrefix as unknown as RegPrefix
    );
  }

  /*
   eg Record from Solo: 'desktop.ashaws.cloud. 60 IN TXT WSpdx'
   singularity code accepts first region if there are multiple present.

   Since its difficult for solo to identify the record format, Web will receive raw records and
   proceed to identify the region
  */
  private transformSoloDnsResponse(response: string, connectionAlias: string) {
    if (!response.includes(connectionAlias)) {
      return;
    }

    response = response.replace(connectionAlias, '');
    const responseString = response.toLowerCase();
    let regionPrefixStartIndex = Infinity;
    let regionPrefixFromDnsResponse;
    RegionConstants.PREFIX_TO_REGION_MAP.forEach((value, regPrefix) => {
      if (
        responseString.includes(regPrefix) &&
        regionPrefixStartIndex > responseString.indexOf(regPrefix)
      ) {
        regionPrefixStartIndex = responseString.indexOf(regPrefix);
        regionPrefixFromDnsResponse = regPrefix;
      }
    });
    return regionPrefixFromDnsResponse;
  }

  private publishDnsLookUpMetric(
    clientErrorCode?: ClientErrorCode,
    error?: any,
    dnsNumRecords?: number
  ) {
    let wsError;
    if (clientErrorCode !== undefined) {
      wsError = new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_INVALID_REG_CODE,
        clientErrorCode,
        error?.message
      );
      wsError.setInnerException(error);
      this.metrics.emit(Operation.DnsLookup, MetricResult.Error, wsError);
    } else {
      // Publish success metric. Singularity also publishes Number of DNS entries.
      // But that is not necessary as same data can be gathered from stream query and its better to add as dimension

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const dimensions = {} as DimensionSet;
      dimensions.NumEntries = dnsNumRecords
        ? String(dnsNumRecords as unknown as string)
        : '0';
      this.metrics.emitWithValue(
        Operation.DnsLookup,
        MetricName.Success,
        1,
        dimensions
      );
    }
  }

  private static getRegionFromPrefix(prefix: RegPrefix) {
    return RegionConstants.PREFIX_TO_REGION_MAP.get(prefix);
  }

  private static getPrefixFromRegCode(regCode?: string) {
    return regCode?.split('+')[0].toLocaleLowerCase();
  }

  private static buildBetaWebClientEndpoint(
    awsRegionCode: string,
    code: string
  ) {
    if (
      !RegionConstants.REG_CODE_BASED_REDIRECTION_BETA_SUPPORTED_REGIONS.includes(
        code
      )
    ) {
      return `https://${window.location.hostname}`;
    }
    if (['pdt', 'osu'].includes(code)) {
      return RegionConstants.WEB_CLIENT_BETA_GOV_HOSTNAME;
    }
    return `https://${awsRegionCode}.${RegionConstants.WEB_CLIENT_BETA_HOSTNAME_SUB_STR}`;
  }

  private static buildGammaWebClientEndpoint(
    awsRegionCode: string,
    code: string
  ) {
    if (
      !RegionConstants.REG_CODE_BASED_REDIRECTION_GAMMA_SUPPORTED_REGIONS.includes(
        code
      )
    ) {
      return `https://${window.location.hostname}`;
    }

    if (['pdt', 'osu'].includes(code)) {
      return RegionConstants.WEB_CLIENT_GAMMA_GOV_HOSTNAME;
    }
    return `https://${awsRegionCode}.${RegionConstants.WEB_CLIENT_GAMMA_HOSTNAME_SUB_STR}`;
  }
}
