import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  ColumnLayout,
  Form,
  Button,
  RadioGroup,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { useSessionStore } from '@/stores/session';
import { RunningModes } from '@/bridge/types/SelfService';
import { useSelfServiceActions } from '../hooks/useSelfServiceActions';
import ErrorAlert from '../components/ErrorAlert';
import SuccessAlert from '../components/SuccessAlert';

const SwitchRunningMode = () => {
  const { t } = useCustomTranslation();
  const { resource } = useSessionStore((state) => ({ ...state }));
  const currMode = useMemo(
    () => resource?.ResourceDetails?.RunningMode as RunningModes,
    [resource?.ResourceDetails?.RunningMode]
  );
  const currModeLabel =
    `${t('ws-my-workspace-switch-running-mode-current-key')}: ` + currMode;
  const [newRunningMode, setNewRunningMode] = useState<RunningModes>(currMode);
  const {
    error,
    cleanError,
    isCompleted,
    isPending,
    isProcessing,
    reset,
    modifyRunningMode,
  } = useSelfServiceActions();
  const [showSuccess, setShowSuccess] = useState(false);
  const onActionButtonClick = () => {
    cleanError();
    setShowSuccess(false);
    modifyRunningMode(newRunningMode);
  };

  useEffect(() => {
    if (isCompleted) {
      setShowSuccess(true);
      reset();
    }
  }, [isCompleted]);

  return (
    <Box padding="m">
      <Form
        actions={
          <Button
            disabled={currMode === newRunningMode || !isPending}
            variant="primary"
            onClick={onActionButtonClick}
            ariaLabel={
              isProcessing
                ? 'Processing'
                : t('ws-my-workspace-switch-running-mode')
            }
          >
            {isProcessing ? (
              <Spinner />
            ) : (
              t('ws-my-workspace-switch-running-mode')
            )}
          </Button>
        }
      >
        <ColumnLayout borders="horizontal">
          <SpaceBetween size="s">
            {error && <ErrorAlert error={error} onDismiss={cleanError} />}
            {showSuccess && (
              <SuccessAlert
                message={t(
                  'ws-my-workspace-switch-running-mode-success-message'
                )}
                onDismiss={() => () => setShowSuccess(false)}
              />
            )}
            <p
              tabIndex={0}
              aria-live="assertive"
              role="region"
              aria-label={currModeLabel}
            >
              {t('ws-my-workspace-switch-running-mode-current-key')}:{' '}
              <strong>
                {currMode === RunningModes.AUTO_STOP &&
                  t('ws-my-workspace-switch-running-mode-auto-stop')}
                {currMode === RunningModes.ALWAYS_ON &&
                  t('ws-my-workspace-switch-running-mode-always-on')}
              </strong>
            </p>
            <p
              tabIndex={0}
              aria-live="assertive"
              role="region"
              aria-label={t('ws-my-workspace-switch-running-mode-description')}
            >
              {t('ws-my-workspace-switch-running-mode-description')}
            </p>
          </SpaceBetween>
          <Box margin={{ vertical: 's' }}>
            <RadioGroup
              onChange={({ detail }) =>
                setNewRunningMode(detail.value as RunningModes)
              }
              value={newRunningMode}
              items={[
                {
                  value: RunningModes.ALWAYS_ON,
                  label: (
                    <strong>
                      {t('ws-my-workspace-switch-running-mode-always-on')}
                    </strong>
                  ),
                  description: t(
                    'ws-my-workspace-switch-running-mode-always-on-description'
                  ),
                  disabled: !isPending,
                },
                {
                  value: RunningModes.AUTO_STOP,
                  label: (
                    <strong>
                      {t('ws-my-workspace-switch-running-mode-auto-stop')}
                    </strong>
                  ),
                  description: t(
                    'ws-my-workspace-switch-running-mode-auto-stop-description'
                  ),
                  disabled: !isPending,
                },
              ]}
            />
          </Box>
        </ColumnLayout>
      </Form>
    </Box>
  );
};

export default SwitchRunningMode;
