import { ICobranding } from '@/bridge/ICobranding';
import { Cobranding } from './Cobranding';
import { IBranding, IDeviceType } from './types';

export class WebCobranding extends Cobranding implements ICobranding {
  getDeviceType(branding?: IBranding): IDeviceType | undefined {
    const { DeviceTypeWeb: deviceTypeWeb } = branding ?? {};

    return deviceTypeWeb;
  }
}
