import { ILogger } from '@bridge/ILogger';
import { LoggerConstants, LogLevel } from '@core/constants/LoggerConstants';
import { IDevice } from '@bridge/IDevice';
import { SoloLogUploader } from '@amzn/euc-web-log-uploader';
import {
  LogFileType,
  Region,
} from '@amzn/euc-web-log-uploader/dist/types/LogUploaderTypes';
import { useLogUploaderPreferenceStore } from '@stores/loguploader';
import {
  LogUploadData,
  LogUploadDetails,
} from '@bridge/types/SoloRTCChannelTypes';
import { IRTCChannel } from '@bridge/IRTCChannel';
import { CoreFactory } from '@bridge/factory/CoreFactory';

/*
 * This class uses SoloLogUploader to save logs to indexDB. During initialization, uploader class is initialized
 * depending only on user configuration (on by default). But the actual uploading of logs is done in UI code
 * which checks if log uploader is enabled at directory level. This can be simplified by moving the
 * isUploaderEnabledAtDirectoryLevel to log uploader store. Adding this as a ToDo for now.
 * */
export class SoloLogger implements ILogger {
  private readonly device: IDevice;
  private logLevel: string;
  private soloUploader?: SoloLogUploader;
  private soloLogUploaderTimerId?: any;
  private rtcChannel?: IRTCChannel | null;
  private static readonly LogUploaderSupportedRegions = [
    'beta.iad',
    'fips.beta.iad',
    'prod.iad',
    'fips.prod.iad',
    'gamma.pdx',
    'fips.gamma.pdx',
    'prod.pdx',
    'fips.prod.pdx',
    'prod.dub',
    'prod.syd',
    'prod.nrt',
    'prod.sin',
    'prod.fra',
    'prod.lhr',
    'prod.yul',
    'prod.gru',
    'prod.icn',
    'prod.cpt',
    'prod.bom',
  ];

  constructor(device: IDevice) {
    this.device = device;
    this.logLevel = LogLevel.Info;
  }

  setLogLevel(logLevel: string) {
    this.logLevel = logLevel;
  }

  startLogUpload(organizationName: string, endPoint: string | undefined) {
    if (!this.isLogUploaderSupported(endPoint)) {
      this.info(
        `Log collection is not supported for current region ${endPoint}`
      );
      return;
    }

    if (!this.soloUploader) {
      this.initializeLogUploader();
    }
    try {
      this.soloUploader?.initializePublisher(
        organizationName,
        endPoint as Region
      );
      this.rtcChannel = CoreFactory.getRTCChannel();
      this.triggerSoloLogUpload();
      clearInterval(this.soloLogUploaderTimerId);

      // TODO: Explore refactoring this logic to setTimeout instead of setInterval
      this.soloLogUploaderTimerId = setInterval(() => {
        this.triggerSoloLogUpload();
      }, LoggerConstants.SOLO_PUBLISHER_INTERVAL_MS);
    } catch (e) {
      this.error(
        `Failed to start log uploader for ${organizationName} and ${endPoint}`
      );
    }
  }

  pauseLogUpload() {
    this.info('Cleared log uploader timer');
    clearInterval(this.soloLogUploaderTimerId);
  }

  flush() {
    this.triggerSoloLogUpload();
  }

  info(message: string): void {
    this.generateLogAndSaveToDevice(LogLevel.Info, message);
  }

  warn(message: string): void {
    this.generateLogAndSaveToDevice(LogLevel.Warn, message);
  }

  error(message: string, exceptionMessage?: string, stackTrace?: string): void {
    this.generateLogAndSaveToDevice(
      LogLevel.Error,
      this.concatExceptionMessage(message, exceptionMessage, stackTrace)
    );
  }

  fatal(message: string, exceptionMessage?: string, stackTrace?: string): void {
    this.generateLogAndSaveToDevice(
      LogLevel.Fatal,
      this.concatExceptionMessage(message, exceptionMessage, stackTrace)
    );
  }

  async isLogFileIndexed(logFileName: string): Promise<boolean> {
    return (await this.soloUploader?.isLogNameIndexed(logFileName)) ?? false;
  }

  soloLogUploadTriggerCallback = async (
    payload: LogUploadDetails | undefined
  ) => {
    if (!payload) {
      this.warn('Received empty log upload details from solo');
      return;
    }
    if (payload && Object.prototype.hasOwnProperty.call(payload, 'ErrorCode')) {
      this.warn(
        'Failed to propagate LogUploadDetails response to RTC listener'
      );
      return;
    }

    const clientLogFileArray = this.retrieveSoloLogFileNames(
      payload.ClientLogFiles
    );
    const protocolLogFileArray = this.retrieveSoloLogFileNames(
      payload.ProtocolLogFiles
    );
    const allLogFileNames = clientLogFileArray.concat(protocolLogFileArray);

    for (const logFileName of allLogFileNames) {
      const isLogFileIndexed = await this.isLogFileIndexed(logFileName);
      if (!isLogFileIndexed) {
        const logUploadData = await this.rtcChannel?.sendEligibleLogNameMessage(
          logFileName,
          LoggerConstants.SOLO_PUBLISHER_WAIT_TIME_MS
        );
        if (logUploadData) {
          this.soloLogUploadFileCallback(logUploadData);
        }
      }
    }
  };

  soloLogUploadFileCallback = (payload: LogUploadData) => {
    try {
      const logUploadDataContents = new Uint8Array(payload?.LogUploadData);
      const textDecoder = new TextDecoder();
      const decodedLogData = textDecoder.decode(logUploadDataContents);
      this.uploadLogFile(payload?.LogUploadFileName, decodedLogData);
    } catch (error: any) {
      this.error('Failed to upload logfile: ', error?.message);
    }
  };

  async triggerSoloLogUpload() {
    try {
      const logUploadDetails = await this.rtcChannel?.sendLogUploadTrigger(
        LoggerConstants.SOLO_PUBLISHER_WAIT_TIME_MS
      );
      await this.soloLogUploadTriggerCallback(logUploadDetails);
    } catch (e: any) {
      this.error(`Error publishing log upload trigger`, e);
    }
  }

  uploadLogFile(logName: string, logFileData: string) {
    this.soloUploader?.uploadLog(logName, logFileData);
  }

  private retrieveSoloLogFileNames(payloadString?: string) {
    if (!payloadString) {
      return [];
    }
    return payloadString.split(',');
  }

  /*
     LogUploader needs deviceID during initialization. Ideally client will always have deviceID.
     But in case of Solo, there is a possibility of Negotiation failing in which case deviceID will
     not be set. Hence leaving this inside try for now.
    */
  initializeLogUploader() {
    try {
      const logUploaderSavedState = useLogUploaderPreferenceStore.getState();
      this.logLevel = logUploaderSavedState.logLevelPreference;

      if (logUploaderSavedState.isLoggingEnabledByUser) {
        this.soloUploader = new SoloLogUploader({
          applicationName: 'WorkSpacesSoloClient',
          deviceId: this.device.getDeviceUUID() as string,
          logFileType: LogFileType.client,
        });
        this.info('Set up log uploader successfully');
      }
      this.info(
        `User Log settings for device::${this.device.getDeviceUUID()} 
                are UserLogsEnabled:${
                  logUploaderSavedState.isLoggingEnabledByUser
                } LogLevel:${this.logLevel}`
      );
    } catch (e: any) {
      this.error(
        `Failed to initialize log uploader for device:${this.device.getDeviceUUID()}`,
        e.message
      );
    }
  }

  private generateLogAndSaveToDevice(
    logLevel: LogLevel,
    message: string
  ): string {
    const time = new Date().toISOString();
    this.device.saveLog(message, logLevel);
    return time + '>[' + logLevel + ']' + message;
  }

  private concatExceptionMessage(
    message: string,
    exceptionMessage?: string,
    stackTrace?: string
  ): string {
    let exceptionLog = message;
    if (exceptionMessage) {
      exceptionLog += `\n[ExceptionMessage]${exceptionMessage}`;
    }

    if (stackTrace) {
      exceptionLog += `\n[StackTrace]${stackTrace}`;
    }
    return exceptionLog;
  }

  isLogUploaderSupported(region: string | undefined) {
    if (region && SoloLogger.LogUploaderSupportedRegions.includes(region)) {
      return true;
    }
    return false;
  }
}
