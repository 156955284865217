import { IPersistentStorage } from '@/bridge/IPersistentStorage';

export class BrowserPersistentStorage implements IPersistentStorage {
  get(key: string): string | undefined {
    const value = localStorage.getItem(key);
    if (value) {
      return value;
    }
    return undefined;
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
