import { useState, useMemo } from 'react';
import { Tabs, Box } from '@cloudscape-design/components';
import About from './About';
import Restart from './Restart';
import ChangeComputeType from './ChangeComputeType';
import IncreaseDiskSize from './IncreaseDiskSize';
import SwitchRunningMode from './SwitchRunningMode';
import Rebuild from './Rebuild';
import { useCustomTranslation } from '@/i18n';
import { useSessionStore } from '@/stores/session';
import { useSelfServiceStore } from './hooks/useSelfServiceStore';
import {
  SelfServiceActionTypes,
  OtherActionTypes,
} from '@/bridge/types/SelfService';
import { TabIds } from './types';

interface TabProps {
  label: string;
  id: string;
  content: JSX.Element;
  disabled: boolean;
}
interface IActionToTabMap {
  [key: string]: TabProps;
}

// TODO: check if it's in session. Go to disconnected page or main page if not.
const SelfService = () => {
  const { t } = useCustomTranslation();
  const [activeTabId, setActiveTabId] = useState(TabIds.ABOUT);
  const { isPending } = useSelfServiceStore();
  const ActionToTabMap: IActionToTabMap = useMemo(
    () => ({
      [SelfServiceActionTypes.REBOOT]: {
        label: t('ws-my-workspace-restart'),
        id: TabIds.RESTART,
        content: <Restart />,
        disabled: !isPending && activeTabId !== TabIds.RESTART,
      },
      [SelfServiceActionTypes.CHANGE_COMPUTE_TYPE]: {
        label: t('ws-my-workspace-change-compute-type-title'),
        id: TabIds.CHANGE_COMPUTE_TYPE,
        content: <ChangeComputeType />,
        disabled: !isPending && activeTabId !== TabIds.CHANGE_COMPUTE_TYPE,
      },
      [SelfServiceActionTypes.INCREASE_VOLUME_SIZE]: {
        label: t('ws-my-workspace-increase-disk-size-title'),
        id: TabIds.INCREASE_DISK_SIZE,
        content: <IncreaseDiskSize />,
        disabled: !isPending && activeTabId !== TabIds.INCREASE_DISK_SIZE,
      },
      [SelfServiceActionTypes.MODIFY_RUNNING_MODE_PROPERTIES]: {
        label: t('ws-my-workspace-switch-running-mode-title'),
        id: TabIds.SWITCH_RUNNING_MODE,
        content: <SwitchRunningMode />,
        disabled: !isPending && activeTabId !== TabIds.SWITCH_RUNNING_MODE,
      },
      [SelfServiceActionTypes.REBUILD]: {
        label: t('ws-my-workspace-rebuild'),
        id: TabIds.REBUILD,
        content: <Rebuild />,
        disabled: !isPending && activeTabId !== TabIds.REBUILD,
      },
    }),
    [isPending, activeTabId]
  );
  const defaultTabs = useMemo(
    () => [
      {
        label: t('ws-my-workspace-about'),
        id: TabIds.ABOUT,
        content: <About />,
        disabled: !isPending && activeTabId !== TabIds.ABOUT,
      },
    ],
    [isPending, activeTabId]
  );
  const resource = useSessionStore((state) => state.resource);
  const authorizedActions = useMemo(
    () =>
      (resource?.ActionsAuthorized ?? [])
        .map(({ ActionType }) => ActionType)
        .filter((ActionType) => ActionType !== OtherActionTypes.CONNECT),
    [resource?.ActionsAuthorized]
  );
  const authorizedActionTabs: TabProps[] = useMemo(
    () => authorizedActions.map((ActionType) => ActionToTabMap[ActionType]),
    [authorizedActions, ActionToTabMap]
  );
  const tabs: TabProps[] = useMemo(
    () => [...defaultTabs, ...authorizedActionTabs],
    [defaultTabs, authorizedActionTabs]
  );

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Box margin="n">
        <Box margin="m">
          <div
            tabIndex={0}
            role="region"
            aria-label={t('ws-my-workspace')}
            aria-live="assertive"
            style={{ paddingBottom: '6px', paddingTop: '4px' }}
          >
            <h1
              style={{
                margin: 0,
                fontWeight: 800,
                fontSize: '24px',
                fontStretch: '100%',
              }}
            >
              {t('ws-my-workspace')}
            </h1>
          </div>
        </Box>
        <Tabs
          activeTabId={activeTabId}
          tabs={tabs}
          onChange={({ detail }) =>
            setActiveTabId(detail.activeTabId as TabIds)
          }
          ariaLabelledby={activeTabId}
        />
      </Box>
    </div>
  );
};

export default SelfService;
