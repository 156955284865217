import { WsError } from '@/core/error/WsError';
import { WsErrorTypes } from '@/core/error/WsErrorTypes';
import { WsErrorCodes } from '@/core/error/WsErrorCodes';
import { IHttpClient } from '@bridge/IHttpClient';
import { IAuthInfoResponse } from '@/core/wsbroker/types';
import { IDevice } from '@bridge/IDevice';
import { ICobranding } from '@bridge/ICobranding';
import { ISessionManager } from '@bridge/ISessionManager';
import { ILogger } from '@bridge/ILogger';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import { IMetrics } from '@bridge/IMetrics';
import { IRegion } from '@bridge/types/RegionTypes';

export class Customization {
  private readonly cobranding: ICobranding;
  private readonly device: IDevice;
  private readonly httpClient: IHttpClient<IAuthInfoResponse>;
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly wsBrokerClient: WSBrokerService;
  private readonly metrics: IMetrics;

  constructor(
    httpClient: IHttpClient<IAuthInfoResponse>,
    device: IDevice,
    cobranding: ICobranding,
    sessionManager: ISessionManager,
    logger: ILogger,
    wsBrokerClient: WSBrokerService,
    metrics: IMetrics
  ) {
    this.httpClient = httpClient;
    this.device = device;
    this.cobranding = cobranding;
    this.sessionManager = sessionManager;
    this.logger = logger;
    this.wsBrokerClient = wsBrokerClient;
    this.metrics = metrics;
  }

  async fetchBrandingAssets(organizationName: string) {
    this.validate();
    const region = this.sessionManager.get('region') as IRegion;
    this.logger.info(
      `Retrieving branding assets for ${organizationName} in region:${region.endpoint}`
    );
    return await this.cobranding.fetchCobrandingAssets(
      region,
      organizationName
    );
  }

  // At this point we must have valid regCode
  private validate() {
    if (!this.sessionManager.get('region')) {
      this.logger.fatal('Missing region for registration');
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_INVALID_REG_CODE
      );
    }
  }
}
