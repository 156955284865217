import { LngOptions } from '.';
import {
  AWSCShortbreadMap,
  AWSCShortbreadOptions,
  WarpDriveLngOptions,
  WarpDriveLocaleMap,
} from './constants';

const mapToWarpDriveLocale = (locale: string) =>
  WarpDriveLocaleMap[locale as LngOptions] ?? WarpDriveLngOptions.en;

const mapToAWSCShortbreadLocale = (locale: string) =>
  AWSCShortbreadMap[locale as LngOptions] ?? AWSCShortbreadOptions.en;

export const I18nUtils = {
  mapToWarpDriveLocale,
  mapToAWSCShortbreadLocale,
};
