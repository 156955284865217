import { useTranslation } from 'react-i18next';
import { I18nError } from '@/lib/I18nError';
import styles from './styles.module.scss';
import CentralizedLayout from '@/layout/CentralizedLayout';
import * as React from 'react';
import Modal from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
interface ErrorDetailsProps {
  error: I18nError;
  onClose: () => void;
  showLoginPageRedirect: boolean;
  goToLoginPage: () => void;
}
const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  error,
  onClose,
  showLoginPageRedirect,
  goToLoginPage,
}) => {
  const { t } = useTranslation();

  return (
    <CentralizedLayout>
      <Modal
        onDismiss={({ detail }) => {
          detail.reason === 'closeButton' && onClose();
        }}
        visible={true}
        closeAriaLabel={t('ws-close-label')}
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {!showLoginPageRedirect && (
                <Button
                  data-testid="close-button"
                  variant="primary"
                  onClick={onClose}
                >
                  {t('ws-new-reg-code')}
                </Button>
              )}
              {showLoginPageRedirect && (
                <Button
                  data-testid="retry-sign-in-button"
                  variant="primary"
                  onClick={goToLoginPage}
                >
                  {t('ws-back-login')}
                </Button>
              )}
            </SpaceBetween>
          </Box>
        }
        header={<h1 className={styles['error-title']}>{error.type}</h1>}
      >
        {error.localeKeys.map((localeKey) => (
          <p key={localeKey} className={styles['error-detail']}>
            {t(localeKey, error.localeData?.[localeKey])}
          </p>
        ))}
        <p className={styles['error-code']}>{error.code}</p>
      </Modal>
    </CentralizedLayout>
  );
};
export default ErrorDetails;
