import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { I18nError } from '@/lib/I18nError';
import { WsError } from '@core/error/WsError';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { AppConstants } from '@bridge/constants/AppConstants';

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const { state: rawError } = useLocation();
  const [searchParams] = useSearchParams();

  const clientErrorCodeAsString = searchParams.get(
    AppConstants.ERROR_URL_CLIENT_ERROR_CODE_SEARCH_PARAM_KEY
  );
  const soloErrorCodeAsString = searchParams.get(
    AppConstants.ERROR_URL_SOLO_ERROR_CODE_SEARCH_PARAM_KEY
  );

  const generateWsError = (error: unknown) => {
    let tempError = error;
    if (!tempError && clientErrorCodeAsString && soloErrorCodeAsString) {
      tempError = WsError.buildErrorFromClientErrorCodes(
        clientErrorCodeAsString,
        soloErrorCodeAsString
      );
    }
    return tempError;
  };
  const generateI18nError = (rawError: unknown) => {
    const tempError = generateWsError(rawError);
    return tempError ? I18nError.getI18nError(tempError) : null;
  };

  const wsError = useMemo(() => generateWsError(rawError), [rawError]);
  const error = useMemo(() => generateI18nError(rawError), [rawError]);
  const isExternalAuthRedirectError = useMemo(() => {
    return !!soloErrorCodeAsString && SoloNativeRTCChannel.isChannelAvailable();
  }, [soloErrorCodeAsString]);

  const showError = (
    rawError: unknown,
    localeData?: Record<string, Record<string, string>>
  ): void => {
    navigate('/error', { state: I18nError.getI18nError(rawError, localeData) });
  };

  return {
    showError,
    error,
    wsError,
    isExternalAuthRedirectError,
  };
};
