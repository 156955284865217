import { ILogger } from '@bridge/ILogger';
import { IDevice } from '@bridge/IDevice';
import {
  PlatformType,
  StreamContextResponse,
} from '@bridge/types/SoloRTCChannelTypes';
import {
  EndpointTransports,
  ExtendedDcvEndpoint,
  IAllocateResourceSuccessResponse,
  IpVersion,
  LoginTypes,
  WsBrokerLoginType,
} from '@core/wsbroker/types';
import { compare } from 'compare-versions';
import { sessionManager } from '@/stores/session';

/*
 * This class is used to generate DCV command args to be sent to SoloClient to start Maxibon workspace streaming.
 * Currently we only support Linux Solo Client.
 * */
export class MaxibonStreamInputGenerator {
  private readonly logger: ILogger;
  private readonly device: IDevice;
  private readonly MINIMUM_CLIENT_VERSION_SUPPORTING_EXTENDED_DCV_GATEWAYS =
    new Map<PlatformType, string>([[PlatformType.LINUX, '2024.4']]);

  constructor(logger: ILogger, device: IDevice) {
    this.logger = logger;
    this.device = device;
  }

  generateWspNativeClientStreamInputs(
    allocatedResource: any,
    streamContext: StreamContextResponse
  ): string {
    this.logger.info(
      `Generating streaming inputs for DCV protocol on platform version ${this.device.getPlatformVersion()} and client version ${this.device.getProductVersion()}`
    );

    const resource = allocatedResource as IAllocateResourceSuccessResponse;
    const authBlob = this.extractAuthBlobForWSPResource(
      resource,
      streamContext
    );

    // Generating legacy gateway endPoint to send this as part of dcv launch command for latest client also.
    const gateway = this.extractGatewayEndpointForWSPResource(resource);

    if (this.isExtendedDcvEndpointsSupported()) {
      this.logger.info(
        `Generating streaming inputs with multiple streaming gateways enabled`
      );
      return `--standalone --auth-token ${authBlob} --extended-dcv-endpoints ${resource?.ActionResult?.ResultValues?.extendedDcvEndpoints} ${gateway.url.href}`;
    } else {
      this.logger.info(
        `Generating streaming inputs with multiple streaming gateways disabled`
      );

      const transportArg =
        gateway.protocol === EndpointTransports.TCP
          ? '--transport websocket'
          : '';

      return `--standalone ${transportArg} --auth-token ${authBlob} ${gateway.url.href}`;
    }
  }

  private extractAuthBlobForWSPResource(
    allocatedResource: IAllocateResourceSuccessResponse,
    streamContext: StreamContextResponse
  ): string {
    // In case of IDC auth, broker returns authBlob to be used in allocResource response.
    this.logger.info(
      `Generating streaming token for ${allocatedResource.ActionResult.ResultValues.loginType} login and sessionId ${allocatedResource.ActionResult.ResultValues.sessionId}`
    );
    if (sessionManager.isUseBrokerProtocolV2()) {
      // For Pwd ReArch flow, authBlob will be fetched from session storage.
      return sessionManager.get('authBlob');
    }
    if (
      allocatedResource.ActionResult.ResultValues.loginType ===
      WsBrokerLoginType.FixedAuthBlob
    ) {
      return btoa(allocatedResource.ActionResult.ResultValues.authBlob);
    } else if (
      allocatedResource.ActionResult.ResultValues.loginType ===
      WsBrokerLoginType.VirtualSmartCardCertificate
    ) {
      const authBlob = allocatedResource.ActionResult.ResultValues.authBlob;
      const certBlob = JSON.parse(authBlob);
      const intermediateAuthBlob = JSON.stringify({
        certBlob,
        loginType: LoginTypes.SEAMLESS,
        highlanderPCACert: '.',
        highlanderPCACRL: '.',
        keyBlob: '',
      });

      return btoa(intermediateAuthBlob);
    } else {
      /*
       For other cases, solo generates authBlob by encrypting authBlob from allocResResp with user creds
       captured during warpDrive auth.
      */
      return streamContext.StreamAttributes.AuthBlob as string;
    }
  }

  private extractGatewayEndpointForWSPResource(
    resource: IAllocateResourceSuccessResponse
  ) {
    const extendedDcvEndPoints = JSON.parse(
      resource?.ActionResult?.ResultValues?.extendedDcvEndpoints
    ) as ExtendedDcvEndpoint[];

    let forceTcp = true;

    // Identify transport protocol based on brokers preference. Broker returns only TCP in case it enforces TCP only
    extendedDcvEndPoints?.forEach((extendedDcvEndpoint) => {
      if (
        extendedDcvEndpoint?.transport === EndpointTransports.QUIC &&
        extendedDcvEndpoint?.ipVersion === IpVersion.IPv4
      ) {
        forceTcp = false;
      }
    });

    const transportTypeToExtract = forceTcp
      ? EndpointTransports.TCP
      : EndpointTransports.QUIC;

    this.logger.info(
      `Retrieving streaming gateway endpoint for ${transportTypeToExtract} protocol`
    );
    let transportUrl;
    let transportPort = '';

    /* Once transportProtocol to be used is identified, pick the first url that matches. Broker sends endpoints based on
       it priority
       ToDo: Once DCV SDK supports intake of multiple DCV endpoints, do not parse the end points and send them directly to dcv.
    */
    for (const extendedDcvEndpoint of extendedDcvEndPoints) {
      if (
        extendedDcvEndpoint?.transport === transportTypeToExtract &&
        extendedDcvEndpoint?.ipVersion === IpVersion.IPv4
      ) {
        transportUrl = `${extendedDcvEndpoint?.hostname}?gatewayToken=${extendedDcvEndpoint?.gatewayAuthToken}`;
        transportPort = extendedDcvEndpoint?.port;
        break;
      }
    }

    const url = new URL(`dcv://${transportUrl}`);
    url.port = transportPort;
    url.hash = `#${resource?.ActionResult?.ResultValues?.sessionId}`;

    return {
      url,
      protocol: transportTypeToExtract,
    };
  }

  private isExtendedDcvEndpointsSupported() {
    const currentClientVersion = this.device.getProductVersion();
    let minimumSupportedClientVersion;
    if (this.device.getPlatform()) {
      minimumSupportedClientVersion =
        this.MINIMUM_CLIENT_VERSION_SUPPORTING_EXTENDED_DCV_GATEWAYS.get(
          this.device.getPlatform() as PlatformType
        );
    }
    this.logger.info(
      `Checking support for multiple gateways for Platform ${this.device.getPlatform()} and app version ${currentClientVersion}`
    );
    if (currentClientVersion && minimumSupportedClientVersion) {
      if (compare(currentClientVersion, minimumSupportedClientVersion, '>=')) {
        return true;
      }
    }
    return false;
  }
}
