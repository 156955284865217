import ActionGroup, { ActionProps } from '@/components/ActionGroup';
import { SoloNativeRTCChannel } from '@/core/rtcChannel/SoloNativeRTCChannel';
import { useCustomTranslation } from '@/i18n';

interface LoginComponentProps {
  onClickLogin: () => void;
  onChangeRegistrationCode: () => void;
  isShowRememberMe: boolean;
  isRememberMe: boolean;
  onIsRememberMeChecked: (checked: boolean) => void;
  isPreparing: boolean;
  isLeaving: boolean;
  isWarpDrive: boolean;
}

const LoginComponent: React.FC<LoginComponentProps> = ({
  onClickLogin,
  onChangeRegistrationCode,
  isShowRememberMe,
  isRememberMe,
  onIsRememberMeChecked,
  isPreparing,
  isLeaving,
  isWarpDrive,
}) => {
  // i18n
  const { t } = useCustomTranslation();
  const actions: ActionProps[] = [
    {
      id: 'login-button',
      onClick: onClickLogin,
      label: t('ws-login'),
      isLoading: isLeaving,
    },
    {
      id: 'register-button',
      onClick: onChangeRegistrationCode,
      label: t('ws-new-reg-code'),
      isLoading:
        isLeaving &&
        (!SoloNativeRTCChannel.isChannelAvailable() || isWarpDrive),
    },
  ];

  return (
    <ActionGroup
      title={t('ws-login')}
      titleDescription={
        !SoloNativeRTCChannel.isChannelAvailable() || isWarpDrive
          ? t('ws-login-description')
          : t('ws-solo-saml-login-desc')
      }
      isLoading={isPreparing}
      checkbox={
        isShowRememberMe
          ? {
              label: t('ws-remember-me'),
              checked: isRememberMe,
              onChange: onIsRememberMeChecked,
            }
          : undefined
      }
      actions={actions}
    />
  );
};

export default LoginComponent;
