import create from 'zustand';
import { persist } from 'zustand/middleware';

const LOG_UPLOADER_STORE = 'logUploaderStore';

interface LogUploaderPreferencesState {
  isLoggingEnabledByUser: boolean;
  logLevelPreference: string;
}

interface LogPreferencesAction {
  updateIsLoggingEnabledByUser: (
    loggingEnabledState: LogUploaderPreferencesState['isLoggingEnabledByUser']
  ) => void;
  updateLogLevelPreference: (
    logLevelPreferenceState: LogUploaderPreferencesState['logLevelPreference']
  ) => void;
}

export const useLogUploaderPreferenceStore = create(
  persist<LogUploaderPreferencesState & LogPreferencesAction>(
    (set) => ({
      isLoggingEnabledByUser: true,
      logLevelPreference: 'INFO',
      updateIsLoggingEnabledByUser: (isLoggingEnabledByUser) =>
        set(() => ({ isLoggingEnabledByUser })),
      updateLogLevelPreference: (logLevelPreference) =>
        set(() => ({ logLevelPreference })),
    }),
    {
      name: LOG_UPLOADER_STORE,
    }
  )
);
