import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { MaxibonInSessionMetricsConfigInput } from '@bridge/types/MetricTypes';
import { EndPointType, IRegion } from '@bridge/types/RegionTypes';
import { DEFAULT_METRIC_PUBLISH_ENDPOINT } from '@bridge/constants/RegionConstants';
import { MetricConstants } from '@core/constants/MetricConstants';

const logger = CoreFactory.getLogger();
const device = CoreFactory.getDevice();
const sessionManager = CoreFactory.getSessionManager();

const getPublisherEndPointUrl = (region: IRegion | undefined): string => {
  const preferredEndpointType =
    sessionManager.get('httpsSettings')?.brokerEndpointType;

  const baseUrl =
    (preferredEndpointType === EndPointType.IPv6
      ? region?.metricEndpoint?.find((ep) => ep.type === EndPointType.IPv6)?.url
      : region?.metricEndpoint?.find((ep) => ep.type === EndPointType.IPv4)
          ?.url) ?? DEFAULT_METRIC_PUBLISH_ENDPOINT;

  return baseUrl + MetricConstants.BROWSER_METRICS_REQUEST_API_URL_PATH;
};

export const buildMaxibonInSessionMetricsConfigInput =
  (): MaxibonInSessionMetricsConfigInput => {
    return {
      platformAppName: device.getPlatformAppName(),
      region: sessionManager.get('region')?.endpoint ?? '',
      endPoint: getPublisherEndPointUrl(sessionManager.get('region')),
      productVersion: device.getProductVersion() as string,
      deviceUUID: device.getDeviceUUID() as string,
      hostOS: device.getHostOS() as string,
      hostOSVersion: device.getHostOSVersion() as string,
      proxyType: device.getProxyType(),
      is64BitClient: device.is64BitClient(),
      sessionId: sessionManager.get('sessionContext')?.SessionId ?? '',
      orgName: sessionManager.get('domainProperties')?.OrganizationName ?? '',
      protocolName: sessionManager.get('resourceProtocol'),
      directoryId: sessionManager.get('directoryId'),
      resourceId: sessionManager.get('resourceId'),
      loginType: sessionManager.get('loginType'),
      resourceType: sessionManager.get('resourceType') ?? '',
      authProvider: sessionManager.get('primaryAuthMethod')?.AuthProvider ?? '',
      // ToDo: Fix publishing of Session metric. Refer: https://t.corp.amazon.com/P156343027/communication
      /*
      sessionTimedMetricSummary: metrics
        .embarkSessionMetric()
        .getTimedMetricOperationSummary(),
      */
    };
  };

export const isFullScreen = (): boolean => {
  // remember to change this to add more browsers :)
  // good thing that this is encapsulated in the streaming-service
  // TODO: try if document.fullscreenElement !== null or document.fullscreenEnabled
  const doc = document as any;
  return (
    (doc.webkitFullscreenElement as boolean) ||
    (doc.mozFullScreenElement as boolean)
  );
};

interface WorkArea {
  width: number;
  height: number;
}

type Dimension = 'Width' | 'Height';

const isZeroOrNaN = (num: number): boolean => num === 0 || isNaN(num);
const getDimension = (dimension: Dimension): number => {
  if (!isZeroOrNaN(document.documentElement[`client${dimension}`])) {
    return document.documentElement[`client${dimension}`];
  }

  if (!isZeroOrNaN(document.body[`client${dimension}`])) {
    return document.body[`client${dimension}`];
  }

  if (!isZeroOrNaN(document.body[`offset${dimension}`])) {
    return document.body[`offset${dimension}`];
  }

  return 0;
};

export const getAvailableWorkArea = (): WorkArea => {
  const fullScreen = isFullScreen();
  const workArea = fullScreen
    ? {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    : {
        width: getDimension('Width'),
        height: getDimension('Height'),
      };
  logger.info(
    `available work area: ${JSON.stringify(workArea)}, fullScreen: ${
      fullScreen ? 'yes' : 'no'
    }`
  );

  return workArea;
};

// scan codes as documented in http://www.usb.org/developers/hidpage/Hut1_12v2.pdf section 10
// for the actual input driver mapping see  ~/Stxhd-HostAgents-InputInjectionAgent/.../DriverKeyCodes.cs#L121
export const getScancode = (e: any) => {
  let scancode = 0x0000;

  switch (e.code) {
    case 'Escape':
      scancode = 0x0001;
      break;
    case 'F1':
      scancode = 0x003b;
      break;
    case 'F2':
      scancode = 0x003c;
      break;
    case 'F3':
      scancode = 0x003d;
      break;
    case 'F4':
      scancode = 0x003e;
      break;
    case 'F5':
      scancode = 0x003f;
      break;
    case 'F6':
      scancode = 0x0040;
      break;
    case 'F7':
      scancode = 0x0041;
      break;
    case 'F8':
      scancode = 0x0042;
      break;
    case 'F9':
      scancode = 0x0043;
      break;
    case 'F10':
      scancode = 0x0044;
      break;
    case 'F11':
      scancode = 0x0057;
      break;
    case 'F12':
      scancode = 0x0058;
      break;
    case 'F13': // FF & Chrome Mac report PrintScreen as F13
    case 'PrintScreen':
      scancode = 0xe037;
      break;
    case 'ScrollLock':
      scancode = 0x0046;
      break;
    case 'Pause':
      scancode = 0x0045;
      break;
    case 'Backquote':
      scancode = 0x0029;
      break;
    case 'Digit1':
      scancode = 0x0002;
      break;
    case 'Digit2':
      scancode = 0x0003;
      break;
    case 'Digit3':
      scancode = 0x0004;
      break;
    case 'Digit4':
      scancode = 0x0005;
      break;
    case 'Digit5':
      scancode = 0x0006;
      break;
    case 'Digit6':
      scancode = 0x0007;
      break;
    case 'Digit7':
      scancode = 0x0008;
      break;
    case 'Digit8':
      scancode = 0x0009;
      break;
    case 'Digit9':
      scancode = 0x000a;
      break;
    case 'Digit0':
      scancode = 0x000b;
      break;
    case 'Minus':
      scancode = 0x000c;
      break;
    case 'Equal':
      scancode = 0x000d;
      break;
    case 'Backspace':
      scancode = 0x000e;
      break;
    case 'Tab':
      scancode = 0x000f;
      break;
    case 'KeyQ':
      scancode = 0x0010;
      break;
    case 'KeyW':
      scancode = 0x0011;
      break;
    case 'KeyE':
      scancode = 0x0012;
      break;
    case 'KeyR':
      scancode = 0x0013;
      break;
    case 'KeyT':
      scancode = 0x0014;
      break;
    case 'KeyY':
      scancode = 0x0015;
      break;
    case 'KeyU':
      scancode = 0x0016;
      break;
    case 'KeyI':
      scancode = 0x0017;
      break;
    case 'KeyO':
      scancode = 0x0018;
      break;
    case 'KeyP':
      scancode = 0x0019;
      break;
    case 'BracketLeft':
      scancode = 0x001a;
      break;
    case 'BracketRight':
      scancode = 0x001b;
      break;
    case 'Enter':
      scancode = 0x001c;
      break;
    case 'Backslash':
      scancode = 0x002b;
      break;
    case 'CapsLock':
      scancode = 0x003a;
      break;
    case 'KeyA':
      scancode = 0x001e;
      break;
    case 'KeyS':
      scancode = 0x001f;
      break;
    case 'KeyD':
      scancode = 0x0020;
      break;
    case 'KeyF':
      scancode = 0x0021;
      break;
    case 'KeyG':
      scancode = 0x0022;
      break;
    case 'KeyH':
      scancode = 0x0023;
      break;
    case 'KeyJ':
      scancode = 0x0024;
      break;
    case 'KeyK':
      scancode = 0x0025;
      break;
    case 'KeyL':
      scancode = 0x0026;
      break;
    case 'Semicolon':
      scancode = 0x0027;
      break;
    case 'ShiftLeft':
      scancode = 0x002a;
      break;
    case 'KeyZ':
      scancode = 0x002c;
      break;
    case 'KeyX':
      scancode = 0x002d;
      break;
    case 'KeyC':
      scancode = 0x002e;
      break;
    case 'KeyV':
      scancode = 0x002f;
      break;
    case 'KeyB':
      scancode = 0x0030;
      break;
    case 'KeyN':
      scancode = 0x0031;
      break;
    case 'KeyM':
      scancode = 0x0032;
      break;
    case 'Comma':
      scancode = 0x0033;
      break;
    case 'Period':
      scancode = 0x0034;
      break;
    case 'Slash':
      scancode = 0x0035;
      break;
    case 'ShiftRight':
      scancode = 0x0036;
      break;
    case 'ControlLeft':
      scancode = 0x001d;
      break;
    case 'MetaLeft': // Chrome
    case 'OSLeft': // FF
      scancode = 0xe05b;
      break;
    case 'AltLeft':
      scancode = 0x0038;
      break;
    case 'Space':
      scancode = 0x0039;
      break;
    case 'AltRight':
      scancode = 0xe038;
      break;
    case 'MetaRight': // Chrome
    case 'OSRight': // FF
      scancode = 0xe05c;
      break;
    case 'ContextMenu':
      scancode = 0xe05d;
      break;
    case 'ControlRight':
      scancode = 0xe01d;
      break;
    case 'ArrowUp':
      scancode = 0xe048;
      break;
    case 'ArrowLeft':
      scancode = 0xe04b;
      break;
    case 'ArrowRight':
      scancode = 0xe04d;
      break;
    case 'ArrowDown':
      scancode = 0xe050;
      break;
    case 'NumLock':
      scancode = 0xe045;
      break;
    case 'NumpadDivide':
      scancode = 0xe035;
      break;
    case 'NumpadMultiply':
      scancode = 0x0037;
      break;
    case 'NumpadSubtract':
      scancode = 0x004a;
      break;
    case 'NumpadAdd':
      scancode = 0x004e;
      break;
    case 'NumpadEnter':
      scancode = 0xe01c;
      break;
    case 'Numpad7':
      scancode = 0x0047;
      break;
    case 'Numpad8':
      scancode = 0x0048;
      break;
    case 'Numpad9':
      scancode = 0x0049;
      break;
    case 'Numpad4':
      scancode = 0x004b;
      break;
    case 'Numpad5':
      scancode = 0x004c;
      break;
    case 'Numpad6':
      scancode = 0x004d;
      break;
    case 'Numpad1':
      scancode = 0x004f;
      break;
    case 'Numpad2':
      scancode = 0x0050;
      break;
    case 'Numpad3':
      scancode = 0x0051;
      break;
    case 'Numpad0':
      scancode = 0x0052;
      break;
    case 'NumpadDecimal':
      scancode = 0x0053;
      break;
    case 'Insert':
      scancode = 0xe052;
      break;
    case 'Home':
      scancode = 0xe047;
      break;
    case 'PageUp':
      scancode = 0xe049;
      break;
    case 'PageDown':
      scancode = 0xe051;
      break;
    case 'Delete':
      scancode = 0xe053;
      break;
    case 'End':
      scancode = 0xe04f;
      break;
    case 'Quote':
      scancode = 0x00028;
      break;
    case 'IntlYen':
      scancode = 0x7d;
      break;
    case 'IntlRo':
      scancode = 0x73;
      break;
    case 'IntlBackslash':
      // UK backslash key http://www.kbdlayout.info/KBDUK/scancodes
      scancode = 0x56;
      break;
  }

  return scancode;
};

const exitFullScreen = () => {
  const doc = document as any;
  if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  } else {
    doc.webkitCancelFullScreen();
  }
};

const enterFullScreen = (inputElement: HTMLDivElement) => {
  const videoContainer = inputElement as any;
  if (!videoContainer) return;

  if (videoContainer.requestFullscreen) {
    videoContainer.requestFullscreen();
  } else if (videoContainer.mozRequestFullScreen) {
    videoContainer.mozRequestFullScreen(); // Firefox
  } else if (videoContainer.webkitRequestFullscreen) {
    videoContainer.webkitRequestFullscreen(); // Chrome and Safari
  }
};

export const toggleFullScreen = (inputElement: HTMLDivElement) => {
  if (isFullScreen()) {
    exitFullScreen();
  } else {
    enterFullScreen(inputElement);
  }
};

export const getResizeWindowDimensions = () => {
  let workArea = getAvailableWorkArea();
  const doc = document as any;
  if (
    doc.webkitFullscreenElement ||
    doc.mozFullScreenElement ||
    doc.fullscreenElement
  ) {
    workArea = { width: window.screen.width, height: window.screen.height };
  }
  const width = workArea.width - (workArea.width % 4);
  const height = workArea.height - (workArea.height % 4);
  return {
    workArea,
    resizedWidth: width,
    resizedHeight: height,
  };
};
