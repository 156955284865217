import {
  ITimedMetricOperation,
  ITimedMetricOperationSummary,
  MetricName,
} from '@bridge/types/MetricTypes';

export class SessionTimedMetricTracker {
  private readonly sessionTimedMetricOperation: ITimedMetricOperation;

  constructor(timedMetricOperation: ITimedMetricOperation) {
    this.sessionTimedMetricOperation = timedMetricOperation;
    this.sessionTimedMetricOperation.metricName = MetricName.LoginTime;
  }

  start(isReconnect: boolean) {
    this.sessionTimedMetricOperation.dimensions.Reconnect = String(isReconnect);
    this.sessionTimedMetricOperation.startTimer();
  }

  updateMetricName(includeStartTime: boolean) {
    if (includeStartTime) {
      this.sessionTimedMetricOperation.metricName =
        MetricName.LoginWithStartTime;
    }
  }

  getTimedMetricOperation() {
    return this.sessionTimedMetricOperation;
  }

  getTimedMetricOperationSummary(): ITimedMetricOperationSummary {
    return {
      operation: this.sessionTimedMetricOperation.operation,
      metricName: this.sessionTimedMetricOperation.metricName,
      dimensions: this.sessionTimedMetricOperation.dimensions,
      result: this.sessionTimedMetricOperation.result,
      startTime: this.sessionTimedMetricOperation.getStartTime(),
    };
  }
}
