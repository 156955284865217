export enum WsErrorTypes {
  ERROR_TYPE_NEGOTIATION = 'ERROR_TYPE_NEGOTIATION',
  ERROR_TYPE_REGISTRATION = 'ERROR_TYPE_REGISTRATION',
  ERROR_TYPE_CUSTOMIZATION = 'ERROR_TYPE_CUSTOMIZATION',
  ERROR_TYPE_AUTHENTICATION = 'ERROR_TYPE_AUTHENTICATION',
  ERROR_TYPE_WARPDRIVE = 'ERROR_TYPE_WARPDRIVE',
  ERROR_TYPE_CONNECTION = 'ERROR_TYPE_CONNECTION',
  ERROR_TYPE_STREAMING = 'ERROR_TYPE_STREAMING',
  ERROR_TYPE_GETRESOURCES = 'ERROR_TYPE_GETRESOURCES',
  ERROR_TYPE_ALLOCATE_RESOURCE = 'ERROR_TYPE_ALLOCATE_RESOURCE',
  ERROR_TYPE_SELF_SERVICE_ACTIONS = 'ERROR_TYPE_SELF_SERVICE_ACTIONS',
  ERROR_TYPE_SESSION_PROVISION = 'ERROR_TYPE_SESSION_PROVISION',
  ERROR_TYPE_UNSUPPORTED_PLATFORM = 'ERROR_TYPE_UNSUPPORTED_PLATFORM',
  ERROR_TYPE_WSP = 'ERROR_TYPE_WSP',
  ERROR_TYPE_UNKNOWN = 'ERROR_TYPE_UNKNOWN',
  ERROR_TYPE_UNSUPPORTED_PROTOCOL = 'ERROR_TYPE_UNSUPPORTED_PROTOCOL',
  ERROR_TYPE_HEARTBEAT = 'ERROR_TYPE_HEARTBEAT',
  ERROR_TYPE_GETAUTHINFO = 'ERROR_TYPE_GETAUTHINFO',
}
