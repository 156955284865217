import { WsError } from '@core/error/WsError';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';

/*
 * Use this class for all new errors to remove dependence on WsError
 * WsError depends on WsErrorType and WsErrorCode which are part if legacy code
 * Client text messages will now depend on ClientErrorCode. When using this class
 * ensure ClientErrorCode used has correct translation in I18nError file
 * * */
export class ClientError extends WsError {
  constructor(clientErrorCode: ClientErrorCode, clientErrorMessage?: string) {
    super(
      WsErrorTypes.ERROR_TYPE_UNKNOWN,
      WsErrorCodes.ERROR_UNKNOWN,
      clientErrorCode,
      clientErrorMessage,
      {
        useClientErrorCodeForLocalesOnly: true,
      }
    );
  }
}
