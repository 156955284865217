import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { ISessionManager } from '@bridge/ISessionManager';
import {
  Action,
  ActionType,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';

export class StreamResponseHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly sessionManager: ISessionManager;

  constructor(
    logger: ILogger,
    metrics: IMetrics,
    sessionManager: ISessionManager
  ) {
    this.logger = logger;
    this.metrics = metrics;
    this.sessionManager = sessionManager;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    try {
      this.validate(msg);
      const payload = msg?.Payload;
      if (payload && 'ErrorCode' in payload) {
        return this.handleErrorResponse(payload);
      }
      this.publishMetrics();
      callback?.(payload);
    } catch (error) {
      this.logger.error(
        'Failed to handle RTC Stream response from native client'
      );
      this.publishMetrics(error);
    }
  }

  private publishMetrics(error?: any) {
    this.metrics.emit(Operation.Streaming, MetricResult.Fault, error);

    // Streaming Response is emitted right after streaming has started. Emit Session metric for LoginTime
    const sessionTimedMetricTracker = this.metrics.embarkSessionMetric();
    const sessionTimedMetric =
      sessionTimedMetricTracker.getTimedMetricOperation();
    this.metrics.emitMetricOperation(sessionTimedMetric, error);
  }

  private validate(msg: RTCChannelMessage) {
    this.logger.info(
      `Received Stream response details from Solo ${JSON.stringify(msg)}`
    );
    if (
      msg?.Action !== Action.STREAM ||
      msg?.ActionType !== ActionType.RESPONSE
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_CONNECTION,
        WsErrorCodes.ERROR_INVALID_INPUT,
        ClientErrorCode.StreamResponseInvalidInput
      );
    }
  }

  private handleErrorResponse(response: any) {
    this.logger.error(
      `Failed to get Streaming details from native client ${JSON.stringify(
        response
      )}`
    );
    const error = new WsError(
      WsErrorTypes.ERROR_TYPE_CONNECTION,
      WsErrorCodes.ERROR_INVALID_INPUT,
      ClientErrorCode.StreamResponseUnKnown
    );
    error.name = response?.ErrorCode;
    error.message = response?.ErrorMessage;
    this.publishMetrics(error);
  }
}
