import { ILogger } from '@bridge/ILogger';
import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionType,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { IMetrics } from '@/bridge/IMetrics';
import { MetricResult, Operation } from '@/bridge/types/MetricTypes';

export class DisplayWarpDriveUrlHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;

  constructor(logger: ILogger, metrics: IMetrics) {
    this.logger = logger;
    this.metrics = metrics;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    if (!this.validate(msg)) {
      return;
    }
    try {
      const payload = msg.Payload;
      this.metrics.emit(Operation.DisplayWarpDriveURL, MetricResult.Success);
      callback?.(payload);
    } catch (error: any) {
      this.logger.error(
        `Sending WD URL to Solo client failed: ${JSON.stringify(msg)}`
      );
      this.metrics.emit(
        Operation.DisplayWarpDriveURL,
        MetricResult.Error,
        error
      );
    }
  }

  private validate(msg: RTCChannelMessage): boolean {
    this.logger.info(
      `Sending WD URL to Solo client that's run in Embedded Mode ${JSON.stringify(
        msg
      )}`
    );

    if (
      msg.Action === Action.DISPLAY_WARPDRIVE_URL &&
      msg.ActionType === ActionType.REQUEST
    ) {
      return true;
    }
    return false;
  }
}
