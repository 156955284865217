import { LngOptions } from '.';

export enum WarpDriveLngOptions {
  en = 'en',
  fr = 'fr',
  de = 'de',
  es = 'es',
  ja = 'ja',
  ko = 'ko',
  pt_BR = 'pt_BR',
  zh_CN = 'zh_CN',
}

export type IWarpDriveLocaleMap = {
  [key in LngOptions]: WarpDriveLngOptions;
};

export const WarpDriveLocaleMap: IWarpDriveLocaleMap = {
  [LngOptions.EN_US]: WarpDriveLngOptions.en,
  [LngOptions.FR_FR]: WarpDriveLngOptions.fr,
  [LngOptions.DE_DE]: WarpDriveLngOptions.de,
  [LngOptions.ES_ES]: WarpDriveLngOptions.es,
  [LngOptions.JA_JP]: WarpDriveLngOptions.ja,
  [LngOptions.KO_KR]: WarpDriveLngOptions.ko,
  [LngOptions.PT_BR]: WarpDriveLngOptions.pt_BR,
  [LngOptions.ZH_CN]: WarpDriveLngOptions.zh_CN,
};

export type IAWSCShortbreadMap = {
  [key in LngOptions]: AWSCShortbreadOptions;
};

export enum AWSCShortbreadOptions {
  en = 'en_US',
  fr = 'fr_FR',
  de = 'de_DE',
  es = 'es_ES',
  ja = 'ja_JP',
  ko = 'ko_KR',
  pt_BR = 'pt_BR',
  zh_CN = 'zh_CN',
}

export const AWSCShortbreadMap: IAWSCShortbreadMap = {
  [LngOptions.EN_US]: AWSCShortbreadOptions.en,
  [LngOptions.FR_FR]: AWSCShortbreadOptions.fr,
  [LngOptions.DE_DE]: AWSCShortbreadOptions.de,
  [LngOptions.ES_ES]: AWSCShortbreadOptions.es,
  [LngOptions.JA_JP]: AWSCShortbreadOptions.ja,
  [LngOptions.KO_KR]: AWSCShortbreadOptions.ko,
  [LngOptions.PT_BR]: AWSCShortbreadOptions.pt_BR,
  [LngOptions.ZH_CN]: AWSCShortbreadOptions.zh_CN,
};
