import { IActionHandler } from '@core/rtcChannel/handlers/IActionHandler';
import {
  Action,
  ActionType,
  OnSuccessRTCCallback,
  RTCChannelMessage,
} from '@bridge/types/SoloRTCChannelTypes';
import { ILogger } from '@bridge/ILogger';
import { IMetrics } from '@bridge/IMetrics';
import { ISessionManager } from '@bridge/ISessionManager';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { WsError } from '@core/error/WsError';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { WsErrorTypes } from '@core/error/WsErrorTypes';

export class StreamContextHandler implements IActionHandler {
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly sessionManager: ISessionManager;

  constructor(
    logger: ILogger,
    metrics: IMetrics,
    sessionManager: ISessionManager
  ) {
    this.logger = logger;
    this.metrics = metrics;
    this.sessionManager = sessionManager;
  }

  handleMessage(msg: RTCChannelMessage, callback?: OnSuccessRTCCallback): void {
    try {
      this.validate(msg);
      const payload = msg.Payload;
      if ('ErrorCode' in payload) {
        return this.handleErrorResponse(payload);
      }
      callback?.(payload);
    } catch (error) {
      this.logger.error(
        'Error parsing Stream context response from native client'
      );
      this.metrics.emit(
        Operation.StreamContextRequest,
        MetricResult.Fault,
        error
      );
    }
  }

  private validate(msg: RTCChannelMessage) {
    this.logger.info(
      `Received Stream context details from Solo ${JSON.stringify(msg)}`
    );
    const response = msg?.Payload;
    if (
      msg.Action !== Action.STREAM_CONTEXT ||
      msg.ActionType !== ActionType.RESPONSE ||
      !('ErrorCode' in response || 'StreamAttributes' in response)
    ) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_CONNECTION,
        WsErrorCodes.ERROR_INVALID_INPUT,
        ClientErrorCode.StreamContextInvalidInput
      );
    }
  }

  private handleErrorResponse(response: any) {
    this.logger.error(`Received error for stream context from native client`);
    const error = new WsError(
      WsErrorTypes.ERROR_TYPE_CONNECTION,
      WsErrorCodes.ERROR_INVALID_INPUT,
      ClientErrorCode.StreamContextUnKnown
    );
    error.name = response?.ErrorCode;
    error.message = response?.ErrorMessage;
    this.metrics.emit(
      Operation.StreamContextRequest,
      MetricResult.Fault,
      error
    );
  }
}
