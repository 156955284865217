import { ILogger } from '@/bridge/ILogger';
import { ISessionManager } from '@/bridge/ISessionManager';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { IMetrics } from '@/bridge/IMetrics';
import { Operation } from '@/bridge/types/MetricTypes';
import { IHttpClient } from '@/bridge/IHttpClient';
import {
  ReportHearbeatRequest,
  ReportHearbeatResponse,
} from '@/bridge/types/HeartbeatTypes';
import { HttpClientConfig, HttpRequestHeader } from '@/core/http/HttpClient';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { generateUUID } from '@/bridge/utility';
import { IHeartBeat } from '@/bridge/IHeartBeat';
import { IRegion } from '@bridge/types/RegionTypes';

const REPORT_HEARTBEAT_TIMEOUT = 10_000;

export class HeartBeat implements IHeartBeat {
  private readonly wsBrokerClient: WSBrokerService;
  private readonly sessionManager: ISessionManager;
  private readonly logger: ILogger;
  private readonly metrics: IMetrics;
  private readonly httpClient: IHttpClient<unknown>;

  constructor(
    wsBrokerClient: WSBrokerService,
    sessionManager: ISessionManager,
    httpClient: IHttpClient<unknown>,
    logger: ILogger,
    metrics: IMetrics
  ) {
    this.sessionManager = sessionManager;
    this.wsBrokerClient = wsBrokerClient;
    this.logger = logger;
    this.metrics = metrics;
    this.httpClient = httpClient;
  }

  async getHeartBeatInfo(regCode?: string, region?: IRegion) {
    const heartbeatMetrics = this.metrics.embark(Operation.Heartbeat);
    if (!regCode || !region) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_HEARTBEAT,
        WsErrorCodes.ERROR_INVALID_REG_CODE
      );
    }
    const sessionContext = this.sessionManager.get('sessionContext');
    const sessionId = sessionContext?.SessionId as string;
    const authToken = this.sessionManager.get('authToken')?.Value as string;
    try {
      const response = await this.wsBrokerClient.getHeartBeatInfo(
        authToken,
        sessionId,
        regCode,
        region
      );
      this.logger.info(`Retrieved heartbeat info for ${regCode}`);
      // Update sessionInfo before emitting metrics
      this.sessionManager.set({
        sessionContext: response.SessionContext,
        heartBeatEndpoint: response.heartBeatEndpoint,
        heartBeatSessionId: response.heartBeatSessionId,
        HeartBeatContext: response.HeartBeatContext,
      });
      return response;
    } catch (error) {
      this.logger.error(
        `Retrieved heart beat info for ${regCode} and the error is ${error}`
      );
      this.metrics.emitMetricOperation(heartbeatMetrics, error);
      if (error instanceof WsError) {
        throw error;
      } else {
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_HEARTBEAT,
          WsErrorCodes.ERROR_CM_REQUEST_FAILED
        );
      }
    }
  }

  async reportHeartBeat(
    heartBeatEndpoint: string
  ): Promise<ReportHearbeatResponse> {
    const heartbeatSessionId = this.sessionManager.get(
      'heartBeatSessionId'
    ) as string;
    const heartbeatSessionToken = this.sessionManager.get('sessionContext')
      ?.AuthToken?.Value as string;
    const heartbeatMetrics = this.metrics.embark(Operation.Heartbeat);
    const reportHeartbeatBody: ReportHearbeatRequest = {
      sessionId: heartbeatSessionId,
      sessionToken: heartbeatSessionToken,
    };

    const amznRequestId = generateUUID();
    const reportHeartBeatHeader: HttpRequestHeader = {
      Accept: 'application/json, text/javascript, */*',
      'x-amzn-RequestId': amznRequestId,
      Connection: 'Keep-Alive',
      'User-Agent': 'command/euc-sso-sm.report-heart-beat',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-amz-json-1.1',
    };

    const heartbeatHttpConfig: HttpClientConfig = {
      headers: reportHeartBeatHeader,
      timeout: REPORT_HEARTBEAT_TIMEOUT,
    };

    this.logger.info(`Reporting heartbeat. amzn request id ${amznRequestId}`);
    try {
      return await this.httpClient
        .post(heartBeatEndpoint, reportHeartbeatBody, heartbeatHttpConfig)
        .then(({ data, status }) => {
          if (status === 200) {
            return data as ReportHearbeatResponse;
          } else if (
            status === 404 ||
            status === 500 ||
            status === 503 ||
            status === 504
          ) {
            this.logger.info(
              `Heartbeat request ${heartbeatSessionId} failed possibly because of a retriable issue ${status}, retrying...`
            );
            throw new WsError(
              WsErrorTypes.ERROR_TYPE_HEARTBEAT,
              WsErrorCodes.ERROR_HEARTBEAT_RETRIABLE,
              ClientErrorCode.ReportHeartbeatRetriableError,
              `Heartbeat failed due to issue of status code ${status}`
            );
          } else {
            this.logger.info(
              `Heartbeat request ${heartbeatSessionId} failed because of a non-retriable issue ${status} .`
            );
            throw new WsError(
              WsErrorTypes.ERROR_TYPE_HEARTBEAT,
              WsErrorCodes.ERROR_CM_REQUEST_FAILED,
              ClientErrorCode.ReportHeartbeatGenericError,
              `Heartbeat failed due to issue of status code ${status}`
            );
          }
        });
    } catch (error: any) {
      this.logger.error(`Reporting heartbeat failed and the error is ${error}`);
      this.metrics.emitMetricOperation(heartbeatMetrics, error);
      if (error.code === 'ECONNABORTED') {
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_HEARTBEAT,
          WsErrorCodes.ERROR_REQUEST_TIMEOUT,
          ClientErrorCode.ReportHeartbeatRequestTimeout
        );
      } else if (error instanceof WsError) {
        throw error;
      } else {
        throw new WsError(
          WsErrorTypes.ERROR_TYPE_HEARTBEAT,
          WsErrorCodes.ERROR_CM_REQUEST_FAILED
        );
      }
    }
  }
}
