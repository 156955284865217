import { WebViewPlatformDevice } from '@/core/device/WebViewPlatformDevice';
import { IDevice } from '@bridge/IDevice';
import { ICoreFactory } from './ICoreFactory';
import { BaseCoreFactory } from './BaseCoreFactory';
import { IRTCChannel } from '@/bridge/IRTCChannel';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { SoloNativeRTCHandler } from '@core/rtcChannel/SoloNativeRTCHandler';
import { ICobranding } from '@/bridge/ICobranding';
import { SoloLogger } from '@core/logger/SoloLogger';
import { ILogger } from '@bridge/ILogger';

export abstract class SoloCoreFactory
  extends BaseCoreFactory
  implements ICoreFactory
{
  abstract getCobranding(): ICobranding;

  getDevice(): IDevice {
    if (!this._device) {
      this._device = new WebViewPlatformDevice();
    }
    return this._device;
  }

  getLogger(): ILogger {
    if (!this._logger) {
      const device = this.getDevice();
      this._logger = new SoloLogger(device);
    }
    return this._logger;
  }

  getRTCChannel(): IRTCChannel | null {
    if (!this._rtcChannel) {
      const logger = this.getLogger();
      const metrics = this.getMetrics();
      const sessionManager = this.getSessionManager();
      const handler = new SoloNativeRTCHandler(logger, metrics, sessionManager);
      this._rtcChannel = new SoloNativeRTCChannel(logger, handler);
    }
    return this._rtcChannel;
  }
}
