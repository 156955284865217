import create, { StoreApi } from 'zustand/vanilla';
import { persist } from 'zustand/middleware';
import {
  SessionDetails,
  SessionActions,
  initState,
} from '@/bridge/types/SessionTypes';
import { ISessionManager } from '@bridge/ISessionManager';
import { ISessionStorage } from '@/bridge/ISessionStorage';
import { IPersistentStorage } from '@/bridge/IPersistentStorage';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { CredSource } from '@core/wsbroker/types';

export class SessionManager implements ISessionManager {
  store: StoreApi<SessionDetails & SessionActions>;

  constructor(
    sessionStorage: ISessionStorage,
    persistStorage: IPersistentStorage
  ) {
    this.store = create(
      persist<SessionDetails & SessionActions>(
        (set) => ({
          ...initState,
          registrationCode: persistStorage.get('regCodeLastUsed'),
          setSession: (data: Partial<SessionDetails>) => set(data),

          // Resets the session in case of disconnect
          resetSession: () =>
            set({
              ...initState,
              registrationCode: persistStorage.get('regCodeLastUsed'),
            }),

          // Deletes session data in case of errors.
          purgeSession: () => {
            persistStorage.remove('regCodeLastUsed');
            set({
              ...initState,
              registrationCode: undefined,
            });
          },
        }),
        {
          name: 'session',
          getStorage: () => sessionStorage,
        }
      )
    );
  }

  get<K extends keyof SessionDetails>(key: K): SessionDetails[K] {
    return this.store.getState()[key];
  }

  set(partialSession: SessionDetails | Partial<SessionDetails>) {
    return this.store.setState(partialSession);
  }

  isNativeMode(): boolean {
    return SoloNativeRTCChannel.isChannelAvailable();
  }

  // For new Password-ReArch credentialsSource will be "CLIENT", and for old login process, it will be "SERVER"
  // Ref: https://w.amazon.com/bin/view/AWS/Services/WorkSpaces/SystemOverview/WSBrokerService/PasswordReArch#HGetInformationForSessionProvisioning
  isUseBrokerProtocolV2(): boolean {
    const credSource =
      this.get('primaryAuthMethod')?.ServiceCapabilities?.CredSource;
    const authBlobFromAuthResponse = this.get('authBlob');

    return credSource === CredSource.CLIENT && !!authBlobFromAuthResponse;
  }
}
