import { CoreFactory } from '@bridge/factory/CoreFactory';
import BrowserMode from '@views/Stream/BrowserMode';
import NativeMode from '@views/Stream/NativeMode';
import { useErrorHandler } from '@hooks/useErrorHandler';
import { useSessionStore } from '@stores/session';
import { useEffect } from 'react';
import { SessionState } from '@bridge/types/SoloRTCChannelTypes';
import { WsErrorCodes } from '@core/error/WsErrorCodes';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { useHeartBeatInfo } from '@/hooks/useHeartBeatInfo';
import ConnectStatus from '@/components/ConnectStatus';
import { SessionProtocols } from '@bridge/types/SessionTypes';

const ConnectionError = WsError.withType(WsErrorTypes.ERROR_TYPE_CONNECTION);
const sessionManager = CoreFactory.getSessionManager();
const isNativeMode = sessionManager.isNativeMode();
const logger = CoreFactory.getLogger();

const Streaming: React.FC = () => {
  const { showError } = useErrorHandler();

  const protocol = useSessionStore((state) => state.resourceProtocol);
  const setSession = useSessionStore((state) => state.setSession);

  const { isSuccess } = useHeartBeatInfo();

  useEffect(() => {
    logger.info('Setting session to connect');
    setSession({ sessionState: SessionState.CONNECT });
  }, []);

  useEffect(() => {
    if (protocol === '' || !protocol) {
      showError(ConnectionError(WsErrorCodes.ERROR_MISSING_PROTOCOL));
    } else if (protocol === SessionProtocols.WSP) {
      // This case will be handled by SessionProvision module. We should never reach this code. But adding a check here
      // as a safeguard.
      logger.fatal(`Encountered WSP unsupported protocol during streaming`);
      showError(ConnectionError(WsErrorCodes.ERROR_WSP_UNSUPPORTED_PROTOCOL));
    }
  }, [protocol, showError]);
  if (!isSuccess) {
    return <ConnectStatus />;
  }
  if (isNativeMode) {
    return <NativeMode />;
  } else {
    return <BrowserMode />;
  }
};
export default Streaming;
