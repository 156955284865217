import { AuthStepDeterminer } from '../AuthStepDeterminer';
import { BaseStep } from './BaseStep';

export class ReconnectStep extends BaseStep {
  async perform() {
    const authResponse = await this.authentication.authenticate(
      await this.authRequest.constructReconnectAuthRequest()
    );
    return AuthStepDeterminer.getNextStepFromAuthResponse(authResponse);
  }
}
