import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import ErrorDetails from './components/ErrorDetails';
import { useSessionStore } from '@stores/session';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { WsError } from '@core/error/WsError';
import { WsErrorTypes } from '@core/error/WsErrorTypes';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { useTelemetryFlush } from '@hooks/useTelemetryFlush';

interface ErrorProps {}
const logger = CoreFactory.getLogger();
const metrics = CoreFactory.getMetrics();

const Error: React.FC<ErrorProps> = () => {
  const { error, wsError, isExternalAuthRedirectError } = useErrorHandler();
  const isMetricsPublished = useRef(false);
  const navigate = useNavigate();
  const { flushTelemetry } = useTelemetryFlush();
  const purgeSession = useSessionStore((state) => state.purgeSession);
  const resetSession = useSessionStore((state) => state.resetSession);

  const goToRegistrationPage = useCallback(() => {
    logger.info(
      `Purging session completely and redirecting to registration page`
    );
    purgeSession();
    navigate('/registration');
  }, [navigate, purgeSession, logger]);

  const goToLoginPage = useCallback(() => {
    logger.info(
      `Resetting session and redirecting to login page from error page`
    );
    resetSession();
    navigate('/login');
  }, [navigate, purgeSession, logger]);

  const publishWarpDriveMetricsIfApplicable = useCallback(
    (error: unknown) => {
      try {
        const errorAsWsError = error as WsError;
        if (errorAsWsError?.errorType === WsErrorTypes.ERROR_TYPE_WARPDRIVE) {
          logger.fatal(
            `Publishing warp drive metrics with error: ${JSON.stringify(
              errorAsWsError
            )}`
          );
          metrics.emit(
            Operation.WDPageLoad,
            MetricResult.Error,
            errorAsWsError
          );
        }
      } catch (e: any) {
        logger.warn(
          `Failure to publish metrics due to conversion error ${e?.message}`
        );
      }
    },
    [metrics, logger]
  );

  useEffect(() => {
    if (!isMetricsPublished.current && wsError) {
      isMetricsPublished.current = true;
      publishWarpDriveMetricsIfApplicable(wsError);
      flushTelemetry();
    }
  }, [wsError]);

  useEffect(() => {
    if (error == null) {
      goToRegistrationPage();
    }
  }, [goToRegistrationPage, error]);

  if (error == null) {
    return null;
  } else {
    return (
      <ErrorDetails
        error={error}
        onClose={goToRegistrationPage}
        showLoginPageRedirect={isExternalAuthRedirectError}
        goToLoginPage={goToLoginPage}
      />
    );
  }
};

export default Error;
