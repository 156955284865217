import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from '@stores/session';
import { SessionState } from '@bridge/types/SoloRTCChannelTypes';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import { useErrorHandler } from '@hooks/useErrorHandler';
import DisconnectComponent from './Disconnect';
import HorizontalSplitLayout from '@/layout/HorizontalSplitLayout';
import FadeAnimation from '@/components/FadeAnimation';
import { useLoginResourceQuery } from '@/hooks/useLoginResourceQuery';
import { useFadeAnimation } from '@/hooks/useFadeAnimation';
import { useRegCodes } from '@stores/registration';
import { useAuthManager } from '@/hooks/useAuthManager';
import { useTelemetryFlush } from '@hooks/useTelemetryFlush';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { useDisconnectHandler } from '@/hooks/useDisconnectHandler';
import DisconnectErrorAlert from '@/components/DisconnectErrorAlert';

const sessionManager = CoreFactory.getSessionManager();
const device = CoreFactory.getDevice();
const isNativeMode = sessionManager.isNativeMode();

const Disconnect = () => {
  const { reconnect, fallback } = useAuthManager();
  const { showError } = useErrorHandler();
  const navigate = useNavigate();
  const { disconnectI18Error } = useDisconnectHandler();

  // Flush metrics, logs.
  const { isLoading: isTelemetryFlushInProgress, flushTelemetry } =
    useTelemetryFlush();
  const registrationCode = useSessionStore((state) => state.registrationCode);
  const getRememberMe = useRegCodes((state) => state.getRememberMeSetting);
  const isRememberMe = useMemo(() => {
    return getRememberMe(registrationCode) ?? false;
  }, [registrationCode]);

  const getRememberMeSettingAtDirectoryLevel = useRegCodes(
    (state) => state.getRememberMeSettingAtDirectoryLevel
  );

  const isRememberMeEnabledAtDirectorylevel = useMemo(() => {
    return (
      getRememberMeSettingAtDirectoryLevel(registrationCode ?? '') ?? false
    );
  }, [registrationCode]);
  const protocol = useSessionStore((state) => state.resourceProtocol);
  const getWSName = useRegCodes((state) => state.getWSName);
  const wsAlias = useMemo(
    () => getWSName(registrationCode ?? ''),
    [registrationCode]
  );

  const { publishLeavePageEvent } = useFadeAnimation();

  const onClickRegistration = () => {
    publishLeavePageEvent().then(() => navigate('/registration'));
  };
  const onClickAuthAndReconnect = () => {
    publishLeavePageEvent().then(() => {
      reconnect();
    });
  };

  const isErrorFallbackRelated = [
    ClientErrorCode.MaxibonWebStreamingFallbackLoginRequired,
    ClientErrorCode.MaxibonWebStreamingUnsupportedCredential,
    ClientErrorCode.AllocateResourceFallbackLoginRequired,
  ].includes(disconnectI18Error?.clientCode as ClientErrorCode);
  const onFallback = isErrorFallbackRelated ? fallback : undefined;

  // ToDo: Remove solo check once web client supports reconnection feature
  const isShowReconnect = useMemo(
    () =>
      isNativeMode &&
      isRememberMeEnabledAtDirectorylevel &&
      isRememberMe &&
      !!protocol &&
      device.isCurrentPlatformSupported([protocol]),
    [isNativeMode, isRememberMeEnabledAtDirectorylevel, isRememberMe, protocol]
  );

  const logo = useSessionStore((state) => state.logo);
  const legalText = useSessionStore((state) => state.legalText);
  const setSession = useSessionStore((state) => state.setSession);

  // Preloading resource data for login page
  const {
    isLoading: isResLoading,
    isSuccess: isResReady,
    error: resError,
    fetchResource,
  } = useLoginResourceQuery();
  const onClickLogin = () => {
    fetchResource();
  };

  // Update session status
  useEffect(() => {
    setSession({
      resourceSessionContext: {},
      sessionState: SessionState.DISCONNECT,
    });
  }, []);

  // Go to login page after resources got preloaded
  useEffect(() => {
    if (isResReady) {
      publishLeavePageEvent().then(() => navigate('/login'));
    }
  }, [isResReady]);

  useEffect(() => {
    resError && showError(resError);
  }, [resError]);

  // Flush metrics and logs
  useEffect(() => {
    flushTelemetry();
  }, []);

  return (
    <HorizontalSplitLayout logo={logo} brandDescription={legalText}>
      <DisconnectErrorAlert error={disconnectI18Error} />
      <FadeAnimation>
        <DisconnectComponent
          regCode={wsAlias}
          onBackToLoginPage={onClickLogin}
          onFallback={onFallback}
          onChangeRegistrationCode={onClickRegistration}
          onReconnect={onClickAuthAndReconnect}
          isShowReconnect={isShowReconnect}
          // Show spinner if branding is being downloaded [before going to login page] or if metrics, logs are being flushed
          isLoading={(isResLoading || isResReady) && isTelemetryFlushInProgress}
        />
      </FadeAnimation>
    </HorizontalSplitLayout>
  );
};

export default Disconnect;
