import { I18nError } from '@/lib/I18nError';
import ErrorAlert from '@views/SelfService/components/ErrorAlert';
import { useState } from 'react';

interface DisconnectErrorAlertProps {
  error?: I18nError;
}

const DisconnectErrorAlert: React.FC<DisconnectErrorAlertProps> = ({
  error,
}) => {
  const [dismissed, setDismissed] = useState(false);
  const onDismiss = () => setDismissed(true);

  return (
    <>
      {error && !dismissed && (
        <div
          style={{
            position: 'absolute',
            padding: '0 24px',
            top: '70px',
            right: 0,
            width: '50%',
            zIndex: 1,
          }}
        >
          <ErrorAlert error={error} onDismiss={onDismiss} />
        </div>
      )}
    </>
  );
};

export default DisconnectErrorAlert;
