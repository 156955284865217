import { useSessionStore } from '@/stores/session';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import {
  Box,
  Button,
  ColumnLayout,
  Toggle,
  ToggleProps,
  Input,
  InputProps,
  SpaceBetween,
  FormField,
} from '@cloudscape-design/components';
import { useMemo } from 'react';
import * as React from 'react';
import RegistrationCodesComponent from './components/RegistrationCodes';
import KeepMeLoggedInBox from '../../KeepMeLoggedInBox';
import { I18nKey } from '@/i18n/types';
import DisableSaveRegCodesModal from './components/DisableSaveRegCodesModal';
import KeepMeLoggedInModal from './components/KeepMeLoggedInModal';
import DisableSaveAlert from './components/DisableSaveAlert';
import { useRegCodes } from '@stores/registration';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';
import { IRegion } from '@/bridge/types/RegionTypes';
import { useTranslation } from 'react-i18next';

// ToDo: Handle CRR connection alias use case while determining IRegion
const ManageLoginInfo = () => {
  const { t } = useTranslation();
  const regCodeLastUsed =
    CoreFactory.getPersistentStorage().get('regCodeLastUsed');
  const [userSelectedCode, setUserSelectedCode] = React.useState(
    regCodeLastUsed as string
  );

  const regCode = useSessionStore((state) => state.registrationCode);

  const getAllRegCodesAlias = useRegCodes((state) => state.getAllRegCodesAlias);
  const updateWSAliasName = useRegCodes((state) => state.updateWSAliasName);
  const getRegCodeDetails = useRegCodes((state) => state.getRegCodeDetails);
  const toggleRememberMe = useRegCodes((state) => state.toggleRememberMe);
  const toggleSavedRegCodesOption = useRegCodes(
    (state) => state.toggleSavedRegCodesOption
  );
  const isSaveEnabled = useRegCodes((state) => state.isSaveEnabled);
  const clearAllSavedRegCodes = useRegCodes(
    (state) => state.clearAllSavedRegCodes
  );
  const [showDisableModal, setShowDisableModal] = React.useState(false);
  const [showKeepMeLoggedAlert, setShowKeeMeLoggedAlert] =
    React.useState(false);

  const getRememberMe = useRegCodes((state) => state.getRememberMeSetting);
  const isRememberMe = useMemo(() => {
    return getRememberMe(userSelectedCode) ?? false;
  }, [userSelectedCode]);

  const [isRememberMeToggle, setIsRememberMeToggle] =
    React.useState(isRememberMe);

  const [inputWSName, setInputWSName] = React.useState(
    () => getRegCodeDetails(regCodeLastUsed as string)?.alias ?? ''
  );
  const [error, setError] = React.useState('');

  const getRegion = (regCode: string): IRegion | undefined => {
    const regCodeDetails = getRegCodeDetails(regCode);
    if (regCodeDetails?.region) {
      return regCodeDetails.region;
    }
    return undefined;
  };

  const [userSelectedRegion, setUserSelectedRegion] = React.useState(
    getRegion(userSelectedCode)
  );

  const [disableSaveButton, setDisableSaveButton] = React.useState(true);

  const saveWsAliasName = () => {
    // serach if the regCodes store already has any reg-code with the same alias name
    const searchAlias = (alias: string): boolean => {
      const foundAlias = getAllRegCodesAlias().find((a) => a === alias);
      return !!foundAlias;
    };

    // persist the remember me settings of user
    toggleRememberMe(userSelectedCode, isRememberMeToggle);

    // Trigger a custom event to notify the page
    window.dispatchEvent(
      new CustomEvent('rememberMeValueChanged', { detail: isRememberMeToggle })
    );

    // show keep me loggen in alert when user checks remember me
    setShowKeeMeLoggedAlert(isRememberMeToggle);

    // set error if the alias already exists for any other regcode
    if (
      searchAlias(inputWSName) &&
      inputWSName !== getRegCodeDetails(userSelectedCode)?.alias
    ) {
      setError(t('ws-reg-code-alias-error'));
    } else {
      setError('');
      // update the alias name for the user selected reg code
      if (userSelectedCode) {
        updateWSAliasName(userSelectedCode, inputWSName);
      }
      setDisableSaveButton(true);
    }
  };

  // set the input field of the workspace name while user selects the reg code
  const updateInputforWSName = (regCode: string) => {
    const details = getRegCodeDetails(regCode);
    setInputWSName(details!.alias ? details!.alias : '');
  };

  // set the remeber me setting for user selects the reg code
  const setRememberMeToggle = (regCode: string) => {
    const details = getRegCodeDetails(regCode);
    setIsRememberMeToggle(details!.isRememberMe);
  };

  const handleInputChange: NonCancelableEventHandler<
    InputProps.ChangeDetail
  > = ({ detail }) => {
    error && setError('');
    const value = detail.value.slice(0, 20); // allow user to only enter 20 characters max
    setInputWSName(value);
    setDisableSaveButton(false);
  };

  const handleToggleChange: NonCancelableEventHandler<
    ToggleProps.ChangeDetail
  > = ({ detail }) => {
    if (!detail.checked) {
      setShowDisableModal(!showDisableModal);
      setUserSelectedCode('');
      setUserSelectedRegion(undefined);
      setInputWSName('');
      setToggleText(t('ws-save-reg-codes-disabled'));
      // update the last saved reg code to be empty when the user selects disable save option
      const store = CoreFactory.getPersistentStorage();
      store.set('regCodeLastUsed', '');
    } else {
      // Pop up disable modal when the user tries to disable the save reg code option
      setShowDisableModal(false);
      toggleSavedRegCodesOption(true);
      setToggleText(t('ws-save-reg-codes-enabled'));
    }
  };

  const handleBoxClick = (regCode: string) => {
    setError('');
    setUserSelectedCode(regCode);
    setUserSelectedRegion(getRegion(regCode));
    updateInputforWSName(regCode);
    setRememberMeToggle(regCode);
  };

  const handleDisableClick = () => {
    // On disable save button click we delete all the saved reg codes except for the default reg-code
    clearAllSavedRegCodes();
    setShowDisableModal(false);
    toggleSavedRegCodesOption(false);
  };

  const handleCancelClick = () => {
    setShowDisableModal(false);
  };

  const rememberMeCheckboxCallback = (isChecked: boolean) => {
    setIsRememberMeToggle(isChecked);
    setDisableSaveButton(false);
  };

  const [toggleText, setToggleText] = React.useState(() =>
    setInitialSaveRegCodeToggleText()
  );

  function setInitialSaveRegCodeToggleText() {
    if (isSaveEnabled) {
      return t('ws-save-reg-codes-enabled');
    } else {
      return t('ws-save-reg-codes-disabled');
    }
  }

  return (
    <div style={{ width: '620px' }}>
      <SpaceBetween direction="vertical" size="l">
        <div>
          <SpaceBetween direction="vertical" size="m">
            <Box float="left">
              <SpaceBetween direction="vertical" size="l">
                <SpaceBetween direction="vertical" size="xs">
                  <Box variant="awsui-key-label">
                    <div
                      tabIndex={0}
                      role="navigation"
                      aria-live="assertive"
                      aria-label={t('ws-save-reg-code')}
                    >
                      {t('ws-save-reg-code')}
                    </div>
                  </Box>
                  <div
                    aria-live="assertive"
                    tabIndex={0}
                    role="navigation"
                    aria-label={t('ws-save-reg-code-toggle-description')}
                  >
                    {t('ws-save-reg-code-toggle-description')}
                  </div>
                  <Toggle
                    ariaLabel={toggleText}
                    onChange={handleToggleChange}
                    checked={isSaveEnabled}
                  >
                    <div
                      tabIndex={0}
                      role="navigation"
                      aria-live="assertive"
                      aria-label={toggleText}
                    >
                      {isSaveEnabled
                        ? t('ws-enabled-save')
                        : t('ws-disabled-save')}
                    </div>
                  </Toggle>
                </SpaceBetween>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        </div>

        {isSaveEnabled ? (
          <div style={{ height: '290px' }}>
            <ColumnLayout columns={2} variant="text-grid">
              <div style={{ width: '200px' }}>
                <Box>
                  <SpaceBetween size="l">
                    <div>
                      <RegistrationCodesComponent
                        key={regCode}
                        onBoxClick={handleBoxClick}
                      ></RegistrationCodesComponent>
                    </div>
                  </SpaceBetween>
                </Box>
              </div>
              <div style={{ width: '435px' }}>
                <Box>
                  <SpaceBetween size="l">
                    <div>
                      <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="s">
                          <div
                            tabIndex={0}
                            role="navigation"
                            aria-live="assertive"
                            aria-label={t(
                              'ws-my-workspace-about-registration-code'
                            )}
                          >
                            <Box variant="awsui-key-label">
                              {t('ws-my-workspace-about-registration-code')}
                            </Box>
                            <div
                              tabIndex={0}
                              role="navigation"
                              aria-live="assertive"
                              aria-label={userSelectedCode}
                            >
                              {userSelectedCode}
                            </div>
                          </div>
                        </SpaceBetween>
                        <SpaceBetween size="s">
                          <div
                            tabIndex={0}
                            role="navigation"
                            aria-live="assertive"
                            aria-label={t('ws-my-workspace-about-region')}
                          >
                            <Box variant="awsui-key-label">
                              {t('ws-my-workspace-about-region')}
                            </Box>
                            <div
                              tabIndex={0}
                              role="navigation"
                              aria-live="assertive"
                              aria-label={t(
                                userSelectedRegion?.localeKey as I18nKey
                              )}
                            >
                              {t(userSelectedRegion?.localeKey as I18nKey)}
                            </div>
                          </div>
                        </SpaceBetween>
                      </ColumnLayout>
                    </div>
                    <SpaceBetween size="s">
                      <div
                        style={{ width: 'auto' }}
                        tabIndex={0}
                        role="navigation"
                        aria-live="assertive"
                        aria-label={t('ws-custom-name-input-title')}
                      >
                        <FormField
                          label={t('ws-custom-name-input-title')}
                          constraintText={t('ws-input-char-limit-description')}
                          description={t('ws-rename-description')}
                          aria-describedby={t(
                            'ws-input-char-limit-description'
                          )}
                          errorText={error}
                        >
                          <Input
                            placeholder={t('ws-custom-name-input-placeholder')}
                            value={inputWSName}
                            ariaLabel={t('ws-custom-name-input-title')}
                            ariaRequired
                            autoFocus
                            onChange={handleInputChange}
                          />
                        </FormField>
                      </div>
                      <KeepMeLoggedInBox
                        regCode={userSelectedCode}
                        onChange={rememberMeCheckboxCallback}
                      ></KeepMeLoggedInBox>
                      <div style={{ float: 'right' }}>
                        <Button
                          variant="primary"
                          onClick={saveWsAliasName}
                          disabled={disableSaveButton}
                          aria-label={t('ws-settings-save-button')}
                        >
                          <span>{t('ws-settings-save-button')}</span>
                        </Button>
                      </div>
                    </SpaceBetween>
                  </SpaceBetween>
                </Box>
              </div>
            </ColumnLayout>
          </div>
        ) : (
          <DisableSaveAlert></DisableSaveAlert>
        )}

        {showDisableModal && (
          <DisableSaveRegCodesModal
            isVisible={showDisableModal}
            onDisableClick={handleDisableClick}
            onCancelClick={handleCancelClick}
          />
        )}

        {showKeepMeLoggedAlert && (
          <KeepMeLoggedInModal
            isVisible={showKeepMeLoggedAlert}
          ></KeepMeLoggedInModal>
        )}
      </SpaceBetween>
    </div>
  );
};

export default ManageLoginInfo;
