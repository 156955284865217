import { useCustomTranslation } from '@/i18n';
import ConnectStatusWithoutLogo from './ConnectStatusWithoutLogo';
import CentralizedLayout from '@/layout/CentralizedLayout';
import { useTheme } from '@/stores/theme';

const ConnectStatusComponent: React.FC = () => {
  const { t } = useCustomTranslation();
  const logo = useTheme((state) => state.logo);

  return (
    <CentralizedLayout>
      <div
        style={{
          textAlign: 'center',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
        }}
      >
        <img
          src={logo}
          alt={t('ws-logo-alt')}
          crossOrigin="anonymous"
          style={{ height: '45px' }}
        />
        <ConnectStatusWithoutLogo />
      </div>
    </CentralizedLayout>
  );
};

export default ConnectStatusComponent;
