import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import { IHttpClient } from '@/bridge/IHttpClient';

export interface HttpClientConfig extends AxiosRequestConfig {}
export interface HttpRequestHeader extends AxiosRequestHeaders {}

export class HttpClient<T> implements IHttpClient<T> {
  private readonly axiosInstance: AxiosInstance;

  constructor(axiosParams: HttpClientConfig) {
    this.axiosInstance = axios.create(axiosParams);
  }

  async get(url: string, config: HttpClientConfig = {}) {
    return await this.axiosInstance.get<T>(url, config);
  }

  async post(url: string, body: unknown, config: HttpClientConfig = {}) {
    return await this.axiosInstance.post<T>(url, body, config);
  }
}
