import { useCallback, useEffect, useRef, useState } from 'react';

interface CustomQueryOptions {
  enabled?: boolean;
}

interface CustomQueryResponse<T> {
  data: T | undefined;
  error: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  refetch: () => void;
}

const useCustomQuery = <T>(
  key: any,
  asyncFn: () => Promise<T>,
  options: CustomQueryOptions
): CustomQueryResponse<T> => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const hasQueryRun = useRef(false);
  const enabled = 'enabled' in options ? options.enabled : true;

  const runQuery = useCallback(() => {
    setLoading(true);
    asyncFn()
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [asyncFn, setData, setError, setLoading]);

  useEffect(() => {
    if (!loading && !data && !hasQueryRun.current && enabled) {
      hasQueryRun.current = true;
      runQuery();
    }
  }, [enabled]);

  return {
    isSuccess: !!data,
    data,
    isLoading: loading,
    error,
    isError: !!error,
    refetch: runQuery,
  };
};

export default useCustomQuery;
