import { WorkSpaceState } from '@/bridge/types/WorkSpaceStateTypes';

type StatusType =
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'stopped'
  | 'pending'
  | 'in-progress'
  | 'loading';

const getStatusType = (workSpaceState: WorkSpaceState): StatusType =>
  workSpaceState === WorkSpaceState.WORKSPACE_STATE_UNHEALTHY
    ? 'error'
    : workSpaceState === WorkSpaceState.WORKSPACE_STATE_HEALTHY
    ? 'success'
    : 'info';

const getPrettyString = (workSpaceState: WorkSpaceState) =>
  workSpaceState.charAt(0).toUpperCase() +
  workSpaceState.slice(1).toLowerCase();

export const WorkSpaceStateUtil = {
  getStatusType,
  getPrettyString,
};
