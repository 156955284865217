import { PreSessionFactory } from '@/bridge/factory/PreSessionFactory';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { IAuthRelativePath } from '@/bridge/routes/AuthRoutes';
import { useNavigate } from 'react-router-dom';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { SoloNativeRTCChannel } from '@core/rtcChannel/SoloNativeRTCChannel';
import { SessionState } from '@bridge/types/SoloRTCChannelTypes';
import { WebInSessionError } from '@/bridge/types/WebInSessionError';
import { AuthProvider } from '@/core/wsbroker/types';

const logger = CoreFactory.getLogger();
const authManager = PreSessionFactory.getAuthenticationManagerModule();
const sessionManager = CoreFactory.getSessionManager();
const rtcChannel = CoreFactory.getRTCChannel();

export const useAuthManager = () => {
  const navigate = useNavigate();
  const { showError } = useErrorHandler();

  const init = () => {
    authManager.init();
  };

  const reconnect = () => {
    const nextAuthStep = authManager.reconnect();
    navigate(nextAuthStep.complete ? '/session' : nextAuthStep.url);
  };

  const goToNextAuthStep = () => {
    try {
      const nextAuthStep = authManager.determineAuthStep();
      navigate(`${nextAuthStep.url}${window.location.search}`);
    } catch (error) {
      logger.error('Error while deciding the next auth step');
      showError(error);
    }
  };

  const fallback = () => {
    try {
      const nextAuthStep = authManager.fallback();
      navigateToAuthUrl(nextAuthStep.url);
    } catch (error) {
      logger.error('Error while perform fallback auth process');
      showError(error);
    }
  };

  const disconnect = (
    disconnectErrorType: WebInSessionError.WspErrorTypes,
    disconnectErrorCode: WebInSessionError.WspErrorCodes
  ) => {
    navigate(
      `/disconnect?${WebInSessionError.DISCONNECT_ERROR_TYPE_SEARCH_NAME}=${disconnectErrorType}&${WebInSessionError.DISCONNECT_STATUS_CODE_SEARCH_NAME}=${disconnectErrorCode}`
    );
  };

  const performAuthStep = async (step: IAuthRelativePath) => {
    try {
      const nextStep = await authManager.performAuthStep(step);
      if (nextStep.complete) {
        return navigate('/session');
      } else if (!('payload' in nextStep)) {
        if (nextStep.external) {
          navigateToAuthUrl(nextStep.url);
        } else {
          navigate(nextStep.url);
        }
      }
    } catch (error) {
      logger.error(`Error while performing an auth step - ${error}`);
      showError(error);
    }
  };

  const navigateToAuthUrl = (baseUrl?: string) => {
    try {
      const nextAuthMethod = sessionManager.get('nextAuthMethod');
      const externalAuthUrl = authManager.buildExternalAuthUrl(baseUrl);

      publishExternalAuthRedirectStateChangeUpdateToSolo(externalAuthUrl);
      // When AuthMethod is WarpDrive or we are not in Solo Context, we should
      // load the external auth URL in browser
      if (
        !SoloNativeRTCChannel.isChannelAvailable() ||
        nextAuthMethod?.AuthProvider === AuthProvider.WARP_DRIVE
      ) {
        window.location.assign(externalAuthUrl);
      } else {
        logger.info(
          'Did not redirect to external auth page, since it is Solo non-Warp Drive case.'
        );
      }
    } catch (error) {
      logger.error(`Error while building external auth url - ${error}`);
      showError(error);
    }
  };

  const publishExternalAuthRedirectStateChangeUpdateToSolo = (
    externalAuthUrl: URL
  ) => {
    if (SoloNativeRTCChannel.isChannelAvailable() && rtcChannel) {
      const nextAuthMethod = sessionManager.get('nextAuthMethod');

      let sessionState = SessionState.WARP_DRIVE_REDIRECTION_START;
      if (nextAuthMethod?.AuthProvider === AuthProvider.SAML_IAM) {
        sessionState = SessionState.SAML_IAM_REDIRECTION_START;
      } else if (nextAuthMethod?.AuthProvider === AuthProvider.IDC) {
        sessionState = SessionState.IDC_IAM_REDIRECTION_START;
      }
      sessionManager.set({ sessionState });
      logger.info(
        `Publishing External Auth URL redirect update to native client for provider ${nextAuthMethod?.AuthProvider}`
      );

      // Flag to identify the new Pwd ReArch workflow, if true, credentials won't be captured in Client side
      const UseBrokerProtocolV2 = sessionManager.isUseBrokerProtocolV2();

      logger.info(
        `Identified Client Auth Capability BrokerProtocolV2: ${UseBrokerProtocolV2}`
      );
      const externalAuthRedirectConfig = {
        AuthRedirectUrl: externalAuthUrl.toString(),
        FailedRedirectFallbackUrl: authManager
          .buildExternalAuthRedirectFailedErrorHandlerUrl()
          .toString(),
        UseBrokerProtocolV2,
      };

      rtcChannel.publishStateChangeUpdate(
        sessionState,
        externalAuthRedirectConfig
      );
    }
  };

  return {
    init,
    reconnect,
    fallback,
    disconnect,
    navigateToAuthUrl,
    goToNextAuthStep,
    performAuthStep,
  };
};
