import { ISessionStorage } from '@/bridge/ISessionStorage';

export class BrowserSessionStorage implements ISessionStorage {
  getItem(key: string): string | null | Promise<string | null> {
    return sessionStorage.getItem(key);
  }

  setItem(key: string, value: string): void | Promise<void> {
    return sessionStorage.setItem(key, value);
  }

  removeItem(key: string): void | Promise<void> {
    return sessionStorage.removeItem(key);
  }
}
