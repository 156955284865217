import { useMemo, useState, useEffect } from 'react';
import {
  Box,
  ColumnLayout,
  Form,
  Button,
  Checkbox,
  RadioGroup,
  Spinner,
} from '@cloudscape-design/components';
import { useCustomTranslation } from '@/i18n';
import { useSessionStore } from '@/stores/session';
import { SelfServiceActionTypes } from '@/bridge/types/SelfService';
import { useSelfServiceActions } from '../hooks/useSelfServiceActions';
import ErrorAlert from '../components/ErrorAlert';

// TODO: handle errors
const ChangeComputeType = () => {
  const { t } = useCustomTranslation();
  const inUseNote = ` - ${t('ws-my-workspace-change-compute-type-in-use')}`;

  const [confirmed, setConfirmed] = useState(false);
  const {
    error,
    cleanError,
    isPending,
    isProcessing,
    downgradeComputeType,
    upgradeComputeType,
  } = useSelfServiceActions();
  const { resourceComputeType: currType, resource } = useSessionStore(
    (state) => ({
      ...state,
    })
  );
  const currLabel = useMemo(() => currType.split(' - ')[0], [currType]);
  const currTypeLabel =
    `${t('ws-my-workspace-change-compute-type-current-key')}: ` + currLabel;
  const [newComputeType, setNewComputeType] = useState(currType);
  const allowUpdate = useMemo(
    () => confirmed && newComputeType !== currType && isPending,
    [currType, newComputeType, confirmed]
  );
  const actionAuthorized = useMemo(
    () => resource?.ActionsAuthorized,
    [resource]
  );
  const changeComputeInfo = useMemo(
    () =>
      actionAuthorized?.find(
        ({ ActionType }) =>
          ActionType === SelfServiceActionTypes.CHANGE_COMPUTE_TYPE
      ),
    [actionAuthorized]
  );
  const actionUserInput = useMemo(
    () => changeComputeInfo?.ActionUserInput,
    [changeComputeInfo]
  );
  const downgradeTypes = useMemo(
    () => actionUserInput?.NewComputeDowngradeType?.Values ?? [],
    [actionUserInput]
  );
  const upgradeTypes = useMemo(
    () => actionUserInput?.NewComputeUpgradeType?.Values ?? [],
    [actionUserInput]
  );
  const rawComputeTypes = useMemo(
    () => [...downgradeTypes, currType, ...upgradeTypes],
    [downgradeTypes, currType, upgradeTypes]
  );
  const computeTypes = useMemo(
    () =>
      rawComputeTypes.map((computeType) => {
        const [label, description] = computeType.split(' - ');
        const isCurrType = currType === computeType;
        return {
          value: computeType,
          label: <strong>{`${label}${isCurrType ? inUseNote : ''}`}</strong>,
          description,
          disabled: !isPending,
        };
      }),
    [rawComputeTypes]
  );
  const onActionButtonClick = () => {
    cleanError();
    if (newComputeType.split(' - ')[1] < currType.split(' - ')[1]) {
      downgradeComputeType(newComputeType);
    } else {
      upgradeComputeType(newComputeType);
    }
  };

  useEffect(() => {
    if (error) {
      setConfirmed(false);
      setNewComputeType(currType);
    }
  }, [error]);

  return (
    <Box padding="m">
      <Form
        actions={
          <Button
            disabled={!allowUpdate}
            variant="primary"
            onClick={onActionButtonClick}
            ariaLabel={
              isProcessing
                ? 'Processing'
                : t('ws-my-workspace-change-compute-type')
            }
          >
            {isProcessing ? (
              <Spinner />
            ) : (
              t('ws-my-workspace-change-compute-type')
            )}
          </Button>
        }
      >
        <ColumnLayout borders="horizontal">
          {error && <ErrorAlert error={error} onDismiss={cleanError} />}
          <div>
            <p
              tabIndex={0}
              aria-live="assertive"
              role="navigation"
              aria-label={currTypeLabel}
            >
              {t('ws-my-workspace-change-compute-type-current-key')}:{' '}
              <strong>{currLabel}</strong>
            </p>
            <p
              tabIndex={0}
              aria-live="assertive"
              role="navigation"
              aria-label={t('ws-my-workspace-change-compute-type-description')}
            >
              {t('ws-my-workspace-change-compute-type-description')}
            </p>
          </div>
          <Box margin={{ vertical: 's' }}>
            <RadioGroup
              onChange={({ detail }) => setNewComputeType(detail.value)}
              value={newComputeType}
              ariaLabel={t('ws-my-workspace-change-compute-type-aria-label')}
              items={computeTypes}
            />
          </Box>
          <Box margin={{ top: 's' }}>
            <Checkbox
              onChange={({ detail }) => setConfirmed(detail.checked)}
              checked={confirmed}
              disabled={!isPending}
              ariaLabel={t(
                'ws-my-workspace-change-compute-type-disconnect-confirm'
              )}
            >
              <strong>
                {t('ws-my-workspace-change-compute-type-disconnect-confirm')}
              </strong>
            </Checkbox>
          </Box>
        </ColumnLayout>
      </Form>
    </Box>
  );
};

export default ChangeComputeType;
