export const LoggerConstants = {
  SOLO_PUBLISHER_INTERVAL_MS: 300000, // 5 minutes
  SOLO_PUBLISHER_WAIT_TIME_MS: 20000,
};

export enum LogLevel {
  Info = 'Info',
  Warn = 'Warn',
  Error = 'Error',
  Fatal = 'Fatal',
}
