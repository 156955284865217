export enum WsErrorCodes {
  ERROR_FORMAT = 'ERR_BAD_FORMAT',
  ERROR_PREFIX = 'ERR_BAD_PREFIX',
  ERROR_PREFIX_UNSUPPORTED_BY_PLATFORM = 'ERR_PREFIX_UNSUPPORTED_BY_PLATFORM',
  ERROR_INVALID_INPUT = 'ERR_INVALID_INPUT',
  ERROR_UNAUTHORIZED_ACTION = 'ERR_UNAUTHORIZED_ACTION',
  ERROR_INVALID_REG_CODE = 'ERR_INVALID_REG_CODE',
  ERROR_USER_AUTH_FAILURE = 'ERR_USER_AUTH_FAILURE',
  ERROR_DEVICE_AUTH_FAILURE = 'ERR_DEVICE_AUTH_FAILURE',
  ERROR_CM_ALLOC_RETRY_TIMEOUT = 'ERR_CM_ALLOC_RETRY_TIMEOUT',
  ERROR_RESOURCE_NOT_FOUND = 'ERR_RESOURCE_NOT_FOUND',
  ERROR_TOKEN_EXPIRED = 'ERR_TOKEN_EXPIRED',
  ERROR_SERVICE_UNAVAILABLE = 'ERR_SERVICE_UNAVAILABLE',
  ERROR_CM_REQUEST_FAILED = 'ERR_CM_REQUEST_FAILED',
  ERROR_CM_SESSION_TIMEOUT = 'ERR_CM_SESSION_TIMEOUT',
  ERROR_DEVICE_ACCESS_DENIED = 'ERR_DEVICE_ACCESS_DENIED',
  ERROR_PLATFORM_DENIED = 'ERR_PLATFORM_DENIED',
  ERROR_UNSUPPORTED_PLATFORM = 'ERR_UNSUPPORTED_PLATFORM',
  ERROR_PROTOCOL_UNSUPPORTED_PLATFORM = 'ERR_PROTOCOL_UNSUPPORTED_PLATFORM',
  ERROR_PROTOCOL_UNSUPPORTED_PLATFORM_VERSION = 'ERR_PROTOCOL_UNSUPPORTED_PLATFORM_VERSION',
  ERROR_GET_LOCAL_REGCODE_FAILED = 'ERR_GET_LOCAL_REGCODE_FAILED',
  ERROR_UNSUPPORTED_PROTOCOL = 'ERR_UNSUPPORTED_PROTOCOL',
  ERROR_PCOIP_UNSUPPORTED_PROTOCOL_IN_REGION = 'ERR_PCOIP_UNSUPPORTED_PROTOCOL_IN_REGION',
  ERROR_PCOIP_UNSUPPORTED_ON_PLATFORM = 'ERR_PCOIP_UNSUPPORTED_ON_PLATFORM',
  ERROR_WSP_UNSUPPORTED_PROTOCOL = 'ERR_WSP_UNSUPPORTED_PROTOCOL',
  ERROR_UNSUPPORTED_RESULT_TYPE = 'ERR_UNSUPPORTED_RESULT_TYPE',
  ERROR_UNSUPPORTED_AUTH_TYPE = 'ERR_UNSUPPORTED_AUTH_TYPE',
  ERROR_UNSUPPORTED_PCOIP_FOR_SAML = 'ERR_UNSUPPORTED_PCOIP_FOR_SAML',
  ERROR_UNSUPPORTED_PCOIP_FOR_IDC = 'ERROR_UNSUPPORTED_PCOIP_FOR_IDC',
  ERROR_MISSING_PROTOCOL = 'ERR_MISSING_PROTOCOL',
  ERROR_WSP_SERVER_UNREACHABLE = 'SERVER_UNREACHABLE_CODE_13',
  ERROR_WSP_INVALID_SESSION_ID = 'INVALID_SESSION_ID_CODE_10',
  ERROR_INVALID_SESSION_ID = 'ERR_INVALID_SESSION_ID',
  ERROR_WSP_AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED_CODE_8',
  ERROR_PC_SETUP_FAILED = 'ERR_PC_SETUP_FAILED',
  ERROR_SIG_CREATE_FAILED = 'ERR_SIG_CREATE_FAILED',
  ERROR_PC_CREATE_FAILED = 'ERR_PC_CREATE_FAILED',
  ERROR_SIG_START_FAILED = 'ERR_SIG_START_FAILED',
  ERROR_SIG_FAILED = 'ERR_SIG_FAILED',
  ERROR_SIG_TIMEOUT = 'ERR_SIG_TIMEOUT',
  ERROR_PCOIP_LOADING_TIMEOUT = 'ERR_PCOIP_LOADING_TIMEOUT',
  ERROR_PC_FAILED = 'ERR_PC_FAILED',
  ERROR_CAPEX_BAD_MSG = 'ERR_CAPEX_BAD_MSG',
  ERROR_CAPEX_NO_VER = 'ERR_CAPEX_NO_VER',
  ERROR_CAPEX_BAD_VER = 'ERR_CAPEX_BAD_VER',
  ERROR_CAPEX_NO_CONFIG = 'ERR_CAPEX_NO_CONFIG',
  ERROR_CAPEX_BAD_PEER = 'ERR_CAPEX_BAD_PEER',
  ERROR_CAPEX_SELECT_FAILED = 'ERR_CAPEX_SELECT_FAILED',
  ERROR_CAPEX_APPLY_FAILED = 'ERR_CAPEX_APPLY_FAILED',
  ERROR_CAPEX_VERIFY_FAILED = 'ERR_CAPEX_VERIFY_FAILED',
  ERROR_SELF_SERVICE_RESTART = 'ERROR_SELF_SERVICE_RESTART',
  ERROR_SELF_SERVICE_REBUILD = 'ERROR_SELF_SERVICE_REBUILD',
  ERROR_SELF_SERVICE_CHANGE_COMPUTE_TYPE = 'ERROR_SELF_SERVICE_CHANGE_COMPUTE_TYPE',
  ERROR_SELF_SERVICE_INCREASE_VOLUME_SIZE = 'ERROR_SELF_SERVICE_INCREASE_VOLUME_SIZE',
  ERROR_SELF_SERVICE_SWITCH_RUNNING_MODE = 'ERROR_SELF_SERVICE_SWITCH_RUNNING_MODE',
  ERROR_UNKNOWN = 'ERR_UNKNOWN',
  ERROR_STREAMING_FAILED = 'ERR_STREAMING_FAILED',
  ERROR_WEB_MAXIBON_STREAMING_AUTH_DENIED = 'ERR_WEB_MAXIBON_STREAMING_AUTH_DENIED',
  ERROR_WEB_MAXIBON_STREAMING_AUTH_REVOKED = 'ERR_WEB_MAXIBON_STREAMING_AUTH_REVOKED',
  ERROR_WEB_MAXIBON_STREAMING_ACCESS_REJECTED = 'ERR_WEB_MAXIBON_STREAMING_AUTH_REJECTED',
  ERROR_WEB_MAXIBON_STREAMING_IDLE_TIMEOUT = 'ERR_WEB_MAXIBON_STREAMING_IDLE_TIMEOUT',
  ERROR_WEB_MAXIBON_STREAMING_OWNER_DISCONNECT = 'ERR_WEB_MAXIBON_STREAMING_OWNER_DISCONNECT',
  ERROR_WEB_MAXIBON_STREAMING_USER_DISCONNECT = 'ERR_WEB_MAXIBON_STREAMING_USER_DISCONNECT',
  ERROR_WEB_MAXIBON_STREAMING_EVICTED = 'ERR_WEB_MAXIBON_STREAMING_EVICTED',
  ERROR_WEB_MAXIBON_STREAMING_UNSUPPORTED_CREDENTIAL = 'ERR_WEB_MAXIBON_STREAMING_UNSUPPORTED_CREDENTIAL',

  ERROR_HEARTBEAT_RETRIABLE = 'ERROR_HEARTBEAT_NETWORK',
  ERROR_REQUEST_TIMEOUT = 'ERROR_REQUEST_TIMEOUT',
  ERROR_MISSING_HEARTBEAT_ENDPOINT = 'ERROR_MISSING_HEARTBEAT_ENDPOINT',
  ERROR_WARP_DRIVE_LOAD_TIMEOUT = 'ERR_WARP_DRIVE_LOAD_TIME_OUT',
  ERROR_WARP_DRIVE_UNAVAILABLE = 'ERR_WARP_DRIVE_UNAVAILABLE',
  ERROR_WARP_DRIVE_UNKNOWN = 'ERR_WARP_DRIVE_UNKNOWN',
  ERROR_FALLBACK_LOGIN_REQUIRED = 'ERR_FALLBACK_LOGIN_REQUIRED',
  ERROR_INVALID_FALLBACK_METHOD = 'ERROR_INVALID_FALLBACK_METHOD',

  ERROR_WORKSPACE_INVALID_STATE = 'ERR_WORKSPACE_INVALID_STATE',

  // For WS Pool, we'll aggregate everything in one error code and depends on client error code
  // to get user error messages. Later we'll get rid of all ws error codes and only depend on client
  // error code across the application
  ERROR_WS_POOL = 'ERR_WS_POLL',
  ERROR_SOLO_SYSTEM_BROWSER_UNAVAILABLE = 'ERROR_SOLO_SYSTEM_BROWSER_UNAVAILABLE',
}
