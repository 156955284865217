/**
 * Note: No plan to rewrite this because PCoIP is be deprecated
 *       we don't have verbose in logger module.
 *       we might not need it but I left the original code in comment.
 */
import { ILogger } from '@/bridge/ILogger';
import { getScancode } from '@views/Stream/utils';
import bowser from 'bowser';

enum InputTypes {
  RAW_MOUSE = 'RAW_MOUSE',
  RAW_KEYBOARD = 'RAW_KEYBOARD',
}

interface InputEventMessage {
  input_type: InputTypes;
  time: number;
}

interface MouseEvenMessage extends InputEventMessage {
  raw_mouse: {
    mouse_id: 0;
    flags: any;
    button_flags: any;
    buttons: 0;
    button_data: any;
    last_x?: number;
    last_y?: number;
  };
}

interface KeyboardEvenMessage extends InputEventMessage {
  raw_keyboard: {
    is_key_down: boolean;
    virtual_key: number;
    scan_code: number;
  };
}

export interface IWorkSpacesInputAdapter {
  shiftAndMetaKeysPressed?: boolean;
  keyWithMetaSaved?: boolean;
  keyWithMetaKeycode?: number;
  keyWithMetaScancode?: number;
  bindEvents: Function;
  unbindEvents: Function;
  clearModifiers: Function;
  sendKey: Function;
  setIgnoreWinKey: Function;
  setKeyboardType: Function;
}

export const WorkSpacesInputAdapter = (
  inputSource: HTMLDivElement,
  inputSink: any,
  videoSource: any,
  videoSink: HTMLVideoElement,
  logger: ILogger,
  keyboardInputSource?: any
) => {
  const TAG = 'WorkSpacesInputAdapter';
  // Flag used to enable copious logging of mouse position calculations
  const debugMouseMapping = false;
  // Flag used to enable copious logging of mouse scrolling calculations
  const debugMouseScrolling = false;
  // Flag used to enable copious logging of keyboard events
  const debugKeyboardMapping = false;
  // Flag used to enable copious logging of window events
  const debugWindowMapping = false;
  // Flag used to enable copious logging of server side resolution change events
  const debugResolutionChange = false;
  // For mouse scrolling we need to know the browser
  const browser = bowser;
  const isMac = navigator.platform.includes('Mac');

  const KEY_CODE = 'keycode';
  const SCAN_CODE = 'scancode';
  // By default ingoner Win key
  let ignoreWinKey = true;
  let keyboardType = KEY_CODE; // keycode|scancode
  const WIN_KEY_CODE = 91;
  const WIN_KEY_SCAN_CODE = 0xe05b;
  const CMD_KEY_CODE_FIREFOX = 224;
  const CAPS_LOCK = 'CapsLock';
  const PRINT_SCREEN = 'PrintScreen';
  const F13 = 'F13';

  const serverResolution = {
    width: 0,
    height: 0,
    aspect: 0,
  };

  // windows.h definitions
  const win = {
    // RAWMOUSE.usFlags definitions
    MOUSE_MOVE_ABSOLUTE: 1,

    // Three lines (one click) of wheel scrolling on Windows
    WHEEL_DELTA: 120,

    // RAWMOUSE.usButtonFlags definitions
    RI_MOUSE_LEFT_BUTTON_DOWN: 0x0001,
    RI_MOUSE_LEFT_BUTTON_UP: 0x0002,
    RI_MOUSE_MIDDLE_BUTTON_DOWN: 0x0010,
    RI_MOUSE_MIDDLE_BUTTON_UP: 0x0020,
    RI_MOUSE_RIGHT_BUTTON_DOWN: 0x0004,
    RI_MOUSE_RIGHT_BUTTON_UP: 0x0008,
    RI_MOUSE_WHEEL: 0x0400,
  };

  // Execution on construction
  if (!inputSource) {
    throw new Error('WorkSpacesInputAdapter: no inputSource given');
  }
  if (!inputSink) {
    throw new Error('WorkSpacesInputAdapter: no inputSink given');
  }
  if (!videoSource) {
    throw new Error('WorkSpacesInputAdapter: no videoSource given');
  }
  if (!videoSink) {
    throw new Error('WorkSpacesInputAdapter: no videoSink given');
  }
  if (!keyboardInputSource) {
    logger.warn(
      `${TAG} No keyboardInputSource was specified, defaulting keyboard events to inputSource.`
    );
  }

  function logRect(title: string, rect: any) {
    // TODO: verbose
    // logger.verbose(TAG,
    //     title,
    //     "left", rect.left,
    //     "top", rect.top,
    //     "width", rect.width,
    //     "height", rect.height);
    logger.info(
      `${TAG}, ${title}, left: ${rect.left}, top: ${rect.top}, width: ${rect.width}, height: ${rect.height}`
    );
  }

  // WebRTC doesn't give us direct access to the client
  // rectangle of the rendered video.  Instead we have the rectangle
  // of the element containg the rendered video and the width and
  // height of the video that is scaled to fit into that element.
  // It appears that WebRTC centers the video in the containing
  // element.  We can use this centering behavior, the aspect ratios
  // of both rectangles and the extent of the containg element to
  // calculate the client rectangle of the video.
  function getVideoClientRect() {
    const video = videoSink;

    if (video.videoWidth === 0 || video.videoHeight === 0) {
      // logger.verbose(TAG, "The videosink has 0 width or height."+
      //     " The frame is probably not ready yet. Mouse input ignored.");
      logger.info(
        `${TAG} The videosink has 0 width or height. The frame is probably not ready yet. Mouse input ignored.`
      );
      return null;
    }

    if (debugMouseMapping) {
      // logger.verbose(TAG,
      //     "getVideoClientRect():",
      //     "video.videoWidth", video.videoWidth,
      //     "video.videoHeight", video.videoHeight);
      logger.info(
        `${TAG} getVideoClientRect(): ${video.videoWidth}, ${video.videoHeight}`
      );
    }

    // Get the aspect ratio of the rendered video and the
    // aspect ratio of the element containing it.
    const videoAspect = video.videoWidth / video.videoHeight;
    const bounds = video.getBoundingClientRect();
    const boundsAspect = bounds.width / bounds.height;

    // Initialize videoRect to bounds.
    const videoRect = {
      left: bounds.left,
      top: bounds.top,
      width: bounds.width,
      height: bounds.height,
    };

    if (debugMouseMapping) {
      logRect('videoRect (bounds)', videoRect);
    }

    // Compare aspect ratios to determine whether width or height is
    // the dimension of the element containing the rendered video
    // that is limiting the size of the rendered video.  Depending
    // upon which is the limiting dimension trim "videoRect"
    // to reflect the bounding rectangle of the rendered video.
    if (boundsAspect > videoAspect) {
      videoRect.width = bounds.height * videoAspect;
      videoRect.left += (bounds.width - videoRect.width) / 2;
    } else {
      videoRect.height = bounds.width / videoAspect;
      videoRect.top += (bounds.height - videoRect.height) / 2;
    }

    if (debugMouseMapping) {
      logRect('videoRect (trimmed)', videoRect);
    }

    return videoRect;
  }

  function notifySendFailed() {
    // if (that.hasOwnProperty("onSendFailed")) {
    // that.onSendFailed();
    // } else {
    logger.warn(
      `${TAG} WorkSpacesInputAdapter.onSendFailed() callback function was not defined.`
    );
    // }
  }

  function notifyResolutionChange(newWidth: number, newHeight: number) {
    // if (that.hasOwnProperty("onResolutionChange")) {
    // that.onResolutionChange(newWidth, newHeight);
    // } else {
    logger.warn(
      `${TAG} WorkSpacesInputAdapter.onResolutionChange(newWidth=${newWidth}, newHeight=${newHeight}) callback function was not defined.`
    );
    // }
  }

  function sendMouseEvent(
    x: number,
    y: number,
    buttonFlags: any,
    buttonData?: any
  ) {
    // Get the client rect bounding the rendered video.
    const videoRect = getVideoClientRect();

    // If no videoRect we can not map mouse input.
    if (videoRect == null) {
      return;
    }

    const message: MouseEvenMessage = {
      input_type: InputTypes.RAW_MOUSE,
      time: Math.floor(window.performance.now()),
      raw_mouse: {
        mouse_id: 0,
        flags: win.MOUSE_MOVE_ABSOLUTE,
        button_flags: buttonFlags,
        buttons: 0,
        button_data: buttonData || 0,
      },
    };

    // Make the mouse coordinates relative to the upper left corner of
    // videoRect, scale them to server units, and then add in any
    // server pixel offset needed to address truncation.
    //
    // Note that we use videoSink.Video{Width|Height} here
    // for scaling instead of serverResolution.{width|height}.
    // WebRTC may have truncated the video feed.  In that case the
    // real dimensons of the video feed are found in
    // videoSink.Video{Width|Height} and they represent a
    // viewport centered upon the original feed.

    // Right now we have a bug where serverResolution could be dropped.
    // In this situation, we would like to still compute the mouse coordinates
    // without the server pixel offset, otherwise we will lose mouse input
    // completely. If the videoSink resolution matches the serverResolution,
    // this is okay. Related SIM: RTP-557
    if (serverResolution.width === 0 || serverResolution.height === 0) {
      logger.warn(
        'Have not received the serverResolution,' +
          ' mouse coordinates offset not computed. ' +
          ' The mouse coordinates is probably off if the video' +
          ' element is resized.'
      );
      serverResolution.width = videoSink.videoWidth;
      serverResolution.height = videoSink.videoHeight;
      serverResolution.aspect =
        serverResolution.width / serverResolution.height;
    }

    const offsetX = (serverResolution.width - videoSink.videoWidth) / 2;
    const offsetY = (serverResolution.height - videoSink.videoHeight) / 2;

    if (debugMouseMapping) {
      // logger.verbose(TAG, "sendMouseEvent(): offsetX", offsetX, "offsetY", offsetY);
      logger.info(
        `${TAG} sendMouseEvent(): offsetX ${offsetX} offsetY ${offsetY}`
      );
    }

    message.raw_mouse.last_x =
      (((x - videoRect.left) * videoSink.videoWidth) / videoRect.width +
        offsetX +
        0.5) |
      0;
    message.raw_mouse.last_y =
      (((y - videoRect.top) * videoSink.videoHeight) / videoRect.height +
        offsetY +
        0.5) |
      0;

    if (debugMouseMapping || debugMouseScrolling) {
      // logger.verbose(TAG,
      //     "sendMouseEvent():",
      //     "last_x", message.raw_mouse.last_x,
      //     "last_y", message.raw_mouse.last_y,
      //     "flags", message.raw_mouse.flags,
      //     "button_flags", message.raw_mouse.button_flags,
      //     "button_data", message.raw_mouse.button_data,
      //     "x", x,
      //     "y", y);
      logger.info(
        'sendMouseEvent(): ' +
          `last_x ${message.raw_mouse.last_x}` +
          `last_y ${message.raw_mouse.last_y}` +
          `flags ${message.raw_mouse.flags}` +
          `button_flags ${message.raw_mouse.button_flags}` +
          `button_data ${message.raw_mouse.button_data}` +
          `x ${x}` +
          `y ${y}`
      );
    }

    try {
      inputSink.send(message);
    } catch (ex: any) {
      // logger.verbose(
      //     TAG,
      //     "sendMouseEvent(): inputSink.send() failed: {0}\n{1}",
      //     ex.message,
      //     ex.stack);
      logger.info(
        `${TAG} sendMouseEvent(): inputSink.send() failed: ${ex.message}\n${ex.stack}`
      );
      notifySendFailed();
    }
  }

  function sendKey(key: any, down: any, scancode: any) {
    if (!scancode) {
      scancode = 0;
    }

    // firefox on OSX has another mapping for
    // the command key, Chrome treats it as Win
    if (key === CMD_KEY_CODE_FIREFOX) {
      key = WIN_KEY_CODE;
    }

    if (
      (key === WIN_KEY_CODE || scancode === WIN_KEY_SCAN_CODE) &&
      ignoreWinKey
    ) {
      // ignore Windows key; it will be grabbed
      // by the local box no matter what we do, and
      // we don't want to accidentally send a keydown
      // with no key-up, or bad things happen on the host.
      return;
    }
    const message: KeyboardEvenMessage = {
      input_type: InputTypes.RAW_KEYBOARD,
      time: Math.floor(window.performance.now()),
      raw_keyboard: {
        is_key_down: down,
        virtual_key: key,
        scan_code: scancode,
      },
    };

    try {
      inputSink.send(message);
      // on Mac we want to make sure we send an up event to
      // avoid getting stuck keys when using CMD+key combinations
      // see https://issues.amazon.com/AWS-WS-1697
      if (key === WIN_KEY_CODE && !ignoreWinKey && !down) {
        sendClearModifiers();
      }
    } catch (ex: any) {
      // logger.verbose(
      //     TAG,
      //     "sendMouseEvent(): inputSink.send() failed: {0}\n{1}",
      //     ex.message,
      //     ex.stack);
      logger.info(
        `${TAG} sendMouseEvent(): inputSink.send() failed: ${ex.message}\n${ex.stack}`
      );
      notifySendFailed();
    }
  }

  function sendClearModifiers() {
    const message: KeyboardEvenMessage = {
      input_type: InputTypes.RAW_KEYBOARD,
      time: Math.floor(window.performance.now()),
      raw_keyboard: {
        is_key_down: false,
        virtual_key: -1,
        scan_code: -1,
      },
    };

    try {
      inputSink.send(message);
    } catch (e) {
      notifySendFailed();
    }
  }

  // Convert between "button" and "which"
  function fixWhich(e: any) {
    if (!e.which && e.button) {
      if (e.button & 1) {
        e.which = 1; // Left
      } else if (e.button & 4) {
        e.which = 2; // Middle
      } else if (e.button & 2) {
        e.which = 3; // Right
      }
    }
  }

  // Overkill/cross-browser event
  // cancel.
  function cancelEvent(e: any) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.cancelBubble = true;
    e.cancel = true;
    e.returnValue = false;
    return false;
  }

  // the callback functions
  function mouseMove(event: any) {
    const e = event || window.event;
    fixWhich(e);
    sendMouseEvent(e.clientX, e.clientY, 0);
    if (debugMouseMapping) {
      // logger.verbose(TAG, "onmousemove",e);
      logger.info(`${TAG} onmousemove ${e}`);
    }
    return cancelEvent(e);
  }

  function mouseDown(event: any) {
    const e = event || window.event;
    fixWhich(e);

    // If the mouse is outside the video rect we want to ignore the mouse down
    // per RTP-656
    const videoRect = getVideoClientRect();
    if (videoRect == null) return;

    if (e.clientX < videoRect.left) {
      // Mouse outside left edge
      return cancelEvent(e);
    } else if (e.clientX > videoRect.left + videoRect.width) {
      // Mouse outside right edge
      return cancelEvent(e);
    } else if (e.clientY < videoRect.top) {
      // Mouse above the top
      return cancelEvent(e);
    } else if (e.clientY > videoRect.top + videoRect.height) {
      // Mouse below the bottom
      return cancelEvent(e);
    }

    switch (e.which) {
      default:
      case 1:
        sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_LEFT_BUTTON_DOWN);
        break;
      case 2:
        sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_MIDDLE_BUTTON_DOWN);
        break;
      case 3:
        sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_RIGHT_BUTTON_DOWN);
        break;
    }
    if (debugMouseMapping) {
      // logger.verbose(TAG, "onmousedown",e);
      logger.info(`${TAG} onmousedown ${e}`);
    }
    return cancelEvent(e);
  }

  function mouseUp(event: any) {
    const e = event || window.event;
    fixWhich(e);
    switch (e.which) {
      default:
      case 1:
        sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_LEFT_BUTTON_UP);
        break;
      case 2:
        sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_MIDDLE_BUTTON_UP);
        break;
      case 3:
        sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_RIGHT_BUTTON_UP);
        break;
    }
    if (debugMouseMapping) {
      // logger.verbose(TAG, "onmouseup",e);
      logger.info(`${TAG} onmouseup ${e}`);
    }
    return cancelEvent(e);
  }

  function mouseOut(event: any) {
    const e = event || window.event;
    fixWhich(e);
    if (debugMouseMapping) {
      // logger.verbose(TAG, "onmouseout",e);
      logger.info(`${TAG} onmouseout ${e}`);
    }
    return cancelEvent(e);
  }

  function mouseWheel(e: any) {
    // deltaY is always inverse what we want to pass through

    // OSX USB mouse gives mouse wheel values in Floating
    // point, which makes the protocol buffers message definition
    // break, so we'll round to the nearest integer
    // see https://issues.amazon.com/issues/AWS-WS-1696
    let amountToScroll = -Math.round(e.deltaY);

    // OSX deltaMode == 0 => trackpad
    // OSX deltaMode == 1 => USB mouse
    if (browser.firefox && e.deltaMode === 1) {
      // PC Firefox by default gives us lines to scroll and the size of a line is 40 "units"
      //  so we need to multiply that value by WHEEL_DELTA / 3
      amountToScroll *= win.WHEEL_DELTA / 3;
    }

    if (debugMouseScrolling) {
      // logger.verbose("DeltaY: " + e.deltaY + " amountToScroll: " + amountToScroll);
      logger.info(`DeltaY: ${e.deltaY} amountToScroll: ${amountToScroll}`);
    }
    sendMouseEvent(e.clientX, e.clientY, win.RI_MOUSE_WHEEL, amountToScroll);

    return cancelEvent(e);
  }

  function contentMenu(event: any) {
    const e = event || window.event;
    return cancelEvent(e);
  }

  // normalized keyCode map for Chrome as documented
  // in https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
  function getKeyCode(e: any) {
    let keyCode = e.keyCode;

    // overriding mismatching key codes
    switch (e.code) {
      case 'Minus':
        keyCode = 189;
        break;
      case 'Equal':
        keyCode = 187;
        break;
      case 'Semicolon':
        keyCode = 186;
        break;
      default:
        break;
    }

    return keyCode;
  }

  function keyDown(event: any) {
    const e = event || window.event;

    if (debugKeyboardMapping) {
      // logger.verbose(TAG, "keydown - key:" + e.key + " code:" + e.code);
      logger.info(`${TAG} keydown - key: ${e.key} code: ${e.code}`);
    }

    const keycode = keyboardType === KEY_CODE ? getKeyCode(e) : 0;
    const scancode = keyboardType === SCAN_CODE ? getScancode(e) : 0;

    // Fix for https://tt.amazon.com/0167045996
    // Creating a screenshot using CMD+Shift+4 leaves CMD and Shift keys in pressed state
    // Chrome on MacOS only
    if (browser.chrome && isMac) {
      if (e.metaKey && e.shiftKey) {
        adapter.shiftAndMetaKeysPressed = true;
      }
      if (!(e.metaKey && e.shiftKey) && adapter.shiftAndMetaKeysPressed) {
        sendClearModifiers();
        adapter.shiftAndMetaKeysPressed = false;
      }
    }

    // Fix for https://sim.amazon.com/issues/AWS-WS-23031
    // CMD+key combination keeps repeating key
    // root cause - keyup for key never generated by browser,
    // solution - we store the key data and send keyup event when CMD key is up
    if (isMac) {
      if (e.metaKey && e.key !== 'Meta') {
        adapter.keyWithMetaSaved = true;
        adapter.keyWithMetaKeycode = keycode;
        adapter.keyWithMetaScancode = scancode;
      } else {
        adapter.keyWithMetaSaved = false;
      }
    }

    if (e.code === CAPS_LOCK && isMac) {
      // OSX registers only one keyDown or only one keyUp
      // event depending on the state of the caps lock
      // windows triggers both as the rest of the keys
      // so we need to send an extra message for OSX
      // to avoid intermediate caps lock states
      // see - https://issues.amazon.com/issues/AWS-WS-1739
      sendKey(keycode, true, scancode);
      sendKey(keycode, false, scancode);
    } else if (e.code === PRINT_SCREEN || e.code === F13) {
      // PrintScreen/F13 registers keyUp on Windows and only keydown on Mac
      sendKey(keycode, true, scancode);
      sendKey(keycode, false, scancode);
    } else {
      sendKey(keycode, true, scancode);
    }

    return cancelEvent(e);
  }

  function keyUp(event: any) {
    const e = event || window.event;

    if (debugKeyboardMapping) {
      // logger.verbose(TAG, "keyup - key:" + e.key + " code:" + e.code);
      logger.info(`${TAG} keyup - key: ${e.key} code: ${e.code}`);
    }

    const keycode = keyboardType === KEY_CODE ? getKeyCode(e) : 0;
    const scancode = keyboardType === SCAN_CODE ? getScancode(e) : 0;

    // Fix for https://tt.amazon.com/0167045996
    // Creating a screenshot using CMD+Shift+4 leaves CMD and Shift keys in pressed state
    // Chrome on MacOS only
    if (browser.chrome && isMac) {
      if (e.metaKey || e.shiftKey) {
        adapter.shiftAndMetaKeysPressed = false;
      }
    }

    // Fix for https://sim.amazon.com/issues/AWS-WS-23031
    // CMD+key combination keeps repeating key
    // root cause - keyup for key never generated by browser,
    // solution - we store the key data and send keyup event when CMD key is up
    if (isMac) {
      if (e.key === 'Meta' && adapter.keyWithMetaSaved) {
        sendKey(adapter.keyWithMetaKeycode, false, adapter.keyWithMetaScancode);
      }
    }

    if (e.code === CAPS_LOCK && isMac) {
      // OSX registers only one keyDown or only one keyUp
      // event depending on the state of the caps lock
      // windows triggers both as the rest of the keys
      // so we need to send an extra message for OSX
      // to avoid intermediate caps lock states
      // see - https://issues.amazon.com/issues/AWS-WS-1739
      sendKey(keycode, true, scancode);
      sendKey(keycode, false, scancode);
    } else if (e.code === PRINT_SCREEN || e.code === F13) {
      // PrintScreen/F13 registers only keyUp on Windows and only keydown on Mac
      sendKey(keycode, true, scancode);
      sendKey(keycode, false, scancode);
    } else {
      sendKey(keycode, false, scancode);
    }

    return cancelEvent(e);
  }

  function blur(event: any) {
    const e = event || window.event;
    sendClearModifiers();
    if (debugKeyboardMapping) {
      logger.info(`${TAG} blur ${e}`);
    }

    return cancelEvent(e);
  }

  function focus(event: any) {
    const e = event || window.event;
    sendClearModifiers();
    if (debugKeyboardMapping) {
      logger.info(`${TAG} focus ${e}`);
    }
    return cancelEvent(e);
  }

  function windowResize(event: any) {
    const e = event || window.event;
    const width =
      window.innerWidth ||
      inputSource.ownerDocument.documentElement.clientWidth ||
      inputSource.ownerDocument.body.clientWidth;
    const height =
      window.innerHeight ||
      inputSource.ownerDocument.documentElement.clientHeight ||
      inputSource.ownerDocument.body.clientHeight;

    if (debugWindowMapping) {
      // logger.verbose(TAG, "resized", e);
      // logger.verbose(TAG, "Window height: "+height+" width: "+width);
      logger.info(`${TAG} resized ${e}`);
      logger.info(`${TAG} Window height: ${height} width: ${width}`);
    }
    return cancelEvent(e);
  }

  videoSource.onResolutionChange = function (
    newWidth: number,
    newHeight: number
  ) {
    if (debugResolutionChange) {
      // logger.verbose(TAG,
      //     "serverResolutionChange():",
      //     "newWidth", newWidth,
      //     "newHeight", newHeight);
      logger.info(
        `serverResolutionChange(): newWidth ${newWidth} newHeight ${newHeight}`
      );
    }
    serverResolution.width = newWidth;
    serverResolution.height = newHeight;
    serverResolution.aspect = newWidth / newHeight;

    notifyResolutionChange(newWidth, newHeight);
  };

  const adapter: IWorkSpacesInputAdapter = {
    bindEvents: () => {
      logger.info(`${TAG} bindEvents`);
      inputSource.addEventListener('mousemove', mouseMove);
      inputSource.addEventListener('mousedown', mouseDown);
      inputSource.addEventListener('mouseup', mouseUp);
      inputSource.addEventListener('mouseout', mouseOut);
      inputSource.addEventListener('wheel', mouseWheel);

      // Grab the context menu so it doesn't pop up over the
      // window.
      inputSource.addEventListener('contextmenu', contentMenu);

      // user of the library may decide to use a sepparate keyboard input source
      // such as a hidden text field
      if (keyboardInputSource) {
        keyboardInputSource.addEventListener('keydown', keyDown);
        keyboardInputSource.addEventListener('keyup', keyUp);
      } else {
        inputSource.addEventListener('keydown', keyDown);
        inputSource.addEventListener('keyup', keyUp);
      }

      window.addEventListener('blur', blur);
      window.addEventListener('focus', focus);
      window.addEventListener('resize', windowResize);
    },
    unbindEvents: () => {
      logger.info(`${TAG} UN-bindEvents`);
      inputSource.removeEventListener('mousemove', mouseMove);
      inputSource.removeEventListener('mousedown', mouseDown);
      inputSource.removeEventListener('mouseup', mouseUp);
      inputSource.removeEventListener('mouseout', mouseOut);
      inputSource.removeEventListener('wheel', mouseWheel);
      inputSource.removeEventListener('contextmenu', contentMenu);

      // user of the library may decide to use a sepparate keyboard input source
      // such as a hidden text field
      if (keyboardInputSource) {
        keyboardInputSource.removeEventListener('keydown', keyDown);
        keyboardInputSource.removeEventListener('keyup', keyUp);
      } else {
        inputSource.removeEventListener('keydown', keyDown);
        inputSource.removeEventListener('keyup', keyUp);
      }

      window.removeEventListener('blur', blur);
      window.removeEventListener('focus', focus);
      window.removeEventListener('resize', windowResize);
    },
    clearModifiers: () => {
      logger.info(`${TAG} clearModifiers`);
      sendClearModifiers();
    },
    sendKey: (key: any, down: any) => {
      logger.info(`${TAG} sendKey`);
      sendKey(key, down, 0);
    },
    setIgnoreWinKey: (ignore: any) => {
      ignoreWinKey = ignore;
    },
    setKeyboardType: (type: any) => {
      keyboardType = type;
    },
  };

  return adapter;
};
