import { BrandedHorizontalSplitLayout } from '@amzn/aws-euc-ui';
import HeaderComponent from '@components/Header';
import FooterComponent from '@components/Footer';
import { PropsWithChildren } from 'react';
import { useCustomTranslation } from '@/i18n';
import { useTheme } from '@/stores/theme';
import DOMPurify from 'dompurify';
import * as awsui from '@cloudscape-design/design-tokens';
import { useMediaQuery } from 'react-responsive';
import Copyright from '@/components/Copyright';
import HealthCheckStatus from '@/components/HealthCheckAlerts';
import styles from './styles.module.scss';

interface HorizontalSplitLayoutProps {
  logo?: string;
  brandDescription?: string;
  supportUrl?: string;
  isShowHealthCheckStatus?: boolean;
}

const HorizontalSplitLayout: React.FC<
  PropsWithChildren<HorizontalSplitLayoutProps>
> = ({ brandDescription, supportUrl, children, isShowHealthCheckStatus }) => {
  const { t } = useCustomTranslation();
  const backgroundImage = useTheme((state) => state.backgroundImage);
  const logo = useTheme((state) => state.logo);
  const backgroundColor = useTheme((state) => state.backgroundColor);
  const leftPanelStyle = useTheme((state) => state.leftPanelStyle);
  const fontColor = useTheme((state) => state.fontColor);
  const isMobile = useTheme((state) => state.isMobile);
  const containerStyle = {
    height: '100%',
    width: '100%',
    backgroundColor: backgroundColor ?? awsui.colorBackgroundLayoutMain,
  };
  const isBigScreen = useMediaQuery({ minWidth: 1440 });
  const brandDescriptionStyle = isBigScreen
    ? {
        fontSize: '60px',
        lineHeight: '80px',
        fontWeight: 300,
        padding: '16px 0',
        color: fontColor,
      }
    : {
        fontSize: '48px',
        lineHeight: '54px',
        fontWeight: 200,
        padding: '16px 0',
        color: fontColor,
      };

  return (
    <div style={containerStyle}>
      {isShowHealthCheckStatus && (
        <div
          tabIndex={0}
          aria-live="assertive"
          role="region"
          className={styles['notification-container']}
        >
          <HealthCheckStatus />
        </div>
      )}
      <BrandedHorizontalSplitLayout
        leftPanelStyle={leftPanelStyle}
        backgroundImageSrc={backgroundImage}
        brandLogoSrc={logo}
        brandLogoAlt={t('ws-logo-alt')}
        brandAriaLabel="Amazon WorkSpaces"
        brandAriaLive="assertive"
        brandLogoRole="navigation"
        brandTabIndex={0}
        brandLogoCrossOrign="anonymous"
        brandDescription={
          brandDescription ? (
            <div
              tabIndex={0}
              aria-live="assertive"
              style={{
                fontSize: '14px',
                lineHeight: '22px',
                padding: '16px 0',
                wordBreak: 'break-all',
                color: fontColor,
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(brandDescription),
              }}
            ></div>
          ) : (
            <div
              tabIndex={0}
              aria-live="assertive"
              aria-label={t('ws-brand-description')}
              role="region"
              style={brandDescriptionStyle}
            >
              {t('ws-brand-description')}
            </div>
          )
        }
        headerComponent={<HeaderComponent supportUrl={supportUrl} />}
        footerComponent={<FooterComponent includeCopyright={isMobile} />}
        brandHeaderComponent={
          <div
            style={{
              minHeight: '2.2rem',
              margin: '2.4rem',
            }}
          ></div>
        }
        brandFooterComponent={
          <div
            style={{
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: 400,
              margin: '2.4rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Copyright color={leftPanelStyle?.color} />
          </div>
        }
      >
        {children}
      </BrandedHorizontalSplitLayout>
    </div>
  );
};

export default HorizontalSplitLayout;
