import { SessionProtocols } from '@bridge/types/SessionTypes';

export namespace NativeInSessionError {
  export const DISCONNECT_STATUS_CODE_SEARCH_NAME = 'disconnect_status_code';

  export enum WspErrorCodes {
    Normal = 'Normal',
  }

  export enum PcoIPErrorCodes {
    UserInitiated = 'UserInitiated',
  }

  export const ProtocolToUserInitiatedErrorCodesMap = new Map<
    SessionProtocols,
    string[]
  >([
    [SessionProtocols.PCOIP, [PcoIPErrorCodes.UserInitiated, '200']],
    [SessionProtocols.MAXIBON, [WspErrorCodes.Normal, '200']],
  ]);
}
