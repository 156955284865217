import { useCustomTranslation } from '@/i18n';
import { Box, TextContent } from '@cloudscape-design/components';
import { IRegCode, useRegCodes } from '@stores/registration';
import { useState, useMemo } from 'react';
import { IRegion } from '@/bridge/types/RegionTypes';
import styles from './styles.module.scss';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import RegistrationCodeSettingsComponent from './RegistrationCodesSettings';

interface RegCodeBoxProps {
  alias: string;
  regCode: string;
  region: IRegion;
  showSettingsButton: boolean;
}

interface RegCodeBoxTheme {
  backgroundColor: string;
}

const RegCodeBoxThemeLight: RegCodeBoxTheme = {
  backgroundColor: '#F2F8FD',
};

const RegCodeBoxThemeDark: RegCodeBoxTheme = {
  backgroundColor: '#414d5c',
};

interface RegistrationCodesComponentProps {
  onBoxClick: (regCode: string) => void;
}

const RegistrationCodesComponent: React.FC<RegistrationCodesComponentProps> = ({
  onBoxClick,
}) => {
  // i18n
  const { t } = useCustomTranslation();

  const regCodeLastUsed =
    CoreFactory.getPersistentStorage().get('regCodeLastUsed');
  const getRegCodeDetails = useRegCodes((state) => state.getRegCodeDetails);
  const regCodes: IRegCode[] | undefined = useRegCodes(
    (state) => state.registrationCodes
  );

  const RegCodeBox: React.FC<RegCodeBoxProps> = ({
    alias,
    regCode,
    region,
    showSettingsButton,
  }) => {
    const [isSelected, setIsSelected] = useState(false);

    const handleBoxMouseEnter = () => {
      setIsSelected(true);
    };

    const handleBoxMouseLeave = () => {
      setIsSelected(false);
    };

    const handleClick = () => {
      onBoxClick(regCode);
      setIsSelected(true);
    };

    const handleKeywordKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (
      e
    ) => {
      if (e.key === 'Enter') {
        onBoxClick(regCode);
      }
    };

    const setRegCodeNameForBox = useMemo((): string => {
      if (!showSettingsButton) {
        return alias + ' ' + t('ws-default-title');
      }
      return alias;
    }, []);

    const mode = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? RegCodeBoxThemeDark
      : RegCodeBoxThemeLight;

    return (
      <div
        style={{
          float: 'left',
          borderRadius: '8px',
          backgroundColor: isSelected ? mode.backgroundColor : 'transparent',
          border: isSelected ? '2px solid' : '',
          borderColor: isSelected ? '#7D8998' : 'transparent',
        }}
        tabIndex={0}
        role="region"
        aria-live="assertive"
        aria-label={setRegCodeNameForBox}
        onMouseEnter={handleBoxMouseEnter}
        onMouseLeave={handleBoxMouseLeave}
        onClick={handleClick}
        onKeyDown={handleKeywordKeyPress}
      >
        <div
          key={regCode}
          style={{ float: 'left', padding: '5px', marginBottom: '2px' }}
        >
          <Box variant="awsui-key-label">
            <div>{setRegCodeNameForBox}</div>
          </Box>
          <div
            tabIndex={0}
            role="region"
            aria-live="assertive"
            aria-label={t(region?.localeKey)}
          >
            {t(region?.localeKey)}
          </div>
        </div>
        <div style={{ float: 'right', padding: '10px' }}>
          {showSettingsButton && (
            <RegistrationCodeSettingsComponent
              userSelectedRegCode={getRegCodeDetails(regCode) as IRegCode}
            ></RegistrationCodeSettingsComponent>
          )}
        </div>
      </div>
    );
  };

  const boxes = useMemo(() => {
    if (regCodes && regCodes.length > 0) {
      return [...regCodes]
        .reverse()
        .map((regCodeData) => (
          <RegCodeBox
            key={regCodeData.name}
            alias={regCodeData.alias ? regCodeData.alias : regCodeData.name}
            regCode={regCodeData.name}
            region={regCodeData.region}
            showSettingsButton={regCodeData.name !== regCodeLastUsed}
          ></RegCodeBox>
        ));
    } else {
      return (
        <TextContent>
          <p
            tabIndex={0}
            role="region"
            aria-live="assertive"
            aria-label={t('ws-no-reg-code-saved-description')}
          >
            {t('ws-no-reg-code-saved-description')}
          </p>
        </TextContent>
      );
    }
  }, [regCodes]);

  return (
    <div
      id="boxed-component"
      style={{ overflowY: 'auto', height: '300px', width: '330px' }}
      tabIndex={0}
      role="region"
      aria-live="assertive"
      aria-label={t('ws-saved-reg-codes-title')}
    >
      <Box variant="h4">{t('ws-saved-reg-codes-title')}</Box>
      <div className={styles.parent}>{boxes}</div>
    </div>
  );
};

export default RegistrationCodesComponent;
