import { useCustomTranslation } from '@/i18n';
import {
  Box,
  SpaceBetween,
  Modal,
  Button,
} from '@cloudscape-design/components';
import * as React from 'react';

interface KeepMeLoggedInModalProps {
  isVisible: boolean;
}

const KeepMeLoggedInModal: React.FC<KeepMeLoggedInModalProps> = ({
  isVisible,
}) => {
  // i18n
  const { t } = useCustomTranslation();
  const [visible, setVisible] = React.useState(isVisible);

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel={t('ws-close-label')}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => setVisible(false)}
              ariaLabel={t('ws-ok-button')}
            >
              {t('ws-ok-button')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t('ws-warning')}
    >
      {t('ws-alert-text-keep-me-logged-in')}
    </Modal>
  );
};

export default KeepMeLoggedInModal;
