import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Cookies } from 'react-cookie';
import { LngOptions } from '@/i18n';

export const DEFAULT_LNG = 'en';

export type I18nResource = Resource;

const cookie = new Cookies();

export class Localization {
  public static init(resources: Resource) {
    i18n.use(initReactI18next).init({
      resources,
      lng: cookie.get('language') ?? DEFAULT_LNG,
      fallbackLng: DEFAULT_LNG,
      interpolation: {
        escapeValue: false,
      },
    });
  }

  public static getCurrentLocale() {
    let currentLocale = DEFAULT_LNG;
    try {
      const locale = cookie.get('language');
      currentLocale = locale ? (locale as LngOptions) : currentLocale;
    } catch (error: any) {
      console.warn(`Error retrieving current locale ${error?.message}`);
    }
    return currentLocale;
  }

  public static updateLocale(locale: LngOptions) {
    console.info(`Updating current local to ${locale}`);
    cookie.set('language', locale);
  }
}
