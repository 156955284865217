export enum HealthCheckProtocol {
  TCP = 'tcp',
  UDP = 'udp',
}

// Naming the ports only based on the health checks for PCoIP/WSP WorkSpaces.
// Reference: https://docs.aws.amazon.com/workspaces/latest/adminguide/workspaces-port-requirements.html
export enum HealthCheckPorts {
  PORT_PCOIP = 4172,
  PORT_WSP = 4195,
}
