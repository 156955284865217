import ActionGroup, { ActionProps } from '@/components/ActionGroup';
import { useMemo } from 'react';
import { useCustomTranslation } from '@/i18n';

interface DisconnectComponentProps {
  regCode?: string;
  onChangeRegistrationCode: () => void;
  onFallback?: () => void;
  onBackToLoginPage: () => void;
  onReconnect: () => void;
  isShowReconnect: boolean;
  isLoading: boolean;
}

const DisconnectComponent: React.FC<DisconnectComponentProps> = ({
  regCode,
  onChangeRegistrationCode,
  onFallback,
  onBackToLoginPage,
  onReconnect,
  isShowReconnect,
  isLoading,
}) => {
  const { t } = useCustomTranslation();

  const actions = useMemo(() => {
    const actions: ActionProps[] = [];
    if (onFallback) {
      actions.push({
        id: 'fallback-button',
        onClick: onFallback,
        label: t('ws-fallback-login'),
        isLoading,
      });
    } else {
      if (isShowReconnect) {
        actions.push({
          id: 'reconnect-button',
          onClick: onReconnect,
          label: t('ws-auth-reconnect'),
          isLoading,
        });
      }
      actions.push({
        id: 'back-to-login-button',
        onClick: onBackToLoginPage,
        label: t('ws-back-login'),
        isLoading,
      });
    }
    actions.push({
      id: 'register-button',
      onClick: onChangeRegistrationCode,
      label: t('ws-new-reg-code'),
      isLoading,
    });

    return actions;
  }, [
    onFallback,
    onReconnect,
    onBackToLoginPage,
    onChangeRegistrationCode,
    isLoading,
    isShowReconnect,
  ]);

  return (
    <ActionGroup
      title={t('ws-disconnected')}
      titleDescription={`${t('ws-reg-code')}: ${regCode}`}
      actions={actions}
    />
  );
};

export default DisconnectComponent;
